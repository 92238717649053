import React from 'react';
import { View } from 'react-native';
import { HelperText, useTheme } from 'react-native-paper';
import Typography from '../../components/Typography';
import Switch from '../../components/ui/Switch';
import { useI18n } from '../../context/I18nContext';
import { ModalRowStyle } from './ConfirmationRow';

interface ConfirmationRowProps {
  balance: number | null | undefined;
  disabled?: boolean;
  currency: string | null | undefined;
  onValueChange: (v: boolean) => void;
  value: boolean;
  errorText?: string;
}

export function UseCreditsRow({ disabled, balance, currency, onValueChange, value, errorText }: ConfirmationRowProps) {
  const { colors } = useTheme();
  const { I18n, formatCurrency } = useI18n();
  const isDisabled = disabled || !balance || balance <= 0;
  return (
    <View>
      <View style={[ModalRowStyle, { justifyContent: 'space-between', flexWrap: 'wrap' }]}>
        <View>
          <Typography variant={'sub1'} color={colors.onSurfaceVariant}>
            {I18n.t('extendReservation.useCredits')}
          </Typography>
          <Typography variant={'body2'} color={colors.onSurface}>
            {I18n.t('extendReservation.currentBalance', {
              balance: formatCurrency(balance, currency),
            })}
          </Typography>
        </View>
        <Switch onValueChange={onValueChange} value={!isDisabled && value} disabled={isDisabled} />
      </View>
      <HelperText
        style={{ textAlign: 'left', paddingStart: 0 }}
        type={'error'}
        visible={disabled && errorText !== undefined}
      >
        {errorText}
      </HelperText>
    </View>
  );
}

import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { Button, Snackbar } from 'react-native-paper';
import Clipboard from '@react-native-clipboard/clipboard';

export default function CopyToClipboard({ response }: { response: string }) {
  const [copiedSnackbarVisible, setCopiedSnackbarVisible] = useState<boolean>(false);

  const handleCopyToClipboard = useCallback(async () => {
    Clipboard.setString(response);
    setCopiedSnackbarVisible(true);
  }, [response]);

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 8,
      }}
    >
      <Button disabled={!response} mode="outlined" onPress={handleCopyToClipboard}>
        {'Copy to clipboard'}
      </Button>
      <Snackbar visible={copiedSnackbarVisible} duration={3000} onDismiss={() => setCopiedSnackbarVisible(false)}>
        Copied to clipboard
      </Snackbar>
    </View>
  );
}

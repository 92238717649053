import { configureFonts, MD3DarkTheme, MD3Theme, useTheme } from 'react-native-paper';
import { loadAsync, useFonts } from 'expo-font';
import { LinearGradientProps } from 'expo-linear-gradient';

const customFonts = {
  // fonts used by react-navigation and material top navigation.
  // initialise them here so that they're loaded before navigation tabs are added
  // 'material-icons': require('../../assets/fonts/MaterialIcons-Regular.otf'),
  'material-community': require('../../assets/fonts/MaterialCommunityIcons.otf'),
  'simple-line-icons': require('../../assets/fonts/SimpleLineIcons.otf'),
  material: require('../../assets/fonts/MaterialIcons-Regular.otf'),
  // fonts used by the app. TODO consider renaming same way as above in the typography.ts file as well
  Roboto: require('../../assets/fonts/Roboto-Light.otf'),
  'Roboto-Regular': require('../../assets/fonts/Roboto-Regular.otf'),
  'RobotoCondensed-Regular': require('../../assets/fonts/RobotoCondensed-Regular.otf'),
  'Roboto-Medium': require('../../assets/fonts/Roboto-Medium.otf'),
  'Quicksand-Bold': require('../../assets/fonts/Quicksand-Bold.otf'),
  'Quicksand-SemiBold': require('../../assets/fonts/Quicksand-SemiBold.otf'),
  'Quicksand-Light': require('../../assets/fonts/Quicksand-Light.otf'),
  'Quicksand-Regular': require('../../assets/fonts/Quicksand-Regular.otf'),
  'Quicksand-Medium': require('../../assets/fonts/Quicksand-Medium.otf'),
};

/* 
Please avoid adding more unless you absolutely need to. Much 
better is to use standard theme colors, and overriding them
if needed.
*/
type CustomSpacehubTheme = {
  gradientLayer1: LinearGradientProps;
  gradientLayer2: LinearGradientProps;
  scrollViewFadeGradient: LinearGradientProps;
};

export type SpacehubTheme = MD3Theme & { custom: CustomSpacehubTheme };
export const useSpacehubTheme = () => useTheme<SpacehubTheme>();

export const useLoadAsyncForFonts = async () => {
  await loadAsync(customFonts);
};

export function useCustomFonts() {
  return useFonts(customFonts);
}

export function getPaperTheme() {
  const fontDefaults = configureFonts({
    config: {
      fontFamily: 'Quicksand-Regular',
      fontWeight: 'normal',
    },
    isV3: true,
  });
  return {
    ...MD3DarkTheme, // or MD3DarkTheme
    roundness: 4,
    colors: {
      /**
       * Colors created with https://callstack.github.io/react-native-paper/docs/guides/theming/#creating-dynamic-theme-colors
       * and they are based on the primary color
       */
      ...MD3DarkTheme.colors,
      primary: '#88ceff',
      onPrimary: '#00344d',
      primaryContainer: '#004c6e',
      onPrimaryContainer: '#c8e6ff',
      secondary: '#b7c9d9',
      onSecondary: '#21323f',
      secondaryContainer: '#384956',
      onSecondaryContainer: '#d3e5f5',
      tertiary: '#cec0e8',
      onTertiary: '#352b4b',
      tertiaryContainer: '#4b4163',
      onTertiaryContainer: '#e9ddff',
      error: '#ffb4ab',
      onError: '#690005',
      errorContainer: '#93000a',
      onErrorContainer: '#ffb4ab',
      background: '#191c1e',
      onBackground: '#e2e2e5',
      surface: '#191c1e',
      onSurface: '#e2e2e5',
      surfaceVariant: '#41474d',
      onSurfaceVariant: '#c1c7ce',
      outline: '#8b9198',
      outlineVariant: '#41474d',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#e2e2e5',
      inverseOnSurface: '#2e3133',
      inversePrimary: '#006590',
      elevation: {
        level0: 'transparent',
        level1: '#1f2529',
        level2: '#222a30',
        level3: '#253037',
        level4: '#263139',
        level5: '#29353e',
      },
      surfaceDisabled: '#e2e2e51f',
      onSurfaceDisabled: '#e2e2e561',
      backdrop: '#2b313766',
    },
    custom: {
      gradientLayer1: {
        /* Base gradient */
        colors: ['#2B6A97', '#063B61'],
        start: { x: 0.25, y: 0 },
        end: { x: 0.8, y: 1 },

        locations: [0, 1],
      },
      gradientLayer2: {
        /* A darker shade at the bottom of the element to give slight-3d effect */
        colors: ['rgba(0, 0, 0, 0.33)', 'rgba(0, 0, 0, 0)'],
        start: { x: 0.5, y: 1 },
        end: { x: 0.5, y: 0 },
        locations: [0, 1],
      },
      scrollViewFadeGradient: {
        colors: ['rgba(31, 48, 63, 0)', 'rgba(31, 48, 63, 1)'],
      },
    },

    // TODO: are these correct?
    fonts: {
      ...fontDefaults,
      labelMedium: {
        ...fontDefaults.labelMedium,
        fontSize: 16,
      },
      bodySmall: {
        ...fontDefaults.bodySmall,
        fontSize: 14,
      },
      headlineSmall: {
        ...fontDefaults.headlineSmall,
        fontSize: 17,
      },
    },
  };
}

import { View } from 'react-native';
import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  loadStripe,
  Stripe as StripeJS,
  StripeCardElement,
  StripeCardNumberElement,
  StripeCardElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardNumberElementOptions,
  StripeCardExpiryElementChangeEvent,
  StripeCardExpiryElementOptions,
  StripeCardCvcElementChangeEvent,
  StripeCardCvcElementOptions,
  StripeElements,
} from '@stripe/stripe-js';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useElements,
  useStripe as useStripeJS,
} from '@stripe/react-stripe-js';
import type {
  useStripe as useRealStripe,
  CardFieldProps,
  CardFormProps,
  PaymentSheet,
  StripeProviderProps,
  CreateTokenResult,
  RetrieveSetupIntentResult,
  PresentPaymentSheetResult,
  CardFormView,
  HandleNextActionResult,
  VerifyMicrodepositsParams,
  Token,
  PaymentMethod,
  CollectBankAccountForPaymentResult,
  CollectBankAccountForSetupResult,
  VerifyMicrodepositsForPaymentResult,
  VerifyMicrodepositsForSetupResult,
  SetupIntent,
  usePlatformPay as usePlatformPayNativeStripe,
  CanAddCardToWalletParams,
  CanAddCardToWalletResult,
  FinancialConnections,
  PlatformPay,
  PlatformPayError,
  StripeError,
  PaymentIntent,
} from '@stripe/stripe-react-native';
import { brandToNative } from './stripe-web/stripe-converters.web';
import { confirmSetupIntentWrapper } from './stripe-web/stripe-setupintent.web';
import { confirmPaymentWrapper } from './stripe-web/stripe-confirmpayment.web';
import { createPaymentMethodWrapper } from './stripe-web/stripe-paymentmethod.web';

export type StripeNative = ReturnType<typeof useRealStripe>;

export const StripeContext = React.createContext<StripeNative | undefined>(undefined);

export const useStripe = () => {
  return useContext(StripeContext);
};

// TODO: just marked all return types as any for now
export const usePlatformPay: typeof usePlatformPayNativeStripe = () => {
  return {
    loading: false,
    isPlatformPaySupported: () => Promise.resolve(false),
    confirmPlatformPaySetupIntent: () => Promise.resolve({} as any),
    confirmPlatformPayPayment: () => Promise.resolve({} as any),
    createPlatformPayPaymentMethod: () => null as any,
    createPlatformPayToken: () => null as any,
    dismissPlatformPay: () => Promise.resolve(false),
    updatePlatformPaySheet: () => Promise.resolve({} as any),
    canAddCardToWallet: () => Promise.resolve({} as any),
    openPlatformPaySetup: () => Promise.resolve(),
  };
};

export const GooglePayButton = () => null;
enum ValidationState {
  Valid = 'Valid',
  Invalid = 'Invalid',
  Incomplete = 'Incomplete',
  Unknown = 'Unknown',
}

const convert = (stripeJS: StripeJS | null, elements: StripeElements | null): StripeNative | undefined => {
  if (!stripeJS || !elements) return undefined;

  return {
    createPaymentMethod: (data: PaymentMethod.CreateParams, options?: PaymentMethod.CreateOptions) => {
      return createPaymentMethodWrapper(stripeJS, elements, data, options);
    },
    confirmSetupIntent: (
      paymentIntentClientSecret: string,
      data: SetupIntent.ConfirmParams,
      options?: SetupIntent.ConfirmOptions,
    ) => {
      return confirmSetupIntentWrapper(stripeJS, elements, paymentIntentClientSecret, data, options);
    },
    confirmPayment: (
      paymentIntentClientSecret: string,
      data?: PaymentIntent.ConfirmParams | undefined,
      options?: PaymentIntent.ConfirmOptions,
    ) => {
      return confirmPaymentWrapper(stripeJS, elements, paymentIntentClientSecret, data, options);
    },
    retrievePaymentIntent: (_clientSecret: string) => {
      throw new Error('Not implemented');
    },
    retrieveSetupIntent: (_clientSecret: string): Promise<RetrieveSetupIntentResult> => {
      throw new Error('Not implemented');
    },
    createToken: (params: Token.CreateParams): Promise<CreateTokenResult> => {
      throw new Error('Not implemented');
    },
    createTokenForCVCUpdate: (_cvc: string) => {
      throw new Error('Not implemented');
    },
    handleURLCallback: (_url: string) => {
      throw new Error('Not implemented');
    },
    /* Payment Sheet */
    confirmPaymentSheetPayment: () => {
      throw new Error('Not implemented');
    },
    presentPaymentSheet: (): Promise<PresentPaymentSheetResult> => {
      throw new Error('Not implemented');
    },
    initPaymentSheet: (_params: PaymentSheet.SetupParams) => {
      throw new Error('Not implemented');
    },
    handleNextAction: (paymentIntentClientSecret: string): Promise<HandleNextActionResult> => {
      throw new Error('Not implemented');
    },
    collectBankAccountForPayment: (
      clientSecret: string,
      params: PaymentMethod.CollectBankAccountParams,
    ): Promise<CollectBankAccountForPaymentResult> => {
      throw new Error('Not implemented');
    },
    collectBankAccountForSetup: (
      clientSecret: string,
      params: PaymentMethod.CollectBankAccountParams,
    ): Promise<CollectBankAccountForSetupResult> => {
      throw new Error('Not implemented');
    },
    verifyMicrodepositsForPayment: (
      clientSecret: string,
      params: VerifyMicrodepositsParams,
    ): Promise<VerifyMicrodepositsForPaymentResult> => {
      throw new Error('Not implemented');
    },
    verifyMicrodepositsForSetup: (
      clientSecret: string,
      params: VerifyMicrodepositsParams,
    ): Promise<VerifyMicrodepositsForSetupResult> => {
      throw new Error('Not implemented');
    },
    canAddCardToWallet(params: CanAddCardToWalletParams): Promise<CanAddCardToWalletResult> {
      throw new Error('Not implemented');
    },
    collectBankAccountToken(clientSecret: string): Promise<FinancialConnections.TokenResult> {
      throw new Error('Not implemented');
    },
    collectFinancialConnectionsAccounts(clientSecret: string): Promise<FinancialConnections.SessionResult> {
      throw new Error('Not implemented');
    },
    resetPaymentSheetCustomer(): Promise<null> {
      throw new Error('Not implemented');
    },
    isPlatformPaySupported(
      params?:
        | {
            googlePay?: PlatformPay.IsGooglePaySupportedParams | undefined;
          }
        | undefined,
    ): Promise<boolean> {
      throw new Error('Not implemented');
    },
    confirmPlatformPaySetupIntent(
      clientSecret: string,
      params: PlatformPay.ConfirmParams,
    ): Promise<PlatformPay.ConfirmSetupIntentResult> {
      throw new Error('Not implemented');
    },
    confirmPlatformPayPayment(
      clientSecret: string,
      params: PlatformPay.ConfirmParams,
    ): Promise<PlatformPay.ConfirmPaymentResult> {
      throw new Error('Not implemented');
    },
    dismissPlatformPay(): Promise<boolean> {
      throw new Error('Not implemented');
    },
    createPlatformPayPaymentMethod(params: PlatformPay.PaymentMethodParams): Promise<PlatformPay.PaymentMethodResult> {
      throw new Error('Not implemented');
    },
    createPlatformPayToken(params: PlatformPay.PaymentMethodParams): Promise<PlatformPay.TokenResult> {
      throw new Error('Not implemented');
    },
    updatePlatformPaySheet(params: {
      applePay: {
        cartItems: Array<PlatformPay.CartSummaryItem>;
        shippingMethods: Array<PlatformPay.ShippingMethod>;
        errors: Array<PlatformPay.ApplePaySheetError>;
      };
    }): Promise<{
      error?: StripeError<PlatformPayError>;
    }> {
      throw new Error('Not implemented');
    },
    openPlatformPaySetup(): Promise<void> {
      throw new Error('Not implemented');
    },
    handleNextActionForSetup(setupIntentClientSecret, returnURL) {
      throw new Error('Not implemented');
    },
  };
};

const StripeConversionProvider: FC<PropsWithChildren> = ({ children }) => {
  const stripeJS = useStripeJS();
  const elements = useElements();

  const v = useMemo(() => convert(stripeJS, elements), [stripeJS, elements]);
  return <StripeContext.Provider value={v}>{children}</StripeContext.Provider>;
};

export const StripeProvider: FC<StripeProviderProps> = ({ children, publishableKey }) => {
  const [stripePromise] = React.useState<Promise<StripeJS | null>>(loadStripe(publishableKey));
  return (
    <Elements stripe={stripePromise}>
      <StripeConversionProvider>{children}</StripeConversionProvider>
    </Elements>
  );
};
export const StripeContainer = View;

export function CardForm({ style, autofocus, cardStyle, onFormComplete }: CardFormProps) {
  // Pending getting added by stripe
  const placeholders: CardFormView.Placeholders = useMemo(() => {
    return {};
  }, []);

  const [numberElement, setNumberElement] = useState<StripeCardNumberElement>();
  // const [expiryElement, setExpiryElement] = useState<StripeCardExpiryElement>();
  // const [cvcElement, setCvcElement] = useState<StripeCardCvcElement>();

  const [numberChanged, setNumberChanged] = useState<StripeCardNumberElementChangeEvent>();
  const [expiryChanged, setExpiryChanged] = useState<StripeCardExpiryElementChangeEvent>();
  const [cvcChanged, setCvcChanged] = useState<StripeCardCvcElementChangeEvent>();

  useEffect(() => {
    if (autofocus && numberElement) {
      numberElement.focus();
    }
  }, [autofocus, numberElement]);

  useEffect(() => {
    if (onFormComplete) {
      if (numberChanged?.complete && expiryChanged?.complete && cvcChanged?.complete) {
        onFormComplete({
          brand: brandToNative(numberChanged.brand) ?? 'Unknown',
          last4: '****',
          expiryMonth: -1,
          expiryYear: -1,
          country: '',
          complete: numberChanged.complete && expiryChanged.complete && cvcChanged.complete,
        });
      }
    }
  }, [onFormComplete, numberChanged, expiryChanged?.complete, cvcChanged?.complete]);

  const opts: {
    number: StripeCardNumberElementOptions;
    expiry: StripeCardExpiryElementOptions;
    cvc: StripeCardCvcElementOptions;
  } = useMemo(() => {
    return {
      number: {
        placeholder: placeholders.number,
        style: {
          base: {
            backgroundColor: cardStyle?.backgroundColor,
          },
        },
      },
      expiry: {
        placeholder: placeholders.expiration,
        style: {
          base: {
            backgroundColor: cardStyle?.backgroundColor,
          },
        },
      },
      cvc: {
        placeholder: placeholders.cvc,
        style: {
          base: {
            backgroundColor: cardStyle?.backgroundColor,
          },
        },
      },
    };
  }, [cardStyle, placeholders]);

  return (
    <View style={style}>
      <CardNumberElement onReady={setNumberElement} onChange={setNumberChanged} options={opts.number} />
      <CardExpiryElement /* onReady={setExpiryElement} */ onChange={setExpiryChanged} options={opts.expiry} />
      <CardCvcElement /* onReady={setCvcElement} */ onChange={setCvcChanged} options={opts.cvc} />
    </View>
  );
}

export function CardField({
  onCardChange,
  onFocus,
  onBlur,
  cardStyle,
  postalCodeEnabled,
  style,
  autofocus,
}: CardFieldProps) {
  const [cardElement, setCardElement] = useState<StripeCardElement>();

  const handleOnChange = useCallback(
    (e: StripeCardElementChangeEvent) => {
      if (onCardChange) {
        onCardChange({
          brand: brandToNative(e.brand) ?? 'Unknown',
          last4: '****',
          expiryMonth: -1,
          expiryYear: -1,
          complete: e.complete,
          validExpiryDate: ValidationState.Unknown,
          validCVC: ValidationState.Unknown,
          validNumber: ValidationState.Unknown,
        });
      }
    },
    [onCardChange],
  );

  useEffect(() => {
    if (cardElement && autofocus) {
      cardElement.focus();
    }
  }, [cardElement, autofocus]);

  const onFocusHandler = useCallback(() => {
    if (onFocus) {
      // Stripe on web does not support field level focus events, so just sending CardNumber
      onFocus('CardNumber');
    }
  }, [onFocus]);

  const cardOpts = useMemo(() => {
    return {
      hidePostalCode: !postalCodeEnabled,

      style: {
        base: {
          backgroundColor: cardStyle?.backgroundColor,
          color: cardStyle?.textColor,
          fontSize: cardStyle?.fontSize ? `${cardStyle?.fontSize}px` : undefined,
          fontFamily: cardStyle?.fontFamily ? `${cardStyle?.fontFamily}, sans-serif` : undefined,
        },
        empty: {
          color: cardStyle?.placeholderColor,
        },
        invalid: {
          color: cardStyle?.textErrorColor,
        },
      },
    };
  }, [cardStyle, postalCodeEnabled]);
  return (
    <View
      style={[
        {
          backgroundColor: cardStyle?.backgroundColor,
          borderColor: cardStyle?.borderColor,
          borderWidth: cardStyle?.borderWidth,
          borderRadius: cardStyle?.borderRadius,
        },
        style,
      ]}
    >
      <CardElement
        onReady={(e) => setCardElement(e)}
        onBlur={onBlur}
        onFocus={onFocusHandler}
        options={cardOpts}
        onChange={handleOnChange}
      />
    </View>
  );
}

// The <const> bit is imortant to be able to use this for typing later
export const screenNames = <const>{
  Login: 'LoginScreen',
  ConfirmCode: 'Verification',
  EnterInvitationCode: 'EnterInvitationCode',
  Intro: 'Intro',
  Map: 'MapView',
  MapScheduleFilter: 'MapScheduleFilter',
  MapBookForLater: 'MapBookForLater',
  QRScanScreen: 'QRScanScreen',
  EnterCodeManuallyScreen: 'EnterCodeManuallyScreen',
  Signup: 'Signup',
  CompleteRegistration: 'CompleteRegistration',
  Bookings: 'Bookings',
  BookingDetail: 'BookingDetail',
  Payments: 'Payments',
  PaymentChangeBeforeCheckoutView: 'PaymentChangeBeforeCheckoutView',
  AddPaymentMethod: 'AddPaymentMethod',
  CardDetail: 'CardDetail',
  WalletDetail: 'WalletDetail',
  Help: 'Help',
  MyInfo: 'MyInfo',
  Share: 'Share',
  News: 'News',
  Home: 'Home',
  FAQ: 'FAQ',
  ToSaP: 'TermsOfServiceAndPrivacyPolicy',
  OSSLicense: 'OpenSourceSoftwareLicenses',
  Instructions: 'Instructions',
  OnlineSubPage: 'OnlineSubPage',
  UpdateName: 'UpdateName',
  UpdateNotifications: 'UpdateNotifications',
  UpdateUnits: 'UpdateUnits',
  UpdateContact: 'UpdateContact',
  Organisation: 'Organisation',
  UpdateOrganisation: 'UpdateOrganisation',
  RequestDiscount: 'RequestDiscount',
  UpdateLanguage: 'UpdateLanguage',
  AboutApp: 'AboutApp',
  AppFeedback: 'AppFeedback',
  SpaceFeedback: 'SpaceFeedback',
  WelcomeWithoutInvite: 'WelcomeWithoutInvite',
  WelcomeWithInvite: 'WelcomeWithInvite',
  AllRecentReceipts: 'AllRecentReceipts',
  CreditBalance: 'CreditBalance',
  LocationsScreen: 'LocationsScreen',
  RegionVenuesScreen: 'RegionVenuesScreen',
  SpaceInfo: 'SpaceInfo',
  SpaceDescription: 'SpaceDescription',
  SecurityDepositDialog: 'SecurityDepositDialog',
  ConfirmReservationDialog: 'ConfirmReservationDialog',
  ExtendReservationDialog: 'ExtendReservationDialog',
  EndReservationDialog: 'EndReservationDialog',
  CancelReservationDialog: 'CancelReservationDialog',
  ErrorDialog: 'ErrorDialog',
  TimezoneWarningDialog: 'TimezoneWarningDialog',
  AccountDeletion: 'AccountDeletion',
  ConfirmAccountDeletionDialog: 'ConfirmAccountDeletionDialog',
  ConfirmLogoutDialog: 'ConfirmLogoutDialog',
  ChooseStoreDialog: 'ChooseStoreDialog',
};

export const drawerScreens = <const>{
  MapStack: 'map',
  MyInfoStack: 'me',
  HelpStack: 'help',
  QRCodeStack: 'scan',
  LocationsStack: 'places',
  NewsStack: 'news',
  AdminStack: 'admin',
};

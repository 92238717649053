import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { addSeconds } from 'date-fns';
import { CARD_DATE_FMT_OPTS, styles } from './sharedCardConfig';
import { useI18n } from '../../context/I18nContext';
import { useMapViewFilter } from '../../context/MapViewFilterContext';
import { useActivePayment } from '../../payment/MapViewActivePaymentContext';
import Hr from '../../components/ui/Hr';
import GradientButton from '../../components/GradientButton';
import { screenNames } from '../../navigators/screenNames';
import { capitalizeFirstLetter, calculateSecurityDepositInCents } from '../../utils/helpers';
import { useNowMinutes } from '../../hooks/useNow';
import { useMyProfileQuery } from '../../apis/appsyncApis';
import ActivePaymentMethodRow from './PaymentMethodRow';
import BookingCardDurationSelector from './BookingCardDurationSelector';
import { PaymentMethodOption, Pod, PricingInfo } from '../../types/misc.types';
import { MapStackRouteParams } from '../../navigators/MapStackNavigator';
import { getData, InternalStorageItemKey } from '../../utils/internalStorage';
import { Pois } from '../../types/appsync-types';
import Spacer from '../../components/Spacer';
import { PlatformPayButton } from '../../payment/utils/stripe';

type Props = {
  space: Pod;
  minBookingMins: number | undefined;
  maxBookingMins: number | undefined;
  isLoading: boolean;
  estimatedPricing: PricingInfo | undefined;
};
type navprops = StackNavigationProp<MapStackRouteParams, 'MapView'>;

function PaymentButtonHelper({ space, disabled, loading }: { space: Pois; disabled: boolean; loading?: boolean }) {
  const navigation = useNavigation<navprops>();
  const myProfileQuery = useMyProfileQuery();
  const { activePaymentMethod } = useActivePayment();
  const { I18n } = useI18n();

  const payButtonOnPressHandler = useCallback(async () => {
    const creditBalance = myProfileQuery.data?.data?.getMyProfile?.creditBalance ?? undefined;
    if (activePaymentMethod || (creditBalance && creditBalance > 0)) {
      const dontShowDepositDialog: boolean = (await getData(InternalStorageItemKey.DONT_SHOW_DEPOSIT_DIALOG)) ?? false;
      const [depositCents, depositCurency] = calculateSecurityDepositInCents(
        space,
        myProfileQuery.data?.data?.getMyProfile,
      );
      if (depositCents && depositCurency && !dontShowDepositDialog) {
        navigation.navigate('SecurityDepositDialog', {
          space,
          amount: depositCents / 100,
          currency: depositCurency,
          showCheckbox: true,
          onOk: 'REPLACE_WITH_CONFIRM',
        });
      } else {
        navigation.navigate('ConfirmReservationDialog', { space });
      }
    } else {
      // If user has no active payment method and no credits,
      // ask them to add a payment method.
      navigation.navigate(screenNames.PaymentChangeBeforeCheckoutView);
    }
  }, [navigation, space, myProfileQuery.data?.data?.getMyProfile, activePaymentMethod]);

  if (activePaymentMethod && activePaymentMethod.paymentMethodType === PaymentMethodOption.GOOGLEPAY) {
    return <PlatformPayButton disabled={disabled} onPress={payButtonOnPressHandler} style={styles.payButton} />;
  }

  return (
    <GradientButton
      disabled={disabled || !activePaymentMethod}
      loading={loading}
      onPress={payButtonOnPressHandler}
      icon={'credit-card'}
    >
      {I18n.t('mapview.podInfoCard.book')}
    </GradientButton>
  );
}

export const AvailableCardContent = ({ space, minBookingMins, maxBookingMins, isLoading, estimatedPricing }: Props) => {
  const { I18n, formatRelative, formatCurrency, formatDuration, formatDateRange } = useI18n();
  const now = useNowMinutes();
  const navigation = useNavigation<navprops>();
  const { filter, onChange } = useMapViewFilter();

  const { activePaymentMethod } = useActivePayment();
  const isUnAvailable =
    minBookingMins !== undefined &&
    maxBookingMins !== undefined &&
    (filter.durationInSecond < minBookingMins * 60 || filter.durationInSecond > maxBookingMins * 60);

  return (
    <>
      <BookingCardDurationSelector
        pickerText={formatDuration(filter.durationInSecond, 'seconds')}
        pickerMin={minBookingMins}
        pickerMax={maxBookingMins}
        pickerValue={filter.durationInSecond / 60}
        pickerStep={15}
        onValueChange={(newValue) =>
          onChange({
            ...filter,
            durationInSecond: newValue * 60,
          })
        }
        caption={`${capitalizeFirstLetter(
          formatRelative(filter.date, now, space.building?.timezone, CARD_DATE_FMT_OPTS),
        )}\n${formatDateRange(
          filter.date,
          addSeconds(filter.date, filter.durationInSecond),
          'time',
          space.building?.timezone,
        )}`}
        mainText={
          isUnAvailable
            ? I18n.t('mapview.podInfoCard.unavailableTitle')
            : formatCurrency(estimatedPricing?.price, estimatedPricing?.currency)
        }
      />
      <Spacer />
      <Hr />

      <ActivePaymentMethodRow
        paymentMethod={activePaymentMethod}
        onPress={() => navigation.navigate(screenNames.PaymentChangeBeforeCheckoutView)}
      />
      <PaymentButtonHelper disabled={isUnAvailable} loading={estimatedPricing === undefined} space={space} />
    </>
  );
};

import { LocationHeadingObject, LocationObject, PermissionStatus as LocationPermissionStatus } from 'expo-location';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as ExpoLocation from 'expo-location';
import { Platform } from 'react-native';
import { useMixpanel } from '../mixpanel/MixpanelContext';

export type UserHeading = {
  bearing: number;
};

export { LocationPermissionStatus };

type UserLocationContextData = {
  locationPermissionStatus: LocationPermissionStatus;
  requestLocationPermissionAsync: (() => Promise<LocationPermissionStatus>) | null;
  location: LocationObject | undefined;
  heading: LocationHeadingObject | undefined;
};

export const UserLocationContext = createContext<UserLocationContextData>({
  location: undefined,
  heading: undefined,
  locationPermissionStatus: LocationPermissionStatus.UNDETERMINED,
  requestLocationPermissionAsync: null,
});

export const useLocation = () => {
  return useContext(UserLocationContext);
};
export const UserLocationProvider: FC<PropsWithChildren> = ({ children }) => {
  /* Instantaneous value */
  const reportedHeadingObject = useRef<LocationHeadingObject>();
  const reportedHeadingTime = useRef<number>(0);

  /* These are only changed when the change is significan enough */
  const [reportedLocation, setReportedLocation] = useState<LocationObject>();
  const [reportedHeading, setReportedHeading] = useState<LocationHeadingObject>();
  const [premissionStatus, setPermissionStatus] = useState<ExpoLocation.PermissionStatus>(
    ExpoLocation.PermissionStatus.UNDETERMINED,
  );

  /* Analytics */
  const mp = useMixpanel();

  const requestPermissionsAsync = useCallback((): Promise<ExpoLocation.PermissionStatus> => {
    if (premissionStatus === ExpoLocation.PermissionStatus.GRANTED) {
      return new Promise((resolve, reject) => resolve(premissionStatus));
    }
    /* 
    Workaround for Expo Location for Web bug:
    On web, requestForegroundPermissionsAsync triggers the dialog, but it 
    gets re-triggered when starting the positioning, so we just fake the 
    granted here, and handle rejection where locations get registered.
    */
    if (Platform.OS === 'web') {
      return new Promise((resolve, reject) => {
        setPermissionStatus(ExpoLocation.PermissionStatus.GRANTED);
        resolve(ExpoLocation.PermissionStatus.GRANTED);
      });
    }
    // console.log("LOCATION: Requesting permission");
    return ExpoLocation.requestForegroundPermissionsAsync().then((i) => {
      // console.log("LOCATION: Requested permission, got ", i.status);
      setPermissionStatus(i.status);
      return i.status;
    });
  }, [premissionStatus]);

  useEffect(() => {
    // console.log("LOCATION: Checking permission status");
    /* 
      On web, just getting the permission status prompts the
      user to accept positioning. This is not intentional, so
      we don't do it.
      */
    if (Platform.OS !== 'web') {
      ExpoLocation.getForegroundPermissionsAsync().then((i) => {
        setPermissionStatus(i.status);
      });
    }
  }, []);

  useEffect(() => {
    if (premissionStatus === ExpoLocation.PermissionStatus.GRANTED) {
      // console.log("LOCATION: Registering positioning listeners", premissionStatus);

      /* We get the position once, this is at least required on Web */
      ExpoLocation.getCurrentPositionAsync()
        .then((loc) => {
          setReportedLocation((lastLocation) => {
            // console.log("LOCATION: Got current known position of", loc);
            if (lastLocation) {
              return lastLocation;
            }
            return loc ?? undefined;
          });
        })
        .catch((error: GeolocationPositionError) => {
          if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
            setPermissionStatus(LocationPermissionStatus.DENIED);
          }
          console.error('Positioning error: ', error);
        });

      let positionSubscription: null | Promise<ExpoLocation.LocationSubscription> = null;
      let headingSubscription: null | Promise<ExpoLocation.LocationSubscription> = null;

      if (Platform.OS !== 'web') {
        /* For some reason, this doesn't seem to work on web */
        positionSubscription = ExpoLocation.watchPositionAsync(
          {
            accuracy: ExpoLocation.Accuracy.BestForNavigation,
            timeInterval: 500,
            distanceInterval: 1,
            mayShowUserSettingsDialog: false,
          },
          (loc) => {
            // console.log("LOCATION: Subscription location", loc);
            if (loc) {
              setReportedLocation(loc);
            }
          },
        );
        positionSubscription.catch((error) => {
          console.error('Position subscription failed', error);
        });
        /* Trying to watch heading on web makes things not work at all */
        headingSubscription = ExpoLocation.watchHeadingAsync((h) => {
          // console.log("Immediate heading", h);
          if (reportedHeadingObject.current) {
            const diff = Math.abs(reportedHeadingObject.current.trueHeading - h.trueHeading) % 360;
            if (diff >= 1 && diff <= 359 && reportedHeadingTime.current + 250 < Date.now()) {
              reportedHeadingObject.current = h;
              reportedHeadingTime.current = Date.now();
              setReportedHeading(h);
            }
          } else {
            reportedHeadingObject.current = h;
            setReportedHeading(h);
          }
        });
        headingSubscription.catch((error) => {
          console.error('Heading subscription failed', error);
        });
      }

      return () => {
        // console.log("LOCATION: Subscription cleanup");
        positionSubscription?.then((e) => e.remove());
        headingSubscription?.then((e) => e.remove());
      };
    }
  }, [premissionStatus]);

  useEffect(() => {
    if (reportedLocation && mp) {
      mp.registerSuperProperties({
        $latitude: reportedLocation.coords.latitude,
        $longitude: reportedLocation.coords.longitude,
        latitude: reportedLocation.coords.latitude,
        longitude: reportedLocation.coords.longitude,
        locationAccuracy: reportedLocation.coords.accuracy,
      });
    } else if (mp) {
      mp.unregisterSuperProperty('$latitude');
      mp.unregisterSuperProperty('$longitude');
      mp.unregisterSuperProperty('latitude');
      mp.unregisterSuperProperty('longitude');
      mp.unregisterSuperProperty('locationAccuracy');
    }
  }, [reportedLocation, mp]);

  useEffect(() => {
    ExpoLocation.hasServicesEnabledAsync().then((enabled) => {
      mp?.registerSuperProperties({ locationServicesEnabled: enabled });
    });
  }, [mp]);

  const value: UserLocationContextData = useMemo(() => {
    return {
      requestLocationPermissionAsync: requestPermissionsAsync,
      location: reportedLocation,
      heading: reportedHeading,
      locationPermissionStatus: premissionStatus,
    };
  }, [requestPermissionsAsync, reportedLocation, reportedHeading, premissionStatus]);

  return <UserLocationContext.Provider value={value}>{React.Children.only(children)}</UserLocationContext.Provider>;
};

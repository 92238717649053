import React, { useCallback, useEffect, useState } from 'react';
import { TextInput } from 'react-native-paper';
import { Platform, StyleSheet } from 'react-native';
import { useMutation, useQuery } from 'react-query';
import { fetchMyProfile, updateProfile } from '../../apis/appsyncApis';
import Loading from '../../components/Loading';
import useUnmount from '../../hooks/useUnmount';
import { useI18n } from '../../context/I18nContext';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import Container from '../../components/Container';
import { MutationUpdateProfileArgs } from '../../types/appsync-types';

interface UpdateNameForm {
  firstname: string;
  lastname: string;
}

const UpdateName = () => {
  const mp = useMixpanel();
  const [formValue, setFormValue] = useState<UpdateNameForm>({
    firstname: '',
    lastname: '',
  });
  const { I18n } = useI18n();
  const result = useQuery(fetchMyProfile.id, fetchMyProfile);
  const data = result.data?.data?.getMyProfile;
  const saveProfile = useMutation(
    async (form: MutationUpdateProfileArgs) => {
      await updateProfile(form);
      if (form.firstname) mp?.getPeople().set('$first_name', form.firstname);
      if (form.lastname) mp?.getPeople().set('$last_name', form.lastname);
    },
    {
      onSuccess: () => {
        result.refetch();
      },
    },
  );
  const firstname = data?.firstname;
  const lastname = data?.lastname;
  const handleChange = useCallback(
    (name: keyof UpdateNameForm) => (value: string) => {
      setFormValue((p) => ({ ...p, [name]: value }));
    },
    [],
  );
  useEffect(() => {
    setFormValue({
      firstname: firstname ?? '',
      lastname: lastname ?? '',
    });
  }, [firstname, lastname]);
  useUnmount(() => {
    saveProfile.mutate({
      firstname: formValue.firstname,
      lastname: formValue.lastname,
      organization: data!.organization,
      phonenumber: data!.phonenumber,
    });
  });
  if (!data) {
    return <Loading />;
  }
  return (
    <ScrollablePage
      summary={{
        title: I18n.t('updateName.summaryTitle'),
        icon: 'pencil',
      }}
    >
      <Container>
        <TextInput
          /* There seems to be a bug on web with autofocus happening during a react navigation transition with a fixed side bar causing glitching */
          // https://github.com/react-navigation/react-navigation/issues/9875
          autoFocus={Platform.OS === 'web' ? undefined : true}
          value={formValue.firstname}
          onChangeText={handleChange('firstname')}
          label={I18n.t('updateName.firstname')}
          style={styles.input}
          mode={'outlined'}
          autoCapitalize={'words'}
        />
        <TextInput
          value={formValue.lastname}
          onChangeText={handleChange('lastname')}
          label={I18n.t('updateName.lastname')}
          style={styles.input}
          mode={'outlined'}
          autoCapitalize={'words'}
        />
      </Container>
    </ScrollablePage>
  );
};

const styles = StyleSheet.create({
  input: {
    marginTop: 30,
  },
});

export default UpdateName;

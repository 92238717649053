import React from 'react';
import Constants from 'expo-constants';
import { Linking, Platform, View, Image, StyleSheet } from 'react-native';
import { Modal, Portal, useTheme } from 'react-native-paper';
import GradientButton from '../../components/GradientButton';
import { useI18n } from '../../context/I18nContext';
import Images from '../../../assets/images';
import SummaryHeader from '../../components/SummaryHeader';

interface ForceUpdateViewProps {
  visible: boolean;
}

const ForceUpdateView: React.FC<ForceUpdateViewProps> = ({ visible }) => {
  const { colors } = useTheme();
  const { I18n } = useI18n();

  const handleRedirect = () => {
    const opts = Constants.manifest?.extra;
    if (Platform.OS === 'android') {
      Linking.openURL(opts?.playStoreLink);
    } else {
      Linking.openURL(opts?.appStoreLink);
    }
  };

  return (
    <Portal>
      <Modal dismissable={false} visible={visible} style={[styles.container, { backgroundColor: colors.background }]}>
        <SummaryHeader
          title={I18n.t('forceUpdate.title')}
          description={I18n.t('forceUpdate.description')}
          icon={'rocket'}
        />
        <View style={styles.imageContainer}>
          <Image style={styles.image} resizeMode={'contain'} source={Images.storeIcon_512} />
        </View>
        <GradientButton
          rounded
          onPress={handleRedirect}
          icon={{
            name: Platform.OS === 'android' ? 'logo-google-playstore' : 'logo-apple-appstore',
            set: 'ion',
          }}
        >
          {Platform.OS === 'android' ? I18n.t('forceUpdate.googleStore') : I18n.t('forceUpdate.appStore')}
        </GradientButton>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    height: '100%',
    padding: 16,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: 32,
  },
  image: {
    width: 160,
    height: 160,
  },
});

export default ForceUpdateView;

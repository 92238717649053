import React from 'react';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { screenNames } from './screenNames';
import { AppbarScreenOptions, DefaultModalOptions, DefaultScreenOptions } from '../components/NavigatorAppbar';
import { useI18n } from '../context/I18nContext';
import { MapView, MapViewRouteParams } from '../screens/MapView';
import QRScanScreen, { QRScreenRouteParamsType } from '../screens/QRScanScreen';
import EnterCodeManuallyScreen from '../screens/EnterCodeManuallyScreen';
import SelectPaymentMethod from '../payment/screens/SelectPaymentMethod';
import BookingDetail, { BookingDetailRouteParams } from '../screens/BookingDetail';
import AddPaymentMethod from '../payment/screens/AddPaymentMethod';
import { SpaceInfo, SpaceInfoRouteParams } from '../screens/SpaceInfo';
import OnlinePage, { OnlinePageRouteParams } from '../screens/OnlinePage';
import { MapScreenOptions } from '../components/MapFilterAppbar';
import { SecurityDepositDialog, SecurityDepositScreenRouteParams } from '../screens/SecurityDepositDialog';
import { ErrorDialog, ErrorDialogRouteParams } from '../screens/CommonDialogs';
import {
  ConfirmReservationDialog,
  ConfirmReservationDialogRouteParams,
} from '../payment/screens/ConfirmReservationDialog';
import EndReservationDialog, { EndReservationDialogRouteParams } from '../payment/screens/EndReservationDialog';
import ExtendReservationDialog, {
  ExtendReservationDialogRouteParams,
} from '../payment/screens/ExtendReservationDialog';
import {
  CancelReservationDialog,
  CancelReservationDialogRouteParams,
} from '../payment/screens/CancelReservationDialog';
import { TimezoneWarningDialog } from '../screens/TimezoneWarningDialog';
import { ChooseStoreDialog } from '../screens/ChooseStoreDialog';

export type MapStackRouteParams = {
  [screenNames.Map]: undefined | MapViewRouteParams;
  [screenNames.MapScheduleFilter]: undefined;
  [screenNames.MapBookForLater]: undefined;
  [screenNames.AddPaymentMethod]: undefined;
  [screenNames.QRScanScreen]: QRScreenRouteParamsType;
  [screenNames.EnterCodeManuallyScreen]: QRScreenRouteParamsType;
  [screenNames.PaymentChangeBeforeCheckoutView]: undefined;
  [screenNames.BookingDetail]: BookingDetailRouteParams;
  [screenNames.SpaceInfo]: SpaceInfoRouteParams;
  [screenNames.SpaceDescription]: OnlinePageRouteParams | undefined;
  [screenNames.SecurityDepositDialog]: SecurityDepositScreenRouteParams;
  [screenNames.ConfirmReservationDialog]: ConfirmReservationDialogRouteParams;
  [screenNames.EndReservationDialog]: EndReservationDialogRouteParams;
  [screenNames.ExtendReservationDialog]: ExtendReservationDialogRouteParams;
  [screenNames.CancelReservationDialog]: CancelReservationDialogRouteParams;
  [screenNames.ErrorDialog]: ErrorDialogRouteParams;
  [screenNames.TimezoneWarningDialog]: undefined;
  [screenNames.ChooseStoreDialog]: undefined;
};

const Stack = createStackNavigator<MapStackRouteParams>();

export function MapStackNavigator() {
  const { I18n } = useI18n();
  return (
    <Stack.Navigator initialRouteName={screenNames.Map} screenOptions={MapScreenOptions}>
      <Stack.Screen name={screenNames.Map} component={MapView} options={MapScreenOptions} />
      <Stack.Screen
        name={screenNames.AddPaymentMethod}
        component={AddPaymentMethod}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('payment.addACard'),
          ...TransitionPresets.ModalSlideFromBottomIOS,
        }}
      />
      <Stack.Screen
        name={screenNames.QRScanScreen}
        component={QRScanScreen}
        options={{
          ...DefaultScreenOptions,
          title: I18n.t('mapview.scanQr.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.EnterCodeManuallyScreen}
        component={EnterCodeManuallyScreen}
        options={DefaultScreenOptions}
      />
      <Stack.Screen
        name={screenNames.PaymentChangeBeforeCheckoutView}
        component={SelectPaymentMethod}
        options={{
          ...DefaultScreenOptions,
          title: I18n.t('payment.changePayment'),
        }}
      />
      <Stack.Screen
        name={screenNames.BookingDetail}
        component={BookingDetail}
        options={{
          ...DefaultScreenOptions,
          title: I18n.t('bookings.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.SpaceInfo}
        component={SpaceInfo}
        options={{
          ...DefaultScreenOptions,
          title: I18n.t('mapview.spaceInfo.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.SpaceDescription}
        component={OnlinePage}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('mapview.spaceInfo.webpage.title'),
        }}
      />
      <Stack.Screen
        name={screenNames.SecurityDepositDialog}
        component={SecurityDepositDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen
        name={screenNames.ConfirmReservationDialog}
        component={ConfirmReservationDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen
        name={screenNames.EndReservationDialog}
        component={EndReservationDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen
        name={screenNames.ExtendReservationDialog}
        component={ExtendReservationDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen
        name={screenNames.CancelReservationDialog}
        component={CancelReservationDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen name={screenNames.ErrorDialog} component={ErrorDialog} options={DefaultModalOptions} />
      <Stack.Screen
        name={screenNames.TimezoneWarningDialog}
        component={TimezoneWarningDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen name={screenNames.ChooseStoreDialog} component={ChooseStoreDialog} options={DefaultModalOptions} />
    </Stack.Navigator>
  );
}

import React, { useCallback, useMemo, useState } from 'react';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { addDays, startOfDay } from 'date-fns';
import { useQuery, useQueryClient } from 'react-query';
import { getTimeZone } from 'react-native-localize';
import { useI18n } from '../../context/I18nContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { appsyncGraphql } from '../../apis/appsyncHelper';
import {
  BuildingBuildingStatusArgs,
  QueryGetBuildingsByRegionIdArgs,
  Reservation,
  TimeRangeInput,
} from '../../types/appsync-types';
import { AdminStackRouteParams } from '../../navigators/AdminStackNavigator';
import DateTimePickerModal from '../../components/modal/DateTimePickerModal';
import ReservationListItem from './ReservationListItem';

export type AdminReservationsForRegionRouteParams = {
  regionName: string;
  regionId: string;
};
const RESERVATIONS_FOR_REGION_QUERY_ID = 'useReservationsForRegion';
function useReservationsForRegion(regionId: string, timeInput: TimeRangeInput) {
  return useQuery([RESERVATIONS_FOR_REGION_QUERY_ID, regionId, ...Object.values(timeInput)], async () => {
    const variables: QueryGetBuildingsByRegionIdArgs & BuildingBuildingStatusArgs = {
      id: regionId,
      input: timeInput,
    };
    const query = /* GraphQL */ `
      query GetBuildingsByRegionIdQuery($id: String!, $input: TimeRangeInput) {
        getBuildingsByRegionId(id: $id) {
          spaces {
            reservations(input: $input) {
              id
              spaceId
              from
              to
              status
              credit
              paymentAmountInCents
              prevReservationId
              currency
              building {
                title
                timezone
              }
            }
          }
        }
      }
    `;
    return appsyncGraphql<'getBuildingsByRegionId'>(query, variables);
  });
}

type Props = CompositeScreenProps<
  StackScreenProps<AdminStackRouteParams, 'AdminRegionReservations'>,
  AppDrawerScreenProp
>;

export const RegionReservations: React.FC<Props> = ({ route }) => {
  const { I18n, currentLocale, formatDate } = useI18n();

  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());

  const queryClient = useQueryClient();
  const buildingsQuery = useReservationsForRegion(route.params.regionId, {
    from: startOfDay(date).toISOString(),
    to: addDays(startOfDay(date), 1).toISOString(),
  });

  const reservations = useMemo(() => {
    const res: Reservation[] = [];
    buildingsQuery.data?.data?.getBuildingsByRegionId?.forEach((building) => {
      building?.spaces?.forEach((space) => {
        space?.reservations?.forEach((reservation) => {
          if (reservation) res.push(reservation);
        });
      });
    });
    res.sort((a, b) => Date.parse(a.from!) - Date.parse(b.from!));
    return res;
  }, [buildingsQuery.data?.data?.getBuildingsByRegionId]);

  const onShowDatePicker = useCallback(() => {
    setDatePickerVisible(true);
  }, []);

  const onDateTimeConfirm = useCallback(
    (newDate: Date) => {
      setDatePickerVisible(false);
      setDate(newDate);
      queryClient.invalidateQueries(RESERVATIONS_FOR_REGION_QUERY_ID);
    },
    [queryClient],
  );

  const onDateTimeCancel = useCallback(() => {
    setDatePickerVisible(false);
  }, []);

  return (
    <>
      <ScrollablePage
        summary={{
          title: I18n.t('locations.region.summaryTitle', {
            region: route.params.regionName,
          }),
          description: I18n.t('locations.region.summaryDescription'),
          icon: 'pin',
          button: {
            label: formatDate(date, 'dateWithYear', getTimeZone()),
            onPress: onShowDatePicker,
          },
        }}
      >
        {reservations.map((res) => (
          <ReservationListItem key={res.id} reservation={res} />
        ))}
      </ScrollablePage>
      <DateTimePickerModal
        isVisible={datePickerVisible}
        mode="date"
        date={date}
        locale={currentLocale}
        onConfirm={onDateTimeConfirm}
        onCancel={onDateTimeCancel}
      />
    </>
  );
};

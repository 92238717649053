import React, { FC, useState } from 'react';
import { View } from 'react-native';
import Images from '../../../assets/images';
import { useI18n } from '../../context/I18nContext';
import useScreenSize from '../../hooks/useScreenSize';
import Slide from './Slide';

interface ImageSizes {
  leftPersonSize: { height: number; width: number };
  rightPersonSize: { height: number; width: number };
  podSize: { height: number; width: number };
}

const Slide1: FC = () => {
  const { I18n } = useI18n();
  const { windowHeight } = useScreenSize();

  // height / width
  const leftPersonAspectRatio = 347 / 217;
  const rightPersonAspectRatio = 238 / 116;
  const podAspectRatio = 280 / 217;

  const [imgSizes, setImgSizes] = useState<ImageSizes>({
    leftPersonSize: { width: 0, height: 0 },
    rightPersonSize: { width: 0, height: 0 },
    podSize: { width: 0, height: 0 },
  });

  function handleImageSizing(containerHeight: number) {
    const leftPersonHeight = containerHeight * 0.8;
    const leftPersonWidth = leftPersonHeight / leftPersonAspectRatio;
    const rightPersonHeight = containerHeight * 0.5;
    const rightPersonWidth = rightPersonHeight / rightPersonAspectRatio;
    const podHeight = containerHeight * 0.75;
    const podWidth = podHeight / podAspectRatio;

    setImgSizes({
      leftPersonSize: {
        width: leftPersonWidth,
        height: leftPersonHeight,
      },
      rightPersonSize: {
        width: rightPersonWidth,
        height: rightPersonHeight,
      },
      podSize: {
        width: podWidth,
        height: podHeight,
      },
    });
  }

  return (
    <Slide
      title={I18n.t('intro.step1Title')}
      description={I18n.t('intro.step1Description')}
      bottomAreaOnLayout={(evt) => {
        handleImageSizing(evt.nativeEvent.layout.height);
      }}
    >
      <Images.imageMask width={'100%'} style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', top: -60 }} />
      {/* Just to make sure there isn't any blank space (black color) under the mask image */}
      {windowHeight > 700 && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#1A2026',
            height: '50%',
          }}
        />
      )}
      <Images.imagePodComfort
        height={imgSizes.podSize.height}
        width={imgSizes.podSize.width}
        style={{
          position: 'absolute',
          right: '15%',
          top: -60,
          zIndex: 1,
          height: imgSizes.podSize.height,
          width: imgSizes.podSize.width,
        }}
      />
      <Images.imageLeftPersonLarge
        height={imgSizes.leftPersonSize.height}
        width={imgSizes.leftPersonSize.width}
        style={{
          position: 'absolute',
          left: 0,
          bottom: -20,
          zIndex: 2,
          height: imgSizes.leftPersonSize.height,
          width: imgSizes.leftPersonSize.width,
        }}
      />
      <Images.imageRightPerson
        height={imgSizes.rightPersonSize.height}
        width={imgSizes.rightPersonSize.width}
        style={{
          position: 'absolute',
          right: '5%',
          top: '30%',
          zIndex: 2,
          height: imgSizes.rightPersonSize.height,
          width: imgSizes.rightPersonSize.width,
        }}
      />
    </Slide>
  );
};

export default Slide1;

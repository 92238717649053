import { hasServicesEnabledAsync } from 'expo-location';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Linking, Platform } from 'react-native';
import * as RNAndroidLocationEnabler from 'react-native-android-location-enabler';
import { PermissionStatus } from 'react-native-permissions';
import { permissions } from '../components/permissions/permissions';
import { useBluetoothStatus } from '../hooks/useBluetoothStatus';

export const PERMISSION_ANDROID_FINE_LOCATION = permissions.PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION;
export const PERMISSION_ANDROID_COARSE_LOCATION = permissions.PERMISSIONS.ANDROID.ACCESS_COARSE_LOCATION;
export const PERMISSION_ANDROID_BLUETOOTH_CONNECT = permissions.PERMISSIONS.ANDROID.BLUETOOTH_CONNECT;
export const PERMISSION_ANDROID_BLUETOOTH_SCAN = permissions.PERMISSIONS.ANDROID.BLUETOOTH_SCAN;
export const PERMISSION_IOS_BLUETOOTH_PERIPHERAL = permissions.PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL;
export const PERMISSION_IOS_LOCATION_WHEN_IN_USE = permissions.PERMISSIONS.IOS.LOCATION_WHEN_IN_USE;
export const { GRANTED, BLOCKED, DENIED, LIMITED, UNAVAILABLE } = permissions.RESULTS;

type HardwareContextType = {
  isBluetoothEnabled: boolean;
  enableBluetooth: () => Promise<void>;
  isLocationServicesEnabled: boolean | undefined;
  setLocationServicesEnabled: (enable: boolean) => Promise<boolean>;
  hasBluetoothPermission: boolean;
  hasLocationPermission: boolean;
  setBluetoothPermission: () => Promise<boolean>;
  setLocationPermission: () => Promise<boolean>;
};

export const HardwareContext = React.createContext<HardwareContextType>({
  isBluetoothEnabled: false,
  isLocationServicesEnabled: undefined,
  enableBluetooth: async () => {},
  setLocationServicesEnabled: async () => false,
  hasBluetoothPermission: false,
  hasLocationPermission: false,
  setBluetoothPermission: async () => false,
  setLocationPermission: async () => false,
});

export const useHardwareStatus = () => {
  return useContext(HardwareContext);
};

export const HardwareStatusProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [locationCheckInterval, setLocationCheckInterval] = useState<number>(1000);
  const [permissionsCheckInterval, setPermissionsCheckInterval] = useState<number>(1000);
  const [isLocationEnabled, setLocationEnabled] = useState<boolean | undefined>();
  const { isBluetoothEnabled, enableBluetooth } = useBluetoothStatus();
  const [hasBluetoothPermission, setHasBluetoothPermission] = useState(
    Platform.OS === 'android' ? Platform.Version < 31 : false,
  );
  const [hasLocationPermission, setHasLocationPermission] = useState(false);

  // Poll location services status
  useEffect(() => {
    let mounted = true;
    const updateEnabled = (enabled: boolean) => {
      if (!mounted) return;
      setLocationEnabled(enabled);
      /* If location is enabled, we just check its state every 5s */
      setLocationCheckInterval(enabled ? 5000 : 1000);
    };
    let t: number;
    if (Platform.OS === 'android') {
      hasServicesEnabledAsync().then(updateEnabled);
      t = window.setInterval(() => hasServicesEnabledAsync().then(updateEnabled), locationCheckInterval);
    }
    return () => {
      mounted = false;
      clearTimeout(t);
    };
  }, [locationCheckInterval]);

  // Poll bluetooth and location permission statuses
  useEffect(() => {
    let mounted = true;
    const neededPermissionsAndroid = [
      PERMISSION_ANDROID_FINE_LOCATION,
      PERMISSION_ANDROID_COARSE_LOCATION,
      PERMISSION_ANDROID_BLUETOOTH_CONNECT,
      PERMISSION_ANDROID_BLUETOOTH_SCAN,
    ];

    const updatePermissionsStatusesAndroid = (
      result: Record<
        | 'android.permission.ACCESS_FINE_LOCATION'
        | 'android.permission.ACCESS_COARSE_LOCATION'
        | 'android.permission.BLUETOOTH_CONNECT'
        | 'android.permission.BLUETOOTH_SCAN',
        PermissionStatus
      >,
    ) => {
      if (!mounted) return;
      const locationPermissionGranted =
        result[PERMISSION_ANDROID_FINE_LOCATION] === GRANTED && result[PERMISSION_ANDROID_COARSE_LOCATION] === GRANTED;

      // TODO: verify this with device that OS is lower than Android 12.
      // Probably returns UNAVAILABLE
      const bluetoothPermissionGranted =
        result[PERMISSION_ANDROID_BLUETOOTH_CONNECT] === GRANTED &&
        result[PERMISSION_ANDROID_BLUETOOTH_SCAN] === GRANTED;
      setHasLocationPermission(locationPermissionGranted);
      // Set automatically as true if version is lower than Android 12.
      setHasBluetoothPermission(Platform.OS === 'android' && Platform.Version < 31 ? true : bluetoothPermissionGranted);

      // Is it necessary to check the status every 5sec?
      setPermissionsCheckInterval(bluetoothPermissionGranted && locationPermissionGranted ? 5000 : 1000);
    };

    const updatePermissionsStatusesIOS = (result: Record<'ios.permission.BLUETOOTH_PERIPHERAL', PermissionStatus>) => {
      if (!mounted) return;
      const bluetoothPermissionGranted = result[PERMISSION_IOS_BLUETOOTH_PERIPHERAL] === GRANTED;
      setHasBluetoothPermission(bluetoothPermissionGranted);
      setPermissionsCheckInterval(bluetoothPermissionGranted ? 5000 : 1000);
    };

    let t: number;
    if (Platform.OS === 'android') {
      permissions.checkMultiple(neededPermissionsAndroid).then(updatePermissionsStatusesAndroid);
      t = window.setInterval(
        () => permissions.checkMultiple(neededPermissionsAndroid).then(updatePermissionsStatusesAndroid),
        permissionsCheckInterval,
      );
    }

    if (Platform.OS === 'ios') {
      permissions.checkMultiple([PERMISSION_IOS_BLUETOOTH_PERIPHERAL]).then(updatePermissionsStatusesIOS);
      t = window.setInterval(
        () => permissions.checkMultiple([PERMISSION_IOS_BLUETOOTH_PERIPHERAL]).then(updatePermissionsStatusesIOS),
        permissionsCheckInterval,
      );
    }

    return () => {
      mounted = false;
      clearTimeout(t);
    };
  }, [permissionsCheckInterval]);

  const setLocationServicesEnabled = useCallback(async (): Promise<boolean> => {
    if (Platform.OS === 'android') {
      await RNAndroidLocationEnabler.promptForEnableLocationIfNeeded({
        interval: 10000,
      });
    }
    if (Platform.OS === 'ios') {
      await Linking.openSettings();
    }
    return hasServicesEnabledAsync();
  }, []);

  const setBluetoothPermission = useCallback(async (): Promise<boolean> => {
    if (Platform.OS === 'android') {
      const userResponse = await permissions.requestMultiple([
        PERMISSION_ANDROID_BLUETOOTH_CONNECT,
        PERMISSION_ANDROID_BLUETOOTH_SCAN,
      ]);

      if (
        userResponse[PERMISSION_ANDROID_BLUETOOTH_CONNECT] === GRANTED &&
        userResponse[PERMISSION_ANDROID_BLUETOOTH_SCAN] === GRANTED
      ) {
        setHasBluetoothPermission(true);
        return true;
      }
      setHasBluetoothPermission(false);
      return false;
    }

    if (Platform.OS === 'ios') {
      const userResponse = await permissions.request(PERMISSION_IOS_BLUETOOTH_PERIPHERAL);
      if (userResponse === GRANTED) {
        setHasBluetoothPermission(true);
        return true;
      }
      if (userResponse === BLOCKED) {
        // Redirect user to settings and let the permission polling handle the rest
        await Linking.openSettings();
        return false;
      }
      return false;
    }

    // Fallback for web
    return true;
  }, []);

  const setLocationPermission = useCallback(async (): Promise<boolean> => {
    if (Platform.OS === 'android') {
      const userResponse = await permissions.requestMultiple([
        PERMISSION_ANDROID_COARSE_LOCATION,
        PERMISSION_ANDROID_FINE_LOCATION,
      ]);

      if (
        userResponse[PERMISSION_ANDROID_COARSE_LOCATION] === GRANTED &&
        userResponse[PERMISSION_ANDROID_FINE_LOCATION] === GRANTED
      ) {
        setHasLocationPermission(true);
        return true;
      }

      if (
        userResponse[PERMISSION_ANDROID_COARSE_LOCATION] === BLOCKED &&
        userResponse[PERMISSION_ANDROID_FINE_LOCATION] === BLOCKED
      ) {
        // Redirect user to settings and let the permission polling handle the rest
        await Linking.openSettings();
        return false;
      }

      setHasLocationPermission(false);
      return false;
    }

    // TODO: implement iOS location permission when we need it. (currently these are only used in Bitwards impl.)
    return true;
  }, []);

  const ctxValues = useMemo(
    () => ({
      isBluetoothEnabled,
      enableBluetooth,
      setLocationServicesEnabled,
      isLocationServicesEnabled: isLocationEnabled,
      hasBluetoothPermission,
      hasLocationPermission,
      setBluetoothPermission,
      setLocationPermission,
    }),
    [
      isBluetoothEnabled,
      isLocationEnabled,
      enableBluetooth,
      setLocationServicesEnabled,
      hasBluetoothPermission,
      hasLocationPermission,
      setBluetoothPermission,
      setLocationPermission,
    ],
  );
  return <HardwareContext.Provider value={ctxValues}>{children}</HardwareContext.Provider>;
};

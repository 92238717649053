import React, { useCallback } from 'react';
import { Appbar, FAB, useTheme } from 'react-native-paper';
import { StackHeaderProps, StackNavigationOptions, TransitionPresets } from '@react-navigation/stack';
import { useWindowDimensions, View } from 'react-native';
import { DrawerActions } from '@react-navigation/core';
import { SafeAreaView } from 'react-native-safe-area-context';
import { uiIconSourceGenerator } from './SimpleListIcon';

function ButtonStackNavigatorHeader(props: StackHeaderProps) {
  const { navigation, back } = props;
  const { width } = useWindowDimensions();
  const showBackButton = !!back;
  const showMenuButton = !showBackButton && width <= 600;
  const { colors } = useTheme();

  if (showBackButton) {
    return (
      <SafeAreaView>
        <View style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
          <FAB
            small={true}
            icon={uiIconSourceGenerator({ name: 'arrow-left', size: 21, color: colors.onBackground })}
            onPress={navigation.goBack}
            style={{ margin: 8, backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
            animated={false}
          />
        </View>
      </SafeAreaView>
    );
  }
  if (showMenuButton) {
    return (
      <SafeAreaView>
        <View style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
          <FAB
            small={true}
            icon={uiIconSourceGenerator({ name: 'menu', size: 21, color: colors.onBackground })}
            onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            style={{ margin: 8, backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
            animated={false}
          />
        </View>
      </SafeAreaView>
    );
  }
  return null;
}

function AppbarStackNavigatorHeader(props: StackHeaderProps) {
  const { navigation, options, back } = props;
  const { width } = useWindowDimensions();
  const { colors } = useTheme();

  const renderAction = useCallback(() => {
    const showBackButton = !!back;
    const showMenuButton = !showBackButton && width <= 600;

    if (options.headerLeft) {
      return <View>{options.headerLeft({})}</View>;
    }
    if (showBackButton) {
      return (
        <Appbar.Action
          animated={false}
          accessibilityRole={'button'}
          icon={uiIconSourceGenerator({ name: 'arrow-left', size: 16 })}
          onPress={navigation.goBack}
        />
      );
    }
    if (showMenuButton) {
      return (
        <Appbar.Action
          animated={false}
          accessibilityRole={'button'}
          icon={uiIconSourceGenerator({ name: 'menu', size: 16 })}
          onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        />
      );
    }
    return null;
  }, [back, navigation, options, width]);

  return (
    <Appbar.Header style={{ elevation: 0, backgroundColor: colors.surface }} accessibilityRole={'header'}>
      {/* Custom HeaderLeft or Menu or back button button */}
      {renderAction()}
      <Appbar.Content title={options.title} subtitle={(options as any).subtitle} />
      {options.headerRight && <View>{options.headerRight({})}</View>}
    </Appbar.Header>
  );
}

export const AppbarScreenOptions: StackNavigationOptions = {
  header: (props: StackHeaderProps) => <AppbarStackNavigatorHeader {...props} />,
  animationEnabled: true,
  headerTransparent: false,
  presentation: 'card',
  headerMode: 'screen',
  ...TransitionPresets.SlideFromRightIOS, // Force iOS styled animation
};

export const DefaultScreenOptions: StackNavigationOptions = {
  header: (props: StackHeaderProps) => <ButtonStackNavigatorHeader {...props} />,
  animationEnabled: true,
  headerTransparent: true,
  presentation: 'card',
  headerMode: 'screen',
  ...TransitionPresets.SlideFromRightIOS, // Force iOS styled animation
};

export const DefaultModalOptions: StackNavigationOptions = {
  headerShown: false,
  animationEnabled: true,
  presentation: 'transparentModal',
  ...TransitionPresets.ModalFadeTransition,
};

import React, { useCallback, useMemo, useState } from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormikHelpers } from 'formik/dist/types';
import Container from '../../components/Container';
import FormikInput from '../../components/formik/FormikInput';
import { useModal } from '../../context/ModalContext';
import * as adminApi from '../../apis/adminApis';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AdminConfirmModalContent, RawAdminCommandOutput, SendAdminCommandButton } from './AdminTools';

interface RequestForm {
  phone: string;
}

export default function DeleteUser({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const [initForm] = useState<RequestForm>({
    phone: '',
  });
  const [request, setRequest] = useState<Promise<any>>();

  const modal = useModal();

  const validateSchema = useMemo(() => {
    return yup.object().shape({
      phone: yup.string().required(),
    });
  }, []);

  const handleAction = useCallback(
    (form: RequestForm) => {
      const p = adminApi.userDelete(form.phone);
      setRequest(p);
      p.finally(() => modal.closeModal());
      return p;
    },
    [modal],
  );

  const handleSubmitForm = useCallback(
    async (form: RequestForm, formikHelper: FormikHelpers<RequestForm>) => {
      modal.openModal({
        content: <AdminConfirmModalContent onDismiss={() => modal.closeModal()} onConfirm={() => handleAction(form)} />,
      });
    },
    [modal, handleAction],
  );

  return (
    <ScrollablePage
      summary={{
        title: 'Delete user account',
        description: 'Delete user account from our system.',
        icon: 'pencil',
      }}
    >
      <Container>
        <Formik initialValues={initForm} onSubmit={handleSubmitForm} validationSchema={validateSchema}>
          {({ values, handleChange, handleSubmit, isSubmitting }) => (
            <>
              <FormikInput
                name={'phone'}
                mode="outlined"
                keyboardType={'phone-pad'}
                autoComplete="off"
                autoCapitalize="sentences"
                label={'Phone number (e.g. +12345123)'}
              />

              <SendAdminCommandButton handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
            </>
          )}
        </Formik>
        <RawAdminCommandOutput request={request} />
      </Container>
    </ScrollablePage>
  );
}

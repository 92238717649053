import React from 'react';
import { View } from 'react-native';
import { IconButton, useTheme } from 'react-native-paper';
import Typography from '../../components/Typography';
import { useI18n } from '../../context/I18nContext';
import { PaymentMethodOption } from '../../types/misc.types';
import { CardPaymentMethod, useActivePayment } from '../MapViewActivePaymentContext';
import { ModalRowStyle } from './ConfirmationRow';
import { uiIconSourceGenerator } from '../../components/SimpleListIcon';

type AmountDueRowProps = {
  canUserMakeBooking: boolean;
  securityDeposit?: number;
  moneyToSpend: number | undefined;
  currency: string | null | undefined;
  cardInfo?: { brand?: string | null; last4?: string | null } | null;
  onInfoPressed?: () => any;
  coveredByLegacyHold?: boolean | null;
};

export function AmountDueRow({
  canUserMakeBooking,
  securityDeposit,
  moneyToSpend,
  currency,
  onInfoPressed,
  coveredByLegacyHold,
}: AmountDueRowProps) {
  const { colors } = useTheme();
  const { I18n, formatCurrency } = useI18n();
  const { activePaymentMethod } = useActivePayment();

  const fromPaymentMethodText = (() => {
    if (!activePaymentMethod) {
      return I18n.t('extendReservation.fromNone');
    }
    const { paymentMethodType } = activePaymentMethod;
    if (paymentMethodType === PaymentMethodOption.CARD) {
      const { stripeCard } = activePaymentMethod as CardPaymentMethod;
      return I18n.t('extendReservation.fromCard', {
        cardType: stripeCard.brand!.toLocaleUpperCase(),
        last4: stripeCard.last4,
      });
    }
    if (paymentMethodType === PaymentMethodOption.GOOGLEPAY) {
      return I18n.t('extendReservation.fromGooglePay');
    }
    return I18n.t('extendReservation.fromUnknownSource');
  })();

  return (
    <View style={[ModalRowStyle, { flexDirection: 'column', alignItems: 'stretch' }]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Amount due */}
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant={'sub1'} color={colors.onSurfaceVariant}>
              {I18n.t('mapview.modalConfirmation.amountDue')}
            </Typography>
            {onInfoPressed ? (
              <IconButton icon={uiIconSourceGenerator({ name: 'info' })} onPress={onInfoPressed} />
            ) : null}
          </View>
        </View>

        <View style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography variant={'h6'} color={canUserMakeBooking ? colors.onSurfaceVariant : colors.error}>
            {formatCurrency(moneyToSpend, currency)}
          </Typography>
        </View>
      </View>
      <View style={{ display: 'flex', alignItems: 'flex-end' }}>
        {securityDeposit && securityDeposit > 0 ? (
          <Typography variant="body2">
            {I18n.t('mapview.modalConfirmation.deposit', {
              amount: formatCurrency(securityDeposit, currency),
            })}
          </Typography>
        ) : null}
        <Typography variant="body2">{fromPaymentMethodText}</Typography>
        <Typography variant="body2">
          {moneyToSpend && moneyToSpend > 0 && coveredByLegacyHold ? I18n.t('extendReservation.coveredByHold') : null}
        </Typography>
      </View>
    </View>
  );
}

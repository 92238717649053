import React, { useCallback, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormikHelpers } from 'formik/dist/types';
import { useI18n } from '../context/I18nContext';
import GradientButton from '../components/GradientButton';
import Spacer from '../components/Spacer';
import Radio from '../components/ui/Radio';
import FormikInput from '../components/formik/FormikInput';
import { sendSpaceFeedback } from '../apis/feedbackApis';
import { useModal } from '../context/ModalContext';
import { useMyProfileQuery } from '../apis/appsyncApis';
import TextModal, { ModalAlertType } from '../components/modal/TextModal';
import ScrollablePage from '../components/ui/ScrollablePage';
import { useMixpanel } from '../mixpanel/MixpanelContext';

import Container from '../components/Container';

interface SpaceFeedbackForm {
  building: string;
  spaceId: string;
  feedback: string;
  email: string;
  type: 'contact' | 'anonymously';
}

export default function SpaceFeedback({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const fetchMyProfileQuery = useMyProfileQuery();
  const mp = useMixpanel();

  const [initForm] = useState<SpaceFeedbackForm>({
    building: '',
    feedback: '',
    spaceId: '',
    type: 'contact',
    email: '',
  });

  const { I18n } = useI18n();
  const modal = useModal();

  const validateSchema = useMemo(() => {
    return yup.object().shape({
      feedback: yup.string().required(I18n.t('help.spaceFeedback.validate.feedbackRequire')),
      building: yup.string().required(I18n.t('help.spaceFeedback.validate.buildingRequired')),
      spaceId: yup.string(),
      email: yup.string(),
      type: yup.string(),
    });
  }, [I18n]);

  // TODOs:
  // - Send button doesn't look like figma
  const handleSubmitForm = useCallback(
    async (form: SpaceFeedbackForm, formikHelper: FormikHelpers<SpaceFeedbackForm>) => {
      const myProfile = fetchMyProfileQuery.data?.data?.getMyProfile;
      const name = `${myProfile?.firstname ?? ''} ${myProfile?.lastname ?? ''}`;

      try {
        await sendSpaceFeedback(
          I18n.t('help.spaceFeedback.emailSubject'),
          form.feedback,
          form.building,
          form.spaceId,
          form.type,
          form.email,
          name,
        );
        mp?.track('Send feedback', { ...form });
        formikHelper.setSubmitting(false);
        modal.openModal({
          content: (
            <TextModal
              icon={'check'}
              title={I18n.t('help.spaceFeedback.success')}
              description={''}
              buttons={[
                {
                  title: I18n.t('general.dismiss'),
                  onPress: () => {
                    modal.closeModal();
                    navigation.goBack();
                  },
                },
              ]}
            />
          ),
        });
      } catch (e) {
        modal.openModal({
          content: (
            <TextModal
              type={ModalAlertType.ERROR}
              title={I18n.t('general.requestFailedTitle')}
              description={I18n.t('general.requestFailedMsg')}
              buttons={[{ title: I18n.t('general.dismiss'), onPress: modal.closeModal }]}
            />
          ),
        });
        formikHelper.setSubmitting(false);
      }
    },
    [I18n, navigation, modal, fetchMyProfileQuery, mp],
  );
  return (
    <ScrollablePage
      summary={{
        title: I18n.t('help.spaceFeedback.summaryTitle'),
        description: I18n.t('help.spaceFeedback.summaryDescription'),
        icon: 'note',
      }}
    >
      <Formik initialValues={initForm} onSubmit={handleSubmitForm} validationSchema={validateSchema}>
        {({ values, handleChange, handleSubmit, isSubmitting, touched, errors }) => (
          <Container>
            <Spacer />
            <FormikInput
              autoFocus={Platform.OS === 'web' ? undefined : true}
              name={'building'}
              autoCapitalize={'words'}
              mode="outlined"
              label={I18n.t('help.spaceFeedback.venue')}
            />
            <Spacer />
            <FormikInput
              name={'spaceId'}
              mode="outlined"
              keyboardType={'numeric'}
              label={I18n.t('help.spaceFeedback.spaceId')}
              placeholder="100001"
            />

            <Spacer />
            <FormikInput
              name="feedback"
              autoCapitalize={'sentences'}
              value={values.feedback}
              mode="outlined"
              label={I18n.t('help.appFeedback.freeFeedback')}
              multiline={true}
              style={{ height: 300, textAlignVertical: 'top' }}
            />
            <Spacer />
            <FormikInput
              name={'email'}
              mode="outlined"
              keyboardType="email-address"
              autoComplete="email"
              autoCapitalize="none"
              label={I18n.t('help.spaceFeedback.email')}
              disabled={values.type !== 'contact'}
              value={values.type !== 'contact' ? '' : undefined} // Hide value from UI if sending anonymously
            />

            <Spacer type="section" />

            <RadioButton.Group onValueChange={handleChange('type')} value={values.type}>
              <Radio label={I18n.t('help.spaceFeedback.allowContacting')} value="contact" />

              <Spacer />
              <Radio label={I18n.t('help.spaceFeedback.sendAnonymously')} value="anonymously" />
            </RadioButton.Group>

            <Spacer type="section" />
            <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
              <GradientButton
                onPress={() => handleSubmit()}
                disabled={isSubmitting}
                loading={isSubmitting}
                icon={'paper-plane'}
              >
                {I18n.t('help.appFeedback.sendButton')}
              </GradientButton>
              <Spacer type="section" />
            </View>
          </Container>
        )}
      </Formik>
    </ScrollablePage>
  );
}

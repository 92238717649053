import type maplibregl from 'maplibre-gl';
import type { Expression, GeoJSONSource, MapboxGeoJSONFeature } from 'maplibre-gl';
import { GJIndoorProperties, MarkerGenerator, MarkerGJFeature, StandardClusterProperties } from '../MarkerCluster';

import { EnrichedMapEvent } from './ClickMonitor';
import { MapWithMovementControls } from './MovementHandling';

export type MarkerData<T> = {
  id: number;
  lastSeen: number;
  location: [number, number];
  marker: maplibregl.Marker;
  onUpdateVars: ((vars: T) => void) | null;
};

export type ClusterData<T> = MarkerData<T> & {
  expansionZ: number;
  markerChildren: Set<number>;
  clusterChildren: Set<number>;
};

type MarkerClusterLayerOptions<V, P extends GJIndoorProperties, C> = {
  onClick: ((e: EnrichedMapEvent) => void) | null;
  markerGenerator: MarkerGenerator<V, P, C> | null;
  centerOnSelect: boolean;
  zoomLevelOnSelect: number | null;
};

export type MarkerClusterMap<T, P extends GJIndoorProperties, C> = {
  steerpath: {
    markercluster: {
      add: (layer: string, clusterProperties: Record<string, Expression> | null, clusterRadius: number | null) => void;
      setCenterOnClick: (layer: string, enabled: boolean, zoom: null | number) => void;
      setClickHandler: (layer: string, handler: null | ((e: EnrichedMapEvent) => void)) => void;
      setMarkerVars: (layer: string, vars: T) => void;
      setMarkerGenerator: (layer: string, gen: MarkerGenerator<T, P, C>) => void;
      setData: (layer: string, data: any) => void;
      remove: (layer: string) => void;
      _zoom: { startZoom: number; prevZoom: number; startTime: number; cleanups: number; isExpanding: boolean };
      _layers: Record<
        string,
        {
          opts: MarkerClusterLayerOptions<T, P, C>;
          generatorVars: unknown;
          markers: Record<number, MarkerData<T>>;
          clusters: Record<number, ClusterData<T>>;
        }
      >;
    };
  };
} & maplibregl.Map;

type GJPropsInternal = {
  id: number;
  cluster: false;
  _original_point_lat: number;
  _original_point_lon: number;
};

export function initMarkerClusterMethods(vars: {}, basicMap: maplibregl.Map, lib: typeof maplibregl, win: Window) {
  const ANIMATION_DURATION_MS = 200;
  const map = basicMap as MarkerClusterMap<unknown, GJIndoorProperties, unknown>;
  if (!map.steerpath) map.steerpath = {} as any;
  if (!map.steerpath.markercluster)
    map.steerpath.markercluster = {
      add: undefined as any,
      setCenterOnClick: undefined as any,
      setClickHandler: undefined as any,
      setMarkerVars: undefined as any,
      setMarkerGenerator: undefined as any,
      setData: undefined as any,
      remove: undefined as any,
      _layers: {},
      _zoom: {
        startZoom: map.getZoom(),
        prevZoom: map.getZoom(),
        startTime: Date.now(),
        cleanups: 0,
        isExpanding: false,
      },
    };

  if (map.steerpath.markercluster.add !== undefined) return;

  /* Internal functions */

  function locationAnimator(
    m: maplibregl.Marker,
    start: [number, number],
    destination: [number, number],
    duration: number,
    completion?: () => void,
  ) {
    const startTime = performance.now();
    const f = (timestamp: number) => {
      const elapsed = timestamp - startTime;
      const percent = Math.min(1, elapsed / duration);
      if (percent < 1) {
        const newLon = start[0] + percent * (destination[0] - start[0]);
        const newLat = start[1] + percent * (destination[1] - start[1]);
        m.setLngLat([newLon, newLat]);
        requestAnimationFrame(f);
      } else {
        m.setLngLat(destination);
        if (completion) {
          completion();
        }
      }
    };
    return f;
  }

  function addNewMarker(
    layer: string,
    newMarkerGJ: MarkerGJFeature<GJPropsInternal>,
    startLocation?: [number, number],
  ) {
    const { markers, opts, generatorVars } = map.steerpath.markercluster._layers[layer];
    let m: maplibregl.Marker;
    let varUpdateCb: ((s: unknown) => void) | null;
    if (opts.markerGenerator) {
      const markerElement = opts.markerGenerator(newMarkerGJ, win.document, generatorVars);
      m = new lib.Marker(markerElement.html, markerElement.options);
      varUpdateCb = markerElement.onStateUpdate;
    } else {
      m = new lib.Marker({ color: '#0f0' });
      varUpdateCb = null;
    }

    m.getElement().onclick = (ev) => clickHandler(layer, ev, newMarkerGJ);
    const lat = newMarkerGJ.properties!._original_point_lat;
    const lon = newMarkerGJ.properties!._original_point_lon;
    const loc: [number, number] = [lon, lat];

    const markerData: MarkerData<unknown> = {
      id: newMarkerGJ.id as number,
      lastSeen: Date.now() + (startLocation ? ANIMATION_DURATION_MS : 0),
      location: loc,
      marker: m,
      onUpdateVars: varUpdateCb,
    };

    if (startLocation) {
      m.setLngLat(startLocation);
      requestAnimationFrame(locationAnimator(m, startLocation, loc, ANIMATION_DURATION_MS));
    } else {
      m.setLngLat(loc);
    }
    markers[newMarkerGJ.id as number] = markerData;
    m.addTo(map);
  }

  function addNewCluster(
    layer: string,
    newClusterGJ: MarkerGJFeature<StandardClusterProperties>,
    implodeChildren?: boolean,
    animateFromLocation?: [number, number],
    onComplete?: () => void,
  ) {
    const { clusters, opts, generatorVars } = map.steerpath.markercluster._layers[layer];
    const src = map.getSource(layer + '-data') as GeoJSONSource;

    let m: maplibregl.Marker;
    let htmlElement: HTMLElement | undefined;
    let varUpdateCb: ((s: unknown) => void) | null;
    if (opts.markerGenerator) {
      const markerElement = opts.markerGenerator(newClusterGJ, win.document, generatorVars);
      htmlElement = markerElement.html;
      m = new lib.Marker(markerElement.html, markerElement.options);
      varUpdateCb = markerElement.onStateUpdate;
    } else {
      m = new lib.Marker({ color: '#f00' });
      varUpdateCb = null;
    }

    m.getElement().onclick = (ev) => clickHandler(layer, ev, newClusterGJ);
    const loc = (newClusterGJ.geometry as GeoJSON.Point).coordinates as [number, number];

    const clusterData: ClusterData<unknown> = {
      id: newClusterGJ.properties!.cluster_id as number,
      lastSeen: Date.now() + (animateFromLocation ? ANIMATION_DURATION_MS : 0),
      location: loc,
      expansionZ: Infinity,
      marker: m,
      markerChildren: new Set<number>(),
      clusterChildren: new Set<number>(),
      onUpdateVars: varUpdateCb,
    };
    if (clusters[clusterData.id as number]) {
      console.error('Duplicate cluster detected. Ignoring.');
      return;
    }
    if (animateFromLocation) {
      m.setLngLat(animateFromLocation);
      requestAnimationFrame(locationAnimator(m, animateFromLocation, loc, ANIMATION_DURATION_MS));
    } else {
      m.setLngLat(loc);
    }

    clusters[clusterData.id as number] = clusterData;

    // If we should implode children first, we add this with opacity of 0.
    if (implodeChildren && htmlElement) {
      htmlElement.style.opacity = '0';
    }
    // console.log('Added cluster', clusterData.id);
    m.addTo(map);

    const populateChildren = (clusterId: number, depth: number, maxKids: number) => {
      src.getClusterChildren(clusterId, (err, children) => {
        let didGoDeeper = false;
        if (children) {
          children.forEach((child) => {
            if (child.properties?.cluster_id) {
              clusterData.clusterChildren.add(child.properties?.cluster_id);
              if (depth && clusterData.clusterChildren.size < maxKids) {
                populateChildren(child.properties?.cluster_id, depth - 1, maxKids);
                didGoDeeper = true;
              }
            } else {
              clusterData.markerChildren.add(child.id as number);
            }
          });
        } else {
          console.error(err);
        }
        if (!didGoDeeper) {
          if (implodeChildren) {
            animateChildrenBecomingCluster(layer, loc, clusterData.clusterChildren, clusterData.markerChildren, () => {
              if (htmlElement) htmlElement.style.opacity = '1';
              if (onComplete) onComplete();
            });
          } else if (onComplete) {
            onComplete();
          }
        }
      });
    };
    populateChildren(clusterData.id, 10, 10);
    // console.log(`Querying expansion zoom ${clusterData.id}`);
    src.getClusterExpansionZoom(clusterData.id, (_err, expansionZoom) => {
      // console.log(`Cluster ${clusterData.id} expands at ${expansionZoom}, currently ${map.getZoom()}`);
      if (map.getZoom() >= expansionZoom) {
        /*
        Damn, this should already have expanded, we are a bit late to the party
        Just delete it.
        */
        // console.log('Cluster already expanded, removing', clusterData.id);
        removeCluster(layer, clusterData.id);
      } else {
        clusterData.expansionZ = expansionZoom;
      }
    });
  }

  function removeMarker(layer: string, oldMarkerId: number, endLocation?: [number, number]) {
    const { markers } = map.steerpath.markercluster._layers[layer];

    const markerData = markers[oldMarkerId];
    if (markerData) {
      markerData.marker.remove();
      delete markers[oldMarkerId];
    }
  }

  function removeCluster(layer: string, oldClusterId: number, endLocation?: [number, number]) {
    const { clusters } = map.steerpath.markercluster._layers[layer];
    // console.log('Removing cluster', oldClusterId);
    const clusterData = clusters[oldClusterId];
    if (clusterData) {
      clusterData.marker.remove();
      delete clusters[clusterData.id];
    }
  }

  function removeIfNotUpdatedSince(layer: string, t: number) {
    const { markers } = map.steerpath.markercluster._layers[layer];
    const { clusters } = map.steerpath.markercluster._layers[layer];
    // console.log('Checking clusters not seen');
    Object.values(markers)
      .filter((m) => m.lastSeen < t)
      .forEach((m) => removeMarker(layer, m.id));
    Object.values(clusters)
      .filter((m) => m.lastSeen < t)
      .map((m) => removeCluster(layer, m.id));
  }

  function animateChildrenBecomingCluster(
    layer: string,
    location: [number, number],
    childClusters: Set<number>,
    childMarkers: Set<number>,
    completion: () => any,
  ) {
    const { markers } = map.steerpath.markercluster._layers[layer];
    const { clusters } = map.steerpath.markercluster._layers[layer];

    const animationsRemaining = [0];
    childClusters.forEach((childId) => {
      if (clusters[childId]) {
        clusters[childId].lastSeen = Date.now() + ANIMATION_DURATION_MS;
        animationsRemaining[0] += 1;
        requestAnimationFrame(
          locationAnimator(
            clusters[childId].marker,
            clusters[childId].location,
            location,
            ANIMATION_DURATION_MS,
            () => {
              // console.log('Animation of children becoming cluster complete');
              removeCluster(layer, childId);
              animationsRemaining[0] -= 1;
              if (animationsRemaining[0] <= 0 && completion) completion();
            },
          ),
        );
      }
    });
    childMarkers.forEach((childId) => {
      if (markers[childId]) {
        markers[childId].lastSeen = Date.now() + ANIMATION_DURATION_MS;
        animationsRemaining[0] += 1;
        requestAnimationFrame(
          locationAnimator(markers[childId].marker, markers[childId].location, location, ANIMATION_DURATION_MS, () => {
            removeMarker(layer, childId);
            animationsRemaining[0] -= 1;
            if (animationsRemaining[0] <= 0 && completion) completion();
          }),
        );
      }
    });
    if (animationsRemaining[0] <= 0) {
      if (completion) completion();
    }
  }

  function animateZoomingOut(layer: string, newZoom: number, deleteUnseen: boolean) {
    const { markers } = map.steerpath.markercluster._layers[layer];
    const { clusters } = map.steerpath.markercluster._layers[layer];

    const now = Date.now();
    const mapItems = map.querySourceFeatures(layer + '-data') as unknown as (
      | MarkerGJFeature<GJPropsInternal>
      | MarkerGJFeature<StandardClusterProperties>
    )[];
    const implosionCounter = [0];

    mapItems.forEach((visibleItem) => {
      if (visibleItem.properties.cluster) {
        const clusterId = visibleItem.properties.cluster_id;
        if (!clusters[clusterId]) {
          /* This is a new cluster, let's add it */
          implosionCounter[0] += 1;
          addNewCluster(layer, visibleItem as MarkerGJFeature<StandardClusterProperties>, true, undefined, () => {
            implosionCounter[0] -= 1;
            if (implosionCounter[0] <= 0) {
              removeIfNotUpdatedSince(layer, now);
            }
          });
        } else {
          clusters[clusterId].lastSeen = now;
        }
      } else {
        if (visibleItem.id === undefined) {
          // There is a bug in maplibre, where id = 0 becomes id = undefined.
          // This is to workaround that.
          /* eslint-disable no-param-reassign */
          visibleItem.id = 0;
        }
        const markerId = visibleItem.id as number;
        if (markers[markerId]) {
          markers[markerId].lastSeen = now;
        } else {
          addNewMarker(layer, visibleItem as MarkerGJFeature<GJPropsInternal>);
        }
      }
    });

    if (deleteUnseen && implosionCounter[0] === 0) {
      removeIfNotUpdatedSince(layer, now);
    }
  }

  function animateZoomingIn(layer: string, newZoom: number, deleteUnseen: boolean) {
    const { markers } = map.steerpath.markercluster._layers[layer];
    const { clusters } = map.steerpath.markercluster._layers[layer];

    const now = Date.now();
    const mapItems = map.querySourceFeatures(layer + '-data') as unknown as MarkerGJFeature<
      GJPropsInternal | StandardClusterProperties
    >[];

    const newClusters = {} as Record<number, MarkerGJFeature<StandardClusterProperties>>;
    const newMarkers = {} as Record<number, MarkerGJFeature<GJPropsInternal>>;

    // Let's identify all the newly created markers and clusters and update lastSeen on the existing ones
    mapItems.forEach((visibleItem) => {
      if (visibleItem.properties.cluster) {
        const clusterId = visibleItem.properties.cluster_id;
        if (!clusters[clusterId]) {
          newClusters[clusterId] = visibleItem as MarkerGJFeature<StandardClusterProperties>;
        } else {
          clusters[clusterId].lastSeen = now;
        }
      } else {
        if (visibleItem.id === undefined) {
          // Maplibre bug with visibleItem.id = 0 becoming undefined
          /* eslint-disable no-param-reassign */
          visibleItem.id = 0;
        }
        const markerId = visibleItem.id as number;
        if (!markers[markerId]) {
          newMarkers[markerId] = visibleItem as MarkerGJFeature<GJPropsInternal>;
        } else {
          markers[markerId].lastSeen = now;
        }
      }
    });

    if (Object.keys(newClusters).length > 0 || Object.keys(newMarkers).length > 0) {
      // Some new stuff appeared. Time to expand clusters:
      // Something new, we need to figure out where the new ones came from.
      // Get any clusters that have expanded, note that some clusters may be pending their
      // expansion zoom and get ignored here.
      const expandedClusters = Object.values(clusters).filter((cl) => cl.expansionZ <= newZoom);
      expandedClusters.forEach((cluster) => {
        cluster.clusterChildren.forEach((childId) => {
          if (newClusters[childId]) {
            // Check if this child is in the new elements that appeared
            addNewCluster(layer, newClusters[childId], false, cluster.location);
            delete newClusters[childId]; /* This has now been added, no longer new */
          }
        });
        cluster.markerChildren.forEach((childId) => {
          if (newMarkers[childId]) {
            // Check if this child is in the new elements that appeared
            addNewMarker(layer, newMarkers[childId], cluster.location);
            delete newMarkers[childId]; /* This has now been added, no longer new */
          }
        });
        /* Also make sure this expanded cluster is not in the new clusters */
        delete newClusters[cluster.id];
      });

      /* Remove the clusters from the map that we have expanded */
      // console.log('Removing expanded clusters');
      expandedClusters.forEach((cl) => removeCluster(layer, cl.id));

      // Any remaining newMarkers and newClusters we don't know where they came from, cannot animate, just show.
      Object.values(newMarkers).forEach((newMarker) => {
        addNewMarker(layer, newMarker);
      });
      Object.values(newClusters).forEach((newCluster) => {
        addNewCluster(layer, newCluster);
      });
    }

    if (deleteUnseen) {
      removeIfNotUpdatedSince(layer, now);
    }
  }

  function clickHandler(
    layer: string,
    evt: MouseEvent,
    feat: MarkerGJFeature<GJPropsInternal | StandardClusterProperties>,
  ) {
    const layerOpts = map.steerpath.markercluster._layers[layer].opts;
    if (feat.properties.cluster) {
      const clusterId = feat?.properties?.cluster_id;
      evt.stopPropagation();
      evt.preventDefault();

      /* Zoom to cluster bounding box */
      const src = map.getSource(layer + '-data') as GeoJSONSource;

      const { clusters } = map.steerpath.markercluster._layers[layer];
      const clusterData = clusters[clusterId];
      if (!clusterData || map.steerpath.markercluster._zoom.isExpanding) {
        // User clicked on a cluster that is gone. Do nothing.
        return;
      }

      map.steerpath.markercluster._zoom.isExpanding = true;

      // TODO: This is a bit insane gettting all the leaves to get their bounds
      // the reason we are doing it is so that we zoom in as much as possible
      // however to make sure we zoomin in at least a little bit, we also provide the
      // minZoom (fixes #579)
      src.getClusterLeaves(clusterId, Infinity, 0, (_err, features) => {
        const coords = features.map((f) => (f.geometry as GeoJSON.Point).coordinates as [number, number]);
        (map as unknown as MapWithMovementControls).steerpath.moveTo(
          {
            movementType: 'ease',
            fit: coords,
            allowInterrupting: true,
            options: {
              minZoom: isFinite(clusterData.expansionZ) ? clusterData.expansionZ : 1,
            },
          },
          () => {
            map.steerpath.markercluster._zoom.isExpanding = false;
          },
        );
      });
    } else {
      if (layerOpts.centerOnSelect) {
        const z =
          layerOpts.zoomLevelOnSelect !== null ? Math.max(map.getZoom(), layerOpts.zoomLevelOnSelect) : map.getZoom();
        (map as unknown as MapWithMovementControls).steerpath.moveTo(
          {
            movementType: 'ease',
            allowInterrupting: false,
            options: {
              center: [feat.properties._original_point_lon, feat.properties._original_point_lat],
              zoom: z,
              duration: 500,
            },
          },
          null,
        );
      }
      const handler = map.steerpath.markercluster._layers[layer].opts.onClick;
      if (handler) {
        evt.stopPropagation();
        evt.preventDefault();

        // TODO: https://github.com/maplibre/maplibre-gl-js/blob/2112766af5d68a7ea885156c8c186c72a4e912ca/src/util/dom.js#L106
        const clickPoint = { x: evt.clientX, y: evt.clientY };
        const lngLat = map.unproject([clickPoint.x, clickPoint.y]);

        const enhancedEvent: EnrichedMapEvent = {
          type: 'click',
          point: clickPoint as maplibregl.Point,
          lngLat: {
            lng: lngLat.lng,
            lat: lngLat.lat,
          } as maplibregl.LngLat,
          defaultPrevented: false,
          preventDefault: null as unknown as () => void,
          target: null as unknown as maplibregl.Map,
          mapFeatures: [feat as unknown as MapboxGeoJSONFeature],
          originalEvent: {
            clientX: evt.clientX,
            clientY: evt.clientY,
            button: evt.button,
            buttons: evt.buttons,
            altKey: evt.altKey,
            ctrlKey: evt.ctrlKey,
            metaKey: evt.metaKey,
            shiftKey: evt.shiftKey,
          } as MouseEvent,
        };

        handler(enhancedEvent);
      }
    }
  }

  /* Public functions */

  map.steerpath.markercluster.add = function addMarkerClusterLayer(name, clusterProperties, clusterRadius) {
    map.steerpath.markercluster._layers[name] = {
      opts: {
        markerGenerator: null,
        zoomLevelOnSelect: null,
        centerOnSelect: true,
        onClick: null,
      },
      generatorVars: {},
      markers: {},
      clusters: {},
    };
    map.addSource(name + '-data', {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
      cluster: true,
      clusterProperties: clusterProperties || undefined,
      clusterMaxZoom: 19, // Max zoom to cluster points on, capped by maxzoom
      clusterRadius: clusterRadius || undefined,
      maxzoom: 19,
    });

    map.addLayer({
      id: name + '-individual',
      type: 'symbol',
      source: name + '-data',
      layout: {
        // "icon-image": "category_retail_x",
        'icon-allow-overlap': true,
        'text-ignore-placement': true,
        'text-allow-overlap': true,
        'icon-ignore-placement': true,
        // "text-field": "{title}",
        'text-font': ['default'],
      },
      paint: {
        'text-color': '#ffffff',
      },
      filter: [
        'all',
        ['!has', 'point_count'],
        [
          'any',
          ['all', ['==', 'buildingRef', 'dumdee'], ['==', 'layerIndex', 0]],
          ['!has', 'buildingRef'],
          ['!has', 'layerIndex'],
        ],
      ],
    });

    map.addLayer({
      id: name + '-cluster',
      type: 'symbol',
      source: name + '-data',
      layout: {
        'icon-allow-overlap': true,
        'text-ignore-placement': true,
        'text-allow-overlap': true,
        'icon-ignore-placement': true,
        // "text-field": "CLUSTER",
        'text-font': ['default'],
      },
      paint: {
        'text-color': '#ffffff',
      },
      filter: [
        'all',
        ['has', 'point_count'],
        [
          'any',
          ['all', ['==', 'buildingRef', 'dumdee'], ['==', 'layerIndex', 0]],
          ['!has', 'buildingRef'],
          ['!has', 'layerIndex'],
          ['>', 'point_count', 1],
        ],
      ],
    });
    // console.log(`MarkerCluster: Added ${name}`);
  };

  map.steerpath.markercluster.remove = function removeLayer(layer: string) {
    if (map.steerpath.markercluster && map.steerpath.markercluster._layers[layer]) {
      const { markers } = map.steerpath.markercluster._layers[layer];
      const { clusters } = map.steerpath.markercluster._layers[layer];

      Object.values(markers).forEach((m) => removeMarker(layer, m.id));
      Object.values(clusters).forEach((m) => removeCluster(layer, m.id));
      delete map.steerpath.markercluster._layers[layer];

      map.removeLayer(layer + '-individual');
      map.removeLayer(layer + '-cluster');
      map.removeSource(layer + '-data');
    }
    // console.log(`MarkerCluster: Removed ${layer}`);
  };

  map.steerpath.markercluster.setClickHandler = function setClickHandler(layer, handler) {
    map.steerpath.markercluster._layers[layer].opts.onClick = handler;
  };

  map.steerpath.markercluster.setMarkerGenerator = function setMarkerGenerator(layer, generator) {
    map.steerpath.markercluster._layers[layer].opts.markerGenerator = generator;
    const { markers } = map.steerpath.markercluster._layers[layer];
    Object.values(markers).forEach((k) => removeMarker(layer, k.id));
    animateZoomingOut(layer, map.getZoom(), true); // Recreate data
  };
  map.steerpath.markercluster.setMarkerVars = function setMarkerVars(layer, generatorVars) {
    map.steerpath.markercluster._layers[layer].generatorVars = generatorVars;
    const { markers, clusters } = map.steerpath.markercluster._layers[layer];
    Object.values(markers).forEach((k) => (k.onUpdateVars ? k.onUpdateVars(generatorVars) : null));
    Object.values(clusters).forEach((k) => (k.onUpdateVars ? k.onUpdateVars(generatorVars) : null));
  };

  map.steerpath.markercluster.setCenterOnClick = function setCenterOnClick(layer, enable, zoomLevel) {
    map.steerpath.markercluster._layers[layer].opts.centerOnSelect = enable;
    map.steerpath.markercluster._layers[layer].opts.zoomLevelOnSelect = zoomLevel;
  };
  map.steerpath.markercluster.setData = function setLayerData(layer: string, data: MapboxGeoJSONFeature[]) {
    const src = map.getSource(layer + '-data') as GeoJSONSource;
    /* console.log(
      `Marker data updated for ${layer}, now has ${data.length} features, has selected: ${data.some(
        (e) => e.properties?.selected
      )}`,
      data
    );
    */

    /*
    So this is slightly tricky due to mapbox, but we need to monitor when the data is loaded,
    and once the map says the source is loaded (not the event, the event never says the source is loaded)
    then we can remove old markers, and trigger a new generation.

    We cannot remove the old markers earlier than this, because then they might get regenerated,
    with the old data.
    */
    const onSourceData = (e: maplibregl.MapSourceDataEvent & maplibregl.EventData) => {
      if (e.sourceId === layer + '-data') {
        if (map.isSourceLoaded(layer + '-data')) {
          // console.log('Source data says loaded, turning off monitoring');
          map.off('sourcedata', onSourceData);
          const { markers, clusters } = map.steerpath.markercluster._layers[layer];
          Object.values(markers).forEach((k) => removeMarker(layer, k.id));
          Object.values(clusters).forEach((k) => removeCluster(layer, k.id));
          // Triggers a query source data and marker regeneration.
          animateZoomingOut(layer, map.getZoom(), true);
        }
      }
    };
    /* Start monitoring if the source data has loaded */
    // console.log('Source data monitoring on');
    map.on('sourcedata', onSourceData);
    // Have to stash the original cooridnates in properties, because the
    // coordinates get rounded at zoom level 0 and if you try to zoom into
    // the coordinates you will end up in the wrong place.

    data.forEach((e) => {
      // JS engine in embeded webviews may not support modern features such
      // as destructuring, hence we disable it
      // eslint-disable-next-line prefer-destructuring
      const coordinates = (e.geometry! as GeoJSON.Point).coordinates;
      // eslint-disable-next-line prefer-destructuring
      e.properties!._original_point_lat = coordinates[1];
      // eslint-disable-next-line prefer-destructuring
      e.properties!._original_point_lon = coordinates[0];
      e.properties!.cluster = false;
    });

    src.setData({ type: 'FeatureCollection', features: data });
  };

  /* Initialize event handling */

  function onZoomStart() {
    map.steerpath.markercluster._zoom.startZoom = map.getZoom();
    map.steerpath.markercluster._zoom.prevZoom = map.steerpath.markercluster._zoom.startZoom;
    map.steerpath.markercluster._zoom.startTime = Date.now();
    map.steerpath.markercluster._zoom.cleanups += 1;
  }

  function onZoom() {
    const { prevZoom } = map.steerpath.markercluster._zoom;
    const newZoom = map.getZoom();
    if (newZoom > prevZoom) {
      // console.log("Zoom in happening");
      // Object.keys(map.steerpath.markercluster._layers).forEach((layer) => animateZoomingIn(layer, newZoom, false));
    } else {
      // console.log("Zoom out happening");
      Object.keys(map.steerpath.markercluster._layers).forEach((layer) => animateZoomingOut(layer, newZoom, false));
    }
    map.steerpath.markercluster._zoom.prevZoom = newZoom;
  }

  function onZoomEnd() {
    // Clusters don't necessarily appear on screen during the zoom so
    // as a workaround we wait for idle and then animate.
  }

  map.on('zoomstart', onZoomStart);
  map.on('zoom', onZoom);
  map.on('zoomend', onZoomEnd);
  map.on('sourcedata', (evt) => {
    if (!map.steerpath.markercluster._zoom.isExpanding) {
      if (evt.sourceId.endsWith('-data')) {
        const newZoom = map.getZoom();
        const name = evt.sourceId.slice(0, -5);
        animateZoomingIn(name, newZoom, false);
      }
    }
  });

  // FYI: map.once("idle") doesn't trigger if map is already idle.
  map.on('idle', () => {
    // console.log('idle');
    const newZoom = map.getZoom();
    if (newZoom > map.steerpath.markercluster._zoom.startZoom) {
      Object.keys(map.steerpath.markercluster._layers).forEach((layer) => animateZoomingIn(layer, newZoom, true));
    } else {
      Object.keys(map.steerpath.markercluster._layers).forEach((layer) => animateZoomingOut(layer, newZoom, true));
    }
  });
}

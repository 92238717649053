import { endOfDay, getHours, getMinutes, set, startOfDay } from 'date-fns';
import I18n from 'i18n-js';
import React, { useCallback, useMemo } from 'react';
import { ReactNativeModalDateTimePickerProps } from 'react-native-modal-datetime-picker';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { ValidRangeType } from 'react-native-paper-dates/lib/typescript/Date/Calendar';
import { useI18n } from '../../context/I18nContext';
import { useNowMinutes } from '../../hooks/useNow';

function DatePickerWebContent({ props }: { props: ReactNativeModalDateTimePickerProps }) {
  /* To make time picker use 24h clock, we don't pass 'en' local but instead pass 'en-GB' */
  const locale = useMemo(() => {
    if (props.locale?.split('-')[0] === 'en') return 'en-GB';
    return props.locale?.split('-')[0] || 'en-GB';
  }, [props.locale]);

  const validRange: ValidRangeType | undefined = useMemo(() => {
    return {
      startDate: props.minimumDate ? startOfDay(props.minimumDate) : undefined,
      endDate: props.maximumDate ? endOfDay(props.maximumDate) : undefined,
    };
  }, [props.maximumDate, props.minimumDate]);

  return (
    <DatePickerModal
      date={props.date}
      mode={'single'}
      onConfirm={(v) => props.onConfirm(v.date as Date)}
      onDismiss={() => {
        props.onCancel(undefined as any);
      }}
      locale={locale}
      startDate={props.date}
      endDate={props.maximumDate}
      visible={props.isVisible || false}
      validRange={validRange}
      // TODO: Do we need to support false? We probably don't have any usage overseas.
      startWeekOnMonday={true}
    />
  );
}

function TimePickerWebContent({
  props,
  I18nRef,
}: {
  props: ReactNativeModalDateTimePickerProps;
  I18nRef: typeof I18n;
}) {
  const now = useNowMinutes();
  /* To make time picker use 24h clock, we don't pass 'en' local but instead pass 'en-GB' */
  const locale = useMemo(() => {
    if (props.locale?.split('-')[0] === 'en') return 'en-GB';
    return props.locale?.split('-')[0] || 'en-GB';
  }, [props.locale]);

  const onTimePickerConfirm = useCallback(
    (hoursAndMinutes: { hours: number; minutes: number }) => {
      props.onConfirm(
        set(props.date ?? new Date(), {
          hours: hoursAndMinutes.hours,
          minutes: hoursAndMinutes.minutes,
          seconds: 0,
          milliseconds: 0,
        }),
      );
    },
    [props],
  );

  return (
    <TimePickerModal
      hours={getHours(props.date || now)}
      minutes={getMinutes(props.date || now)}
      onConfirm={onTimePickerConfirm}
      locale={locale}
      confirmLabel={I18nRef.t('general.confirm')}
      cancelLabel={I18nRef.t('general.cancel')}
      visible={props.isVisible}
      onDismiss={() => {
        props.onCancel(undefined as any);
      }}
      label={I18nRef.t('mapview.filter.selectTime')}
    />
  );
}

export default function DateTimePickerWebModal(props: ReactNativeModalDateTimePickerProps) {
  const { I18n: MyI18n } = useI18n();
  if (props.mode === 'time') {
    return <TimePickerWebContent props={props} I18nRef={MyI18n} />;
  }
  if (props.mode === 'date') {
    return <DatePickerWebContent props={props} />;
  }
  throw new Error('Not implemented');
}

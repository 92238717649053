import React, { useCallback, useLayoutEffect, useState } from 'react';
import { View, Share, TouchableOpacity, Platform } from 'react-native';
import { Snackbar, useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import Clipboard from '@react-native-clipboard/clipboard';
import SummaryHeader from '../components/SummaryHeader';
import { useI18n } from '../context/I18nContext';
import GradientButton from '../components/GradientButton';
import { useMyProfileQuery } from '../apis/appsyncApis';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import ScrollablePage from '../components/ui/ScrollablePage';
import Container from '../components/Container';
import Spacer from '../components/Spacer';
import { screenNames } from '../navigators/screenNames';
import { UiIcon } from '../components/SimpleListIcon';
import { AppDrawerNavigationProp } from '../components/DrawerMenuContent';

export default function ShareScreen() {
  const { colors } = useTheme();
  const { I18n } = useI18n();
  const mp = useMixpanel();
  const navigation = useNavigation<AppDrawerNavigationProp>();
  const myProfileResult = useMyProfileQuery();

  const invitationCode = myProfileResult.data?.data?.getMyProfile?.inviteCode ?? '';
  const [copiedSnackbarVisible, setCopiedSnackbarVisible] = useState<boolean>(false);

  const handleCopyToClipboard = useCallback(async () => {
    Clipboard.setString(invitationCode);
    setCopiedSnackbarVisible(true);
  }, [invitationCode]);

  const handleShareAction = useCallback(async () => {
    if (Platform.OS !== 'web') {
      const result = await Share.share(
        {
          title: invitationCode,
          message: I18n.t('share.shareMessage', { code: invitationCode }),
        },
        { subject: I18n.t('share.shareSubject') },
      );
      if (result.action === Share.sharedAction) {
        // action when shared
        mp?.track('Share', { invitationCode });
        mp?.getPeople().increment('Shared invite code', 1);
      } else {
        // action when dismiss
      }
    } else {
      handleCopyToClipboard();
    }
  }, [I18n, invitationCode, mp, handleCopyToClipboard]);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: invitationCode,
      headerRight: () => {
        return (
          <TouchableOpacity
            onPress={() => {
              handleShareAction();
            }}
            style={{ margin: 6 }}
          >
            <UiIcon name="share" size={25} color={colors.secondary} />
          </TouchableOpacity>
        );
      },
    });
  }, [navigation, myProfileResult, handleShareAction, colors, invitationCode]);
  return (
    <>
      <ScrollablePage
        summary={{
          title: invitationCode,
          description: I18n.t('share.description'),
          icon: 'fire',
          titleAction: {
            icon: 'docs',
            accessibilityLabel: I18n.t('share.copyToClipboard'),
            onPress: handleCopyToClipboard,
          },
        }}
      >
        <Container>
          <GradientButton narrow={true} onPress={handleShareAction}>
            {I18n.t('share.shareBtn')}
          </GradientButton>

          <Spacer type={'section'} />
          <Spacer type={'section'} />
          <Spacer type={'section'} />

          <SummaryHeader
            title={I18n.t('share.companyEnrollTitle')}
            description={I18n.t('share.companyEnrollSubtitle')}
            icon={<View />}
            button={{
              label: I18n.t('share.fillDetails'),
              onPress: () => {
                navigation.navigate('me', { screen: screenNames.RequestDiscount });
              },
            }}
          />
        </Container>
      </ScrollablePage>
      <Snackbar visible={copiedSnackbarVisible} duration={3000} onDismiss={() => setCopiedSnackbarVisible(false)}>
        {I18n.t('share.copiedToClipboard')}
      </Snackbar>
    </>
  );
}

import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { Banner } from 'react-native-paper';

/**
 * We do support reactive notification banner (displaying time left), so the main
 * content can be a function returning a string. If that is the case, the banner should re-render
 * every 20 seconds (can't place a timer longer than 30 seconds in Android)
 */
export type NotificationPayload = {
  // Notification key is used to identify if we should clean it up later,
  notificationKeys: string[];
  helperContent: string;
  // By default notification will be dismissed after 10 seconds
  shouldBePersistent?: boolean;
  mainContent: string;
  actions: React.ComponentProps<typeof Banner>['actions'] & { disabled?: boolean };
  icon: React.ComponentProps<typeof Banner>['icon'];
};

export const NOTIFICATION_LIFE_TIME_MS = 10000;

type MapViewBannerContextValue = {
  displayNotification: (notification: NotificationPayload | null) => void;
  currentNotificationPayload: NotificationPayload | null;
  removeNotificationWithKey: (notificationKey: string) => void;
};

const MapViewBannerContext = createContext<MapViewBannerContextValue | null>(null);

export function useMapViewBanner() {
  const value = useContext(MapViewBannerContext);
  if (!value) {
    throw new Error('mapview banner should we used within MapViewBannerProvider');
  }
  return value;
}

export function MapViewBannerProvider(props: PropsWithChildren<{}>) {
  const [currentNotification, setCurrentNotification] = useState<NotificationPayload | null>(null);

  const removeNotificationWithKey = useCallback((key: string) => {
    setCurrentNotification((notification) => {
      if (notification?.notificationKeys.includes(key)) {
        return null;
      }
      return notification;
    });
  }, []);

  return (
    <MapViewBannerContext.Provider
      value={{
        displayNotification: setCurrentNotification,
        currentNotificationPayload: currentNotification,
        removeNotificationWithKey,
      }}
    >
      {props.children}
    </MapViewBannerContext.Provider>
  );
}

export const RESERVED_NOTIFICATION_KEYS = {
  BOOKING_FAILED: 'BOOKING_FAILED',
  BOOKING_SUCCEED: 'BOOKING_SUCCEED',
};

import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AppbarScreenOptions } from '../components/NavigatorAppbar';
import { screenNames } from './screenNames';
import { useI18n } from '../context/I18nContext';
import OnlinePage from '../screens/OnlinePage';

export type NewsStackRouteParams = {
  [screenNames.News]: { url: string } | undefined;
};

const Stack = createStackNavigator<NewsStackRouteParams>();

export default function NewsStackNavigator() {
  const { I18n } = useI18n();

  return (
    <Stack.Navigator initialRouteName={screenNames.News} screenOptions={AppbarScreenOptions}>
      <Stack.Screen
        name={screenNames.News}
        component={OnlinePage}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('news.title'),
        }}
        initialParams={{
          url: I18n.t('news.url'),
        }}
      />
    </Stack.Navigator>
  );
}

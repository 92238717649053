import React, { Component } from 'react';
import { View, ViewProps } from 'react-native';
import {
  WebViewErrorEvent,
  WebViewNavigationEvent,
  WebViewRenderProcessGoneEvent,
  WebViewTerminatedEvent,
} from 'react-native-webview/lib/WebViewTypes';
import WebView, { WebViewMessageEvent } from '../../webview/WebView';
import * as Sentry from '../../sentry/sentry';

export type HandlerId = string;

export interface JsonMap {
  [member: string]: string | number | boolean | null | JsonArray | JsonMap;
}

export type JsonArray = (string | number | boolean | null | JsonArray | JsonMap)[];
export type JsonValue =
  | JsonMap
  | JsonArray
  | string
  | number
  | boolean
  | null
  | Record<string, JsonMap | JsonArray | string | number | boolean | null>;

export type Callback = (...params: any[]) => void;

// TODO: T should be limited to being methods which accept transferable parameters and return void.
export type WrappedCallback<T extends Callback> = {
  _wrapped_handler: string;
  call: T;
};

type SpecialParam = Callback | ExecutionContextVariable | WrappedCallback<Callback>;

export type RootParam = SpecialParam | JsonValue | Record<string, SpecialParam | JsonValue>;
export type Params = RootParam[];

export type ExecutionContextVariable = {
  _execution_context_variable: string;
};

export type WrappedWebViewProps = ViewProps & {
  html: { html: string };
  onLoadEnd: (e: WebViewNavigationEvent | WebViewErrorEvent) => void;
};

interface HandlerInfo {
  cb: (...v: any[]) => void;
  fail: (err: Error) => void;
  autoRelease: boolean;
}

export default class WrappedWebView extends Component<WrappedWebViewProps, { reloadKey: number }> {
  handlers: Record<HandlerId, HandlerInfo>;

  nextHandlerId: number;

  webview?: WebView<{
    ref: unknown;
    style: { flex: number };
    originWhitelist: string[];
    source: any;
    onMessage: any;
  }> | null;

  constructor(props: WrappedWebViewProps) {
    super(props);

    this.handlers = {};
    this.nextHandlerId = 1;

    this.state = { reloadKey: 0 };

    this._onMessage = this._onMessage.bind(this);
    this.registerCb = this.registerCb.bind(this);
    this.unregisterCb = this.unregisterCb.bind(this);
    this.invokeJS = this.invokeJS.bind(this);

    this.handleRenderProcessGone = this.handleRenderProcessGone.bind(this);
    this.handleContentProcessDidTerminate = this.handleContentProcessDidTerminate.bind(this);
  }

  handleRenderProcessGone(evt: WebViewRenderProcessGoneEvent) {
    Sentry.captureException(new Error('WebViewRenderProcessGoneEvent: ' + evt.toString()));
    this.setState((prevState) => {
      this.handlers = {};
      return {
        reloadKey: prevState.reloadKey + 1,
      };
    });
  }

  handleContentProcessDidTerminate(evt: WebViewTerminatedEvent) {
    Sentry.captureException(new Error('WebViewTerminatedEvent: ' + evt.toString()));
    this.setState((prevState) => {
      this.handlers = {};
      return {
        reloadKey: prevState.reloadKey + 1,
      };
    });
  }

  _onMessage(event: WebViewMessageEvent) {
    const res =
      typeof event.nativeEvent.data === 'string' ? JSON.parse(event.nativeEvent.data) : event.nativeEvent.data;
    if (res._handlerId) {
      // console.log(`${res._handlerId}: from iFrame: `, res);
      if (res._wrappedMsgType === 'error') {
        const err: any = new Error(res.params.message);
        err.name = res.params.name;
        err.message = res.params.message;
        err.description = res.params.description;
        err.stack = res.params.stack;
        err.fileName = res.params.fileName;
        err.lineNumber = res.params.lineNumber;
        err.columnNumber = res.params.columnNumber;
        err.toString = () => res.params.toString;
        err.name = () => res.className;
        this.handlers[res._handlerId].fail(err);
      } else if (res.params) {
        this.handlers[res._handlerId].cb(...res.params);
      } else {
        this.handlers[res._handlerId].cb();
      }

      if (this.handlers[res._handlerId].autoRelease) {
        delete this.handlers[res._handlerId];
      }
    }
  }

  _genHandlerId(): string {
    this.nextHandlerId += 1;
    return '' + this.nextHandlerId;
  }

  // eslint-disable-next-line class-methods-use-this
  _generateEventInvoker(handlerId: HandlerId): string {
    return `(...params) => window.ReactNativeWebView.postMessage(JSON.stringify({
          "_wrappedMsgType": "event",
          "_handlerId": "${handlerId}",
          "params": params
      }))`;
  }

  // eslint-disable-next-line class-methods-use-this
  _generateResultInvoker(handlerId: HandlerId): string {
    return `(...params) => window.ReactNativeWebView.postMessage(JSON.stringify({
          "_wrappedMsgType": "result",
          "_handlerId": "${handlerId}",
          "params": params
      }))`;
  }

  // eslint-disable-next-line class-methods-use-this
  _generateErrorInvoker(handlerId: HandlerId): string {
    return `(err) => window.ReactNativeWebView.postMessage(JSON.stringify({
          "_wrappedMsgType": "error",
          "_handlerId": "${handlerId}",
          "params": {
            "name": err.name,
            "message": err.message,
            "description": err.description,
            "stack": err.stack,
            "fileName": err.fileName,
            "lineNumber": err.lineNumber,
            "columnNumber": err.columnNumber,
            "toString": err.toString(),
            "className": err.constructor ? err.constructor.name : undefined,
          }
      }))`;
  }

  _tryConvertParamToSpecial(param: RootParam): string | undefined {
    if (param) {
      if ((param as WrappedCallback<Callback>)._wrapped_handler) {
        return this._generateEventInvoker((param as WrappedCallback<Callback>)._wrapped_handler);
      }
      if (typeof param === 'function') {
        const handler = this.registerCb(param);
        return this._generateEventInvoker(handler._wrapped_handler);
      }
      if ((param as ExecutionContextVariable)._execution_context_variable) {
        return (param as ExecutionContextVariable)._execution_context_variable;
      }
    }
  }

  _convertRootParam(param: RootParam): string {
    if (param) {
      const special = this._tryConvertParamToSpecial(param);
      if (special) return special;

      if (typeof param === 'object') {
        const partial: string[] = [];
        Object.entries(param).forEach(([k, v]) => {
          const stringValue = this._tryConvertParamToSpecial(v) || JSON.stringify(v);
          partial.push(`"${k}":${stringValue}`);
        });
        return `{${partial.join(',')}}`;
      }
    }
    return JSON.stringify(param);
  }

  // TODO: Callbacks should be stored also on the iframe side,
  //       so that they can be removed later. Right now removeListeenr doesn't work
  //       as we don't have a pointer to the original listener so we could remove it.
  registerCb(cb: (...params: any[]) => void): WrappedCallback<Callback> {
    const handlerId = this._genHandlerId();
    this.handlers[handlerId] = {
      cb,
      fail: (e) => null,
      autoRelease: false,
    };
    return { _wrapped_handler: handlerId, call: cb };
  }

  unregisterCb(cb: WrappedCallback<Callback>) {
    delete this.handlers[cb._wrapped_handler];
  }

  invokeJS(
    name: string,
    functionData: string,
    params: RootParam[],
    resultCallback: (...args: any[]) => void,
    errorCallback: (exc: Error) => void,
  ) {
    const handlerId = this._genHandlerId();
    this.handlers[handlerId] = {
      cb: resultCallback,
      fail: errorCallback,
      autoRelease: true,
    };

    const paramStrings: string[] = params.map((param) => this._convertRootParam(param));
    // console.log(`Running ${name} ${functionData.slice(0, 100)}`);

    const jsCode = `/* ${name} */ (()=>{
        try {
          const result = (${functionData})(${paramStrings.join(', \n\n')});
          const resultMsg = JSON.stringify({
            "_wrappedMsgType": "result",
            "_handlerId": "${handlerId}",
            "params": result,
          });
          window.ReactNativeWebView.postMessage(resultMsg);
        } catch(exception) {
          console.error("Error executing function in webview.");
          console.error(exception.stack);
          Sentry.captureException(exception);
        }
      })();`;

    if (this.webview) {
      // console.log(`${handlerId}: to iframe: ${functionData.substr(0, 30)}`);
      this.webview.injectJavaScript(jsCode);
    } else {
      console.log('WARNING: Invoke silently failed!');
    }
  }

  render() {
    const { html, ...viewProps } = this.props;

    return (
      <View {...viewProps}>
        <WebView
          ref={(c) => {
            this.webview = c;
          }}
          style={{ flex: 1 }}
          originWhitelist={['*']}
          source={html}
          scrollEnabled={false}
          onLoadEnd={this.props.onLoadEnd}
          onMessage={this._onMessage}
          /* Crash stuff */
          key={this.state.reloadKey}
          onRenderProcessGone={this.handleRenderProcessGone}
          onContentProcessDidTerminate={this.handleContentProcessDidTerminate}
        />
      </View>
    );
  }
}

import { useEffect, useState } from 'react';
import { Expression, MarkerOptions } from 'maplibre-gl';
import { useMapWrapper } from '../MapContext';
import { EnrichedMapEvent } from './helpers/ClickMonitor';
import { initMarkerClusterMethods, MarkerClusterMap } from './helpers/MarkerClusterManagement';
import { JsonValue, RootParam } from '../wrap/WrappedWebView';

export type StandardClusterProperties = {
  cluster: true; // Is true if the point is a cluster
  cluster_id: number; // A unqiue id for the cluster to be used in conjunction with the cluster inspection methods
  point_count: number; // Number of original points grouped into this cluster
  point_count_abbreviated: string; // An abbreviated point count
};

export type GJIndoorProperties = { buildingRef?: string; layerIndex?: number };
export type GJPropertiesType = {
  [x: string]: string | number | boolean | null;
};
type GJFeaturesProps = GJPropertiesType & GJIndoorProperties;
type ClusterPropertiesType<C> = C & StandardClusterProperties;

export type MarkerGJFeature<P extends GJPropertiesType> = {
  type: 'Feature';
  id: number;
  properties: P;
  geometry: {
    type: 'Point';
    coordinates: [number, number];
  };
};
export enum VisibilityMode {
  Always, // Always show, ignoring floor switcher etc.
  NotOnFloor, // Show unless viewing that specific building's floor.
  OnFloor, // Show only if item's buildingRef and layerIndex match the current visible floor and building.
}

export type MarkerGenerator<T, P extends GJPropertiesType, C> = (
  e: MarkerGJFeature<P & { cluster: false }> | MarkerGJFeature<ClusterPropertiesType<C>>,
  doc: HTMLDocument,
  initialState: T,
) => MarkerElement<T>;
export type MarkerClickHandler = (e: EnrichedMapEvent) => void;

export type MarkerElement<T> = {
  html: HTMLElement;
  options?: MarkerOptions;
  onStateUpdate: (newState: T) => void;
};

export type MarkerClusterProperties<T> = {
  [x in keyof T]: Expression;
};

export type MarkerClusterProps<V extends RootParam, P extends GJFeaturesProps, C extends RootParam> = {
  id: string; // This is the data source id.

  features: MarkerGJFeature<P>[];
  /* clusterProperties is straight from maplibre cluster source definitions */
  clusterProperties?: MarkerClusterProperties<C>;
  clusterRadius?: number;
  marker?: MarkerGenerator<V, P, C>;
  markerVars?: V;
  zoomLevelOnSelect?: number;
  visibility?: VisibilityMode;
  onClick?: MarkerClickHandler;
};

const MarkerCluster: <V extends RootParam, P extends GJFeaturesProps, C extends RootParam>(
  props: MarkerClusterProps<V, P, C>,
) => React.ReactElement<MarkerClusterProps<V, P, C>> | null = (props) => {
  const { mapWrap, isStyleLoaded, selectedBuildingAndFloor } = useMapWrapper();

  const [isIntialized, setInitialized] = useState<boolean>(false);

  /* Register data source, styling and cleanup function */
  useEffect(() => {
    if (isStyleLoaded) {
      /* Init cluster marker methods if this happens to be the first time this is called */
      mapWrap.run(initMarkerClusterMethods);

      /* Do actual layer adding etc */
      mapWrap.run(
        (untypedVars, basicMap, lib, win) => {
          const vars = untypedVars as any;
          const map = basicMap as MarkerClusterMap<unknown, GJIndoorProperties, unknown>;
          /* Add layer */
          map.steerpath.markercluster.add(vars.id, vars.clusterProperties, vars.clusterRadius);

          /* Set marker generator */
          if (vars.generator !== null) {
            /* eslint-disable no-eval */
            const markerGenerator: MarkerGenerator<unknown, GJIndoorProperties, unknown> = eval(`(${vars.generator})`);
            map.steerpath.markercluster.setMarkerGenerator(vars.id, markerGenerator);
          }
        },
        {
          id: props.id,
          clusterProperties: props.clusterProperties ?? null,
          clusterRadius: props.clusterRadius ?? null,
          generator: props.marker ? props.marker.toString() : null,
        },
        () => {
          setInitialized(true);
        },
      );

      /* Clean up */
      return () => {
        mapWrap.run(
          (vars, basicMap, lib, win) => {
            const map = basicMap as MarkerClusterMap<unknown, GJIndoorProperties, unknown>;
            map.steerpath.markercluster.remove(vars.id);
          },
          { id: props.id },
        );
        setInitialized(false);
      };
    }
  }, [mapWrap, isStyleLoaded, props.id, props.marker, props.clusterProperties, props.clusterRadius]);

  useEffect(() => {
    if (isStyleLoaded) {
      mapWrap.run(
        (vars, basicMap, lib, win) => {
          const map = basicMap as MarkerClusterMap<unknown, GJIndoorProperties, unknown>;
          map.steerpath.markercluster.setMarkerVars(vars.id, vars.markerVars);
        },
        { id: props.id, markerVars: props.markerVars ?? {} },
      );
    }
  }, [props.markerVars, props.id, mapWrap, isStyleLoaded]);

  useEffect(() => {
    if (isIntialized) {
      let click = null;
      if (props.onClick) click = props.onClick;

      mapWrap.run(
        (vars, basicMap, lib, win) => {
          const map = basicMap as MarkerClusterMap<unknown, GJIndoorProperties, unknown>;
          map.steerpath.markercluster.setClickHandler(vars.id, vars.onClick);
        },
        { id: props.id, onClick: click },
      );
    }
  }, [mapWrap, isIntialized, props.id, props.onClick]);

  useEffect(() => {
    if (isIntialized) {
      mapWrap.run(
        (vars, basicMap, lib, win) => {
          const map = basicMap as MarkerClusterMap<unknown, GJIndoorProperties, unknown>;
          map.steerpath.markercluster.setCenterOnClick(vars.id, true, vars.z);
        },
        { id: props.id, z: props.zoomLevelOnSelect ?? null },
      );
    }
  }, [mapWrap, isIntialized, props.id, props.zoomLevelOnSelect]);

  /* Filter based on building if given selectedBuildingAndFloor */
  useEffect(() => {
    if (isIntialized && props.visibility !== undefined && props.visibility !== VisibilityMode.Always) {
      if (props.features.some((e) => typeof e.id !== 'number')) {
        console.error('All features must have a numeric id defined');
      }
      let filt;
      if (props.visibility === VisibilityMode.OnFloor) {
        filt = (feat: MarkerGJFeature<GJIndoorProperties>) =>
          selectedBuildingAndFloor &&
          feat.properties.buildingRef === selectedBuildingAndFloor.building &&
          feat.properties.layerIndex === selectedBuildingAndFloor.floor;
      } else if (props.visibility === VisibilityMode.NotOnFloor) {
        filt = (feat: MarkerGJFeature<GJIndoorProperties>) =>
          !selectedBuildingAndFloor ||
          feat.properties.buildingRef === undefined ||
          (selectedBuildingAndFloor && feat.properties.buildingRef !== selectedBuildingAndFloor.building);
      } else {
        console.error('Unexpected visibility type', props.visibility);
        return;
      }

      const feats = props.features.filter(filt);
      mapWrap.run(
        (vars, basicMap, lib, win) => {
          const map = basicMap as MarkerClusterMap<unknown, GJIndoorProperties, unknown>;
          map.steerpath.markercluster.setData(vars.id, vars.features);
        },
        { id: props.id, features: feats as JsonValue[] },
      );
    }
  }, [props.features, mapWrap, isIntialized, props.id, selectedBuildingAndFloor, props.visibility]);

  /* Add it without filtering, avoids flickering when switching buildings */
  useEffect(() => {
    if (isIntialized && (props.visibility === VisibilityMode.Always || !props.visibility)) {
      if (props.features.some((e) => typeof e.id !== 'number')) {
        console.error('All features must have a numeric id defined');
      }
      mapWrap.run(
        (vars, basicMap, lib, win) => {
          const map = basicMap as MarkerClusterMap<unknown, GJIndoorProperties, unknown>;
          map.steerpath.markercluster.setData(vars.id, vars.features);
        },
        { id: props.id, features: props.features as JsonValue[] },
      );
    }
  }, [props.features, mapWrap, isIntialized, props.id, props.visibility]);

  /* Map components don't actually render anything into the DOM */
  return null;
};
export default MarkerCluster;

import React, { useCallback } from 'react';
import Constants from 'expo-constants';
import { List } from 'react-native-paper';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useI18n } from '../../context/I18nContext';
import Container from '../../components/Container';
import GradientButton from '../../components/GradientButton';
import { screenNames } from '../../navigators/screenNames';
import { useGetStripePaymentQuery } from '../paymentApis';
import { useMyProfileQuery } from '../../apis/appsyncApis';
import PaymentCardList from '../components/PaymentCardList';
import ScrollablePage from '../../components/ui/ScrollablePage';
import PaymentWalletList from '../components/PaymentWalletList';
import { ListItemRow } from '../../components/ListItemRow';

export default function PaymentView({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const { bottom } = useSafeAreaInsets();
  const { I18n, formatCurrency } = useI18n();
  const cards = useGetStripePaymentQuery({ keepPreviousData: true });
  const myProfileResult = useMyProfileQuery();
  const cardList = cards.data?.data?.getStripePayment;

  const handleAddPaymentMethod = useCallback(() => {
    navigation.navigate(screenNames.AddPaymentMethod);
  }, [navigation]);

  const hasCards = (cardList ?? []).length > 0;
  return (
    <ScrollablePage
      summary={{
        title: hasCards ? I18n.t('payment.allGood') : I18n.t('payment.noCardTitle'),
        description: hasCards ? I18n.t('payment.allCardGoodDescription') : I18n.t('payment.noCardDescription'),
        icon: 'docs',
      }}
    >
      {/* Wallets */}
      <PaymentWalletList
        hasArrows={true}
        onPress={(paymentMethodType) => {
          navigation.navigate(screenNames.WalletDetail, { paymentMethodType });
        }}
      />
      {/* Cards */}
      <PaymentCardList
        cardList={cardList}
        hasArrows={true}
        onPress={(card) => {
          if (card) navigation.navigate(screenNames.CardDetail, { cardId: card.id });
        }}
      />
      {/* Credits */}
      <List.Subheader>{I18n.t('payment.bonusCredit.header')}</List.Subheader>
      <ListItemRow
        title={I18n.t('payment.bonusCredit.title')}
        description={formatCurrency(
          myProfileResult.data?.data?.getMyProfile?.creditBalance,
          myProfileResult.data?.data?.getMyProfile?.currency,
        )}
        leftIcon={'wallet'}
        onPress={() => {
          navigation.navigate(screenNames.CreditBalance);
        }}
        type="navigation"
      />
      <ListItemRow
        title={I18n.t('payment.scanVoucher.title')}
        description={I18n.t('payment.scanVoucher.details')}
        leftIcon={{ name: 'qr-code-outline', set: 'ion' }}
        onPress={() => {
          if (Constants?.manifest?.extra?.hideScanningSpaceQRCode) {
            navigation.navigate(screenNames.EnterCodeManuallyScreen, {
              sourceScreen: screenNames.Payments,
              mode: 'VOUCHER',
            });
          } else {
            navigation.navigate(screenNames.QRScanScreen, {
              sourceScreen: screenNames.Payments,
              mode: 'VOUCHER',
            });
          }
        }}
        type="navigation"
      />

      {/* Add payment method button */}
      <Container style={{ flex: 0, marginBottom: Math.max(bottom, 20), marginTop: 80 }}>
        <GradientButton onPress={handleAddPaymentMethod} icon={'plus'}>
          {I18n.t('payment.addPaymentMethod')}
        </GradientButton>
      </Container>
    </ScrollablePage>
  );
}

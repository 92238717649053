import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { StripeCard } from '../types/appsync-types';
import { PaymentMethodOption } from '../types/misc.types';
import { useGetStripePaymentQuery } from './paymentApis';
import { storeData, getData, InternalStorageItemKey } from '../utils/internalStorage';
import { usePlatformPay } from './utils/stripe';

export type PaymentMethod = CardPaymentMethod | GooglePayPaymentMethod;
export type CardPaymentMethod = {
  paymentMethodType: PaymentMethodOption.CARD;
  stripeCard: StripeCard;
};

export type GooglePayPaymentMethod = {
  paymentMethodType: PaymentMethodOption.GOOGLEPAY;
};

interface ActivePaymentMethodValue {
  activePaymentMethod: PaymentMethod | null;
  setActivePaymentMethod: (newPaymentMethod: PaymentMethod) => void;
  isActivePaymentMethodLoading: boolean;
}

const ActivePaymentMethodContext = createContext<ActivePaymentMethodValue>({
  activePaymentMethod: null,
  isActivePaymentMethodLoading: true,
  setActivePaymentMethod: () => undefined,
});

export const useActivePayment = () => {
  return useContext(ActivePaymentMethodContext);
};

export function ActivePaymentProvider(props: PropsWithChildren<{}>) {
  const [activePaymentMethod, _setActivePaymentMethodInternal] = useState<PaymentMethod | null>(null);
  const stripePaymentQuery = useGetStripePaymentQuery();
  const { isPlatformPaySupported } = usePlatformPay();

  useEffect(() => {
    // when the app launches, get active payment method from local storage, regardless of it is valid or not.
    (async () => {
      const storedPaymentMethod = await getData<PaymentMethod>(InternalStorageItemKey.ACTIVE_PAYMENT_METHOD);
      _setActivePaymentMethodInternal(storedPaymentMethod);
    })();
  }, []);

  useEffect(() => {
    // check if the current/prev payment method is valid. If it is invalid, get new valid payment method, set it as active and update payment method local storage.
    const stripePayments = stripePaymentQuery.data?.data?.getStripePayment;
    if (stripePayments) {
      (async () => {
        const isPlatformPayAvailable = Platform.OS === 'android' && (await isPlatformPaySupported());
        _setActivePaymentMethodInternal((prevMethod) => {
          if (prevMethod?.paymentMethodType === PaymentMethodOption.GOOGLEPAY && isPlatformPayAvailable) {
            return prevMethod;
          }
          if (prevMethod?.paymentMethodType === PaymentMethodOption.CARD) {
            const validPrevMethod = stripePayments.find((payment) => payment?.id === prevMethod.stripeCard.id);
            if (validPrevMethod) return prevMethod;
          }
          // if the previous payment method is null or not valid, set a new payment method and update local storage.
          // payment method priority order: GooglePay, First Card payment in Stripe payments list.
          const newPaymentMethod: PaymentMethod | null = (() => {
            if (isPlatformPayAvailable) {
              return { paymentMethodType: PaymentMethodOption.GOOGLEPAY } as GooglePayPaymentMethod;
            }
            if (stripePayments.length > 0 && stripePayments[0]) {
              return {
                paymentMethodType: PaymentMethodOption.CARD,
                stripeCard: stripePayments[0]!,
              } as CardPaymentMethod;
            }
            return null;
          })();
          storeData(InternalStorageItemKey.ACTIVE_PAYMENT_METHOD, newPaymentMethod);
          return newPaymentMethod;
        });
      })();
    }
  }, [stripePaymentQuery.data?.data?.getStripePayment, isPlatformPaySupported]);

  const value = useMemo(
    () => ({
      activePaymentMethod,
      setActivePaymentMethod: (paymentMethod: PaymentMethod | null) => {
        _setActivePaymentMethodInternal(paymentMethod);
        storeData(InternalStorageItemKey.ACTIVE_PAYMENT_METHOD, paymentMethod);
      },
      isActivePaymentMethodLoading: stripePaymentQuery.isLoading,
    }),
    [activePaymentMethod, stripePaymentQuery.isLoading],
  );
  return <ActivePaymentMethodContext.Provider value={value}>{props.children}</ActivePaymentMethodContext.Provider>;
}

import { createAxiosInstance, TokenType } from '../utils/apiInstanceFactory';
import podExport from '../../pod-exports.json';

export interface Destination {
  ToAddresses: string[];
}

export interface Content {
  Charset: string;
  Data: string;
}
export interface Body {
  Text: Content;
  Html?: Content;
}

export interface Subject {
  Charset: string;
  Data: string;
}

export interface Message {
  Body: Body;
  Subject: Subject;
}

// https://docs.aws.amazon.com/ses/latest/APIReference/API_SendEmail.html
export interface EmailPayload {
  Destination: Destination;
  Message: Message;
  Source: string;
  ReplyToAddresses?: string[];
}

const axiosInstance = createAxiosInstance(
  {
    headers: {
      Authorization: podExport.emailGatewayToken,
    },
  },
  TokenType.NONE,
);

export function sendMail(subject: string, bodyText: string, replyToAddress?: string, replyToName?: string) {
  const emailPayload: EmailPayload = {
    Destination: {
      ToAddresses: [podExport.targetEmail], // Email address,
    },
    Message: {
      Body: {
        Text: {
          Charset: 'UTF-8',
          Data: bodyText,
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: subject,
      },
    },

    Source: podExport.senderEmail ?? podExport.targetEmail, // AWS SES verified sender e-mail,
  };
  if (replyToAddress) {
    if (replyToName) {
      emailPayload.ReplyToAddresses = [`"${replyToName.replace('"', '').replace('\\', '')}" <${replyToAddress}>`];
    } else {
      emailPayload.ReplyToAddresses = [replyToAddress];
    }
  }
  return axiosInstance.post(podExport.emailGatewayEndpoint, emailPayload);
}

import React, { useCallback } from 'react';
import Constants from 'expo-constants';
import { Linking } from 'react-native';
import { getMobileOperatingSystem } from '../utils/helpers';
import GradientButton, { GradientButtonProps } from './GradientButton';

export function GetFromStoreButton({
  children,
  onUnknownStore,
  onPress,
  ...other
}: GradientButtonProps & { onUnknownStore: () => void }) {
  const handlePress = useCallback(() => {
    const mobileOs = getMobileOperatingSystem();
    const opts = Constants.manifest?.extra;
    if (mobileOs === 'android') {
      Linking.openURL(opts?.playStoreLink);
      return;
    }
    if (mobileOs === 'ios') {
      Linking.openURL(opts?.appStoreLink);
      return;
    }

    onUnknownStore();
  }, [onUnknownStore]);
  return (
    <GradientButton onPress={handlePress} {...other}>
      {children}
    </GradientButton>
  );
}

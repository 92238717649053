import { useState, useEffect } from 'react';
import { graphqlOperation } from '@aws-amplify/api';
import { Observable, ZenObservable } from 'zen-observable-ts';

import { PublicGraphQLAPI } from '../apis/publicGraphQLApiClient';

export default function useSubscription<T>(
  graphQl: string,
  params?: any,
  config?: {
    maxRetry?: number;
    trigger?: any;
    onSuccess?: (value: T) => void;
    onError?: (error: any) => void;
  },
) {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let retries = 0;
    let subscription: ZenObservable.Subscription;

    function createSubscription() {
      subscription = (PublicGraphQLAPI.graphql(graphqlOperation(graphQl, params)) as Observable<T>).subscribe(
        async (value) => {
          config?.onSuccess?.(value);
        },
        (error) => {
          if (retries < (config?.maxRetry || 0)) {
            subscription = createSubscription();
            retries += 1;
          } else {
            setIsError(true);
            config?.onError?.(error);
          }
        },
      );
      return subscription;
    }

    createSubscription();

    return () => {
      subscription?.unsubscribe();
    };
  }, [config, graphQl, params]);

  return {
    isError,
  };
}

import React, { FC, useMemo } from 'react';
import { FAB, FABProps } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import { TrackingStatus } from '../map/SmartMap';
import { uiIconSourceGenerator } from '../SimpleListIcon';

export type LocateMeButtonProps = {
  trackingStatus: TrackingStatus;
} & Omit<FABProps, 'icon'>;

const LocateMeButton: FC<LocateMeButtonProps> = (props) => {
  const { trackingStatus, style, disabled, onPress } = props;
  const icon = useMemo(() => {
    switch (trackingStatus) {
      case 'initial-state':
      case 'not-tracking':
        return uiIconSourceGenerator({ set: 'material', name: 'location-searching' });
      case 'tracking-location':
        return uiIconSourceGenerator({ set: 'material', name: 'my-location' });
      case 'tracking-location-and-heading':
        return uiIconSourceGenerator({ set: 'ion', name: 'compass-outline' });
      case 'location-unavailable':
      default:
        return uiIconSourceGenerator({ set: 'material', name: 'location-disabled' });
    }
  }, [trackingStatus]);
  const styles = useMemo(
    () =>
      StyleSheet.create({
        fab: {
          elevation: 6,
          flexGrow: 0,
          alignSelf: 'center',
        },
      }),
    [],
  );

  return (
    <FAB
      accessible
      animated={false}
      style={[styles.fab, style]}
      icon={icon}
      disabled={disabled}
      onPress={onPress}
      variant="secondary"
    />
  );
};

export default LocateMeButton;

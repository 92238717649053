import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { HelperText, Modal, Portal, Surface, useTheme } from 'react-native-paper';
import { Linking, Platform, View, ViewProps } from 'react-native';
import { NotificationFeedbackType } from 'expo-haptics';
import LockAnimation, { LockAnimationStatus } from './LockAnimation';
import { UnlockError, TroubleshootingDialog } from './BitwardsUtils';
import { useHardwareStatus } from '../context/HardwareContext';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import { UiDefaultIconName } from '../components/SimpleListIcon';
import { Haptics } from '../components/haptics/Haptics';
import SummaryHeader from '../components/SummaryHeader';
import { BlurOverlay } from '../components/BlurOverlay';
import Typography from '../components/Typography';
import { ListItemRow } from '../components/ListItemRow';

export type UnlockStage =
  | 'IDLE'
  | 'STARTING'
  | 'INITIALIZING'
  | 'ENABLING_BLUETOOTH'
  | 'SCANNING'
  | 'ACCESSING'
  | 'OPEN';

export type BitwardsTranslationId =
  | `bitward.errorInStage.${UnlockStage}`
  | 'bitward.unknownError.actions.close'
  | 'bitward.unknownError.actions.help'
  | 'bitward.unknownError.actions.retry'
  | 'bitward.unknownError.summaryDescription'
  | 'bitward.unknownError.summaryTitle'
  | 'bitward.unknownError.extraText'
  | 'bitward.grantLocationPermission.actions.close'
  | 'bitward.grantLocationPermission.actions.grant'
  | 'bitward.grantLocationPermission.summaryDescription'
  | 'bitward.grantLocationPermission.summaryTitle'
  | 'bitward.enableBluetooth.actions.close'
  | 'bitward.requestBluetoothPermissionIOS.actions.goToSettings'
  | 'bitward.requestBluetoothPermissionIOS.summaryDescription'
  | 'bitward.requestBluetoothPermissionIOS.summaryTitle'
  | 'bitward.requestBluetoothPermissionAndroid.summaryDescription'
  | 'bitward.requestBluetoothPermissionAndroid.summaryTitle'
  | 'bitward.requestBluetoothPermissionAndroid.actions.grant'
  | 'bitward.enableLocation.actions.close'
  | 'bitward.enableLocation.actions.openSettings'
  | 'bitward.enableLocation.actions.enable'
  | 'bitward.enableLocation.summaryDescription'
  | 'bitward.enableLocation.summaryTitle'
  | 'bitward.enableBluetooth.summaryDescription'
  | 'bitward.enableBluetooth.summaryTitle'
  | 'bitward.enableBluetooth.actions.enable'
  | 'bitward.enableBluetooth.actions.openSettings'
  | 'bitward.successUnlock.summaryDescription'
  | 'bitward.successUnlock.summaryTitle'
  | 'bitward.unlocking.summaryTitle'
  | `bitward.unlocking.${UnlockStage}`;

type UnlockErrorDetailsProps = ViewProps & {
  t: (s: BitwardsTranslationId) => string;
  unlockStage: UnlockStage;
  unlockError: UnlockError | null;
};

const UnlockErrorDetails: FC<UnlockErrorDetailsProps> = ({ unlockStage, unlockError, style, t, ...other }) => {
  let msg: string | undefined;
  const { colors } = useTheme();

  if (unlockError?.source === 'BITWARDS') {
    msg = `${unlockError.bitwardsMethod}(): ${unlockError.bitwardsError}`;
  } else if (unlockError?.source === 'APP') {
    switch (unlockError.error) {
      case 'BITWARDS_NOT_CONNECTED_AFTER_SUCCESSFUL_AUTH':
        msg = `${unlockStage} - isConnected(): FALSE_AFTER_CONNECT_SUCCESS`;
        break;
      case 'EXCEPTION_WHILE_ACCESSING_RESOURCE':
        msg = `Exception while accessing: ${unlockError.exception}`;
        break;
      case 'EXCEPTION_WHILE_INTIALIZING':
        msg = `Exception while initializing: ${unlockError.exception}`;
        break;
      case 'USER_RIGHTS_NOT_VALID_FOR_RESOURCE':
        msg = `Unlock not allowed for user`;
        break;
      case 'SCAN_NOT_STARTED':
        msg = `Bluetooth scan for locks not started`;
        break;
    }
  }
  if (!msg) return null;
  return (
    <View
      style={[
        {
          marginBottom: 10,
          backgroundColor: colors.surface,
          marginHorizontal: 16,
        },
        style,
      ]}
    >
      <HelperText visible={msg !== undefined} type={'info'} {...other}>
        {t(`bitward.errorInStage.${unlockStage}` as BitwardsTranslationId)}
      </HelperText>
      <HelperText visible={msg !== undefined} type={'info'} {...other}>
        {msg}
      </HelperText>
    </View>
  );
};

type PopupPayload = {
  title: string;
  description: string;
  extraText?: string;
  actions: {
    title: string;
    onPress: () => Promise<any>;
    iconName: UiDefaultIconName;
  }[];
};

interface UnlockingModalProps {
  visible: boolean;
  onClose: () => Promise<void>;
  unlockStage: UnlockStage;
  unlockError: UnlockError | null;
  retryUnlock: () => any;
  errorDialog: TroubleshootingDialog | null;
  onTryEnablingBluetooth: () => Promise<void>;
  t: (s: BitwardsTranslationId) => string;
  onHelpClicked: () => any;
}

const UnlockingModal: FC<UnlockingModalProps> = ({
  visible,
  unlockStage,
  errorDialog,
  unlockError,
  retryUnlock,
  onTryEnablingBluetooth,
  onHelpClicked,
  t,
  onClose,
}) => {
  const mp = useMixpanel();
  const { setLocationServicesEnabled, setBluetoothPermission, setLocationPermission } = useHardwareStatus();

  const handleOnClose = useCallback(async () => {
    mp?.track('Unlock modal closing');
    await onClose();
  }, [mp, onClose]);

  const handleOnRetry = useCallback(async () => {
    mp?.track('Unlock attempt retry');
    return retryUnlock();
  }, [retryUnlock, mp]);

  const handleEnableLocation = useCallback(async () => {
    mp?.track('Attempting to enable location services');
    await setLocationServicesEnabled(true);
  }, [mp, setLocationServicesEnabled]);

  const handleLocationPermissionRequest = useCallback(async () => {
    mp?.track('Requesting location permissions');
    await setLocationPermission();
  }, [mp, setLocationPermission]);

  const handleBluetoothPermissionRequest = useCallback(async () => {
    mp?.track('Requesting bluetooth scan and bluetooth connect permissions');
    await setBluetoothPermission();
  }, [mp, setBluetoothPermission]);

  const payload = useMemo((): PopupPayload | null => {
    if (!errorDialog) {
      if (unlockStage === 'OPEN') {
        return {
          title: t('bitward.successUnlock.summaryTitle'),
          description: t('bitward.successUnlock.summaryDescription'),
          actions: [],
        };
      }
      return {
        title: t('bitward.unlocking.summaryTitle'),
        description: t(`bitward.unlocking.${unlockStage}` as BitwardsTranslationId),
        actions: [],
      };
    }
    switch (errorDialog) {
      case 'BLUETOOTH_HW_OFF':
        return {
          title: t('bitward.enableBluetooth.summaryTitle'),
          description: t('bitward.enableBluetooth.summaryDescription'),
          actions: [
            {
              title:
                Platform.OS === 'android'
                  ? t('bitward.enableBluetooth.actions.enable')
                  : t('bitward.enableBluetooth.actions.openSettings'),
              iconName: 'support',
              onPress: onTryEnablingBluetooth,
            },
            {
              title: t('bitward.enableBluetooth.actions.close'),
              iconName: 'close',
              onPress: handleOnClose,
            },
          ],
        };
      case 'LOCATION_HW_OFF':
        return {
          title: t('bitward.enableLocation.summaryTitle'),
          description: t('bitward.enableLocation.summaryDescription'),
          actions: [
            {
              title:
                Platform.OS === 'android'
                  ? t('bitward.enableLocation.actions.enable')
                  : t('bitward.enableLocation.actions.openSettings'),
              iconName: 'support',
              onPress: handleEnableLocation,
            },
            {
              title: t('bitward.enableLocation.actions.close'),
              iconName: 'close',
              onPress: handleOnClose,
            },
          ],
        };
      case 'BLUETOOTH_PERMISSION_IOS':
        return {
          title: t('bitward.requestBluetoothPermissionIOS.summaryTitle'),
          description: t('bitward.requestBluetoothPermissionIOS.summaryDescription'),
          actions: [
            {
              title: t('bitward.requestBluetoothPermissionIOS.actions.goToSettings'),
              iconName: 'support',
              onPress: async () => {
                if (Platform.OS === 'ios') {
                  await Linking.openSettings();
                  await handleOnClose();
                }
              },
            },
            {
              title: t('bitward.enableBluetooth.actions.close'),
              iconName: 'close',
              onPress: handleOnClose,
            },
          ],
        };
      case 'BLUETOOTH_PERMISSION_ANDROID':
        return {
          title: t('bitward.requestBluetoothPermissionAndroid.summaryTitle'),
          description: t('bitward.requestBluetoothPermissionAndroid.summaryDescription'),
          actions: [
            {
              title: t('bitward.requestBluetoothPermissionAndroid.actions.grant'),
              iconName: 'support',
              onPress: async () => {
                if (Platform.OS === 'android') {
                  return handleBluetoothPermissionRequest();
                }
              },
            },
            {
              title: t('bitward.enableBluetooth.actions.close'),
              iconName: 'close',
              onPress: handleOnClose,
            },
          ],
        };
      case 'LOCATION_PERMISSION':
        return {
          title: t('bitward.grantLocationPermission.summaryTitle'),
          description: t('bitward.grantLocationPermission.summaryDescription'),
          actions: [
            {
              title: t('bitward.grantLocationPermission.actions.grant'),
              iconName: 'support',
              onPress: () => {
                return handleLocationPermissionRequest();
              },
            },
            {
              title: t('bitward.grantLocationPermission.actions.close'),
              iconName: 'close',
              onPress: handleOnClose,
            },
          ],
        };
      default:
        return {
          title: t('bitward.unknownError.summaryTitle'),
          description: t('bitward.unknownError.summaryDescription'),
          extraText: t('bitward.unknownError.extraText'),
          actions: [
            {
              title: t('bitward.unknownError.actions.retry'),
              iconName: 'reload',
              onPress: handleOnRetry,
            },
            {
              title: t('bitward.unknownError.actions.help'),
              iconName: 'support',
              onPress: () => {
                onHelpClicked();
                return handleOnClose();
              },
            },
            {
              title: t('bitward.unknownError.actions.close'),
              iconName: 'check',
              onPress: handleOnClose,
            },
          ],
        };
    }
  }, [
    t,
    errorDialog,
    handleEnableLocation,
    handleOnClose,
    handleOnRetry,
    handleLocationPermissionRequest,
    handleBluetoothPermissionRequest,
    onTryEnablingBluetooth,
    unlockStage,
    onHelpClicked,
  ]);

  const animationStatus = useMemo((): LockAnimationStatus => {
    if (errorDialog) return 'UNLOCK_FAIL';
    if (unlockStage === 'OPEN') return 'UNLOCK_SUCCESS';
    if (unlockStage === 'IDLE') return 'LOCKED';
    return 'UNLOCKING';
  }, [errorDialog, unlockStage]);

  useEffect(() => {
    switch (animationStatus) {
      case 'UNLOCK_SUCCESS':
        Haptics.notificationAsync(NotificationFeedbackType.Success);
        break;
      case 'UNLOCK_FAIL':
        Haptics.notificationAsync(NotificationFeedbackType.Error);
        break;
    }
  }, [animationStatus]);

  return (
    <Portal>
      {visible && (
        <BlurOverlay>
          <Modal dismissable={false} visible={visible} onDismiss={handleOnClose} style={{ marginHorizontal: 16 }}>
            <Surface>
              <View style={{ overflow: 'hidden' }}>
                <SummaryHeader
                  style={{ marginHorizontal: 16 }}
                  title={payload?.title ?? ''}
                  description={payload?.description ?? ''}
                  icon={'link'}
                />
                {animationStatus !== 'UNLOCK_FAIL' ? (
                  <LockAnimation
                    style={{ alignItems: 'center' }}
                    unlockStatus={animationStatus}
                    onUnlockCompleted={handleOnClose}
                  />
                ) : null}
                <UnlockErrorDetails unlockStage={unlockStage} unlockError={unlockError} t={t} />
                {payload?.extraText ? (
                  <Typography variant="body2" style={{ marginHorizontal: 16 }}>
                    {payload.extraText}
                  </Typography>
                ) : null}
                <View style={{ paddingBottom: 20 }}>
                  {payload?.actions.map((action, index) => {
                    return (
                      <ListItemRow
                        // eslint-disable-next-line react/no-array-index-key
                        key={`action-${action.title}-${index}`}
                        onPress={action.onPress}
                        leftIcon={action.iconName}
                        title={action.title}
                        titleNumberOfLines={2}
                      />
                    );
                  })}
                </View>
              </View>
            </Surface>
          </Modal>
        </BlurOverlay>
      )}
    </Portal>
  );
};

export default UnlockingModal;

import axios, { AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';

export enum TokenType {
  NONE,
  BEARER,
  COGNITO,
}
export const createAxiosInstance = (config?: AxiosRequestConfig, tokenType?: TokenType) => {
  const instance = axios.create(config);
  if (tokenType !== TokenType.NONE) {
    instance.interceptors.request.use(async (interceptorConfig) => {
      const user = await Auth.currentAuthenticatedUser();
      const cognitoToken = user?.signInUserSession?.idToken?.jwtToken;

      let authorization;
      switch (tokenType) {
        case TokenType.BEARER:
          authorization = cognitoToken ? `Bearer ${cognitoToken}` : undefined;
          break;
        case TokenType.COGNITO:
          authorization = cognitoToken;
          break;
      }

      return {
        ...interceptorConfig,
        headers: {
          Authorization: authorization,
          ...interceptorConfig.headers,
        },
      };
    });
  }
  instance.interceptors.response.use((response) => response.data);
  return instance;
};

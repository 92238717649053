import React, { FC } from 'react';
import { Dimensions, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

const Loading: FC = () => {
  return (
    <View
      style={{
        flex: 1,
        minHeight: 300,
        maxHeight: Dimensions.get('screen').height,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ActivityIndicator />
    </View>
  );
};

export default Loading;

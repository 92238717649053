import React, { useCallback, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { Formik } from 'formik';
import * as yup from 'yup';

import { FormikHelpers } from 'formik/dist/types';
import Container from '../../components/Container';
import FormikInput from '../../components/formik/FormikInput';
import { useModal } from '../../context/ModalContext';
import * as adminApi from '../../apis/adminApis';
import ScrollablePage from '../../components/ui/ScrollablePage';

import { AdminConfirmModalContent, RawAdminCommandOutput, SendAdminCommandButton } from './AdminTools';
import { useI18n } from '../../context/I18nContext';

interface RequestForm {
  batchId: string;
  source: string;
  valueEuros: string;
  count: string;
  currency: string;
  expiryDate: string;
}

export default function CreateVouchers({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const [initForm] = useState<RequestForm>({
    batchId: '',
    source: '',
    count: '',
    valueEuros: '',
    currency: 'EUR',
    expiryDate: new Date().toISOString().split('T')[0],
  });
  const [request, setRequest] = useState<Promise<any>>();
  const { parseDecimal } = useI18n();

  const modal = useModal();

  const validateSchema = useMemo(() => {
    return yup.object().shape({
      batchId: yup.string().required(),
      source: yup.string().required(),
      currency: yup.string().required(),
      valueEuros: yup
        .string()
        .required()
        .test('is-parseable', 'not a number', (v: any) => !!v && parseDecimal(v) !== null),
      expiryDate: yup.date().min(new Date()).required(),
    });
  }, [parseDecimal]);

  const handleAction = useCallback(
    (form: RequestForm) => {
      const p = adminApi.vouchersCreate(
        form.source,
        form.batchId,
        form.currency,
        (parseDecimal(form.valueEuros) ?? 0) * 100,
        parseInt(form.count, 10),
        form.expiryDate,
      );
      p.finally(() => modal.closeModal());
      setRequest(p);
      return p;
    },
    [modal, parseDecimal],
  );

  const handleSubmitForm = useCallback(
    async (form: RequestForm, formikHelper: FormikHelpers<RequestForm>) => {
      modal.openModal({
        content: <AdminConfirmModalContent onDismiss={() => modal.closeModal()} onConfirm={() => handleAction(form)} />,
      });
    },
    [modal, handleAction],
  );
  return (
    <ScrollablePage
      summary={{
        title: 'Create vouchers',
        description: 'Create a batch of vouchers',
        icon: 'pencil',
      }}
    >
      <Container>
        <Formik initialValues={initForm} onSubmit={handleSubmitForm} validationSchema={validateSchema}>
          {({ values, handleChange, handleSubmit, isSubmitting }) => (
            <>
              <FormikInput
                name={'batchId'}
                mode="outlined"
                keyboardType="default"
                autoComplete="off"
                autoCapitalize="sentences"
                label={'Batch Id (aka internal note)'}
              />
              <FormikInput
                name={'valueEuros'}
                mode="outlined"
                keyboardType="numeric"
                autoComplete="off"
                autoCapitalize="none"
                label={'Value in euros (not cents)'}
              />
              <FormikInput
                name={'currency'}
                mode="outlined"
                keyboardType="default"
                autoComplete="off"
                autoCapitalize="none"
                label={'Currency (i.e. EUR)'}
              />
              <FormikInput
                name={'count'}
                mode="outlined"
                keyboardType="numeric"
                autoComplete="off"
                autoCapitalize="none"
                label={'How many'}
              />
              <FormikInput
                name={'source'}
                mode="outlined"
                keyboardType="default"
                autoComplete="off"
                autoCapitalize="none"
                label={'Code owner (visible to user)'}
              />

              <FormikInput
                name={'expiryDate'}
                mode="outlined"
                keyboardType={Platform.OS === 'ios' ? 'numbers-and-punctuation' : 'numeric'}
                autoComplete="off"
                autoCapitalize="none"
                label={'Expiry date (i.e. 2021-12-31)'}
              />

              <SendAdminCommandButton handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
            </>
          )}
        </Formik>
        <RawAdminCommandOutput request={request} />
      </Container>
    </ScrollablePage>
  );
}

import React, { FC, PropsWithChildren } from 'react';
import { SafeAreaView, View } from 'react-native';
import { useTheme } from 'react-native-paper';

const IntroWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { colors } = useTheme();
  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <SafeAreaView style={{ flex: 1 }}>{children}</SafeAreaView>
    </View>
  );
};

export default IntroWrapper;

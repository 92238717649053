import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { getData, InternalStorageItemKey, storeData } from '../utils/internalStorage';

const noop = () => {
  console.error('No provider found, please wrap the app in PreferenceProvider');
};

const DEFAULT_UNIT = 'metric';

interface UnitContextValues {
  unit: string;
  handleChange(unit: string): void;
}

export const PreferenceContext = createContext<UnitContextValues>({
  unit: DEFAULT_UNIT,
  handleChange: noop,
});

interface Props {
  renderWhileInitializing?: boolean;
}

export const usePreference = () => {
  return useContext(PreferenceContext);
};

const PreferenceProvider: FC<PropsWithChildren<Props>> = ({ children, renderWhileInitializing = true }) => {
  const [initializing, setInitializing] = useState(true);
  const [currentUnit, setCurrentUnit] = useState(DEFAULT_UNIT);

  useEffect(() => {
    getData<string>(InternalStorageItemKey.PREFERRED_UNIT).then((unit) => {
      setInitializing(false);
      setCurrentUnit(unit ?? DEFAULT_UNIT);
    });
  }, []);
  useEffect(() => {
    storeData(InternalStorageItemKey.PREFERRED_UNIT, currentUnit);
  }, [currentUnit]);

  const renderChild = () => {
    if (initializing && !renderWhileInitializing) {
      return null;
    }
    return React.Children.only(children);
  };

  const contextValues = useMemo(() => ({ unit: currentUnit, handleChange: setCurrentUnit }), [currentUnit]);

  return <PreferenceContext.Provider value={contextValues}>{renderChild()}</PreferenceContext.Provider>;
};

export default PreferenceProvider;

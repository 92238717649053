import { useLayoutEffect, useState } from 'react';
import { Dimensions, ScaledSize } from 'react-native';

export default function useScreenSize() {
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('screen').width);
  const [screenHeight, setScreenHeight] = useState(Dimensions.get('screen').height);
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);
  const [windowHeight, setWindowHeight] = useState(Dimensions.get('window').height);

  const handleWindowWidth = ({ window }: { window: ScaledSize }) => setWindowWidth(window.width);
  const handleWindowHeight = ({ window }: { window: ScaledSize }) => setWindowHeight(window.height);

  const handleScreenWidth = ({ screen }: { screen: ScaledSize }) => setScreenWidth(screen.width);
  const handleScreenHeight = ({ screen }: { screen: ScaledSize }) => setScreenHeight(screen.height);

  useLayoutEffect(() => {
    const windowWidthListener = Dimensions.addEventListener('change', handleWindowWidth);
    const windowHeightListener = Dimensions.addEventListener('change', handleWindowHeight);
    const screenWidthListener = Dimensions.addEventListener('change', handleScreenWidth);
    const screenHeightListener = Dimensions.addEventListener('change', handleScreenHeight);
    return () => {
      windowWidthListener.remove();
      windowHeightListener.remove();
      screenWidthListener.remove();
      screenHeightListener.remove();
    };
  }, []);

  return { windowWidth, windowHeight, screenWidth, screenHeight };
}

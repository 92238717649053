import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Formik, FormikProps } from 'formik';
import { useMutation } from 'react-query';
import { StackScreenProps } from '@react-navigation/stack';
import * as Sentry from '../../components/sentry/sentry';
import Images from '../../../assets/images';
import FormikInput from '../../components/formik/FormikInput';
import { useI18n } from '../../context/I18nContext';
import { signupSchema } from '../../utils/inputValidation';
import GradientButton from '../../components/GradientButton';
import { useAuth } from '../../context/AuthContext';
import { screenNames } from '../../navigators/screenNames';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import { fetchMyProfile, updateProfile } from '../../apis/appsyncApis';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { RootStackRouteParams } from '../../navigators/RootNavigator';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';

interface Form {
  firstname: string;
  lastname: string;
  organization: string;
}

const CompleteRegistration: React.FC<StackScreenProps<RootStackRouteParams, 'CompleteRegistration'>> = ({
  navigation,
}) => {
  const [initValues, _setInitValues] = useState<Form>({
    firstname: '',
    lastname: '',
    organization: '',
  });
  const { I18n } = useI18n();
  const mp = useMixpanel();
  const auth = useAuth();
  const validateSchema = useMemo(() => signupSchema(I18n), [I18n]);

  useEffect(() => {
    mp?.timeEvent('Profile info added');
  }, [mp]);

  const onSuccessfullyRegistered = async () => {
    const profile = await fetchMyProfile();
    const registrationCode = profile.data?.getMyProfile?.registerInviteCode;
    mp?.track('Profile info added');
    mp?.track('Registration');
    if (registrationCode) {
      mp?.track('Welcome Questionnaire', { source: 'Invite', code: registrationCode });
      mp?.getPeople().set('Source', registrationCode);
      navigation.navigate(screenNames.WelcomeWithInvite);
    } else {
      navigation.navigate(screenNames.WelcomeWithoutInvite);
    }

    // auth.refreshAuthState(); -> TODO: uncomment or handle auth state refresh in welcome screen
  };

  const submitUpdateMutation = useMutation(
    async (form: Form) => {
      mp?.getPeople().set('$first_name', form.firstname);
      mp?.getPeople().set('$last_name', form.lastname);
      mp?.getPeople().set('Organisation', form.organization);
      await updateProfile({
        phonenumber: auth.cognitoUser?.getUsername(),
        firstname: form.firstname,
        lastname: form.lastname,
        organization: form.organization,
      });
    },
    {
      onSuccess: () => {
        onSuccessfullyRegistered();
      },
      onError: (error: any) => {
        const errorCode = error?.errors?.[0].data;
        console.log(JSON.stringify(error?.errors));
        Sentry.captureException(new Error(errorCode));
        auth.refreshAuthState();
      },
    },
  );
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);
  return (
    <Formik
      initialValues={initValues}
      onSubmit={(form) => submitUpdateMutation.mutate(form)}
      validationSchema={validateSchema}
      validateOnMount={true}
    >
      {({ handleSubmit, isValid }: FormikProps<Form>) => (
        <ScrollablePage
          summary={{
            title: I18n.t('signup.title'),
            description: I18n.t('signup.subtitle'),
            icon: <Images.iconProfile />,
            iconOpacity: 0.38,
          }}
        >
          <Container>
            <FormikInput
              autoFocus={true}
              name={'firstname'}
              label={I18n.t('inputFields.firstname')}
              autoCapitalize={'words'}
            />
            <FormikInput
              name={'lastname'}
              label={I18n.t('inputFields.lastname')}
              style={styles.input}
              autoCapitalize={'words'}
            />
            <FormikInput
              name={'organization'}
              label={I18n.t('inputFields.organisation')}
              style={styles.input}
              autoCapitalize={'words'}
            />

            <Spacer type="section" />

            <GradientButton
              style={{ marginBottom: 15 }}
              loading={submitUpdateMutation.isLoading}
              onPress={() => handleSubmit()}
              disabled={submitUpdateMutation.isLoading || !isValid}
            >
              {I18n.t('general.continue')}
            </GradientButton>
          </Container>
        </ScrollablePage>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  input: {
    marginTop: 10,
  },
});

export default CompleteRegistration;

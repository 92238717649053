import { differenceInDays, differenceInMinutes } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { format as formatTzFn } from 'date-fns-tz';
import { Pod, CompoundReservation } from '../types/misc.types';
import { flattenDict } from './helpers';
import { AppsyncData, AppsyncResult } from '../apis/appsyncHelper';
import { MixpanelLibraryType } from '../mixpanel/Mixpanel';

function startEndHours(
  startDate: Date,
  endDate: Date,
  podTz: string | undefined | null,
): [number, number, number[], string] {
  const startHour = parseInt(formatTzFn(startDate, 'H', { locale: enGB, timeZone: podTz ?? undefined }), 10);
  // If the end time is exactly 14:00, we shouldn't mark 14 as occupied, hence the -1 in endHour.
  const onTheNewHour = !parseInt(formatTzFn(endDate, 'm', { locale: enGB, timeZone: podTz ?? undefined }), 10);
  const endHour =
    parseInt(formatTzFn(endDate, 'H', { locale: enGB, timeZone: podTz ?? undefined }), 10) + (onTheNewHour ? -1 : 0);
  const occupiedHours: number[] = [];
  for (
    let occupiedHour = Math.min(23, Math.max(0, startHour));
    occupiedHour <= Math.min(23, Math.max(0, endHour));
    occupiedHour = (occupiedHour + 1) % 24
  ) {
    occupiedHours.push(occupiedHour);
    // Quick fix to prevent infinite loop when endHour is over 23.
    if (occupiedHour === 23) break;
  }
  const weekday = formatTzFn(startDate, 'EEEE', { locale: enGB, timeZone: podTz ?? undefined });
  return [startHour, endHour, occupiedHours, weekday];
}

export function trackMakeReservation(
  mp: MixpanelLibraryType | undefined,
  makeAReservation: AppsyncData<'makeAReservation'>['makeAReservation'],
  reservedSpace: Pod,
  moneyToUse: number,
  creditsToUse: number,
  currency: string,
) {
  if (!mp) return;
  if (!makeAReservation) return;
  const startDate = new Date(makeAReservation.from!);
  const endDate = new Date(makeAReservation.to!);

  if (moneyToUse > 0) {
    mp.track('Reservation payment success', flattenDict('reservation', makeAReservation));
    mp.getPeople().increment('Payment successes', 1);
  }
  // Fyi: Charges are tracked only on server side
  const [startHour, endHour, occupiedHours, weekday] = startEndHours(
    startDate,
    endDate,
    makeAReservation.building?.timezone,
  );

  mp.track('Reservation made', {
    /* Building */
    buildingName: reservedSpace.building?.title ?? makeAReservation.building?.title ?? '',
    buildingRef: makeAReservation.buildingRef,

    /* Space */
    spaceId: reservedSpace.localRef,
    spaceName: reservedSpace.title,
    layerIndex: reservedSpace.layerIndex,
    poisId: makeAReservation?.poisId,

    /* Reservation */
    reservationId: makeAReservation.id,
    receipt: makeAReservation.receiptUrl,

    /* Money */
    usedDefaultCard: makeAReservation?.card?.is_default,
    payed: moneyToUse, // Legacy
    moneyUsed: moneyToUse,
    creditsUsed: creditsToUse,
    price: moneyToUse + creditsToUse,
    currency,

    /* Time things */
    startTime: makeAReservation?.from,
    endTime: makeAReservation?.to,
    durationMinutes: differenceInMinutes(endDate, startDate),
    inAdvanceAsDays: Math.max(0, differenceInDays(new Date(), startDate)),
    inAdvanceAsMinutes: Math.max(0, differenceInMinutes(new Date(), startDate)),
    weekday,
    startHour24LocalTime: startHour,
    endHour24LocalTime: endHour,
    occupiedHours,
  });

  // Total reservations
  mp.getPeople().increment('Reservations', 1);

  // First pod
  mp.getPeople().setOnce('First space', reservedSpace.localRef);
  mp.getPeople().setOnce(
    'First space building',
    reservedSpace.building?.title ?? makeAReservation?.building?.title ?? '',
  );
  mp.getPeople().setOnce('First space buildingRef', reservedSpace.buildingRef ?? '');
  mp.getPeople().setOnce('First reservation made', new Date().toISOString());
}

// This takes currentReservation as an array because of typing.
export function trackExtendReservation(
  mp: MixpanelLibraryType | undefined,
  extendAReservation: AppsyncData<'extendAReservation'>['extendAReservation'],
  currentReservation: CompoundReservation | null | undefined,
  moneyUsed: number,
  creditsUsed: number,
  currency: string,
) {
  if (!mp) return;
  if (!extendAReservation) return;
  const startDate = new Date(currentReservation?.to!); // Start of extension is end of current
  const endDate = new Date(extendAReservation.to!);
  const podTz = extendAReservation.building?.timezone ?? undefined;

  const [startHour, endHour, occupiedHours, weekday] = startEndHours(startDate, endDate, podTz);
  const firstReservation = currentReservation?.originals[0];
  mp.track('Reservation extended', {
    /* Building */
    buildingName: firstReservation?.building?.title ?? extendAReservation?.building?.title ?? '',
    buildingRef: firstReservation?.buildingRef ?? extendAReservation?.buildingRef ?? '',

    /* Space */
    spaceId: currentReservation?.spaceId ?? extendAReservation?.pois?.localRef ?? '',
    spaceName: firstReservation?.pois?.title ?? extendAReservation?.pois?.title ?? '',
    layerIndex: firstReservation?.pois?.layerIndex ?? extendAReservation?.pois?.layerIndex ?? '',
    poisId: firstReservation?.poisId,

    /* Reservation */
    reservationId: extendAReservation?.id,
    receipt: extendAReservation?.receiptUrl,

    /* Money */
    usedDefaultCard: extendAReservation?.card?.is_default,
    payed: moneyUsed, // Legacy
    moneyUsed,
    creditsUsed,
    price: moneyUsed + creditsUsed,
    currency,

    /* Time things */
    startTime: extendAReservation?.from,
    endTime: extendAReservation?.to,
    durationMinutes: differenceInMinutes(endDate, startDate),
    inAdvanceAsDays: Math.max(0, differenceInDays(new Date(), startDate)),
    inAdvanceAsMinutes: Math.max(0, differenceInMinutes(new Date(), startDate)),
    weekday,
    startHour24LocalTime: startHour,
    endHour24LocalTime: endHour,
    occupiedHours,
  });

  mp.getPeople().increment('Extended reservation', 1);
}

export function trackProfile(mp: MixpanelLibraryType | undefined, profileQuery: AppsyncResult<'getMyProfile'>) {
  const profile = profileQuery.data?.getMyProfile;
  if (mp && profile) {
    mp.getPeople().set('$first_name', profile.firstname);
    mp.getPeople().set('$last_name', profile.lastname);
    mp.getPeople().set('Organisation', profile.organization);
    mp.getPeople().set('language', profile.language);
    mp.getPeople().set('Phone number', profile.phonenumber);
    mp.getPeople().set('Invite code', profile.inviteCode);
  }
}

import { useQuery } from 'react-query';
import { Auth } from 'aws-amplify';
import { UrlAction } from '../utils/urlHandler';
import {
  QueryGetBuildingsWithinRadiusArgs,
  QueryGetPoisByCodeArgs,
  QueryGetPoisByIdArgs,
  QueryGetVoucherByCodeArgs,
  TimeRangeInput,
} from '../types/appsync-types';
import { appsyncGraphql, publicAppsyncGraphql } from './appsyncHelper';

getBuildingsWithinRadius.id = 'getBuildingsWithinRadius';

export async function getBuildingsWithinRadius({
  variable = {
    lat: 60.248962,
    lon: 25.011763,
    radius: 1000000,
  },
  poiStatusFilter,
}: {
  variable?: QueryGetBuildingsWithinRadiusArgs;
  poiStatusFilter: TimeRangeInput;
}) {
  const query = /* GraphQL */ `
    query GetBuildingsWithinRadius(
      $lat: Float
      $lon: Float
      $radius: Float
      $input: TimeRangeInput
      $from: AWSDateTime
    ) {
      getBuildingsWithinRadius(lat: $lat, lon: $lon, radius: $radius) {
        id
        lat
        lon
        buildingRef
        title
        timezone
        buildingStatus(input: $input) {
          buildingRef
          from
          to
          bookable
          total
        }
        spaces {
          id
          lat
          lon
          buildingRef
          localRef
          layerIndex
          title
          bitwardId
          type
          depositAmountInCents
          currency
          building {
            timezone
          }
          availabilityStatus(from: $from) {
            nextFreeStart
            nextFreeEnd
            status
          }
        }
      }
    }
  `;
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      return await appsyncGraphql<'getBuildingsWithinRadius'>(query, {
        ...variable,
        from: poiStatusFilter.from,
        input: poiStatusFilter,
      });
    }
    throw new Error('User not found');
  } catch (error) {
    return publicAppsyncGraphql<'getBuildingsWithinRadius'>(query, {
      ...variable,
      from: poiStatusFilter.from,
      input: poiStatusFilter,
    });
  }
}

// TODO: Split this to re-usable functions and fix the horrible naming continue from here
useGetQRPayloadValue.id = 'useGetQRPayloadValue';

export function useGetQRPayloadValue(payload: UrlAction | null) {
  return useQuery(
    [useGetQRPayloadValue.id, ...Object.values(payload ?? {})],
    async () => {
      if (!payload) {
        throw new Error('No payload yet');
      }
      if (payload.type === 'UNLOCK' || payload.type === 'SELECT') {
        const variables: QueryGetPoisByCodeArgs = {
          code: payload.id,
        };
        const query = /* GraphQL */ `
          query GetPoisByCode($code: String!) {
            getPoisByCode(code: $code) {
              id
              lat
              lon
              buildingRef
              localRef
              layerIndex
              title
              bitwardId
            }
          }
        `;

        const result = await appsyncGraphql<'getPoisByCode' | 'getVoucherByCode'>(query, variables);
        if (result.data?.getPoisByCode) {
          return result;
        }
        throw new Error('Pod not found');
      }
      if (payload.type === 'VOUCHER') {
        const variables: QueryGetVoucherByCodeArgs = {
          code: payload.id,
        };
        const query = /* GraphQL */ `
          query GetVoucherByCode($code: String!) {
            getVoucherByCode(code: $code) {
              voucherNumber
              voucherCode
              enabled
              used
              value
              currency
              sourceName
              creationDate
              expiryDate
            }
          }
        `;

        const result = await appsyncGraphql<'getPoisByCode' | 'getVoucherByCode'>(query, variables);
        if (result.data?.getVoucherByCode) {
          return result;
        }
        throw new Error('Voucher not found');
      }
    },
    { enabled: !!payload },
  );
}

useGetSpaceScheduleInfo.id = 'useGetSpaceScheduleInfo';

export function useGetSpaceScheduleInfo(podId: string, timeInput: TimeRangeInput) {
  return useQuery(
    [useGetSpaceScheduleInfo.id, podId, ...Object.values(timeInput)],
    async () => {
      const variables: QueryGetPoisByIdArgs = {
        id: podId,
      };
      const query = /* GraphQL */ `
        query GetPoisById($id: ID!, $input: TimeRangeInput, $openAt: AWSDateTime) {
          getPoisByID(id: $id) {
            id
            type
            lat
            lon
            buildingRef
            localRef
            layerIndex
            title
            bitwardId
            building {
              timezone
            }
            reservations(input: $input) {
              id
              userId
              status
              from
              to
            }
            openingHour(at: $openAt) {
              open
              from
              to
            }
            availabilityStatus(from: $openAt) {
              nextFreeStart
              nextFreeEnd
              status
            }
          }
        }
      `;

      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          return await appsyncGraphql<'getPoisByID'>(query, { ...variables, input: timeInput, openAt: timeInput.from });
        }
        throw new Error('User not found');
      } catch (error) {
        return publicAppsyncGraphql<'getPoisByID'>(query, { ...variables, input: timeInput, openAt: timeInput.from });
      }
    },
    {},
  );
}

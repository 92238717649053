import {
  PaymentIntentResult as PaymentIntentResultJS,
  Stripe as StripeJS,
  StripeElements,
  ConfirmCardPaymentOptions,
  ConfirmCardPaymentData,
  ConfirmAfterpayClearpayPaymentData,
  ConfirmAfterpayClearpayPaymentOptions,
} from '@stripe/stripe-js';
import type {
  PaymentMethod,
  ConfirmPaymentError,
  ConfirmPaymentResult,
  PaymentIntent,
} from '@stripe/stripe-react-native';
import {
  paymentIntentLastPaymentErrorToNative,
  paymentIntentShippingToNative,
  paymentIntentStatusToNative,
  intentNextActionToNative,
  paymentMethodIdToNative,
} from './stripe-converters.web';

const strangeTypescriptWorkaround: PaymentMethod.CardParams = {
  paymentMethodType: 'Card',
  paymentMethodData: { paymentMethodId: '' },
};

type PaymentMethodCreateParamsCardParams = typeof strangeTypescriptWorkaround;

function convertConfirmPaymentResult(resultJS: PaymentIntentResultJS): ConfirmPaymentResult {
  if (resultJS.paymentIntent) {
    const pi = resultJS.paymentIntent;
    return {
      paymentIntent: {
        id: pi.id,
        amount: pi.amount,
        created: new Date(pi.created).toISOString(),
        currency: pi.currency,
        status: paymentIntentStatusToNative(pi.status),
        description: pi.description,
        receiptEmail: pi.receipt_email,
        canceledAt: pi.canceled_at !== null ? new Date(pi.canceled_at).toISOString() : null,
        clientSecret: pi.client_secret ?? '',
        livemode: pi.livemode,
        paymentMethodId: paymentMethodIdToNative(pi.payment_method) ?? '',
        captureMethod: pi.capture_method === 'automatic' ? 'Automatic' : 'Manual',
        confirmationMethod: pi.confirmation_method === 'automatic' ? 'Automatic' : 'Manual',
        lastPaymentError: paymentIntentLastPaymentErrorToNative(pi.last_payment_error),
        shipping: paymentIntentShippingToNative(pi.shipping),
        nextAction: intentNextActionToNative(pi.next_action),
        paymentMethod: pi.payment_method as any, // TODO
      },
    };
  }
  return {
    error: {
      message: resultJS.error.message ?? 'Stripe-js error without error message',
      code: (resultJS.error.code ?? 'Unknown') as ConfirmPaymentError,
    },
  };
}

export function confirmPaymentWrapper(
  stripeJS: StripeJS,
  _elements: StripeElements | null,
  clientSecret: string,
  data?: PaymentIntent.ConfirmParams,
  options?: PaymentIntent.ConfirmOptions,
): Promise<ConfirmPaymentResult> {
  if (data?.paymentMethodType === 'Card') {
    const cardParams = data as PaymentMethodCreateParamsCardParams;

    const dataJs: ConfirmCardPaymentData = {
      payment_method: cardParams.paymentMethodData.paymentMethodId,
    };
    const optsJs: ConfirmCardPaymentOptions = {
      handleActions: true,
    };
    const p = stripeJS.confirmCardPayment(clientSecret, dataJs, optsJs);
    return p.then(convertConfirmPaymentResult);
  }
  if (data?.paymentMethodType === 'AfterpayClearpay') {
    const dataJs: ConfirmAfterpayClearpayPaymentData = {};
    const optsJs: ConfirmAfterpayClearpayPaymentOptions = {};
    const p = stripeJS.confirmAfterpayClearpayPayment(clientSecret, dataJs, optsJs);
    return p.then((e) => convertConfirmPaymentResult(e as PaymentIntentResultJS));
  }

  throw new Error('Not implemented');
}

import {
  CardElement,
  EpsBankElement,
  FpxBankElement,
  IbanElement,
  IdealBankElement,
  P24BankElement,
} from '@stripe/react-stripe-js';

import {
  PaymentMethodResult as PaymentMethodResultJS,
  CreatePaymentMethodData,
  Stripe as StripeJS,
  StripeElements as StripeElementsJS,
  CreatePaymentMethodGrabPayData,
  CreatePaymentMethodIdealData,
  CreatePaymentMethodOxxoData,
  CreatePaymentMethodP24Data,
  CreatePaymentMethodAlipayData,
  CreatePaymentMethodEpsData,
  CreatePaymentMethodSepaDebitData,
  CreatePaymentMethodFpxData,
  CreatePaymentMethodSofortData,
  StripeElements,
} from '@stripe/stripe-js';
import type { CreatePaymentMethodError, CreatePaymentMethodResult, PaymentMethod } from '@stripe/stripe-react-native';
import { billingDetailsToJS, paymentMethodToNative } from './stripe-converters.web';

function createPaymentMethodResultToNative(resultJS: PaymentMethodResultJS): CreatePaymentMethodResult {
  if (resultJS.paymentMethod) {
    return {
      paymentMethod: paymentMethodToNative(resultJS.paymentMethod),
    };
  }
  return {
    error: {
      message: resultJS.error.message ?? 'Error from stripe',
      code: resultJS.error.code as CreatePaymentMethodError,
    },
  };
}

function paymentMethodParamsToJs(
  params: PaymentMethod.CreateParams,
  elements: StripeElementsJS | null,
  options?: PaymentMethod.CreateOptions,
): CreatePaymentMethodData {
  if (params.paymentMethodType === 'Card') {
    const elem = elements?.getElement(CardElement);
    if (!elem) {
      throw new Error('Card element not found');
    }
    return { type: 'card', card: elem };
  }
  if (params.paymentMethodType === 'Ideal') {
    const elem = elements?.getElement(IdealBankElement);
    if (!elem) {
      throw new Error('Ideal Bank element not found');
    }
    const r: CreatePaymentMethodIdealData = {
      type: 'ideal',
      ideal: elem,
    };
    return r;
  }
  if (params.paymentMethodType === 'Oxxo') {
    const bdJs = billingDetailsToJS(params.paymentMethodData.billingDetails);
    if (!bdJs || !bdJs.email || !bdJs.name) {
      throw new Error('Email and name are required for Oxxo');
    }
    const r: CreatePaymentMethodOxxoData = {
      type: 'oxxo',
      billing_details: {
        ...bdJs,
        email: bdJs.email,
        name: bdJs.name,
      },
    };
    return r as unknown as CreatePaymentMethodData;
  }
  if (params.paymentMethodType === 'P24') {
    const bdJs = billingDetailsToJS(params.paymentMethodData.billingDetails);
    if (!bdJs || !bdJs.email) {
      throw new Error('Email is required for P24');
    }
    const elem = elements?.getElement(P24BankElement) ?? undefined;
    const r: CreatePaymentMethodP24Data = {
      type: 'p24',
      billing_details: {
        ...bdJs,
        email: bdJs.email,
      },
      p24: elem,
    };
    return r;
  }
  if (params.paymentMethodType === 'Alipay') {
    const r: CreatePaymentMethodAlipayData = {
      type: 'alipay',
    };
    return r;
  }
  if (params.paymentMethodType === 'GrabPay') {
    const r: CreatePaymentMethodGrabPayData = {
      type: 'grabpay',
      billing_details: billingDetailsToJS(params.paymentMethodData?.billingDetails),
    };
    return r;
  }
  if (params.paymentMethodType === 'Eps') {
    const bdJs = billingDetailsToJS(params.paymentMethodData.billingDetails);
    if (!bdJs || !bdJs.name) {
      throw new Error('Eps requires name');
    }
    const elem = elements?.getElement(EpsBankElement);
    if (!elem) {
      throw new Error('EPS Bank element not found');
    }
    const r: CreatePaymentMethodEpsData = {
      type: 'eps',
      billing_details: {
        ...bdJs,
        name: bdJs.name,
      },
      eps: elem,
    };
    return r;
  }
  if (params.paymentMethodType === 'SepaDebit') {
    const bdJs = billingDetailsToJS(params.paymentMethodData.billingDetails);
    if (!bdJs || !bdJs.name || !bdJs.email) {
      throw new Error('Name and email are required for sepa debit');
    }
    const r: CreatePaymentMethodSepaDebitData = {
      type: 'sepa_debit',
      billing_details: {
        ...bdJs,
        name: bdJs.name,
        email: bdJs.email,
      },
      sepa_debit: elements?.getElement(IbanElement) ?? { iban: params.paymentMethodData.iban },
    };
    return r;
  }
  if (params.paymentMethodType === 'Fpx') {
    const elem = elements?.getElement(FpxBankElement);
    if (!elem) {
      throw new Error('EPS Bank element not found');
    }
    const r: CreatePaymentMethodFpxData = {
      type: 'fpx',
      fpx: elem,
    };
    return r;
  }
  if (params.paymentMethodType === 'Sofort') {
    const r: CreatePaymentMethodSofortData = {
      type: 'sofort',
      sofort: {
        country: params.paymentMethodData.country,
      },
      billing_details: billingDetailsToJS(params.paymentMethodData.billingDetails),
    };
    return r;
  }
  throw new Error('Unsupported payment type');
}

export function createPaymentMethodWrapper(
  stripeJS: StripeJS,
  elements: StripeElements | null,
  data: PaymentMethod.CreateParams,
  options?: PaymentMethod.CreateOptions,
): Promise<CreatePaymentMethodResult> {
  const paymentMethodData = paymentMethodParamsToJs(data, elements);
  return stripeJS.createPaymentMethod(paymentMethodData).then(createPaymentMethodResultToNative);
}

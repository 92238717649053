import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { DefaultScreenOptions } from '../components/NavigatorAppbar';
import { useI18n } from '../context/I18nContext';
import SendPushNotification from '../screens/Admin/SendPushNotification';
import ShowPromoCode from '../screens/Admin/ShowPromoCode';
import CreatePromoCode from '../screens/Admin/CreatePromoCode';
import EnablePromoCode from '../screens/Admin/EnablePromoCode';
import ShowVouchers from '../screens/Admin/ShowVouchers';
import CreateVouchers from '../screens/Admin/CreateVouchers';
import EnableVouchers from '../screens/Admin/EnableVouchers';
import SuspendUser from '../screens/Admin/SuspendUser';
import ResetFirstTimeUse from '../screens/Admin/ResetFirstTimeUse';
import SetUserRole from '../screens/Admin/SetUserRole';
import { AdminTools } from '../screens/Admin/AdminTools';
import { AdminReservationsForRegionRouteParams, RegionReservations } from '../screens/Admin/RegionReservations';
import RegionsListing from '../screens/Admin/RegionsListing';
import { AdminReservationDetailsRouteParams, ReservationDetails } from '../screens/Admin/ReservationDetails';
import { SpaceReservations } from '../screens/Admin/SpaceReservations';
import { PhonenumberReservations } from '../screens/Admin/PhonenumberReservations';
import DeleteUser from '../screens/Admin/DeleteUser';

export type AdminStackRouteParams = {
  AdminTools: undefined;
  AdminSendPushNotification: undefined;
  AdminSetUserRole: undefined;
  AdminSuspendUser: undefined;
  AdminResetFirstTimeLogin: undefined;
  AdminCreateVouchers: undefined;
  AdminEnableVouchers: undefined;
  AdminShowVouchers: undefined;
  AdminCreatePromoCode: undefined;
  AdminEnablePromoCode: undefined;
  AdminShowPromoCode: undefined;
  AdminSpaceReservations: undefined;
  AdminRegionsListing: undefined;
  AdminRegionReservations: AdminReservationsForRegionRouteParams;
  AdminReservationDetails: AdminReservationDetailsRouteParams;
  AdminDeleteUser: undefined;
  AdminPhonenumberReservations: undefined;
};

const Stack = createStackNavigator<AdminStackRouteParams>();

export default function AdminStackNavigator() {
  const { I18n } = useI18n();

  return (
    <Stack.Navigator initialRouteName={'AdminTools'} screenOptions={DefaultScreenOptions}>
      <Stack.Screen
        name={'AdminTools'}
        component={AdminTools}
        options={{
          title: I18n.t('admin.title'),
        }}
      />
      <Stack.Screen
        name={'AdminSendPushNotification'}
        component={SendPushNotification}
        options={{
          title: I18n.t('admin.pushnotification.title'),
        }}
      />
      {/* User */}
      <Stack.Screen
        name={'AdminSetUserRole'}
        component={SetUserRole}
        options={{
          title: 'Role',
        }}
      />
      <Stack.Screen
        name={'AdminSuspendUser'}
        component={SuspendUser}
        options={{
          title: 'Suspend',
        }}
      />
      <Stack.Screen
        name={'AdminResetFirstTimeLogin'}
        component={ResetFirstTimeUse}
        options={{
          title: 'Reset first time login',
        }}
      />
      {/* Vouchers */}
      <Stack.Screen
        name={'AdminCreateVouchers'}
        component={CreateVouchers}
        options={{
          title: 'Create',
        }}
      />
      <Stack.Screen
        name={'AdminEnableVouchers'}
        component={EnableVouchers}
        options={{
          title: 'Enable',
        }}
      />
      <Stack.Screen
        name={'AdminShowVouchers'}
        component={ShowVouchers}
        options={{
          title: 'Show details',
        }}
      />
      {/* Promo and invite codes */}
      <Stack.Screen
        name={'AdminCreatePromoCode'}
        component={CreatePromoCode}
        options={{
          title: 'Create',
        }}
      />
      <Stack.Screen
        name={'AdminEnablePromoCode'}
        component={EnablePromoCode}
        options={{
          title: 'Enable',
        }}
      />
      <Stack.Screen
        name={'AdminShowPromoCode'}
        component={ShowPromoCode}
        options={{
          title: 'Show details',
        }}
      />
      <Stack.Screen
        name={'AdminRegionsListing'}
        component={RegionsListing}
        options={{
          title: 'Regions',
        }}
      />
      <Stack.Screen
        name={'AdminRegionReservations'}
        component={RegionReservations}
        options={{
          title: 'Reservations',
        }}
      />
      <Stack.Screen
        name={'AdminSpaceReservations'}
        component={SpaceReservations}
        options={{
          title: 'Reservations',
        }}
      />
      <Stack.Screen
        name={'AdminPhonenumberReservations'}
        component={PhonenumberReservations}
        options={{
          title: 'Reservations',
        }}
      />
      <Stack.Screen
        name={'AdminReservationDetails'}
        component={ReservationDetails}
        options={{
          title: 'Reservation',
        }}
      />
      <Stack.Screen
        name={'AdminDeleteUser'}
        component={DeleteUser}
        options={{
          title: 'Delete user',
        }}
      />
    </Stack.Navigator>
  );
}

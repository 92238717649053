import React, { useMemo } from 'react';
import Constants from 'expo-constants';
import { List } from 'react-native-paper';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { getTimeZone } from 'react-native-localize';
import { useMyNotificationPreferenceQuery, useMyProfileQuery } from '../apis/appsyncApis';
import { screenNames } from '../navigators/screenNames';
import { useI18n } from '../context/I18nContext';
import { usePreference } from '../context/PreferenceContext';
import ScrollablePage from '../components/ui/ScrollablePage';
import { useExtractedReservationData } from '../apis/reservationApis';
import { useActivePayment } from '../payment/MapViewActivePaymentContext';
import { SummaryHeaderProps } from '../components/SummaryHeader';
import { ListItemRow } from '../components/ListItemRow';

export default function MyInfoView({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const profileQuery = useMyProfileQuery();
  const profile = profileQuery?.data?.data?.getMyProfile;
  const notification = useMyNotificationPreferenceQuery();
  const { I18n, currentLocale, formatDate } = useI18n();
  const { unit: _unit } = usePreference();
  const { upcomingReservations, ongoingReservations } = useExtractedReservationData();

  const notificationPreferences: { [key: string]: boolean | string | null } = useMemo(
    () => notification.data?.data?.getMyNotificationPreference ?? {},
    [notification.data?.data?.getMyNotificationPreference],
  );
  const activePaymentMethod = useActivePayment();

  const summaryData = useMemo((): SummaryHeaderProps => {
    const name = `${profile?.firstname} ${profile?.lastname}`;
    const joinedDate = formatDate(new Date(Number(profile?.createdAt)), 'dateWithYear', getTimeZone());
    return {
      title: I18n.t('myinfo.hi', { name }),
      description: I18n.t('myinfo.memberSince', { time: joinedDate }),
      icon: 'user',
    };
  }, [I18n, formatDate, profile?.createdAt, profile?.firstname, profile?.lastname]);

  const allNotificationsOff: boolean = useMemo(
    () =>
      Object.keys(notificationPreferences)
        .filter((key) => key !== '__typename')
        .every((key) => {
          return !notificationPreferences[key];
        }),
    [notificationPreferences],
  );

  const reservationsSummary: string = useMemo(() => {
    if (ongoingReservations.length > 1) {
      return I18n.t('myinfo.ongoingReservations', { count: ongoingReservations.length });
    }
    if (ongoingReservations.length === 1) {
      return I18n.t('myinfo.ongoingReservation');
    }
    if (upcomingReservations.length > 1) {
      return I18n.t('myinfo.upcomingReservations', { count: upcomingReservations.length });
    }
    if (upcomingReservations.length === 1) {
      return I18n.t('myinfo.upcomingReservation');
    }
    return I18n.t('myinfo.noReservations');
  }, [ongoingReservations, upcomingReservations, I18n]);

  const paymentMethodSummary: string = useMemo(() => {
    return activePaymentMethod ? I18n.t('myinfo.hasPaymentMethod') : I18n.t('myinfo.noPaymentMethod');
  }, [I18n, activePaymentMethod]);

  return (
    <ScrollablePage summary={summaryData}>
      {/* Payments and bookings */}
      <List.Subheader>{I18n.t('myinfo.sections.reservations')}</List.Subheader>
      <ListItemRow
        title={I18n.t('myinfo.items.bookings')}
        description={reservationsSummary}
        leftIcon={'calendar'}
        onPress={() => navigation.navigate(screenNames.Bookings)}
        type="navigation"
      />

      <ListItemRow
        title={I18n.t('myinfo.items.payments')}
        description={paymentMethodSummary}
        leftIcon={'wallet'}
        onPress={() => navigation.navigate(screenNames.Payments)}
        type="navigation"
      />

      <ListItemRow
        title={I18n.t('myinfo.items.enrollCompany')}
        description={I18n.t('myinfo.suggestSpacehub')}
        leftIcon={'fire'} // TODO: change icon?
        onPress={() => navigation.navigate('me', { screen: screenNames.RequestDiscount })}
        type="navigation"
      />

      {/* Preferences */}
      <List.Subheader>{I18n.t('myinfo.sections.preferences')}</List.Subheader>
      <ListItemRow
        title={I18n.t('myinfo.items.language')}
        description={I18n.t(`language.${currentLocale}`)}
        leftIcon={{ name: 'language-outline', set: 'ion' }}
        onPress={() => navigation.navigate(screenNames.UpdateLanguage)}
        type="navigation"
      />
      {!Constants.manifest?.extra?.hideNotificationSettings ? (
        <ListItemRow
          title={I18n.t('myinfo.items.notifications')}
          description={allNotificationsOff ? I18n.t('myinfo.disabled') : I18n.t('myinfo.enabled')}
          leftIcon={'bell'}
          onPress={() => navigation.navigate(screenNames.UpdateNotifications)}
          type="navigation"
        />
      ) : null}
      {/* Basic information */}
      <List.Subheader>{I18n.t('myinfo.sections.basic')}</List.Subheader>
      <ListItemRow
        title={I18n.t('myinfo.items.name')}
        description={`${profile?.firstname ?? ''} ${profile?.lastname ?? ''}`}
        onPress={() => navigation.navigate(screenNames.UpdateName)}
        leftIcon={'user'}
        type="navigation"
      />

      <ListItemRow
        title={I18n.t('myinfo.items.organisation')}
        description={profile?.organization ? profile.organization : I18n.t('organisation.noOrganisation')}
        leftIcon={'organization'}
        onPress={() => navigation.navigate(screenNames.Organisation)}
        type="navigation"
      />

      <ListItemRow
        title={I18n.t('myinfo.items.contact')}
        description={profile?.phonenumber ?? ''}
        leftIcon={'phone'}
        onPress={() => navigation.navigate(screenNames.UpdateContact)}
        type="navigation"
      />

      {/* Other */}
      <List.Subheader>{I18n.t('myinfo.sections.other')}</List.Subheader>
      <ListItemRow
        onPress={() => {
          navigation.navigate(screenNames.AccountDeletion);
        }}
        title={I18n.t('myinfo.items.deleteAccount')}
        description={I18n.t('myinfo.items.deleteAccountDescription')}
        leftIcon={'user-unfollow'}
        type="navigation"
      />
      <ListItemRow
        onPress={() => {
          navigation.navigate('ConfirmLogoutDialog');
        }}
        title={I18n.t('myinfo.items.signout')}
        description={I18n.t('myinfo.items.signoutDescription')}
        leftIcon={{ name: 'power', set: 'ion' }}
        type="navigation"
      />
    </ScrollablePage>
  );
}

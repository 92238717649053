import React, { FC } from 'react';
import { useI18n } from '../../context/I18nContext';
import Images from '../../../assets/images';
import Slide from './Slide';

const Slide5: FC = () => {
  const { I18n } = useI18n();

  return (
    <Slide
      title={I18n.t('intro.step5Title')}
      description={I18n.t('intro.step5Description')}
      image={Images.imageSlide5}
    />
  );
};

export default Slide5;

import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { screenNames } from './screenNames';
import { useI18n } from '../context/I18nContext';
import QRScanScreen, { QRScreenRouteParamsType } from '../screens/QRScanScreen';
import EnterCodeManuallyScreen from '../screens/EnterCodeManuallyScreen';
import { DefaultScreenOptions } from '../components/NavigatorAppbar';

export type QRCodeStackRouteParams = {
  [screenNames.QRScanScreen]: QRScreenRouteParamsType;
  [screenNames.EnterCodeManuallyScreen]: QRScreenRouteParamsType;
};

const Stack = createStackNavigator<QRCodeStackRouteParams>();

export default function QRCodeStackNavigator() {
  const { I18n } = useI18n();

  return (
    <Stack.Navigator initialRouteName={screenNames.QRScanScreen} screenOptions={DefaultScreenOptions}>
      <Stack.Screen
        name={screenNames.QRScanScreen}
        component={QRScanScreen}
        options={{
          title: I18n.t('mapview.scanQr.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.EnterCodeManuallyScreen}
        component={EnterCodeManuallyScreen}
        options={DefaultScreenOptions}
      />
    </Stack.Navigator>
  );
}

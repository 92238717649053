import React from 'react';
import { List } from 'react-native-paper';
import { useQuery } from 'react-query';
import { fetchMyProfile } from '../../apis/appsyncApis';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { useI18n } from '../../context/I18nContext';
import { ListItemRow } from '../../components/ListItemRow';

export default function UpdateContact() {
  const result = useQuery(fetchMyProfile.id, fetchMyProfile);
  const { I18n } = useI18n();

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('updateContact.summaryTitle'),
        icon: 'compass',
      }}
    >
      <List.Subheader>{I18n.t('updateContact.sectionTitle')}</List.Subheader>
      <ListItemRow
        title={I18n.t('updateContact.phoneNumber')}
        description={result?.data?.data?.getMyProfile?.phonenumber ?? ''}
        leftIcon={'phone'}
        accessibilityRole="button"
        onPress={() => null}
      />
    </ScrollablePage>
  );
}

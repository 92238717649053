import { StackScreenProps } from '@react-navigation/stack';
import Constants from 'expo-constants';
import React, { useCallback } from 'react';
import { Linking, TouchableOpacity } from 'react-native';
import Images from '../../assets/images';
import Spacer from '../components/Spacer';
import { useI18n } from '../context/I18nContext';
import { NavigationDialog } from './CommonDialogs';
import { UiIconType } from '../components/SimpleListIcon';

type ChooseStoreDialogProps = StackScreenProps<{ ChooseStoreDialog: undefined }, 'ChooseStoreDialog'>;

const Options = Constants.manifest?.extra ?? {};

const iconFromSet: UiIconType = {
  set: 'material-community',
  name: 'storefront-outline',
};

export function ChooseStoreDialog({ navigation }: ChooseStoreDialogProps) {
  const { I18n } = useI18n();

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <NavigationDialog
      title={I18n.t('storeDialog.title')}
      description={I18n.t('storeDialog.description')}
      iconFromSet={iconFromSet}
      onDismiss={goBack}
      actions={[
        {
          title: I18n.t('general.cancel'),
          onPress: goBack,
        },
      ]}
    >
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(Options.playStoreLink);
          goBack();
        }}
        style={{ alignSelf: 'center' }}
      >
        <Images.playStore.default />
      </TouchableOpacity>
      <Spacer />
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(Options.appStoreLink);
          goBack();
        }}
        style={{ alignSelf: 'center' }}
      >
        <Images.appStore.default />
      </TouchableOpacity>
      <Spacer />
    </NavigationDialog>
  );
}

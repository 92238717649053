import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { screenNames } from './screenNames';
import { DefaultScreenOptions } from '../components/NavigatorAppbar';
import { useI18n } from '../context/I18nContext';
import LocationsScreen from '../screens/Locations/LocationsScreen';
import { RegionVenuesScreen, RegionVenuesRouteParams } from '../screens/Locations/RegionVenuesScreen';

export type LocationStackRouteParams = {
  [screenNames.LocationsScreen]: undefined;
  [screenNames.RegionVenuesScreen]: RegionVenuesRouteParams;
};

const Stack = createStackNavigator<LocationStackRouteParams>();

export default function LocationsStackNavigator() {
  const { I18n } = useI18n();

  return (
    <Stack.Navigator initialRouteName={screenNames.LocationsScreen} screenOptions={DefaultScreenOptions}>
      <Stack.Screen
        name={screenNames.LocationsScreen}
        component={LocationsScreen}
        options={{
          title: I18n.t('locations.title'),
        }}
      />
      <Stack.Screen
        name={screenNames.RegionVenuesScreen}
        component={RegionVenuesScreen}
        options={{
          title: I18n.t('locations.region.title'),
        }}
      />
    </Stack.Navigator>
  );
}

import React, { useCallback } from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useI18n } from '../../context/I18nContext';
import Container from '../../components/Container';
import GradientButton from '../../components/GradientButton';
import { useGetStripePaymentQuery } from '../paymentApis';
import { useActivePayment } from '../MapViewActivePaymentContext';
import PaymentCardList from '../components/PaymentCardList';
import PaymentWalletList from '../components/PaymentWalletList';
import { screenNames } from '../../navigators/screenNames';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { StripeCard } from '../../types/appsync-types';
import { PaymentMethodOption } from '../../types/misc.types';

export default function SelectPaymenthMethod({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const { bottom } = useSafeAreaInsets();
  const { I18n } = useI18n();
  const cards = useGetStripePaymentQuery();
  const cardList = cards.data?.data?.getStripePayment;

  const { setActivePaymentMethod } = useActivePayment();

  const handleAddPaymentMethod = useCallback(async () => {
    navigation.navigate(screenNames.AddPaymentMethod);
  }, [navigation]);

  const handleSelectCardPayment = useCallback(
    (stripeCard: StripeCard) => {
      setActivePaymentMethod({
        paymentMethodType: PaymentMethodOption.CARD,
        stripeCard,
      });
      navigation.goBack();
    },
    [navigation, setActivePaymentMethod],
  );

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('payment.select.summaryTitle'),
        description: I18n.t('payment.select.summaryDescription'),
        icon: 'credit-card',
      }}
    >
      <PaymentWalletList
        hasArrows={false}
        onPress={(option) => {
          setActivePaymentMethod({
            paymentMethodType: option,
          });
          navigation.goBack();
        }}
      />
      <PaymentCardList cardList={cardList} onPress={handleSelectCardPayment} isLoading={cards.isLoading} />
      <Container style={{ flex: 0, marginBottom: Math.max(bottom, 20), marginTop: 80 }}>
        <GradientButton rounded onPress={handleAddPaymentMethod} icon={'plus'}>
          {I18n.t('payment.addPaymentMethod')}
        </GradientButton>
      </Container>
    </ScrollablePage>
  );
}

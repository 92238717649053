import { addDays, addMinutes, differenceInMilliseconds, startOfMinute } from 'date-fns';
import { useEffect, useState } from 'react';
import { startOfDayTz } from '../utils/date-utils';

export const useNowMinutes = (): Date => {
  const [now, setNow] = useState<Date>(startOfMinute(new Date()));

  useEffect(() => {
    let timeoutId: number;
    const f = () => {
      const newNow = startOfMinute(Date.now());
      setNow(newNow);
      const waitMs = differenceInMilliseconds(addMinutes(newNow, 1), Date.now());
      timeoutId = window.setTimeout(f, waitMs);
    };
    f();
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return now;
};

export const useToday = (tz: string): Date => {
  const [now, setNow] = useState<Date>(startOfDayTz(new Date(), tz));

  useEffect(() => {
    let timeoutId: number;
    const update = () => {
      const newNow = startOfDayTz(Date.now(), tz);
      setNow(newNow);
      const waitMs = differenceInMilliseconds(addDays(newNow, 1), Date.now());
      timeoutId = window.setTimeout(update, waitMs);
    };
    update();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [tz]);
  return now;
};

import Images from '../images';

export type SupportedSpaceType =
  | 'framery-2q-huddle-av'
  | 'framery-q-maggie'
  | 'framery-q-maggie-usb'
  | 'framery-o-usb'
  | 'smartblock-meettime-closed-av'
  | 'smartblock-chattime-closed-av'
  | 'smartblock-meettime-closed'
  | 'smartblock-chattime-closed'
  | 'vetrospace-groupspace-m'
  | 'unknown'
  | 'King Bed' // King Bed is for testing
  | 'meeting-room-6-person'
  | 'meeting-room-10-person';

export type SpaceTypeInfo = {
  title: string;
  minimumBookingMinutes: number;
  manufacturer: string;
  seating: string;
  screen: false | string;
  whiteboard: boolean;
  usbAPower: number;
  usbCPower: number;
  powerOutlets: number;
  image: any;
};

// TODO: Pod type information should really be
//       coming from CMS.

export const spaceInfo: Record<SupportedSpaceType, SpaceTypeInfo> = {
  'King Bed': {
    title: 'Huge bed',
    minimumBookingMinutes: 15,
    seating: '2-4',
    manufacturer: 'Mikko',
    screen: '65" FullHD + HDMI',
    whiteboard: true,
    usbAPower: 3,
    usbCPower: 4,
    powerOutlets: 1,
    image: Images.pods.framery_2q_huddle_av,
  },
  'framery-2q-huddle-av': {
    title: 'Spacehub',
    minimumBookingMinutes: 15,
    seating: '4',
    manufacturer: 'Framery',
    screen: '32" FullHD + HDMI',
    whiteboard: false,
    usbAPower: 1,
    usbCPower: 1,
    powerOutlets: 6,
    image: Images.pods.framery_2q_huddle_av,
  },
  'framery-q-maggie': {
    title: 'Spacehub',
    minimumBookingMinutes: 15,
    seating: '2',
    manufacturer: 'Framery',
    screen: false,
    whiteboard: false,
    usbAPower: 0,
    usbCPower: 0,
    powerOutlets: 3,
    image: Images.pods.framery_q_maggie,
  },
  'framery-q-maggie-usb': {
    title: 'Spacehub',
    minimumBookingMinutes: 15,
    seating: '2',
    manufacturer: 'Framery',
    screen: false,
    whiteboard: false,
    usbAPower: 1,
    usbCPower: 1,
    powerOutlets: 2,
    image: Images.pods.framery_q_maggie,
  },
  'framery-o-usb': {
    title: 'Spacehub',
    minimumBookingMinutes: 15,
    seating: '1',
    manufacturer: 'Framery',
    screen: false,
    whiteboard: false,
    usbAPower: 1,
    usbCPower: 1,
    powerOutlets: 1,
    image: Images.pods.framery_o,
  },
  'smartblock-meettime-closed-av': {
    title: 'Spacehub',
    minimumBookingMinutes: 15,
    seating: '4',
    manufacturer: 'Smartblock',
    screen: '32" FullHD + HDMI',
    usbAPower: 0,
    usbCPower: 0,
    powerOutlets: 2,
    whiteboard: false,
    image: Images.pods.smartblock_meettime_closed_av,
  },
  'smartblock-meettime-closed': {
    title: 'Spacehub',
    minimumBookingMinutes: 15,
    seating: '4',
    manufacturer: 'Smartblock',
    screen: false,
    usbAPower: 0,
    usbCPower: 0,
    powerOutlets: 2,
    whiteboard: false,
    image: Images.pods.smartblock_meettime_closed,
  },
  'smartblock-chattime-closed-av': {
    title: 'Spacehub',
    minimumBookingMinutes: 15,
    seating: '2',
    manufacturer: 'Smartblock',
    screen: '32" FullHD + HDMI',
    usbAPower: 0,
    usbCPower: 0,
    powerOutlets: 2,
    whiteboard: false,
    image: Images.pods.smartblock_chattime_closed_av,
  },
  'smartblock-chattime-closed': {
    title: 'Spacehub',
    minimumBookingMinutes: 15,
    seating: '2',
    manufacturer: 'Smartblock',
    screen: false,
    usbAPower: 0,
    usbCPower: 0,
    powerOutlets: 2,
    whiteboard: false,
    image: Images.pods.smartblock_chattime_closed,
  },
  'vetrospace-groupspace-m': {
    title: 'Spacehub',
    minimumBookingMinutes: 15,
    seating: '4',
    manufacturer: 'Vetrospace',
    screen: false,
    usbAPower: 1,
    usbCPower: 0,
    powerOutlets: 4,
    whiteboard: false,
    image: Images.pods.vetrospace_groupspace_m,
  },
  unknown: {
    title: 'Spacehub',
    minimumBookingMinutes: 15,
    seating: '?',
    manufacturer: 'Unknown',
    screen: false,
    usbAPower: 0,
    usbCPower: 0,
    powerOutlets: 0,
    whiteboard: false,
    image: Images.pods.coming_soon,
  },
  'meeting-room-6-person': {
    title: 'Meeting space',
    minimumBookingMinutes: 120,
    manufacturer: '',
    seating: '6',
    screen: 'TV + HDMI', // TODO: which TV?
    whiteboard: true,
    usbAPower: 0,
    usbCPower: 0,
    powerOutlets: 0, // TODO
    image: Images.meetingRoom,
  },
  'meeting-room-10-person': {
    title: 'Meeting space',
    minimumBookingMinutes: 120,
    manufacturer: '',
    seating: '10',
    screen: '65" 4K UHD + HDMI',
    whiteboard: true,
    usbAPower: 0,
    usbCPower: 0,
    powerOutlets: 7,
    image: Images.meetingRoom,
  },
};

export function getSpaceType(spaceType: any): SupportedSpaceType {
  if (spaceType && spaceInfo[spaceType as SupportedSpaceType]) return spaceType as SupportedSpaceType;
  return 'unknown';
}

export function getSpaceTypeInfo(spaceType: any): SpaceTypeInfo {
  return spaceInfo[getSpaceType(spaceType)];
}

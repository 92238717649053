import { useQuery } from 'react-query';
import { MutationUpdateMyNotificationPreferenceArgs, MutationUpdateProfileArgs } from '../types/appsync-types';
import { appsyncGraphql } from './appsyncHelper';

fetchMyProfile.id = 'myProfile';

export function fetchMyProfile() {
  const query = /* GraphQL */ `
    query GetMyProfileQuery {
      getMyProfile {
        sub
        phonenumber
        firstname
        lastname
        inviteCode
        registerInviteCode
        creditBalance
        skipDepositAmountInCents
        currency
        language
        organization
        createdAt
        updatedAt
        userRole
      }
    }
  `;
  return appsyncGraphql<'getMyProfile'>(query, {});
}

useMyProfileQuery.id = fetchMyProfile.id;

export function useMyProfileQuery() {
  return useQuery(fetchMyProfile.id, fetchMyProfile);
}
export function syncProfileToPinpoint() {
  return appsyncGraphql<'updateProfilePinpoint'>(/* GraphQL */ `
    mutation UpdateProfilePinpoint {
      updateProfilePinpoint {
        sub
      }
    }
  `);
}

export async function updateProfile(input: MutationUpdateProfileArgs) {
  const mutation = /* GraphQL */ `
    mutation UpdateProfile($phonenumber: AWSPhone, $firstname: String, $lastname: String, $organization: String) {
      updateProfile(
        phonenumber: $phonenumber
        firstname: $firstname
        lastname: $lastname
        organization: $organization
      ) {
        sub
        phonenumber
        firstname
        lastname
        organization
        createdAt
        updatedAt
      }
    }
  `;
  await appsyncGraphql<'updateProfile'>(mutation, input);
  /* After profile mutations, we need to start a sync to pinpoint */
  syncProfileToPinpoint();
}

export function updatePreferredLanguage(input: Pick<MutationUpdateProfileArgs, 'language'>) {
  const mutation = /* GraphQL */ `
    mutation UpdateProfile($language: SupportLanguage) {
      updateProfile(language: $language) {
        sub
        phonenumber
        firstname
        lastname
        language
        organization
        createdAt
        updatedAt
      }
    }
  `;
  return appsyncGraphql<'updateProfile'>(mutation, input);
}

fetchMyNotificationPreference.id = 'myNotificationPreference';

export function fetchMyNotificationPreference() {
  const query = /* GraphQL */ `
    query GetMyNotificationPreference {
      getMyNotificationPreference {
        beforeStartingTenMinutes
        beforeStaringOneDay
        startingNow
        beforeEndingTenMinutes
        completed
        news
      }
    }
  `;
  return appsyncGraphql<'getMyNotificationPreference'>(query, {});
}

export function useMyNotificationPreferenceQuery() {
  return useQuery(fetchMyNotificationPreference.id, fetchMyNotificationPreference);
}

export function updateNotificationPreference(input: MutationUpdateMyNotificationPreferenceArgs) {
  const mutation = /* GraphQL */ `
    mutation NotificationPreference($input: NotificationPreferenceInput) {
      updateMyNotificationPreference(input: $input) {
        beforeStartingTenMinutes
        beforeStaringOneDay
        startingNow
        beforeEndingTenMinutes
        completed
        news
      }
    }
  `;
  return appsyncGraphql<'updateMyNotificationPreference'>(mutation, input);
}

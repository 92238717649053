import { StyleSheet } from 'react-native';
import { Card, Button, Text, TextInput, Paragraph, useTheme, ActivityIndicator } from 'react-native-paper';
import { CompositeScreenProps, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useState } from 'react';
import { AdminStackRouteParams } from '../../navigators/AdminStackNavigator';
import { AppDrawerNavigationProp, AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import Container from '../../components/Container';
import { Reservation, getReservationsByPhonenumber } from '../../apis/adminApis';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { useI18n } from '../../context/I18nContext';
import DateTimePickerModal from '../../components/modal/DateTimePickerModal';
import { UiIcon } from '../../components/SimpleListIcon';

type Props = CompositeScreenProps<
  StackScreenProps<AdminStackRouteParams, 'AdminPhonenumberReservations'>,
  AppDrawerScreenProp
>;

function ReservationCardDetail({ reservation }: { reservation: Reservation }) {
  const navigation = useNavigation<AppDrawerNavigationProp>();
  return (
    <Card style={styles.cardDetail}>
      <Card.Title title={`Reservation at #${reservation.spaceId}`} />
      <Card.Content>
        <Paragraph>
          From (international time): {reservation.from}
          {'\n'}
          To (internation time): {reservation.to}
          {'\n'}
          Building Id: {(reservation as any).buildingId}
          {'\n'}
          Space Id: {reservation.spaceId}
          {'\n'}
          Status: {reservation.status}
          {'\n'}
          Stripe: {reservation.paymentId}
        </Paragraph>
        <Button
          onPress={() => {
            navigation.navigate('admin', {
              screen: 'AdminReservationDetails',
              params: {
                reservationId: reservation.id,
              },
            });
          }}
          style={styles.fullDetailsButton}
        >
          Full details
        </Button>
      </Card.Content>
    </Card>
  );
}

function ReservationCards({ reservations }: { reservations: Reservation[] }) {
  if (reservations.length === 0) {
    return <Text>No reservations found.</Text>;
  }
  return (
    <>
      {reservations.map((reservation, index) => (
        <ReservationCardDetail key={index} reservation={reservation} />
      ))}
    </>
  );
}

export const PhonenumberReservations: React.FC<Props> = () => {
  const { currentLocale } = useI18n();

  const { colors } = useTheme();

  const [queriedReservations, setQueriedReservations] = useState<Reservation[]>([]);
  const [userPhoneInput, setUserPhoneInput] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [fromDatePickerVisible, setFromDatePickerVisible] = useState(false);
  const [toDatePickerVisible, setToDatePickerVisible] = useState(false);
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const onQueryReservationsByUserPhone = async () => {
    setIsLoading(true);
    try {
      const response = await getReservationsByPhonenumber(userPhoneInput, {
        from: fromDate.toISOString().split('T')[0],
        to: `${toDate.toISOString().split('T')[0]}T23:59:59.999Z`,
      });
      const { reservations } = response;
      reservations.sort((first, second) => (first.from <= second.from ? -1 : 1));
      setQueriedReservations(reservations);
    } catch (err) {
      setErrorMessage(String(err));
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onShowFromDatePicker = useCallback(() => {
    setFromDatePickerVisible(true);
  }, []);

  const onFromDatePickerConfirm = useCallback((newDate: Date) => {
    setFromDatePickerVisible(false);
    setFromDate(newDate);
  }, []);

  const onFromDatePickerCancel = useCallback(() => {
    setFromDatePickerVisible(false);
  }, []);

  const onShowToDatePicker = useCallback(() => {
    setToDatePickerVisible(true);
  }, []);

  const onToDatePickerConfirm = useCallback((newDate: Date) => {
    setToDatePickerVisible(false);
    setToDate(newDate);
  }, []);

  const onToDatePickerCancel = useCallback(() => {
    setToDatePickerVisible(false);
  }, []);

  return (
    <>
      <ScrollablePage
        summary={{
          title: 'Query reservations by user phonenumber',
          description: 'Get list of reservatons given user phonenumber and time range',
          icon: 'pin',
        }}
      >
        <Container>
          <TextInput
            placeholder="Enter user phonenumber"
            value={userPhoneInput}
            onChangeText={setUserPhoneInput}
            error={!!errorMessage}
            right={errorMessage && <UiIcon name={'exclamation'} color={colors.error} />}
            mode={'outlined'}
            keyboardType={'phone-pad'}
          />
          <Button style={styles.toggleDateModalButton} mode="contained" onPress={onShowFromDatePicker}>
            Select From Date: {fromDate.toISOString().split('T')[0]}
          </Button>
          <Button style={styles.toggleDateModalButton} mode="contained" onPress={onShowToDatePicker}>
            Select To Date: {toDate.toISOString().split('T')[0]}
          </Button>
        </Container>
        <DateTimePickerModal
          isVisible={fromDatePickerVisible}
          mode="date"
          date={fromDate}
          locale={currentLocale}
          onConfirm={onFromDatePickerConfirm}
          onCancel={onFromDatePickerCancel}
        />
        <DateTimePickerModal
          isVisible={toDatePickerVisible}
          mode="date"
          date={toDate}
          locale={currentLocale}
          onConfirm={onToDatePickerConfirm}
          onCancel={onToDatePickerCancel}
        />
        <Button style={styles.queryButton} onPress={onQueryReservationsByUserPhone}>
          Query reservations
        </Button>

        <Text>Reservations list:</Text>
        {isLoading ? <ActivityIndicator /> : <ReservationCards reservations={queriedReservations} />}
      </ScrollablePage>
    </>
  );
};

const styles = StyleSheet.create({
  toggleDateModalButton: {
    marginTop: 10,
    marginBottom: 10,
  },
  queryButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  cardDetail: {
    margin: 10,
  },
  fullDetailsButton: {
    margin: 10,
  },
});

import React, { useCallback, useState } from 'react';
import { CompositeScreenProps } from '@react-navigation/native';
import { Image, Platform, Share } from 'react-native';
import { List, Snackbar, useTheme } from 'react-native-paper';
import Clipboard from '@react-native-clipboard/clipboard';
import { StackScreenProps } from '@react-navigation/stack';
import { useI18n } from '../context/I18nContext';
import ScrollablePage from '../components/ui/ScrollablePage';
import SpaceSchedule from '../components/ui/SpaceSchedule';
import Spacer from '../components/Spacer';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import { getSpaceTypeInfo, SupportedSpaceType } from '../../assets/other/spacetypes';
import { AmenityIcon } from './MapView/PodInformationCard';
import { MapStackRouteParams } from '../navigators/MapStackNavigator';
import { AppDrawerScreenProp } from '../components/DrawerMenuContent';

export type SpaceInfoRouteParams = { spaceId: string; spaceType: SupportedSpaceType };

type Props = CompositeScreenProps<StackScreenProps<MapStackRouteParams, 'SpaceInfo'>, AppDrawerScreenProp>;

const AmenityItem: React.FC<{
  amenity: 'seating' | 'screen' | 'whiteboard' | 'usbPower' | 'powerOutlet';
  title: string;
}> = ({ amenity, title }) => {
  const { colors } = useTheme();
  return (
    <List.Item
      style={{ paddingVertical: 0 }}
      titleStyle={{ fontSize: 14, color: colors.onSurfaceVariant }}
      left={() => <List.Icon icon={(props) => <AmenityIcon name={amenity} {...props} />} />}
      title={title}
      titleNumberOfLines={2}
    />
  );
};
export const SpaceInfo: React.FC<Props> = ({ navigation, route }) => {
  const { I18n } = useI18n();
  const mp = useMixpanel();
  const [copiedSnackbarVisible, setCopiedSnackbarVisible] = useState<boolean>(false);

  const handleSharePodDetails = useCallback(async () => {
    if (Platform.OS !== 'web') {
      const result = await Share.share(
        {
          title: `#${route.params?.spaceId}`,
          url: `https://app.getspacehub.com/c/space/${route.params?.spaceId}`,
        },
        { subject: I18n.t('mapview.spaceInfo.shareSubject') },
      );
      if (result.action === Share.sharedAction) {
        // action when shared
        mp?.track('Share Spacehub', { spaceId: route.params?.spaceId });
        mp?.getPeople().increment('Shared spacehub links', 1);
      } else {
        // action when dismiss
      }
    } else {
      Clipboard.setString(`https://app.getspacehub.com/c/space/${route.params.spaceId}`);
      setCopiedSnackbarVisible(true);
    }
  }, [I18n, route.params?.spaceId, mp]);

  const info = getSpaceTypeInfo(route.params?.spaceType);

  const renderDetails = () => {
    return (
      <>
        <AmenityItem amenity="seating" title={I18n.t('mapview.spaceInfo.amenities.seating', { count: info.seating })} />
        {info.screen ? (
          <AmenityItem
            amenity="screen"
            title={I18n.t('mapview.spaceInfo.amenities.screen', {
              type: info.screen,
            })}
          />
        ) : null}
        {info.powerOutlets ? (
          <AmenityItem
            amenity="powerOutlet"
            title={I18n.t('mapview.spaceInfo.amenities.powerOutlets', {
              count: info.powerOutlets,
            })}
          />
        ) : null}
        {info.whiteboard ? (
          <AmenityItem amenity="whiteboard" title={I18n.t('mapview.spaceInfo.amenities.whiteboard')} />
        ) : null}
        {info.usbAPower ? (
          <AmenityItem
            amenity="usbPower"
            title={I18n.t('mapview.spaceInfo.amenities.usbAPower', {
              count: info.usbAPower,
            })}
          />
        ) : null}
        {info.usbCPower ? (
          <AmenityItem
            amenity="usbPower"
            title={I18n.t('mapview.spaceInfo.amenities.usbCPower', {
              count: info.usbCPower,
            })}
          />
        ) : null}
        <Spacer type="section" />
      </>
    );
  };

  return (
    <>
      <ScrollablePage
        summary={{
          title: `${info.title} #${route.params?.spaceId}`,
          description: renderDetails(),
          button: {
            label: I18n.t('share.share'),
            onPress: handleSharePodDetails,
          },
          icon: (props) => {
            return (
              <Image
                source={info.image}
                style={{
                  width: props.size,
                  height: props.size,
                }}
              />
            );
          },
        }}
      >
        <Spacer type="section" />
        <Spacer />
        <SpaceSchedule spaceId={route.params.spaceId} />
        <Spacer />
      </ScrollablePage>
      <Snackbar visible={copiedSnackbarVisible} duration={3000} onDismiss={() => setCopiedSnackbarVisible(false)}>
        {I18n.t('share.copiedToClipboard')}
      </Snackbar>
    </>
  );
};

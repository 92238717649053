import { DrawerNavigationProp } from '@react-navigation/drawer';
import React from 'react';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { useI18n } from '../../context/I18nContext';

const AccountDeletionScreen: React.FC<{ navigation: DrawerNavigationProp<any> }> = ({ navigation }) => {
  const { I18n } = useI18n();
  return (
    <ScrollablePage
      summary={{
        title: I18n.t('accountDeletion.summaryTitle'),
        description: I18n.t('accountDeletion.summaryDescription'),
        icon: 'user-unfollow',
        button: {
          label: I18n.t('accountDeletion.buttonTitle'),
          onPress: () => {
            navigation.navigate('ConfirmAccountDeletionDialog');
          },
        },
      }}
    />
  );
};

export default AccountDeletionScreen;

import React from 'react';
import { ColorValue, Platform, ViewStyle } from 'react-native';
import { Switch as RNPaperSwitch, useTheme } from 'react-native-paper';

interface SwitchProps {
  color?: string;
  trackColor?: {
    false: ColorValue;
    true: ColorValue;
  };
  thumbColor?: ColorValue;
  onValueChange: (value: boolean) => void;
  value: boolean;
  disabled?: boolean;
  style?: ViewStyle;
}

const Switch: React.FC<SwitchProps> = ({
  color,
  style,
  trackColor,
  onValueChange,
  value,
  disabled = false,
  thumbColor,
}) => {
  const { colors } = useTheme();

  const getThumbColor = (enabled: boolean) => {
    if (enabled) return colors.primary;
    return colors.onSurface;
  };

  return (
    <RNPaperSwitch
      color={color ?? colors.primary}
      trackColor={
        trackColor ?? {
          false: colors.onSurfaceDisabled,
          true: Platform.OS === 'ios' ? colors.primary : 'rgba(85, 136, 171, 0.5)',
        }
      }
      thumbColor={thumbColor ?? Platform.OS === 'ios' ? '#ffffff' : getThumbColor(value)}
      value={value}
      onValueChange={onValueChange}
      disabled={disabled}
      style={[{ opacity: Platform.OS !== 'ios' && disabled ? 0.5 : 1 }, style]}
    />
  );
};

export default Switch;

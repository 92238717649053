import React, { useEffect, useState } from 'react';
import Constants from 'expo-constants';
import { Button, TextInput } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import { useMutation, useQuery } from 'react-query';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { fetchMyProfile, updateProfile } from '../../apis/appsyncApis';
import Loading from '../../components/Loading';
import useUnmount from '../../hooks/useUnmount';
import { screenNames } from '../../navigators/screenNames';
import { useI18n } from '../../context/I18nContext';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import Container from '../../components/Container';
import { InformationStackRouteParams } from '../../navigators/InformationStackNavigator';
import { MutationUpdateProfileArgs } from '../../types/appsync-types';

interface UpdateOrganisationForm {
  organisation: string;
  discountCode: string;
}
type navprops = StackNavigationProp<InformationStackRouteParams, 'UpdateOrganisation'>;

const UpdateOrganisation = () => {
  const [formValue, setFormValue] = useState<UpdateOrganisationForm>({
    organisation: '',
    discountCode: '',
  });
  const { I18n } = useI18n();
  const mp = useMixpanel();
  const navigation = useNavigation<navprops>();
  const result = useQuery(fetchMyProfile.id, fetchMyProfile);
  const data = result.data?.data?.getMyProfile;
  const saveProfile = useMutation(
    async (form: MutationUpdateProfileArgs) => {
      await updateProfile(form);
      if (form.organization) mp?.getPeople().set('Organisation', form.organization);
    },
    {
      onSuccess: () => {
        result.refetch();
      },
    },
  );
  const organisation = data?.organization;
  const handleChange = (name: keyof UpdateOrganisationForm) => (value: string) => {
    setFormValue((p) => ({ ...p, [name]: value }));
  };

  useEffect(() => {
    setFormValue({
      organisation: organisation ?? '',
      discountCode: '',
    });
  }, [organisation]);
  useUnmount(() => {
    saveProfile.mutate({
      firstname: data!.firstname,
      lastname: data!.lastname,
      organization: formValue.organisation,
      phonenumber: data!.phonenumber,
    });
  });
  if (!data) {
    return <Loading />;
  }
  return (
    <ScrollablePage
      summary={{
        title: I18n.t('organisation.summaryTitle'),
        description: I18n.t('organisation.summaryDescription'),
        icon: 'pencil',
      }}
    >
      <Container>
        <TextInput
          value={formValue.organisation}
          onChangeText={handleChange('organisation')}
          label={I18n.t('organisation.name')}
          style={styles.input}
          mode={'outlined'}
          autoCapitalize={'words'}
        />

        <Button
          mode={'text'}
          onPress={() => {
            if (Constants?.manifest?.extra?.hideScanningSpaceQRCode) {
              navigation.navigate(screenNames.EnterCodeManuallyScreen, { mode: 'VOUCHER' });
            } else {
              navigation.navigate(screenNames.QRScanScreen, { mode: 'VOUCHER' });
            }
          }}
        >
          {I18n.t('organisation.scanDiscountCode')}
        </Button>
        <Button mode={'text'} onPress={() => navigation.navigate(screenNames.RequestDiscount)}>
          {I18n.t('organisation.discountRequest')}
        </Button>
      </Container>
    </ScrollablePage>
  );
};

const styles = StyleSheet.create({
  input: {
    marginTop: 30,
    marginBottom: 14,
  },
  textButton: {
    marginTop: 40,
    alignSelf: 'center',
  },
});

export default UpdateOrganisation;

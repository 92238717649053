import React, { PropsWithChildren, useCallback } from 'react';
import { GestureResponderEvent } from 'react-native';
import GradientButton, { GradientButtonProps } from './GradientButton';
import { useBitwards } from '../bitwards/BitwardsContext';

export type BitwardLockButtonProps = GradientButtonProps & { resourceId: string | undefined | null };

/**
 * This component is a logic wrapper to universally handle error cases when interacting with
 * Bitwards SDK. It accepts a single react component as a child and will inject an onPress prop
 * Which internally call the injected onStartUnlockAttempt props
 * @param children
 * @param onStartUnlockAttempt
 * @constructor
 */
export function BitwardLockButton({
  onPress,
  children,
  resourceId,
  loading,
  ...other
}: PropsWithChildren<BitwardLockButtonProps>) {
  const { accessResource, loading: bitwardsLoading } = useBitwards();

  const handlePress = useCallback(
    (e: GestureResponderEvent) => {
      if (resourceId) {
        accessResource(resourceId);
      }
      if (onPress) onPress(e);
    },
    [accessResource, onPress, resourceId],
  );

  return (
    <GradientButton onPress={handlePress} loading={bitwardsLoading || loading} icon={'lock'} {...other}>
      {children}
    </GradientButton>
  );
}

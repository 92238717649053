import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTheme, Dialog, Button, Surface } from 'react-native-paper';
import { View, StyleSheet, ViewProps } from 'react-native';
import { UiDefaultIconName } from '../SimpleListIcon';
import SummaryHeader from '../SummaryHeader';

type TextModalProps = {
  title: string;
  buttons: ModalButtonProps[];
  description: string;
  content?: React.ReactNode;
  icon?: UiDefaultIconName;
  type?: ModalAlertType;
  children?: ReactNode;
} & ViewProps;

export type ModalButtonProps = {
  title: string;
  onPress: () => void | Promise<void>;
  loading?: boolean;
  disable?: boolean;
};

export function ModalButtonWrapper(btn: ModalButtonProps & { key: string }) {
  const { colors } = useTheme();
  const [buttonLoading, setButtonLoading] = useState(false);
  const mountedRef = useRef<boolean>(false);

  // Just for tracking mounted state
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handlePress = useCallback(async () => {
    try {
      setButtonLoading(true);
      await btn.onPress();
    } finally {
      // Only set state if view is still mounted
      if (mountedRef.current) setButtonLoading(false);
    }
  }, [btn]);

  return (
    <Button
      mode="text"
      key={btn.title}
      onPress={handlePress}
      loading={buttonLoading || btn.loading}
      disabled={!!btn.disable}
      textColor={colors.primary}
      /**
       * Default letter spacing is 0.10. Increasing it just a tiny bit to fix an issue where text gets cut off on some Android devices.
       * More info: https://github.com/callstack/react-native-paper/issues/3472#issuecomment-1376723484
       */
      labelStyle={{ marginStart: btn.loading || buttonLoading ? 12 : 0, marginEnd: 0, letterSpacing: 0.1001 }}
      style={{ marginLeft: 24 }}
    >
      {btn.title}
    </Button>
  );
}

export enum ModalAlertType {
  ERROR,
  INFO,
}

export default function TextModal({
  title,
  buttons,
  description,
  icon,
  content,
  type,
  style,
  children,
}: TextModalProps) {
  let iconName: UiDefaultIconName = 'question';
  if (icon) {
    iconName = icon;
  } else if (type === ModalAlertType.ERROR) {
    iconName = 'close';
  } else if (type === ModalAlertType.INFO) {
    iconName = 'info';
  }

  return (
    <Surface style={[styles.modalSurface, style]}>
      <View style={{ overflow: 'hidden' }}>
        <SummaryHeader title={title} description={description} style={styles.summaryHeader} icon={iconName} />
        <View>{children || content || null}</View>
        <Dialog.Actions>
          {buttons.map((btn) => (
            <ModalButtonWrapper {...btn} key={btn.title} />
          ))}
        </Dialog.Actions>
      </View>
    </Surface>
  );
}

const styles = StyleSheet.create({
  summaryHeader: { paddingHorizontal: 16 },
  modalSurface: {
    paddingTop: 0,
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
  },
});

import React, { FC } from 'react';
import { View, ViewProps } from 'react-native';

interface Props extends ViewProps {}

const Hr: FC<Props> = ({ style, ...other }) => {
  return <View style={[{ borderTopWidth: 1, borderColor: 'rgba(255, 255, 255, 0.38)' }, style]} {...other} />;
};

export default Hr;

import AuthenticatedAPI from '@aws-amplify/api';
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { PublicGraphQLAPI } from './publicGraphQLApiClient';
import { Query, Mutation } from '../types/appsync-types';

type Operations = Query & Mutation;

export type AppsyncResult<T extends keyof Operations> = GraphQLResult<Pick<Operations, T>>;
export type AppsyncData<T extends keyof Operations> = NonNullable<AppsyncResult<T>['data']>;

export function appsyncGraphql<T extends keyof Operations, E = any>(query: string, vars?: E) {
  return AuthenticatedAPI.graphql(graphqlOperation(query, vars ?? {})) as Promise<AppsyncResult<T>>;
}
export function publicAppsyncGraphql<T extends keyof Operations, E = any>(query: string, vars?: E) {
  return PublicGraphQLAPI.graphql(graphqlOperation(query, vars ?? {})) as Promise<AppsyncResult<T>>;
}

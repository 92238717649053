import React, { useCallback, useMemo, useState } from 'react';
import { Text, Button } from 'react-native-paper';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { useQuery, useQueryClient } from 'react-query';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useI18n } from '../../context/I18nContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { ReservationStatus } from '../../types/appsync-types';
import { AdminStackRouteParams } from '../../navigators/AdminStackNavigator';
import Typography from '../../components/Typography';
import * as adminApi from '../../apis/adminApis';
import { useModal } from '../../context/ModalContext';
import { AdminConfirmModalContent, RawAdminCommandOutput, SendAdminCommandButton } from './AdminTools';
import FormikInput from '../../components/formik/FormikInput';
import Container from '../../components/Container';

export type AdminReservationDetailsRouteParams = {
  reservationId: string;
};

interface PartialCaptureDepositRequestForm {
  reservationId: string;
  captureAmountInCents: number;
}

interface FullRefundRequestForm {
  reservationId: string;
}

const RESERVATION_QUERY_ID = 'useReservation';

function useReservationDetails(reservationId: string) {
  return useQuery([RESERVATION_QUERY_ID, reservationId], async () => {
    return adminApi.queryReservation(reservationId);
  });
}

type Props = CompositeScreenProps<
  StackScreenProps<AdminStackRouteParams, 'AdminReservationDetails'>,
  AppDrawerScreenProp
>;

export const ReservationDetails: React.FC<Props> = ({ route }) => {
  const queryClient = useQueryClient();
  const { I18n } = useI18n();
  const { parseDecimal } = useI18n();
  const modal = useModal();

  const routeReservationId = route.params.reservationId;
  const reservationDetails = useReservationDetails(routeReservationId);
  const reservation = reservationDetails.data?.reservation;

  const [partialCaptureDepositRequest, setPartialCaptureDepositRequest] = useState<Promise<any>>();
  const [initPartialCaptureDepositForm] = useState<PartialCaptureDepositRequestForm>({
    reservationId: routeReservationId,
    captureAmountInCents: 0,
  });

  const [fullRefundRequest, setFullRefundRequest] = useState<Promise<any>>();
  const [initFullRefundDepositForm] = useState<FullRefundRequestForm>({
    reservationId: routeReservationId,
  });

  const validatePartialCaptureDepositSchema = useMemo(() => {
    return yup.object().shape({
      captureAmountInCents: yup
        .number()
        .required()
        .min(0)
        .max(reservation?.depositAmountInCents ?? 0 / 100),
    });
  }, [reservation?.depositAmountInCents]);

  const requeryReservation = useCallback(() => {
    queryClient.invalidateQueries([RESERVATION_QUERY_ID, routeReservationId]);
  }, [queryClient, routeReservationId]);

  const handlePartialCaptureDepositAction = useCallback(
    (form: PartialCaptureDepositRequestForm) => {
      const p = adminApi.captureDeposit(form.reservationId, (parseDecimal(form.captureAmountInCents) ?? 0) * 100);
      p.finally(() => modal.closeModal());
      setPartialCaptureDepositRequest(p);
      return p;
    },
    [modal, parseDecimal],
  );

  const handleSubmitPartialCaptureDepositForm = useCallback(
    async (form: PartialCaptureDepositRequestForm) => {
      modal.openModal({
        content: (
          <AdminConfirmModalContent
            onDismiss={() => modal.closeModal()}
            onConfirm={() => handlePartialCaptureDepositAction(form)}
          />
        ),
      });
    },
    [modal, handlePartialCaptureDepositAction],
  );

  const handleFullRefundAction = useCallback(
    async (form: FullRefundRequestForm) => {
      console.log('Handle full refund for', form.reservationId);
      const p = adminApi.fullRefundReservation(form.reservationId);
      p.finally(() => {
        modal.closeModal();
      });
      setFullRefundRequest(p);
      return p;
    },
    [modal],
  );

  const handleSubmitFullRefundForm = useCallback(
    async (form: FullRefundRequestForm) => {
      modal.openModal({
        content: (
          <AdminConfirmModalContent
            onDismiss={() => modal.closeModal()}
            onConfirm={() => handleFullRefundAction(form)}
            icon="minus"
            title="Full Refund"
            description="This will refund all money for this reservation. The operation cannot be undone."
          />
        ),
      });
    },
    [modal, handleFullRefundAction],
  );

  return (
    <ScrollablePage
      summary={{
        title: reservation?.id ?? '',
        description:
          reservation?.status === ReservationStatus.REFUND_REQUIRED
            ? 'You can capture the deposit for this reservation. If you put 0 in the value field, the deposit will be refunded to the user in full. If the value is greater than 0, that is the amount we take from the user`s deposit.'
            : I18n.t('locations.region.summaryDescription'),
        icon: 'pin',
      }}
    >
      <Container>
        <Button
          mode={'contained'}
          disabled={reservationDetails.isLoading}
          onPress={requeryReservation}
          style={{
            marginVertical: 10,
          }}
        >
          Requery reservation
        </Button>
        <Typography variant="body2">{JSON.stringify(reservation, null, 2)}</Typography>
      </Container>
      {reservation?.status === ReservationStatus.REFUND_REQUIRED && (
        <Container style={{ marginVertical: 10 }}>
          <Formik
            initialValues={initPartialCaptureDepositForm}
            onSubmit={handleSubmitPartialCaptureDepositForm}
            validationSchema={validatePartialCaptureDepositSchema}
          >
            {({ handleSubmit, isSubmitting }) => (
              <>
                <FormikInput
                  name={'reservationId'}
                  mode="outlined"
                  editable={false}
                  label={'Reservation ID (prefilled)'}
                  value={routeReservationId}
                />
                <FormikInput
                  name={'captureAmountInCents'}
                  mode="outlined"
                  keyboardType="numeric"
                  autoComplete="off"
                  autoCapitalize="none"
                  label={`Capture deposit in ${reservation.currency} (not cents)`}
                />
                <SendAdminCommandButton handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
              </>
            )}
          </Formik>
          <RawAdminCommandOutput request={partialCaptureDepositRequest} />
        </Container>
      )}

      {[ReservationStatus.COMPLETED, ReservationStatus.REFUND_REQUIRED].includes(reservation?.status!) && (
        <Container style={{ marginVertical: 10 }}>
          <Formik initialValues={initFullRefundDepositForm} onSubmit={handleSubmitFullRefundForm}>
            {({ handleSubmit, isSubmitting }) => (
              <>
                <Text
                  style={{
                    color: 'red',
                    marginTop: 20,
                    marginBottom: 10,
                    fontSize: 17,
                  }}
                >
                  Full Refund API
                </Text>
                <Text
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Refund all money and credit to make this reservation.
                </Text>
                <FormikInput
                  name={'reservationId'}
                  mode="outlined"
                  editable={false}
                  label={'Reservation ID (prefilled)'}
                  value={routeReservationId}
                />
                <SendAdminCommandButton handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
              </>
            )}
          </Formik>
          <RawAdminCommandOutput request={fullRefundRequest} />
        </Container>
      )}
    </ScrollablePage>
  );
};

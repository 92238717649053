import { useNavigation } from '@react-navigation/native';
import { addDays, differenceInMinutes, isSameDay, isSameMinute, set, subMinutes, isSameYear } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Caption, Surface } from 'react-native-paper';
import { utcToZonedTime } from 'date-fns-tz';
import { getSpaceTypeInfo } from '../../../assets/other/spacetypes';
import { useGetSpaceScheduleInfo } from '../../apis/mapApis';
import { ArrowButton } from '../LeftRightPicker';
import { useI18n } from '../../context/I18nContext';
import { DEFAULT_DURATION_STEP_MINS, useMapViewFilter } from '../../context/MapViewFilterContext';
import { useNowMinutes, useToday } from '../../hooks/useNow';
import { roundToNextMinutes } from '../../utils/helpers';
import Container from '../Container';
import { AppDrawerNavigationProp } from '../DrawerMenuContent';
import Typography from '../Typography';
import DateTimePickerModal from '../modal/DateTimePickerModal';
import { setTz, startOfDayTz } from '../../utils/date-utils';
import { MAX_ADVANCE_RESERVE_DAYS } from '../../apis/reservationApis';
import Spacer from '../Spacer';

const EARLY_MORNING_STARTS = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }; // "00:00";
const MORNING_STARTS = { hours: 6, minutes: 0, seconds: 0, milliseconds: 0 }; // "06:00";
const AFTERNOON_STARTS = { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }; // "12:00";
const EVENING_STARTS = { hours: 18, minutes: 0, seconds: 0, milliseconds: 0 }; // "18:00";
const ALMOST_MIDNIGHT = { hours: 23, minutes: 59, seconds: 0, milliseconds: 0 }; // "23:59";

interface SpaceScheduleProps {
  spaceId: string;
}
type TimeZone = string;

type SlotData =
  | {
      type: 'free';
      start: Date;
      end?: undefined;
    }
  | {
      type: 'busy' | 'mine';
      start: Date;
      end: Date;
    };

type SlotButtonProps = SlotData & {
  spaceTz: TimeZone | undefined;
  onPress: () => void;
};

function isEarly(d: Date, tz: TimeZone | undefined | null) {
  if (!tz) return false;
  const zonedDate = utcToZonedTime(d, tz);
  return zonedDate >= set(zonedDate, EARLY_MORNING_STARTS) && zonedDate < set(zonedDate, MORNING_STARTS);
}
function isMorning(d: Date, tz: TimeZone | undefined | null) {
  if (!tz) return false;
  const zonedDate = utcToZonedTime(d, tz);
  return zonedDate >= set(zonedDate, MORNING_STARTS) && zonedDate < set(zonedDate, AFTERNOON_STARTS);
}
function isAfternoon(d: Date, tz: TimeZone | undefined | null) {
  if (!tz) return false;
  const zonedDate = utcToZonedTime(d, tz);
  return zonedDate >= set(zonedDate, AFTERNOON_STARTS) && zonedDate < set(zonedDate, EVENING_STARTS);
}
function isEvening(d: Date, tz: TimeZone | undefined | null) {
  if (!tz) return false;
  const zonedDate = utcToZonedTime(d, tz);
  return zonedDate >= set(zonedDate, EVENING_STARTS) && zonedDate < set(zonedDate, ALMOST_MIDNIGHT);
}

const SlotButton: React.FC<SlotButtonProps> = ({ start, end, spaceTz, type, onPress }) => {
  const { formatDate, formatDateRange, I18n } = useI18n();
  const now = useNowMinutes();
  if (type === 'free') {
    return (
      <View style={{ flexDirection: 'row' }}>
        <Button compact={true} mode={'outlined'} onPress={onPress} style={styles.slotFreeButton}>
          {isSameMinute(start, now) ? I18n.t('general.now') : formatDate(start, 'time-no-tz', spaceTz)}
        </Button>
      </View>
    );
  }
  if (type === 'busy' && end) {
    return (
      <View style={{ flexDirection: 'row' }}>
        <Button compact={true} mode={'outlined'} onPress={onPress} style={styles.slotBusyButton} disabled={true}>
          {formatDateRange(start, end, 'time-no-tz', spaceTz)}
        </Button>
      </View>
    );
  }
  if (type === 'mine' && end) {
    return (
      <View style={{ flexDirection: 'row' }}>
        <Button compact={true} mode={'outlined'} onPress={onPress} style={styles.slotMineButton}>
          {formatDateRange(start, end, 'time-no-tz', spaceTz)}
        </Button>
      </View>
    );
  }
  return null;
};

function generateFreeSlots(start: Date, end: Date, spaceMinimumBooking: number): SlotData[] {
  const ret: SlotData[] = [];
  /* Move through the slot DEFAULT_DURATION_STEP_MINS at a time, until the remaining time is shorter than minimum time */
  let cur = start;
  while (differenceInMinutes(end, cur) >= spaceMinimumBooking) {
    ret.push({
      start: cur,
      type: 'free',
    });
    cur = roundToNextMinutes(cur, DEFAULT_DURATION_STEP_MINS);
  }
  return ret;
}

const SpaceSchedule: React.FC<SpaceScheduleProps> = ({ spaceId }) => {
  const { filter, onChange, timeZone } = useMapViewFilter();
  const navigation = useNavigation<AppDrawerNavigationProp>();
  const today = useToday(timeZone);
  const now = useNowMinutes();
  const [isDatePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const { I18n, currentLocale, formatDate } = useI18n();
  const [dayNoTz, setDayNoTz] = useState<Date>(filter.date);
  const spaceInfo = useGetSpaceScheduleInfo(spaceId, {
    from: startOfDayTz(dayNoTz, timeZone).toISOString(),
    to: addDays(startOfDayTz(dayNoTz, timeZone), 1).toISOString(),
  });

  const dateString = useMemo(() => {
    if (isSameYear(now, dayNoTz)) return formatDate(dayNoTz, 'date-no-tz', timeZone);
    return formatDate(dayNoTz, 'dateWithYear-no-tz', timeZone);
  }, [dayNoTz, formatDate, now, timeZone]);

  const previousDate = useCallback(() => {
    setDayNoTz((oldDay) => {
      const d = addDays(oldDay, -1);
      if (d >= now) {
        return d;
      }
      return now;
    });
  }, [now]);

  const nextDate = useCallback(() => {
    setDayNoTz((oldDay) => {
      const newDay = addDays(oldDay, 1);
      const maxDate = addDays(now, MAX_ADVANCE_RESERVE_DAYS);
      if (newDay < maxDate) {
        return newDay;
      }
      return maxDate;
    });
  }, [now]);

  const onDateConfirm = useCallback(
    (newDate: Date) => {
      setDatePickerVisible(false);
      if (newDate) {
        setDayNoTz((oldDate) => {
          const newTimeInTz = setTz(
            oldDate,
            {
              year: newDate.getFullYear(),
              month: newDate.getMonth(),
              date: newDate.getDate(),
            },
            timeZone,
          );
          if (newTimeInTz < today) {
            return today;
          }
          return newTimeInTz;
        });
      }
    },
    [timeZone, today],
  );

  const openingHoursForDay: { open: true; from: Date; to: Date } | { open: false } | undefined = useMemo(() => {
    const v = spaceInfo.data?.data?.getPoisByID?.openingHour;
    if (v && v.open && v.from && v.to) {
      return {
        open: v.open,
        from: new Date(v.from),
        to: new Date(v.to),
      };
    }
    if (v && v.open === false) {
      return {
        open: v.open,
      };
    }
  }, [spaceInfo.data?.data?.getPoisByID?.openingHour]);

  const daySlots = useMemo(() => {
    const spaceTypeInfo = getSpaceTypeInfo(spaceInfo.data?.data?.getPoisByID?.type);
    if (!openingHoursForDay?.open) {
      return { early: [], morning: [], afternoon: [], evening: [] };
    }
    /* Start by ordering the busySlots */
    const busySlots: { start: Date; end: Date; userId: string | undefined | null }[] =
      spaceInfo.data?.data?.getPoisByID?.reservations
        ?.map((e) => ({ start: new Date(e!.from!), end: new Date(e!.to!), userId: e?.userId }))
        .filter(
          (e) =>
            isSameDay(startOfDayTz(dayNoTz, timeZone), startOfDayTz(e.start, timeZone)) ||
            isSameDay(startOfDayTz(dayNoTz, timeZone), startOfDayTz(e.end, timeZone)),
        )
        .sort((a, b) => a.start.getTime() - b.end.getTime()) ?? [];

    /* Add free slots around the busy slots */
    let allSlots: SlotData[] = [];

    /* Start generating slots from start of day */
    let prevEnd = openingHoursForDay.from;
    busySlots.forEach((busySlot) => {
      /* Find previous slot, if any */
      const prevSlot = allSlots.length > 0 ? allSlots[allSlots.length - 1] : undefined;
      /* If previous slot, start generating from its end, otherwise start of day */
      /* Generate free slots from previous slot to next busy slot start */
      const freeSlots = generateFreeSlots(prevEnd, busySlot.start, spaceTypeInfo.minimumBookingMinutes);
      if (freeSlots || prevSlot?.type !== 'busy') {
        freeSlots.forEach((e) => allSlots.push(e));
        allSlots.push({
          start: busySlot.start,
          end: busySlot.end,
          type: busySlot.userId ? 'mine' : 'busy',
        });
      } else {
        /* 
        No free slots added between the previous busy slot and next, 
        so we update the end of the prevous busy slot instead 
        */
        // eslint-disable-next-line prefer-destructuring
        prevSlot.end = busySlot.end;
      }
      prevEnd = busySlot.end;
    });

    /* Add end of day free slots */
    const lastSlot = allSlots.length > 0 ? allSlots[allSlots.length - 1] : undefined;
    const lastSlotEnd = lastSlot?.end ?? openingHoursForDay.from;
    const endDayFreeSlots = generateFreeSlots(lastSlotEnd, openingHoursForDay.to, spaceTypeInfo.minimumBookingMinutes);
    endDayFreeSlots.forEach((e) => allSlots.push(e));

    /* Check if we can fit a last possible slot after the last known slot */
    if (lastSlot && differenceInMinutes(openingHoursForDay.to, lastSlotEnd) > spaceTypeInfo.minimumBookingMinutes) {
      const lastPossibleSlotStart = subMinutes(openingHoursForDay.to, spaceTypeInfo.minimumBookingMinutes);
      if (lastSlot.start !== lastPossibleSlotStart) {
        allSlots.push({
          type: 'free',
          start: lastPossibleSlotStart,
        });
      }
    }

    // Remove any slots in the past
    allSlots = allSlots.filter((e) => {
      return e.start >= now || (e.end && e.end >= now);
    });

    /* Check if we should prepend a slot for "now" */
    if (
      isSameDay(startOfDayTz(dayNoTz, timeZone), today) &&
      allSlots.length &&
      allSlots[0].type === 'free' &&
      !isSameMinute(allSlots[0].start, now)
    ) {
      allSlots.unshift({
        type: 'free',
        start: now,
      });
    }
    /* Group slots for time of day */
    return {
      early: allSlots.filter((s) => isEarly(s.start, timeZone)),
      morning: allSlots.filter((s) => isMorning(s.start, timeZone)),
      afternoon: allSlots.filter((s) => isAfternoon(s.start, timeZone)),
      evening: allSlots.filter((s) => isEvening(s.start, timeZone)),
    };
  }, [dayNoTz, now, openingHoursForDay, spaceInfo, today, timeZone]);

  const handleSlotPress = useCallback(
    (slot: SlotData) => {
      onChange({
        ...filter,
        date: slot.start,
      });
      navigation.navigate('map', { screen: 'MapView' });
    },
    [onChange, filter, navigation],
  );

  const renderSlotGroup = useCallback(
    (slots: SlotData[], title: string) => {
      if (slots.length) {
        return (
          <>
            <Caption>{title}</Caption>
            <View style={styles.slotButtonGroup}>
              {slots.map((e) => (
                <SlotButton key={+e.start} spaceTz={timeZone} onPress={() => handleSlotPress(e)} {...e} />
              ))}
            </View>
          </>
        );
      }
      return null;
    },
    [handleSlotPress, timeZone],
  );

  const openingHoursText: string = useMemo(() => {
    if (!openingHoursForDay) return '';
    if (!openingHoursForDay.open) return I18n.t('mapview.spaceInfo.closed');
    return I18n.t('mapview.spaceInfo.openingHours', {
      from: formatDate(openingHoursForDay.from, 'time-no-tz', timeZone),
      to: formatDate(openingHoursForDay.to, 'time', timeZone ?? undefined),
    });
  }, [I18n, formatDate, openingHoursForDay, timeZone]);

  return (
    <Surface style={{ paddingVertical: 10, marginVertical: 10 }}>
      <Container>
        <Spacer />
        <View style={styles.arrowButtonRow}>
          <ArrowButton direction="left" onPress={previousDate} />
          <View style={styles.arrowButtonDateContainer}>
            <Button onPress={() => setDatePickerVisible(true)}>{dateString}</Button>
          </View>
          <ArrowButton direction="right" onPress={nextDate} />
        </View>
        <View style={styles.openingHours}>
          <Typography variant={'caption'}>{openingHoursText}</Typography>
        </View>
        <Spacer />
        <View>
          {renderSlotGroup(daySlots.early, I18n.t('mapview.spaceInfo.earlyMorning'))}
          {renderSlotGroup(daySlots.morning, I18n.t('mapview.spaceInfo.morning'))}
          {renderSlotGroup(daySlots.afternoon, I18n.t('mapview.spaceInfo.afternoon'))}
          {renderSlotGroup(daySlots.evening, I18n.t('mapview.spaceInfo.evening'))}
        </View>
        <Spacer />
      </Container>
      <DateTimePickerModal
        isVisible={isDatePickerVisible}
        mode="date"
        date={utcToZonedTime(dayNoTz, timeZone)}
        locale={currentLocale}
        maximumDate={addDays(now, MAX_ADVANCE_RESERVE_DAYS)}
        minimumDate={utcToZonedTime(now, timeZone)}
        onConfirm={onDateConfirm}
        onCancel={() => setDatePickerVisible(false)}
      />
    </Surface>
  );
};
const FREE_BUTTON_WIDTH = 60;
const SLOT_BUTTON_MARGIN = 8;
const BUSY_BUTTON_WIDTH = 2 * (FREE_BUTTON_WIDTH + SLOT_BUTTON_MARGIN);
const styles = StyleSheet.create({
  openingHours: {
    alignItems: 'center',
  },
  slotButtonGroup: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  slotFreeButton: {
    flexGrow: 0,
    borderColor: '#0f0',
    margin: SLOT_BUTTON_MARGIN,
    minWidth: FREE_BUTTON_WIDTH,
  },
  slotBusyButton: {
    flexGrow: 0,
    borderColor: '#f00',
    margin: SLOT_BUTTON_MARGIN,
    minWidth: BUSY_BUTTON_WIDTH,
  },
  slotMineButton: {
    flexGrow: 0,
    borderColor: '#fff',
    margin: SLOT_BUTTON_MARGIN,
    minWidth: BUSY_BUTTON_WIDTH,
  },
  arrowButtonRow: {
    flexDirection: 'row',
  },
  arrowButtonDateContainer: {
    flexGrow: 1,
  },
});

export default SpaceSchedule;

import { FC, useEffect } from 'react';

import { useMapWrapper } from '../MapContext';
import {
  initBluedot,
  BluedotAccuracy,
  BluedotBearing,
  BluedotLocation,
  BluedotStyle,
  setBluedotAccuracy,
  setBluedotBearing,
  SetBluedotBearingVars,
  setBluedotLocation,
  setBluedotAnimating,
} from './helpers/AnimatedBluedot';

export type BluedotProps = {
  isAnimating?: boolean;
  location?: BluedotLocation;
  heading?: BluedotBearing;
  locationAccuracy?: number;
};

export const Bluedot: FC<BluedotProps> = (props) => {
  const { mapWrap, isStyleLoaded } = useMapWrapper();

  /* Initialize bluedot */
  useEffect(() => {
    if (isStyleLoaded) {
      const conf: BluedotStyle = {
        pulseAnimationDuration: 2000,
        haloRadius: 200,
        bearingRadius: 50,
        haloRGB: [200, 200, 255],
        dotRadius: 20,
        dotFillColor: 'rgb(100,100,255)',
        dotStrokeWidth: 4,
        dotStrokeColor: '#fff',
      };
      mapWrap.run(initBluedot, conf);
    }
    // TODO: Cleanup function
  }, [mapWrap, isStyleLoaded]);

  /* Bluedot location */
  useEffect(() => {
    if (isStyleLoaded) {
      mapWrap.run<BluedotLocation | null>(setBluedotLocation, props.location || null);
    }
  }, [props.location, mapWrap, isStyleLoaded]);

  /* Bluedot animation */
  useEffect(() => {
    if (isStyleLoaded) {
      mapWrap.run<boolean>(setBluedotAnimating, props.isAnimating ?? true);
    }
  }, [props.isAnimating, mapWrap, isStyleLoaded]);

  /* Bluedot accuracy */
  useEffect(() => {
    if (props.location && isStyleLoaded) {
      mapWrap.run<BluedotAccuracy>(setBluedotAccuracy, {
        lat: props.location.lat,
        accuracyM: props.locationAccuracy || null,
      });
    }
  }, [props.location, props.locationAccuracy, mapWrap, isStyleLoaded]);

  /* Bluedot heading */
  useEffect(() => {
    if (isStyleLoaded) {
      mapWrap.run<SetBluedotBearingVars>(setBluedotBearing, {
        bearingMode: props.heading ? 'rotate_icon' : 'hidden',
        bearing: props.heading || null,
      });
    }
  }, [props.heading, mapWrap, isStyleLoaded]);

  return null;
};

import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { RadioButton, useTheme } from 'react-native-paper';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormikHelpers } from 'formik/dist/types';
import Container from '../../components/Container';
import { useI18n } from '../../context/I18nContext';
import Typography from '../../components/Typography';
import Radio from '../../components/ui/Radio';
import FormikInput from '../../components/formik/FormikInput';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import { useModal } from '../../context/ModalContext';
import * as adminApi from '../../apis/adminApis';
import Switch from '../../components/ui/Switch';
import ScrollablePage from '../../components/ui/ScrollablePage';
import podExport from '../../../pod-exports.json';
import { AdminConfirmModalContent, RawAdminCommandOutput, SendAdminCommandButton } from './AdminTools';
import { SupportLanguage } from '../../types/appsync-types';
import Spacer from '../../components/Spacer';

interface RequestForm {
  language: SupportLanguage;
  title: string;
  body: string;
  url: string;
  audience: string;
  sendToAll: boolean;
}

export default function AppFeedback({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const [initForm] = useState<RequestForm>({
    language: SupportLanguage.FI,
    title: '',
    body: '',
    url: `${podExport.webLinkBaseUrl}/c/news`,
    audience: '',
    sendToAll: true,
  });
  const { colors } = useTheme();
  const mp = useMixpanel();
  const { I18n } = useI18n();
  const modal = useModal();
  const [request, setRequest] = useState<Promise<any>>();

  const validateSchema = useMemo(() => {
    return yup.object().shape({
      title: yup.string().required(I18n.t('general.emptyFieldError')),
      body: yup.string().max(200).required(I18n.t('general.emptyFieldError')),
      url: yup.string().required(I18n.t('general.emptyFieldError')),
      language: yup.string(),
      audience: yup.string(),
    });
  }, [I18n]);

  const handleAction = useCallback(
    (form: RequestForm) => {
      mp?.track('Send push notification', { ...form });
      const p = adminApi.sendNewsNotification(
        form.title,
        form.body,
        form.url,
        form.language,
        new Date(),
        form.sendToAll
          ? 'ALL'
          : form.audience
              .split(',')
              .map((e) => e.trim())
              .filter((e) => !!e),
      );
      p.finally(() => modal.closeModal());
      setRequest(p);
      return p;
    },
    [modal, mp],
  );

  const handleSubmitForm = useCallback(
    async (form: RequestForm, formikHelper: FormikHelpers<RequestForm>) => {
      modal.openModal({
        content: <AdminConfirmModalContent onDismiss={() => modal.closeModal()} onConfirm={() => handleAction(form)} />,
      });
    },
    [modal, handleAction],
  );

  return (
    <Formik initialValues={initForm} onSubmit={handleSubmitForm} validationSchema={validateSchema}>
      {({ values, handleChange, setFieldValue, handleSubmit, isSubmitting, touched, errors }) => (
        <ScrollablePage
          summary={{
            title: I18n.t('admin.pushnotification.title'),
            description: I18n.t('admin.pushnotification.description'),
            icon: 'pencil',
          }}
        >
          <Container>
            <Typography variant="body1">{I18n.t('admin.pushnotification.form.language')}</Typography>
            <RadioButton.Group onValueChange={handleChange('language')} value={values.language}>
              <Radio label={I18n.t('language.fi')} value={SupportLanguage.FI} />
              <Spacer />
              <Radio label={I18n.t('language.en')} value={SupportLanguage.EN} />
              <Spacer />
              <Radio label={I18n.t('language.sv')} value={SupportLanguage.SV} />
            </RadioButton.Group>

            <Spacer />
            <FormikInput
              name={'title'}
              mode="outlined"
              keyboardType="default"
              autoComplete="off"
              autoCapitalize="sentences"
              label={I18n.t('admin.pushnotification.form.title')}
            />
            <Spacer />
            <FormikInput
              multiline
              name={'body'}
              label={I18n.t('admin.pushnotification.form.body')}
              mode="outlined"
              numberOfLines={10}
              autoFocus={true}
              autoCapitalize={'sentences'}
              value={values.body}
              placeholder={''}
            />
            <Spacer />
            <FormikInput
              name={'url'}
              mode="outlined"
              keyboardType="url"
              autoComplete="off"
              autoCapitalize="none"
              label={I18n.t('admin.pushnotification.form.url')}
            />
            <Spacer />
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginVertical: 10,
              }}
            >
              <Typography variant={'sub1'} style={{ flex: 1, marginRight: 12 }} color={colors.secondary}>
                {'Send to all'}
              </Typography>
              <Switch
                value={values.sendToAll}
                onValueChange={(v) => {
                  setFieldValue('sendToAll', v);
                }}
              />
            </View>
            {values.sendToAll ? null : (
              <FormikInput
                multiline
                name={'audience'}
                label={I18n.t('admin.pushnotification.form.audience')}
                mode="outlined"
                disabled={values.sendToAll}
                numberOfLines={values.sendToAll ? 1 : 6}
                autoFocus={true}
                autoCapitalize={'sentences'}
                value={values.sendToAll ? '' : values.audience}
                onChangeText={handleChange('body')}
                placeholder={''}
              />
            )}

            <SendAdminCommandButton handleSubmit={handleSubmit} isSubmitting={isSubmitting} />

            <RawAdminCommandOutput request={request} />
          </Container>
        </ScrollablePage>
      )}
    </Formik>
  );
}

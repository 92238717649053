import { addMinutes, getMinutes, setMinutes, startOfMinute, subMinutes } from 'date-fns';
import { Dimensions, Platform, PixelRatio } from 'react-native';
import { AppsyncData } from '../apis/appsyncHelper';
import { Currency } from '../types/appsync-types';
import { Pod } from '../types/misc.types';

declare global {
  interface Window {
    MSStream: any;
  }
}

const { width: SCREEN_WIDTH } = Dimensions.get('window');

// based on iphone 5s's scale
const scale = SCREEN_WIDTH / 320;

export function getFontsize(size: number) {
  const newSize = size * scale;
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  }
  return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
}

export function isCodeValid({ code }: { code: string }): boolean {
  const FAKE_CODE = '000000';
  if (code.trim() === FAKE_CODE) {
    return false;
  }
  return true;
}

export type ExpireStatus = 'check' | 'ban' | 'exclamation';

export function getExpireStatusName(month: number, year: number): ExpireStatus {
  const oneMonth = 24 * 60 * 60 * 1000 * 30;
  const expireIn = +new Date(year, month - 1) - +new Date();
  if (expireIn <= 0) {
    return 'ban';
  }
  if (expireIn <= oneMonth) {
    return 'exclamation';
  }
  return 'check';
}

export function roundToPrevMinutes(date: Date, minutes: number): Date {
  const roundedMinutes = Math.floor(getMinutes(date) / minutes) * minutes;
  let ret;
  if (roundedMinutes === getMinutes(date)) {
    ret = subMinutes(startOfMinute(date), minutes);
  } else {
    ret = setMinutes(startOfMinute(date), roundedMinutes);
  }
  return ret;
}

export function roundToNextMinutes(date: Date, minutes: number): Date {
  const roundedMinutes = Math.ceil(getMinutes(date) / minutes) * minutes;
  let ret;
  if (roundedMinutes === getMinutes(date)) {
    ret = addMinutes(startOfMinute(date), minutes);
  } else {
    ret = setMinutes(startOfMinute(date), roundedMinutes);
  }
  return ret;
}

export function roundSecondsToPrevMinutes(secs: number, minutes: number): number {
  const roundedMinutes = Math.floor(secs / 60);
  const intervals = Math.ceil(roundedMinutes / minutes);
  return (intervals - 1) * minutes * 60;
}

export function roundSecondsToNextMinutes(secs: number, minutes: number): number {
  const roundedMinutes = Math.floor(secs / 60);
  const intervals = Math.floor(roundedMinutes / minutes);
  return (intervals + 1) * minutes * 60;
}

export function capitalizeFirstLetter(txt: string) {
  return txt.charAt(0).toUpperCase() + txt.slice(1);
}

declare global {
  interface Window {
    opera: any;
  }
}

export const isMobileDevice = () => {
  let check = false;
  ((a) => {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    /* iPad on iOS 13 detection */ (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    return 'ios';
  }

  return 'unknown';
};

export const parseSpaceIdFromURL = (pathName: string | undefined) => {
  if (pathName && (pathName.includes('c/space/') || pathName.includes('/c/lock/') || pathName.includes('/c/unlock/'))) {
    const spaceId = pathName.split('/')[pathName.split('/').length - 1];
    if (spaceId) {
      return spaceId;
    }
  }
};

/**
 * -1 version1 is smaller than version2
 * 0 version1 is same as version2
 * 1 version1 is bigger than version2
 * examples
 * "0.1", "1.1" -> -1
 * "1.0.1", "1" -> 1
 * "7.5.2.4", "7.5.3" -> -1
 * "1.1.2", "1.1.2" -> 0
 */
export const compareVersion = (version1: string, version2: string) => {
  const levels1 = version1.split('.');
  const levels2 = version2.split('.');

  const length = Math.max(levels1.length, levels2.length);

  for (let i = 0; i < length; i++) {
    const v1 = i < levels1.length ? parseInt(levels1[i], 10) : 0;
    const v2 = i < levels2.length ? parseInt(levels2[i], 10) : 0;

    if (v1 > v2) return 1;
    if (v2 > v1) return -1;
  }
  return 0;
};

function isDate(o: unknown) {
  return o && Object.prototype.toString.call(o) === '[object Date]';
}

function isPrimitive(o: unknown) {
  return (
    isDate(o) ||
    typeof o === 'boolean' ||
    typeof o === 'number' ||
    typeof o === 'string' ||
    o === null ||
    o === undefined
  );
}

type Primitive = number | boolean | string | Date;

export function flattenDict(
  prefix: string,
  o: unknown,
  previouslyVisited?: Set<unknown>,
): Record<string, Primitive | Primitive[]> {
  if (isPrimitive(o)) {
    return { [prefix]: o as Primitive };
  }
  const visited = previouslyVisited ?? new Set();
  if (visited.has(o)) return {};
  visited.add(o);

  if (Array.isArray(o)) {
    const isArrayOfObject = o.some((elem) => !isPrimitive(elem));
    if (!isArrayOfObject) {
      return { [prefix]: o as Primitive[] };
    }

    return o.reduce((prev, cur, index) => {
      return {
        ...prev,
        ...flattenDict(`${prefix}.${index}`, cur, visited),
      };
    }, {});
  }

  return Object.entries(o as object).reduce((prev, [key, value]) => {
    return {
      ...prev,
      ...flattenDict(`${prefix}.${key}`, value, visited),
    };
  }, {});
}

export function calculateSecurityDepositInCents(
  space: Pod | undefined | null,
  profile: AppsyncData<'getMyProfile'>['getMyProfile'],
): [number, Currency] | [undefined, undefined] {
  if (space && profile) {
    const spaceDepositCents = space.depositAmountInCents;
    const spaceCurrency = space.currency;
    const userTrustedCents = profile.skipDepositAmountInCents;
    const userCurrency = profile.currency;
    if (
      spaceCurrency &&
      userCurrency &&
      spaceDepositCents !== undefined &&
      spaceDepositCents !== null &&
      userTrustedCents !== undefined &&
      userTrustedCents !== null
    ) {
      if (spaceCurrency === userCurrency && spaceDepositCents <= userTrustedCents) {
        return [0, spaceCurrency];
      }
    }
    if (
      spaceDepositCents !== null &&
      spaceDepositCents !== undefined &&
      spaceCurrency !== null &&
      spaceCurrency !== undefined
    ) {
      return [spaceDepositCents, spaceCurrency];
    }
  }
  return [undefined, undefined];
}

export function safeJsonStringify(k: any) {
  try {
    return JSON.stringify(k);
  } catch (err) {
    return `<failed to stringify ${k}, error: ${err}>`;
  }
}

import React, { Component } from 'react';
import { ViewProps } from 'react-native';
import { WebViewNavigationEvent, WebViewErrorEvent } from 'react-native-webview/lib/WebViewTypes';
import type maplibregl from 'maplibre-gl';
import WrappedWebView, { JsonValue, RootParam } from './WrappedWebView';

const html = require('../../../../assets/map/map_html.json');

export type MaplibreRunnable<T> = (
  vars: T,
  maplibreMap: maplibregl.Map,
  lib: typeof maplibregl,
  window: Window,
) => JsonValue | void;

export type MapLibreWrapperProps = ViewProps & {
  onLoadEnd: (e: WebViewNavigationEvent | WebViewErrorEvent) => void;
};

export default class MapLibreWrapper extends Component<MapLibreWrapperProps> {
  webWrapperRef: React.RefObject<WrappedWebView>;

  constructor(props: MapLibreWrapperProps) {
    super(props);
    this.webWrapperRef = React.createRef();
  }

  run<T extends RootParam>(fun: MaplibreRunnable<T>, vars?: T, result?: (v: JsonValue) => void) {
    const params: [RootParam, RootParam, RootParam, RootParam] = [
      vars ?? {},
      { _execution_context_variable: 'maplibreMap' },
      { _execution_context_variable: 'maplibregl' },
      { _execution_context_variable: 'window' },
    ];
    const resultCb = result ?? (() => null);
    const errCb = () => console.error;

    this.webWrapperRef?.current?.invokeJS(fun.name, fun.toString(), params, resultCb, errCb);
  }

  render() {
    return <WrappedWebView html={html} ref={this.webWrapperRef} {...this.props} />;
  }
}

import { Platform } from 'react-native';
import { NavigationContainerRef } from '@react-navigation/native';
import { drawerScreens, screenNames } from '../navigators/screenNames';
import podExport from '../../pod-exports.json';
import { RootStackRouteParams } from '../navigators/RootNavigator';
import { MixpanelLibraryType } from '../mixpanel/Mixpanel';

export type UrlAction =
  | UnlockAction
  | SelectSpaceAction
  | ShowVenueAction
  | SetVoucherCodeAction
  | SetInviteCodeAction
  | NewsCodeAction;

export type UnlockAction = {
  type: 'UNLOCK';
  id: string;
};
export type SelectSpaceAction = {
  type: 'SELECT';
  id: string;
};
export type ShowVenueAction = {
  type: 'VENUE';
  id: string;
};
export type ZoomPointAction = {
  type: 'ZOOM';
  lat: number;
  lon: number;
  buildingRef?: string;
  layerIndex?: number;
  zoom: number;
};
export type SetVoucherCodeAction = {
  type: 'VOUCHER';
  id: string;
};
export type CheckNotificationPermissionAction = {
  type: 'CHECK-PERMISSION';
};

type SetInviteCodeAction = {
  type: 'INVITE';
  id: string;
};

type NewsCodeAction = {
  type: 'NEWS';
  id: string;
};
// LEGACY
const LEGACY_CORRECT_QR_CODE_URL = 'app.getspacehub.com/qr/';
const LEGACY_QR_CODE_URL = 'qr.getspacehub.com/';
const LEGACY_VOUCHER_QR_CODE_URL = 'app.getspacehub.com/c/qr/';

export const baseUrl =
  Platform.OS === 'web' && window.location.hostname === 'localhost' ? window.location.origin : podExport.webLinkBaseUrl;
const SPACE_CODE_URL = `${baseUrl}/c/space`;
const VENUE_CODE_URL = `${baseUrl}/c/venue`;
const VOUCHER_CODE_URL = `${baseUrl}/c/voucher`;
const LOCK_CODE_URL = `${baseUrl}/c/unlock`;
const INVITE_CODE_URL = `${baseUrl}/c/invite`;
const NEWS_CODE_URL = `${baseUrl}/c/news`;

/**
 * @param id Pod's localRef
 * @returns url as string
 */
export function generateDeepLinkForSpace(id: string): string {
  return `${SPACE_CODE_URL}/${id}`;
}

/**
 * @param id Friend or promo code
 * @returns url as string
 */
export function generateDeepLinkForInvite(id: string): string {
  return `${INVITE_CODE_URL}/${id}`;
}

export async function parseAppUrl(
  url: string,
  navigateCallback: (screen: string, p: any) => void,
  mp: MixpanelLibraryType | undefined,
) {
  if (!url) {
    return false;
  }
  const action = parseUrlAction(url);

  if (!action) {
    mp?.track('URL Action unknown', { url });
    return false;
  }

  mp?.track('URL Action', action);

  switch (action.type) {
    case 'SELECT':
    case 'VENUE':
    case 'UNLOCK':
      navigateCallback(screenNames.Map, { action });
      break;
    case 'VOUCHER':
      {
        const params = {
          code: action.id,
          sourceScreen: screenNames.EnterCodeManuallyScreen,
          mode: 'VOUCHER',
        };
        navigateCallback(screenNames.EnterCodeManuallyScreen, params);
      }
      break;
    case 'INVITE':
      // TODO: Likely need to sign out first
      // TODO: handle invite url
      navigateCallback(screenNames.EnterInvitationCode, { invitationCode: action.id });
      break;
    case 'NEWS':
      navigateCallback(drawerScreens.NewsStack, { screen: screenNames.News, url: action.id });
      break;
  }
  return true;
}

// Why wrapping navigation tasks with timeout? https://github.com/react-navigation/react-navigation/issues/8537
export async function handleAppUrl(
  navigationRef: NavigationContainerRef<RootStackRouteParams>,
  url: string,
  mp: MixpanelLibraryType | undefined,
): Promise<boolean> {
  // Wrapping navigation tasks with timeout: https://github.com/react-navigation/react-navigation/issues/8537
  const handleNavigate = (screenName: string, params: any) => {
    setTimeout(() => {
      if (navigationRef.getCurrentRoute()?.name === screenName) {
        navigationRef.setParams(params);
      } else {
        // We might need to navigate between nested navigators, so
        // passing params in two different ways...
        // https://reactnavigation.org/docs/params/#passing-params-to-nested-navigators
        // TODO: We should define the stacks where screens live etc.
        navigationRef.navigate(screenName as any, {
          ...params,
          screen: screenName,
          params,
        });
      }
    }, 100);
  };
  return parseAppUrl(url, handleNavigate, mp);
}

export function parseUrlAction(data: string): UrlAction | undefined {
  if (data.includes(LEGACY_CORRECT_QR_CODE_URL)) {
    return {
      id: data.split('qr/').pop() || '',
      type: 'UNLOCK',
    };
  }
  if (data.includes(LEGACY_QR_CODE_URL)) {
    return {
      id: data.split('/').pop() || '',
      type: 'UNLOCK',
    };
  }
  if (data.includes(LOCK_CODE_URL)) {
    return {
      id: data.split('lock/').pop() || '',
      type: 'UNLOCK',
    };
  }
  // VOUCHERS
  if (data.includes(LEGACY_VOUCHER_QR_CODE_URL)) {
    return {
      id: data.split('/').pop() || '',
      type: 'VOUCHER',
    };
  }
  if (data.includes(VOUCHER_CODE_URL)) {
    return {
      id: data.split('voucher/').pop() || '',
      type: 'VOUCHER',
    };
  }
  // SPACE
  if (data.includes(SPACE_CODE_URL)) {
    return {
      id: data.split('space/').pop() || '',
      type: 'SELECT',
    };
  }
  // VENUE
  if (data.includes(VENUE_CODE_URL)) {
    return {
      id: data.split('venue/').pop() || '',
      type: 'VENUE',
    };
  }
  // INVITE
  if (data.includes(INVITE_CODE_URL)) {
    return {
      id: data.split('invite/').pop() || '',
      type: 'INVITE',
    };
  }

  // NEWS
  if (data.includes(NEWS_CODE_URL)) {
    return {
      id: data.split('news/').pop() || '',
      type: 'NEWS',
    };
  }
}

import React, { useCallback, useState } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { useMutation, useQueryClient } from 'react-query';
import { HelperText } from 'react-native-paper';
import { cancelReservation, invalidateReservationData } from '../../apis/reservationApis';
import { useI18n } from '../../context/I18nContext';
import { RESERVED_NOTIFICATION_KEYS, useMapViewBanner } from '../../context/MapViewBannerContext';
import { NavigationDialog } from '../../screens/CommonDialogs';
import Container from '../../components/Container';
import { flattenDict } from '../../utils/helpers';
import { useMixpanel } from '../../mixpanel/MixpanelContext';

export type CancelReservationDialogRouteParams = {
  originalReservationId: string;
};

type DialogScreenProps = StackScreenProps<
  { CancelReservationDialog: CancelReservationDialogRouteParams },
  'CancelReservationDialog'
>;
export function CancelReservationDialog({ route, navigation }: DialogScreenProps) {
  const { originalReservationId } = route.params;
  const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined);
  const mp = useMixpanel();
  const queryClient = useQueryClient();
  const { I18n } = useI18n();
  const { removeNotificationWithKey } = useMapViewBanner();

  const cancelReservationMutation = useMutation(
    async (input: Parameters<typeof cancelReservation>) => {
      return cancelReservation(...input);
    },
    {
      onSuccess(data) {
        mp?.track('Reservation cancel success', flattenDict('reservation', data.data?.cancelAReservation));
        mp?.getPeople().increment('Cancellations', 1);
        removeNotificationWithKey(RESERVED_NOTIFICATION_KEYS.BOOKING_SUCCEED);
        invalidateReservationData(queryClient);
        navigation.goBack();
      },
      onError(error: any) {
        mp?.track('Reservation cancel error', error ?? null);
        setErrorMessage(`${I18n.t('cancelReservation.failTitle')}: ${error?.message}`);
      },
    },
  );

  const onDismiss = useCallback(() => {
    navigation.goBack();
  }, [navigation]);
  return (
    <NavigationDialog
      title={I18n.t('cancelReservation.confirmTitle')}
      description={I18n.t('cancelReservation.confirmDescription')}
      onDismiss={onDismiss}
      iconName="question"
      actions={[
        {
          title: I18n.t('cancelReservation.backButton'),
          onPress: onDismiss,
          disable: cancelReservationMutation.isLoading,
        },
        {
          title: I18n.t('cancelReservation.confirmButton'),
          onPress: () => {
            return cancelReservationMutation.mutate([{ id: originalReservationId }]);
          },
          loading: cancelReservationMutation.isLoading,
          disable: cancelReservationMutation.isLoading,
        },
      ]}
    >
      <Container>
        <HelperText visible={!!errorMessage} type="error">
          {errorMessage}
        </HelperText>
      </Container>
    </NavigationDialog>
  );
}

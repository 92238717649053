import React from 'react';
import { Linking } from 'react-native';
import { List } from 'react-native-paper';
import { useI18n } from '../../context/I18nContext';
import { useGetRecentReceipts } from '../paymentApis';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { listIconGenerator } from '../../components/SimpleListIcon';

const AllRecentReceiptsView = () => {
  const { I18n, formatDate, formatCurrency } = useI18n();

  const getRecentReceipts = useGetRecentReceipts();

  const recentReceipts = getRecentReceipts.data?.data?.getRecentTransactions ?? [];

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('payment.receipts.summaryTitle'),
        description: I18n.t('payment.receipts.summaryDescription'),
        icon: 'doc',
      }}
    >
      {recentReceipts.map((item) => {
        return (
          <List.Item
            key={String(item?.reservation?.id)}
            title={formatCurrency(item?.amount, item?.currency!)}
            description={formatDate(
              new Date(item?.reservation?.from!),
              'timeAndDate',
              item?.reservation?.building?.timezone,
            )}
            left={listIconGenerator('doc')}
            right={listIconGenerator('link')}
            onPress={() => {
              if (item?.reservation?.receiptUrl) {
                Linking.openURL(item?.reservation?.receiptUrl);
              }
            }}
          />
        );
      })}
    </ScrollablePage>
  );
};

export default AllRecentReceiptsView;

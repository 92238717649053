import React, { useCallback, useState } from 'react';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { Checkbox } from 'react-native-paper';
import { AppDrawerScreenProp } from '../components/DrawerMenuContent';
import { useI18n } from '../context/I18nContext';
import { MapStackRouteParams } from '../navigators/MapStackNavigator';
import { NavigationDialog } from './CommonDialogs';
import { InternalStorageItemKey, storeData } from '../utils/internalStorage';
import Spacer from '../components/Spacer';
import { useMapViewFilter } from '../context/MapViewFilterContext';

type Props = CompositeScreenProps<StackScreenProps<MapStackRouteParams, 'TimezoneWarningDialog'>, AppDrawerScreenProp>;

export function TimezoneWarningDialog({ navigation, route }: Props) {
  const { I18n, formatDate } = useI18n();
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);
  const { timeZone, filter } = useMapViewFilter();

  const handleNavigateBack = useCallback(() => {
    if (dontShowAgain) {
      storeData(InternalStorageItemKey.DONT_SHOW_TIMEZONE_DIALOG, true);
    }
    navigation.goBack();
  }, [dontShowAgain, navigation]);

  return (
    <NavigationDialog
      title={I18n.t('mapview.timezoneDialog.title', { zone: timeZone })}
      description={I18n.t('mapview.timezoneDialog.description', { time: formatDate(filter.date, 'time', timeZone) })}
      iconName={'globe'}
      onDismiss={() => navigation.goBack()}
      actions={[
        {
          title: I18n.t('general.ok'),
          onPress: handleNavigateBack,
        },
      ]}
    >
      <Spacer type="section" />
      <Checkbox.Item
        mode="android"
        style={{
          flexDirection: 'row-reverse',
        }}
        labelStyle={{
          fontSize: 14,
        }}
        label={I18n.t('general.dontShowAgain')}
        status={dontShowAgain ? 'checked' : 'unchecked'}
        onPress={() => {
          storeData(InternalStorageItemKey.DONT_SHOW_DEPOSIT_DIALOG, !dontShowAgain);
          setDontShowAgain((prev) => !prev);
        }}
      />
    </NavigationDialog>
  );
}

import React, { useCallback, useMemo } from 'react';
import { View, StyleSheet, ScrollView, Platform, TouchableOpacity, Linking } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Button, HelperText, Surface, useTheme } from 'react-native-paper';
import {
  DrawerContentScrollView,
  DrawerContentComponentProps,
  DrawerItem,
  DrawerScreenProps,
  DrawerNavigationProp,
} from '@react-navigation/drawer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { NavigatorScreenParams } from '@react-navigation/core';
import Constants from 'expo-constants';
import { useI18n } from '../context/I18nContext';
import Images from '../../assets/images';
import { drawerScreens } from '../navigators/screenNames';
import { useModal } from '../context/ModalContext';
import { useAuth, AuthState, AccessLevel } from '../context/AuthContext';
import { useMyProfileQuery } from '../apis/appsyncApis';
import TextModal from './modal/TextModal';
import { AdminStackRouteParams } from '../navigators/AdminStackNavigator';
import { HelpStackRouteParams } from '../navigators/HelpStackNavigator';
import { InformationStackRouteParams } from '../navigators/InformationStackNavigator';
import { LocationStackRouteParams } from '../navigators/LocationsStackNavigator';
import { MapStackRouteParams } from '../navigators/MapStackNavigator';
import { NewsStackRouteParams } from '../navigators/NewsStackNavigator';
import { QRCodeStackRouteParams } from '../navigators/QRCodeStackNavigator';
import { getMobileOperatingSystem } from '../utils/helpers';
import Spacer from './Spacer';
import { MenuItemType } from '../navigators/DrawerNavigator';
import { useSpacehubTheme } from '../utils/paperTheme';
import Typography from './Typography';
import { UiIcon } from './SimpleListIcon';

export const GRADIENT_HEADER_BASE_HEIGHT = 167;

type DrawerMenuContentHeaderProps = {
  isLoggedIn: boolean;
  name?: string;
  organization?: string | null;
};

export type DrawerRouteParams = {
  [drawerScreens.MapStack]: NavigatorScreenParams<MapStackRouteParams>;
  [drawerScreens.MyInfoStack]: NavigatorScreenParams<InformationStackRouteParams>;
  [drawerScreens.QRCodeStack]: NavigatorScreenParams<QRCodeStackRouteParams>;
  [drawerScreens.LocationsStack]: NavigatorScreenParams<LocationStackRouteParams>;
  [drawerScreens.NewsStack]: NavigatorScreenParams<NewsStackRouteParams>;
  [drawerScreens.AdminStack]: NavigatorScreenParams<AdminStackRouteParams>;
  [drawerScreens.HelpStack]: NavigatorScreenParams<HelpStackRouteParams>;
};

export type AppDrawerScreenProp = DrawerScreenProps<DrawerRouteParams>;

export type AppDrawerNavigationProp<T extends keyof DrawerRouteParams = keyof DrawerRouteParams> = DrawerNavigationProp<
  DrawerRouteParams,
  T
>;

function DrawerMenuContentHeader({ name, organization, isLoggedIn }: DrawerMenuContentHeaderProps) {
  const { colors, custom } = useSpacehubTheme();
  const { top } = useSafeAreaInsets();
  const { I18n } = useI18n();
  const { logoutAsGuest } = useAuth();
  return (
    <Surface style={[{ elevation: 4 }, styles.headerWrapper]}>
      <LinearGradient
        {...custom.gradientLayer1}
        style={[styles.headerWrapper, { height: GRADIENT_HEADER_BASE_HEIGHT + top, paddingTop: top }]}
      >
        <LinearGradient
          {...custom.gradientLayer2}
          style={[styles.headerContainer, { height: GRADIENT_HEADER_BASE_HEIGHT }]}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              style={{
                marginRight: 10,
                paddingTop: 5,
                letterSpacing: 5,
              }}
              variant="button"
              color={colors.secondary}
            >
              {I18n.t('general.appName')}
            </Typography>
            <Images.iconApp
              style={{ width: 20, height: 20 }}
              width={20}
              height={20}
              /* setting resizeMode on web causes very long error message on console */
              {...(Platform.OS !== 'web' ? { resizeMode: 'contain' } : {})}
            />
          </View>
          <View>
            <Typography variant="h6" color={colors.secondary}>
              {name}
            </Typography>
            <Typography variant="body2" color={colors.secondary}>
              {isLoggedIn && organization}
            </Typography>
            {!isLoggedIn ? (
              <Button onPress={() => logoutAsGuest()} mode="contained">
                {I18n.t('myinfo.signInButton')}
              </Button>
            ) : null}
          </View>
        </LinearGradient>
      </LinearGradient>
    </Surface>
  );
}

function StoreLinks() {
  const opts = Constants.manifest?.extra;
  const webOs = getMobileOperatingSystem();
  if (webOs === 'android') {
    return (
      <TouchableOpacity onPress={() => Linking.openURL(opts?.playStoreLink)}>
        <Images.playStore.default />
      </TouchableOpacity>
    );
  }
  if (webOs === 'ios') {
    return (
      <TouchableOpacity onPress={() => Linking.openURL(opts?.appStoreLink)}>
        <Images.appStore.default />
      </TouchableOpacity>
    );
  }
  return (
    <>
      <TouchableOpacity onPress={() => Linking.openURL(opts?.playStoreLink)}>
        <Images.playStore.default />
      </TouchableOpacity>
      <Spacer />
      <TouchableOpacity onPress={() => Linking.openURL(opts?.appStoreLink)}>
        <Images.appStore.default />
      </TouchableOpacity>
    </>
  );
}

export default function DrawerMenuContent(props: DrawerContentComponentProps & { items: MenuItemType[] }) {
  const { navigation, state } = props;
  const { I18n } = useI18n();
  const { colors, fonts } = useTheme();
  const { closeModal, openModal } = useModal();
  const { state: authState, logoutAsGuest, userAccessLevel } = useAuth();

  const navigateAction = useCallback(
    (requiredAccessLevel: AccessLevel, screenStackName: string, params?: Record<string, any>) => {
      if (userAccessLevel < requiredAccessLevel) {
        openModal({
          content: (
            <TextModal
              title={I18n.t('drawerMenu.modal.unauthenticatedTitle')}
              description={I18n.t('drawerMenu.modal.unauthenticatedDesc')}
              icon="user"
              buttons={[
                {
                  title: I18n.t('general.dismiss'),
                  onPress: closeModal,
                },
                {
                  title: I18n.t('general.createAcc'),
                  onPress: () => {
                    closeModal();
                    logoutAsGuest();
                  },
                },
              ]}
            />
          ),
        });
      } else {
        navigation.navigate(screenStackName, params);
      }
    },
    [userAccessLevel, logoutAsGuest, closeModal, I18n, navigation, openModal],
  );

  const fetchMyProfileQuery = useMyProfileQuery();

  const name: string | undefined =
    authState === AuthState.GUEST_LOGIN
      ? undefined
      : `${fetchMyProfileQuery.data?.data?.getMyProfile?.firstname ?? ''} ${
          fetchMyProfileQuery.data?.data?.getMyProfile?.lastname ?? ''
        }`;

  const visibleTranslatedItems = useMemo(() => {
    return props.items.map((k) => {
      return {
        ...k,
        translationKey: I18n.t(k.translationKey),
      };
    });
  }, [props.items, I18n]);

  return (
    <DrawerContentScrollView
      contentContainerStyle={{
        // Trying to mimic the background color of the drawer as much as possible here
        backgroundColor: '#363636',
        // Override default value of padding top: 4 in the library
        paddingTop: 0,
        flex: 1,
      }}
      {...props}
      scrollEnabled={false}
    >
      <DrawerMenuContentHeader
        name={name}
        isLoggedIn={authState === AuthState.AUTHENTICATED}
        organization={fetchMyProfileQuery.data?.data?.getMyProfile?.organization}
      />

      <ScrollView bounces={false}>
        {visibleTranslatedItems.map((item, index) => (
          <DrawerItem
            key={item.translationKey}
            label={() => (
              <Typography variant="body2" color={state.index === index ? colors.primary : colors.onSurface}>
                {item.translationKey}
              </Typography>
            )}
            icon={() => (
              <UiIcon {...item.uiIcon} size={24} color={state.index === index ? colors.primary : colors.onSurface} />
            )}
            labelStyle={[styles.itemLabel, { color: colors.onSurface, fontFamily: fonts.default.fontFamily }]}
            onPress={() => {
              navigateAction(item.requiredAccessLevel, item.screenStackName, item.screenParams);
            }}
            activeTintColor={colors.secondary}
            inactiveTintColor={colors.onSurface}
          />
        ))}
      </ScrollView>
      {Constants.manifest?.extra?.showStoreLinksInMenu ? (
        <View style={{ alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>
          <Spacer type="section" />
          <HelperText type="info" visible={true}>
            {I18n.t('drawerMenu.noteUnlockRequiresApp')}
          </HelperText>
          <StoreLinks />
          <Spacer />
        </View>
      ) : null}
    </DrawerContentScrollView>
  );
}

const styles = StyleSheet.create({
  headerWrapper: {
    width: '100%',
    padding: 0,
  },
  headerContainer: {
    width: '100%',
    justifyContent: 'space-between',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255,255,255,0.12)',
  },
  itemLabel: {
    fontSize: 14,
  },
});

import { BleManager as PlxBleManager, State as BluetoothStatus } from 'react-native-ble-plx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Linking, NativeModules, Platform } from 'react-native';

type BluetoothHookValue = {
  manager: PlxBleManager | undefined;
  bluetoothStatus: BluetoothStatus;
  isBluetoothEnabled: boolean;
  enableBluetooth: () => Promise<void>;
};

const manager = Platform.OS === 'web' ? undefined : new PlxBleManager();

/* 
Since there should be only one PlxBleManager we have this hook implementation
wrapped by singletonHook().
*/
export const useBluetoothStatus = (): BluetoothHookValue => {
  const [bluetoothStatus, setBluetoothStatus] = useState<BluetoothStatus>(BluetoothStatus.Unknown);
  const [isBluetoothEnabled, setIsBluetoothEnabled] = useState<boolean>(false);

  useEffect(() => {
    const subscription = manager?.onStateChange((s) => {
      setBluetoothStatus(s);
      if (s === BluetoothStatus.PoweredOn) {
        setIsBluetoothEnabled(true);
      } else {
        setIsBluetoothEnabled(false);
      }
    }, true);
    return () => {
      subscription?.remove();
    };
  }, []);

  const enableBluetooth = useCallback((): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (Platform.OS === 'ios') {
        resolve(Linking.openURL('App-Prefs:Bluetooth'));
      }
      if (Platform.OS === 'android') {
        console.log('enable ble android');

        resolve(NativeModules.RNUtilsModule.requestEnableBluetooth(() => {}));
      }
      return new Promise((res, _rej) => res(false));
    });
  }, []);

  const result = useMemo(() => {
    return {
      manager,
      bluetoothStatus,
      isBluetoothEnabled,
      enableBluetooth,
    };
  }, [bluetoothStatus, isBluetoothEnabled, enableBluetooth]);
  return result;
};

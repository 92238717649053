/* eslint-disable prefer-destructuring */

import { MarkerClusterProperties, MarkerGenerator } from '../components/map/content/MarkerCluster';
import { PodStatus } from '../types/appsync-types';
import { GeoJsonWithReservation, GeoJsonWithVenueAvailability } from './geojsonConverter';

export type GJClusterProps = {
  available: number;
  isPod: number;
  total: number;
  currentFreeEnd: number;
  nextFreeStart: number;
  podTzOffsetMs: number;
};

/* In clusters, the total number of available will be in the available prop */
export const venueAvailabilityClusterProperties: MarkerClusterProperties<GJClusterProps> = {
  available: ['+', ['number', ['get', 'available'], 0]],
  isPod: ['number', 0],
  total: ['+', ['number', ['get', 'total'], 0]],
  currentFreeEnd: ['max', ['number', ['get', 'currentFreeEnd'], -1]],
  nextFreeStart: ['min', ['number', ['get', 'nextFreeStart'], 60 * 60 * 24 * 7]],
  podTzOffsetMs: ['min', ['get', 'podTzOffsetMs']],
};

export const podAvailabilityClusterProperties: MarkerClusterProperties<GJClusterProps> = {
  available: ['+', ['case', ['==', ['string', PodStatus.FREE], ['get', 'podStatus']], 1, 0]],
  isPod: ['+', 1, ['number', 1]],
  total: ['+', 1],
  currentFreeEnd: ['max', ['number', ['get', 'currentFreeEnd'], -1]],
  nextFreeStart: ['min', ['number', ['get', 'nextFreeStart'], 60 * 60 * 24 * 7]],
  podTzOffsetMs: ['min', ['get', 'podTzOffsetMs']],
};

type ClusterAvailableVars = {
  available: number;
  total: number;
  hours: number;
  minutes: number;
};

type ClusterUnavailableVars = {
  available: number;
  total: number;
  fmt_H: string;
  fmt_h: string;
  fmt_mm: string;
  fmt_tt: string;
};

type VenueLeafAvailableVars = {
  title: string;
  available: number;
  total: number;
  hours: number;
  minutes: number;
};

type PodLeafAvailableVars = {
  title: string;
  hours: number;
  minutes: number;
};
type VenueLeafUnavailableVars = {
  title: string;
  available: number;
  total: number;
  fmt_H: string;
  fmt_h: string;
  fmt_mm: string;
  fmt_tt: string;
};

type PodLeafUnavailableVars = {
  title: string;
  fmt_H: string;
  fmt_h: string;
  fmt_mm: string;
  fmt_tt: string;
};

type PodLeafMyBookingVars = PodLeafUnavailableVars;

export type AvailabilityMarkerVars = {
  podBookedByMe: string;
  podAvailable: string;
  podUnavailable: string;
  venueWithAvailableHours: string;
  venueWithAvailableMinutes: string;
  venueWithoutAvailable: string;
  venueNoneExist: string;
  clusterSomeAvailableHours: string;
  clusterSomeAvailableMinutes: string;
  clusterNoneAvailable: string;
  clusterNoneExist: string;
  viewTimestampS: number;
  selectedId?: number;
};

type GJProps = GeoJsonWithReservation | GeoJsonWithVenueAvailability;

export const generateAvailabilityMarker: MarkerGenerator<AvailabilityMarkerVars, GJProps, GJClusterProps> = (
  e,
  doc,
  vars,
) => {
  // Simple parser for templates like "Hi [[name]]!"

  function stringTemplateParser<T>(expression: string, valueObj: T) {
    const templateMatcher = /\[\[\s?([^[\]\s]*)\s?\]\]/g;

    const text = expression
      ? expression.replace(templateMatcher, (_substring, value, _index) => {
          return '' + valueObj[value as keyof T];
        })
      : '';
    return text;
  }

  const container = doc.createElement('div');

  const el = doc.createElement('div');
  container.appendChild(el);

  const arrow = !e.properties.cluster ? doc.createElement('div') : null;
  if (arrow) {
    // Uses a fun trick by using a border to create an arrow shape
    arrow.appendChild(doc.createTextNode(''));
    arrow.style.borderStyle = 'solid';
    arrow.style.borderWidth = '10px';
    arrow.style.borderColor = 'transparent';
    arrow.style.marginTop = '-1px'; // Avoid artifacts between arrow and box
    arrow.style.borderTopColor = '#ccc';
    arrow.style.borderBottom = 'none';

    arrow.style.width = '0';
    arrow.style.height = '0';
    arrow.style.marginLeft = 'auto';
    arrow.style.marginRight = 'auto';

    container.appendChild(arrow);
  }

  function formatAvailableCluster() {
    if (e.properties.cluster !== true) return;
    const availableInSeconds = e.properties.currentFreeEnd - vars.viewTimestampS;
    const availableHours = Math.floor(availableInSeconds / 60 / 60);
    const availableMinutes = Math.floor((availableInSeconds - availableHours * 3600) / 60);
    const params: ClusterAvailableVars = {
      available: e.properties.available,
      total: e.properties.total,
      hours: availableHours,
      minutes: availableMinutes,
    };
    if (availableHours > 0) {
      el.innerHTML = stringTemplateParser<ClusterAvailableVars>(vars.clusterSomeAvailableHours, params);
    } else {
      el.innerHTML = stringTemplateParser<ClusterAvailableVars>(vars.clusterSomeAvailableMinutes, params);
    }
  }

  function formatUnavailableCluster() {
    if (e.properties.cluster !== true) return;
    const busyUntil = new Date(e.properties.nextFreeStart * 1000 + e.properties.podTzOffsetMs);
    const hours = busyUntil.getHours();
    const mins = busyUntil.getMinutes();

    const params: ClusterUnavailableVars = {
      available: e.properties.available,
      total: e.properties.total,
      fmt_H: '' + hours,
      fmt_h: '' + (hours < 12 ? hours : hours - 12),
      fmt_mm: (mins < 10 ? '0' : '') + mins,
      fmt_tt: hours < 12 ? 'am' : 'pm',
    };
    if (e.properties.total > 0) {
      el.innerHTML = stringTemplateParser<ClusterUnavailableVars>(vars.clusterNoneAvailable, params);
    } else {
      el.innerHTML = stringTemplateParser<ClusterUnavailableVars>(vars.clusterNoneExist, params);
    }
  }

  function formatPodAvailableLeaf(isSelected: boolean) {
    if (e.properties.cluster || e.properties.type !== 'space') return;
    const availableInSeconds = (e.properties.currentFreeEnd ?? vars.viewTimestampS) - vars.viewTimestampS;
    const availableHours = Math.floor(availableInSeconds / 60 / 60);
    const availableMinutes = Math.floor((availableInSeconds - availableHours * 3600) / 60);

    const params: PodLeafAvailableVars = {
      title: e.properties.title ?? '',
      hours: availableHours,
      minutes: availableMinutes,
    };
    el.innerHTML = stringTemplateParser<PodLeafAvailableVars>(vars.podAvailable, params);

    if (isSelected) {
      container.style.zIndex = '2';
      el.style.borderColor = '#fff';
      arrow!.style.borderTopColor = '#fff';
      el.style.borderWidth = '2.5px';
      el.style.boxShadow = '0px 0px 8px #EEEEEE';
    } else {
      container.style.zIndex = '1';
    }
  }

  function formatPodUnavailableLeaf(isSelected: boolean) {
    if (e.properties.cluster || e.properties.type !== 'space') return;
    const busyUntil = new Date(1000 * (e.properties.nextFreeStart ?? vars.viewTimestampS) + e.properties.podTzOffsetMs);
    const hours = busyUntil.getHours();
    const mins = busyUntil.getMinutes();

    const params: PodLeafUnavailableVars = {
      title: e.properties.title ?? '',
      fmt_H: '' + hours,
      fmt_h: '' + (hours < 12 ? hours : hours - 12),
      fmt_mm: (mins < 10 ? '0' : '') + mins,
      fmt_tt: hours < 12 ? 'am' : 'pm',
    };

    el.innerHTML = stringTemplateParser<PodLeafUnavailableVars>(vars.podUnavailable, params);

    el.style.borderColor = '#888';
    el.style.color = '#888';
    arrow!.style.borderTopColor = '#888';
    if (isSelected) {
      container.style.zIndex = '2';
      el.style.borderWidth = '2.5px';
      el.style.boxShadow = '0px 0px 8px #AAA';
    } else {
      container.style.zIndex = '1';
    }
  }

  function formatPodBookedByMeLeaf(isSelected: boolean) {
    if (e.properties.cluster || e.properties.type !== 'space') return;
    const busyUntil = new Date(1000 * (e.properties.nextFreeStart ?? vars.viewTimestampS) + e.properties.podTzOffsetMs);
    const hours = busyUntil.getHours();
    const mins = busyUntil.getMinutes();

    const params: PodLeafMyBookingVars = {
      title: e.properties.title ?? '',
      fmt_H: '' + hours,
      fmt_h: '' + (hours < 12 ? hours : hours - 12),
      fmt_mm: (mins < 10 ? '0' : '') + mins,
      fmt_tt: hours < 12 ? 'am' : 'pm',
    };

    el.innerHTML = stringTemplateParser<PodLeafMyBookingVars>(vars.podBookedByMe, params);

    el.style.borderWidth = '2px';
    el.style.background =
      'linear-gradient(0deg, rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0) 100%), ' +
      'linear-gradient(92.68deg, #2B6A97 25.94%, #063B61 82.45%)';
    if (isSelected) {
      container.style.zIndex = '2';
      el.style.borderColor = '#fff';
      arrow!.style.borderTopColor = '#fff';
      el.style.borderWidth = '2.5px';
      el.style.boxShadow = '0px 0px 8px #EEEEEE';
    } else {
      container.style.zIndex = '1';
    }
  }

  function formatVenueAvailableLeaf() {
    if (e.properties.cluster || e.properties.type !== 'venue') return;
    const availableInSeconds = e.properties.currentFreeEnd - vars.viewTimestampS;
    const availableHours = Math.floor(availableInSeconds / 60 / 60);
    const availableMinutes = Math.floor((availableInSeconds - availableHours * 3600) / 60);

    const params: VenueLeafAvailableVars = {
      title: e.properties.title ?? '',
      available: e.properties.available,
      total: e.properties.total,
      hours: availableHours,
      minutes: availableMinutes,
    };
    if (availableHours > 0) {
      el.innerHTML = stringTemplateParser<VenueLeafAvailableVars>(vars.venueWithAvailableHours, params);
    } else {
      el.innerHTML = stringTemplateParser<VenueLeafAvailableVars>(vars.venueWithAvailableMinutes, params);
    }
  }

  function formatVenueUnavailableLeaf() {
    if (e.properties.cluster || e.properties.type !== 'venue') return;
    const busyUntil = new Date(1000 * e.properties.nextFreeStart + e.properties.podTzOffsetMs);
    const hours = busyUntil.getHours();
    const mins = busyUntil.getMinutes();

    const params: VenueLeafUnavailableVars = {
      title: e.properties.title ?? '',
      available: e.properties.available,
      total: e.properties.total,
      fmt_H: '' + hours,
      fmt_h: '' + (hours < 12 ? hours : hours - 12),
      fmt_mm: (mins < 10 ? '0' : '') + mins,
      fmt_tt: hours < 12 ? 'am' : 'pm',
    };
    if (e.properties.total > 0) {
      el.innerHTML = stringTemplateParser<VenueLeafUnavailableVars>(vars.venueWithoutAvailable, params);
    } else {
      el.innerHTML = stringTemplateParser<VenueLeafUnavailableVars>(vars.venueNoneExist, params);
    }
  }

  function onStateDidChange(newVars: AvailabilityMarkerVars) {
    el.style.borderStyle = 'solid';
    el.style.borderRadius = '6px';
    el.style.paddingLeft = '8px';
    el.style.paddingRight = '8px';
    el.style.paddingTop = '5px';
    el.style.paddingBottom = '5px';
    el.style.boxShadow = '0px 0px 8px #000';
    el.style.fontSize = '14px';

    el.style.backgroundColor = '#273442';
    el.style.minWidth = '90px';
    el.style.width = 'min-content';
    el.style.textAlign = 'center';
    el.style.borderWidth = '1px';
    el.style.color = '#fff';
    el.style.borderColor = '#ccc';
    if (arrow) arrow.style.borderTopColor = '#ccc';

    if (e.properties.cluster) {
      /* Clusters */
      const hasAvailable = e.properties.available;
      if (hasAvailable > 0) {
        formatAvailableCluster();
      } else {
        formatUnavailableCluster();
      }
    } else if (e.properties.type === 'space') {
      const isSelected =
        e.properties.selected === true || (newVars.selectedId !== undefined && newVars.selectedId === e.id);

      const podStatus: PodStatus = e.properties.podStatus;

      if (podStatus === 'RESERVED_BY_ME') {
        formatPodBookedByMeLeaf(isSelected);
      } else if (podStatus === 'FREE') {
        formatPodAvailableLeaf(isSelected);
      } else {
        formatPodUnavailableLeaf(isSelected);
      }
    } else {
      /* Venue */
      const hasAvailable = e.properties.available;
      if (hasAvailable > 0) {
        formatVenueAvailableLeaf();
      } else {
        formatVenueUnavailableLeaf();
      }
    }
  }

  onStateDidChange(vars);

  return {
    html: container,
    options: {
      anchor: e.properties.cluster ? 'center' : 'bottom',
    },
    onStateUpdate: onStateDidChange,
  };
};

import { Auth } from 'aws-amplify';
import { sendMail } from './mailApis';

export async function sendAppFeedback(
  subject: string,
  body: string,
  type: 'contact' | 'anonymously',
  email: string,
  name: string,
) {
  const isAnon = type === 'anonymously';
  let user;
  if (!isAnon) {
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (e) {
      user = undefined;
    }
  }

  const signatureName = user ? name : '';
  const signature = isAnon
    ? 'Sent anonymously'
    : `Sent by ${signatureName} <${email}> (${user?.username ?? 'not logged in'})`;

  return sendMail(
    subject,
    `${body}

${signature}
`,
    isAnon ? undefined : email,
    isAnon || !user ? undefined : name,
  );
}

export async function sendSpaceFeedback(
  subject: string,
  feedback: string,
  building: string,
  spaceId: string,
  type: 'contact' | 'anonymously',
  email: string,
  name: string,
) {
  const isAnon = type === 'anonymously';
  let user;
  if (!isAnon) {
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (e) {
      user = undefined;
    }
  }

  const signatureName = user ? name : '';
  const signature = isAnon
    ? 'Sent anonymously'
    : `Sent by ${signatureName} <${email}> (${user?.username ?? 'not logged in'})`;

  return sendMail(
    subject,
    `Building: ${building}
Space id: ${spaceId}
Feedback content: ${feedback}

${signature}`,
    isAnon ? undefined : email,
    isAnon || !user ? undefined : name,
  );
}

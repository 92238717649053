import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Camera, FlashMode, PermissionResponse } from 'expo-camera/legacy';
import { LayoutChangeEvent, LayoutRectangle, Platform, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { CompositeScreenProps, useIsFocused } from '@react-navigation/native';
import { Button, useTheme } from 'react-native-paper';
import { BarCodeScanner } from 'expo-barcode-scanner';
import Svg, { Defs, Mask, Rect } from 'react-native-svg';
import { StackScreenProps } from '@react-navigation/stack';
import Images from '../../assets/images';
import { useI18n } from '../context/I18nContext';
import { drawerScreens, screenNames } from '../navigators/screenNames';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import { useGetQRPayloadValue } from '../apis/mapApis';
import { useModal } from '../context/ModalContext';
import { parseUrlAction, UnlockAction, UrlAction } from '../utils/urlHandler';
import ApplyVoucherCodeModal from '../components/modal/ApplyVoucherCodeModal';
import { AppDrawerScreenProp } from '../components/DrawerMenuContent';
import { MapStackRouteParams } from '../navigators/MapStackNavigator';
import { AppsyncData } from '../apis/appsyncHelper';
import { UiIcon } from '../components/SimpleListIcon';

export type QRScreenRouteParamsType =
  | {
      mode: 'UNLOCK';
    }
  | {
      mode: 'VOUCHER';
      code?: string;
    };

type Props = CompositeScreenProps<StackScreenProps<MapStackRouteParams, 'QRScanScreen'>, AppDrawerScreenProp>;

const QRScanScreen = ({ navigation, route }: Props) => {
  const { I18n } = useI18n();
  const [isFlashEnable, setIsFlashEnable] = useState(false);
  const modal = useModal();
  const { colors } = useTheme();
  const mp = useMixpanel();
  const camera = useRef<Camera>(null);
  const [cameraPermission, setCameraPermission] = useState<PermissionResponse>();
  const [cameraLayoutRectangle, setCameraLayoutRectangle] = useState<LayoutRectangle>();

  const [action, setAction] = useState<UrlAction | null>(null);
  const qrCodeResult = useGetQRPayloadValue(action?.id ? action : null);

  useEffect(() => {
    Camera.requestCameraPermissionsAsync().then(setCameraPermission);
  }, []);

  /* Parses action, which results in an update to the qrCodeResult */
  const handleBarcode = useCallback(
    (data: string) => {
      const parsedAction = parseUrlAction(data);
      if (parsedAction?.type === 'UNLOCK') {
        mp?.track('QR code scanned', { data });
        mp?.getPeople().increment('QR codes scanned', 1);
      }
      if (parsedAction?.type === 'VOUCHER') {
        mp?.track('Voucher QR code scanned', { data });
        mp?.getPeople().increment('Voucher QR codes scanned', 1);
      }
      setAction(parsedAction ?? null);
    },
    [mp],
  );

  const handleSpaceCodeQueryResult = useCallback(
    (poi: AppsyncData<'getPoisByCode'>) => {
      if (poi.getPoisByCode?.localRef) {
        camera.current?.pausePreview();
        // We cannot directly re-use action, as it may have changed as
        // someone could have scanned something else before this query
        // finished.
        const params: { action: UnlockAction } = {
          action: {
            type: 'UNLOCK',
            id: poi.getPoisByCode.localRef,
          },
        };
        navigation.navigate({
          name: drawerScreens.MapStack,
          params: {
            screen: screenNames.Map,
            params,
          },
          merge: true, // This makes the parent screen, i.e. mapview get these new params
        });
      }
    },
    [navigation],
  );

  const handleVoucherCodeQueryResult = useCallback(
    (voucher: AppsyncData<'getVoucherByCode'>) => {
      camera.current?.pausePreview();
      modal.openModal({
        content: (
          <ApplyVoucherCodeModal
            voucher={voucher}
            onDismiss={() => {
              camera.current?.resumePreview();
              // Reset action so if user scans the same thing
              // again, the action changes.
              setAction(null);
              modal.closeModal();
            }}
          />
        ),
      });
    },
    [modal, camera],
  );

  /* Executed when qrCodeResult changes */
  useEffect(() => {
    if (qrCodeResult.isSuccess) {
      if (qrCodeResult.data?.data?.getPoisByCode) {
        handleSpaceCodeQueryResult(qrCodeResult.data?.data);
      }
      if (qrCodeResult.data?.data?.getVoucherByCode) {
        handleVoucherCodeQueryResult(qrCodeResult.data?.data);
      }
    }
  }, [
    qrCodeResult.data?.data,
    qrCodeResult.isSuccess,
    handleSpaceCodeQueryResult,
    handleVoucherCodeQueryResult,
    qrCodeResult,
  ]);

  const isScreenFocused = useIsFocused();
  mp?.timeEvent('QR code scanned');
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: colors.background,
      }}
    >
      <Images.ImageScanTarget
        style={{ position: 'absolute', zIndex: 9 }}
        {...(Platform.OS !== 'web'
          ? {
              onLayout: (evt: LayoutChangeEvent) => {
                setCameraLayoutRectangle(evt.nativeEvent.layout);
              },
            }
          : {
              /* onLayout gives error on web so not adding it */
            })}
      />

      {cameraLayoutRectangle && (
        <View style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 8 }}>
          <Svg height="100%" width="100%">
            <Defs>
              <Mask id="mask" x="0" y="0" height="100%" width="100%">
                <Rect height="100%" width="100%" fill="#fff" />
                <Rect
                  x={cameraLayoutRectangle.x}
                  y={cameraLayoutRectangle.y}
                  width={cameraLayoutRectangle.width}
                  height={cameraLayoutRectangle.height}
                  fill="black"
                  rx="5"
                />
              </Mask>
            </Defs>
            <Rect height="100%" width="100%" fill="rgba(0, 0, 0, 0.5)" mask="url(#mask)" fill-opacity="0" />
          </Svg>
        </View>
      )}

      <TouchableWithoutFeedback onPress={() => setIsFlashEnable((p) => !p)}>
        <View style={[styles.torchButton, isFlashEnable && styles.torchEnable]}>
          <UiIcon set={'ion'} name={'flashlight-outline'} size={24} color={colors.secondary} />
        </View>
      </TouchableWithoutFeedback>
      {/** for some reason the button disappears on iOS when not wrapping it with plain view */}
      <View style={styles.enterButton}>
        <Button
          mode={'outlined'}
          loading={qrCodeResult.isLoading}
          onPress={() =>
            navigation.navigate(screenNames.EnterCodeManuallyScreen, {
              mode: route.params?.mode || 'UNLOCK',
            })
          }
        >
          {route.params?.mode === 'VOUCHER'
            ? I18n.t('mapview.scanQr.enterVoucherCode')
            : I18n.t('mapview.scanQr.enterSpaceId')}
        </Button>
      </View>
      {isScreenFocused && cameraPermission?.status === 'granted' && (
        <Camera
          flashMode={isFlashEnable ? FlashMode.torch : FlashMode.off}
          ref={camera}
          style={{ flex: 1, width: '100%', height: '100%' }}
          barCodeScannerSettings={{
            /* Settings for iOS have a bug: https://github.com/expo/expo/issues/11726 */
            /* Specifying QR code type makes it work on Web and should reduce battery consumtpion on other platforms */
            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
          }}
          onBarCodeScanned={(e) => handleBarcode(e.data)}
        />
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  torchButton: {
    position: 'absolute',
    bottom: 120,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.30)',
    padding: 10,
    zIndex: 10,
  },
  enterButton: {
    position: 'absolute',
    bottom: 50,
    zIndex: 11,
  },
  torchEnable: {
    backgroundColor: 'rgba(255,255,255,0.12)',
  },
});

export default QRScanScreen;

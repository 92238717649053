import React, { Fragment, useEffect, useState } from 'react';
import { List, TextInput } from 'react-native-paper';
import { CompositeScreenProps } from '@react-navigation/native';
import { Keyboard, View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { useI18n } from '../../context/I18nContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import GradientButton from '../../components/GradientButton';
import CopyToClipboard from './CopyToClipboard';
import TextModal, { ModalAlertType } from '../../components/modal/TextModal';
import { AdminStackRouteParams } from '../../navigators/AdminStackNavigator';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { UiDefaultIconName } from '../../components/SimpleListIcon';
import Spacer from '../../components/Spacer';
import { ListItemRow } from '../../components/ListItemRow';

type AdminItemType = {
  leftIcon: UiDefaultIconName;
  title: string;
  screen: keyof Omit<AdminStackRouteParams, 'AdminTools' | 'AdminRegionReservations' | 'AdminReservationDetails'>;
};

type SectionType = {
  title: string;
  items: Array<AdminItemType>;
};

export function AdminConfirmModalContent(props: {
  onDismiss: () => any;
  onConfirm: () => any;
  title?: string;
  description?: string;
  icon?: UiDefaultIconName;
}) {
  return (
    <TextModal
      type={ModalAlertType.INFO}
      icon={props.icon ?? 'info'}
      title={props.title ?? 'Confirm'}
      description={props.description ?? 'Are you sure?'}
      buttons={[
        {
          title: 'Cancel',
          onPress: props.onDismiss,
        },
        {
          title: 'Absolutely',
          onPress: props.onConfirm,
        },
      ]}
    />
  );
}

export function RawAdminCommandOutput(props: { request: Promise<any> | undefined }) {
  const [response, setResponse] = useState<string>('');

  useEffect(() => {
    if (props.request) {
      props.request
        .then((res) => {
          const v = JSON.stringify(res, null, 2);
          setResponse(v);
        })
        .catch((e) => {
          setResponse(e);
          console.log('Error', e);
        });
    }
  }, [props.request]);

  return (
    <>
      <TextInput
        multiline
        label={'Output'}
        mode="outlined"
        numberOfLines={6}
        autoCapitalize={'sentences'}
        value={response}
        placeholder={'<no output>'}
        // Disables keyboard from this input. Currently for Android only, but it is included for iOS on version 0.64
        showSoftInputOnFocus={false}
      />
      <CopyToClipboard response={response} />
    </>
  );
}
export function SendAdminCommandButton(props: {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isSubmitting: boolean;
}) {
  const { I18n } = useI18n();
  return (
    <>
      <Spacer type="section" />

      <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
        <GradientButton
          onPress={() => {
            Keyboard.dismiss();
            props.handleSubmit();
          }}
          disabled={props.isSubmitting}
          loading={props.isSubmitting}
          icon={'paper-plane'}
        >
          {I18n.t('general.sendButton')}
        </GradientButton>
        <Spacer type="section" />
      </View>
    </>
  );
}

function AdminItem(props: { leftIcon: UiDefaultIconName; title: string; onPress: () => void }) {
  const { title, leftIcon, onPress } = props;

  return (
    <ListItemRow title={title} leftIcon={leftIcon} onPress={onPress} accessibilityRole="button" type="navigation" />
  );
}

type Props = CompositeScreenProps<StackScreenProps<AdminStackRouteParams, 'AdminTools'>, AppDrawerScreenProp>;
export const AdminTools: React.FC<Props> = ({ navigation }) => {
  const { I18n } = useI18n();

  const AdminToolSections: SectionType[] = [
    {
      title: I18n.t('admin.sections.communication'),
      items: [
        {
          title: I18n.t('admin.sections.pushnotification'),
          leftIcon: 'pencil',
          screen: 'AdminSendPushNotification',
        },
      ],
    },
    {
      title: 'User',
      items: [
        {
          title: 'Reset first time login',
          leftIcon: 'pencil',
          screen: 'AdminResetFirstTimeLogin',
        },
        {
          title: 'Set user role',
          leftIcon: 'pencil',
          screen: 'AdminSetUserRole',
        },
        {
          title: 'Suspend user',
          leftIcon: 'pencil',
          screen: 'AdminSuspendUser',
        },
        {
          title: 'Delete user',
          leftIcon: 'pencil',
          screen: 'AdminDeleteUser',
        },
      ],
    },
    {
      title: 'Reservations',
      items: [
        {
          title: 'By region',
          leftIcon: 'pencil',
          screen: 'AdminRegionsListing',
        },
        {
          title: 'By space',
          leftIcon: 'pencil',
          screen: 'AdminSpaceReservations',
        },
        {
          title: 'By user phone',
          leftIcon: 'pencil',
          screen: 'AdminPhonenumberReservations',
        },
      ],
    },
    {
      title: 'Vouchers',
      items: [
        {
          title: 'Create vouchers',
          leftIcon: 'pencil',
          screen: 'AdminCreateVouchers',
        },
        {
          title: 'Enable vouchers',
          leftIcon: 'pencil',
          screen: 'AdminEnableVouchers',
        },
        {
          title: 'Show voucher details',
          leftIcon: 'pencil',
          screen: 'AdminShowVouchers',
        },
      ],
    },
    {
      title: 'Invite & Promo codes',
      items: [
        {
          title: 'Create invitation code',
          leftIcon: 'pencil',
          screen: 'AdminCreatePromoCode',
        },
        {
          title: 'Enable invitation code',
          leftIcon: 'pencil',
          screen: 'AdminEnablePromoCode',
        },
        {
          title: 'Get invitation code details',
          leftIcon: 'pencil',
          screen: 'AdminShowPromoCode',
        },
      ],
    },
  ];

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('admin.title'),
        description: I18n.t('admin.description'),
        icon: 'diamond',
      }}
    >
      {AdminToolSections.map((section) => (
        <Fragment key={section.title}>
          <List.Subheader accessibilityRole={'header'}>{section.title}</List.Subheader>
          {section.items.map((item) => (
            <AdminItem
              key={item.title}
              leftIcon={item.leftIcon}
              title={item.title}
              onPress={() => {
                navigation.navigate(item.screen);
              }}
            />
          ))}
        </Fragment>
      ))}
    </ScrollablePage>
  );
};

import AsyncStorage from '@react-native-async-storage/async-storage';

export enum InternalStorageItemKey {
  WHATS_NEW_VERSION = 'whatsNewVersion',
  STORED_APP_VERSION = 'app-version',
  CURRENT_LOCALE = 'currentLocale',
  PREFERRED_UNIT = 'preferred:unit',
  UNHANDLED_NOTIFICATION = 'unhandledNotification',
  DONT_SHOW_DEPOSIT_DIALOG = 'dontShowDepositDialog',
  DONT_SHOW_HOLD_DIALOG = 'dontShowHoldAmountDialog', // No longer used, but kept here to avoid conflicts
  DONT_SHOW_TIMEZONE_DIALOG = 'dontShowTimezoneDialog',
  PINPOINT_UP_TO_DATE = 'pinpointUpToDate',
  PUSH_NOTIFICATION_TOKEN = 'pushnotificationToken',
  ACTIVE_PAYMENT_METHOD = 'activePaymentMethod',
  DONT_SHOW_NOTIFICATION_DIALOG = 'dontShowNotificationDialog',
}

export async function storeData<T>(key: InternalStorageItemKey, value: T) {
  try {
    return await AsyncStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.info('Async storage error', error);
    throw error;
  }
}

export async function getData<T>(key: InternalStorageItemKey): Promise<T | null> {
  try {
    const data = await AsyncStorage.getItem(key);
    if (data !== null) {
      return JSON.parse(data) as T;
    }
    return data;
  } catch (error) {
    console.info('Async storage error', error);
    throw error;
  }
}

export const clearData = async (key: InternalStorageItemKey) => {
  try {
    return await AsyncStorage.removeItem(key);
  } catch (error) {
    console.info('Async storage error', error);
    throw error;
  }
};

export const clearAll = async () => {
  try {
    return await AsyncStorage.clear();
  } catch (error) {
    console.info('Async storage error', error);
    throw error;
  }
};

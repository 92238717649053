import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { HelperText, useTheme } from 'react-native-paper';
import { useMutation, useQueryClient } from 'react-query';
import { useI18n } from '../../context/I18nContext';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import { applyVoucherCode, useGetRecentCreditBalanceHistory } from '../../payment/paymentApis';
import Container from '../Container';
import TextModal from './TextModal';
import * as Sentry from '../sentry/sentry';
import { UiIcon } from '../SimpleListIcon';
import { AppsyncData } from '../../apis/appsyncHelper';
import { useMyProfileQuery } from '../../apis/appsyncApis';
import Typography from '../Typography';

export default function ApplyVoucherCodeModal({
  voucher,
  onDismiss,
}: {
  voucher: AppsyncData<'getVoucherByCode'>;
  onDismiss: () => void;
}) {
  const { I18n, formatCurrency } = useI18n();
  const queryClient = useQueryClient();
  const mp = useMixpanel();
  const { colors } = useTheme();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const applyVoucherMutation = useMutation(async (input: Parameters<typeof applyVoucherCode>[number]) => {
    return applyVoucherCode(input);
  }, {});

  const applyVoucherCodeActionAsync = useCallback(async () => {
    try {
      await applyVoucherMutation.mutateAsync({
        code: voucher.getVoucherByCode?.voucherCode!,
      });
      mp?.getPeople().increment('Vouchers applied', 1);
      mp?.track('Voucher applied', { code: voucher.getVoucherByCode?.voucherCode });
      queryClient.invalidateQueries(useMyProfileQuery.id);
      queryClient.invalidateQueries(useGetRecentCreditBalanceHistory.id);
      setIsSuccess(true);
    } catch (error) {
      let errorMsg = I18n.t('mapview.scanQr.creditFailedTitle');
      const errorString = JSON.stringify(error);
      console.log(errorString);
      // Couldn't get error.message working
      if (errorString.includes('AlreadyUsed')) {
        errorMsg = I18n.t('mapview.scanQr.voucherAlreadyUsed');
      } else if (errorString.includes('CurrencyMismatch')) {
        errorMsg = I18n.t('mapview.scanQr.currencyMistmatch');
      }
      mp?.track('Voucher code apply failed', {
        code: voucher.getVoucherByCode?.voucherCode,
        error: JSON.stringify(error),
      });
      mp?.getPeople().increment('Voucher code apply failed', 1);
      Sentry.captureException(new Error('Cannot apply voucher: ' + error));
      setErrorMessage(errorMsg);
      setIsSuccess(false);
    }
  }, [I18n, applyVoucherMutation, mp, queryClient, voucher.getVoucherByCode?.voucherCode]);

  const buttons = useMemo(() => {
    if (isSuccess) {
      return [
        {
          title: I18n.t('general.ok'),
          onPress: onDismiss,
        },
      ];
    }
    return [
      {
        title: I18n.t('general.cancel'),
        onPress: onDismiss,
      },
      {
        title: I18n.t('mapview.scanQr.creditAddButton'),
        onPress: applyVoucherCodeActionAsync,
      },
    ];
  }, [I18n, onDismiss, applyVoucherCodeActionAsync, isSuccess]);

  return (
    <TextModal
      title={I18n.t(`mapview.scanQr.${isSuccess ? 'creditAddedTitle' : 'creditAddTitle'}`)}
      description={I18n.t(`mapview.scanQr.${isSuccess ? 'creditAddedDescription' : 'creditAddDescription'}`)}
      icon={isSuccess ? 'check' : 'question'}
      buttons={buttons}
    >
      <Container>
        <View style={{ flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
          <UiIcon name={'wallet'} size={25} color={colors.secondary} />
          <View style={{ marginLeft: 20 }}>
            <Typography variant={'sub1'} color={colors.secondary}>
              {formatCurrency(voucher.getVoucherByCode?.value, voucher.getVoucherByCode?.currency)}
            </Typography>
          </View>
        </View>
        <HelperText type="error" visible={!!errorMessage}>
          {errorMessage}
        </HelperText>
      </Container>
    </TextModal>
  );
}

import React from 'react';
import { SectionList, Linking } from 'react-native';
import { List } from 'react-native-paper';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { useI18n } from '../context/I18nContext';
import { screenNames } from '../navigators/screenNames';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import ScrollablePage from '../components/ui/ScrollablePage';
import { HelpStackRouteParams } from '../navigators/HelpStackNavigator';
import { AppDrawerScreenProp } from '../components/DrawerMenuContent';
import { UiDefaultIconName } from '../components/SimpleListIcon';
import { ListItemRow } from '../components/ListItemRow';

type HelpItemType = {
  leftIcon: UiDefaultIconName;
  title: string;
  onPress: () => void;
};

type HelpSectionType = {
  title: string;
  data: Array<HelpItemType>;
};
type Props = CompositeScreenProps<StackScreenProps<HelpStackRouteParams, 'Help'>, AppDrawerScreenProp>;
export const HelpView: React.FC<Props> = ({ navigation }) => {
  const mp = useMixpanel();
  const { I18n } = useI18n();

  const HELP_DATA: HelpSectionType[] = [
    {
      title: I18n.t('help.section.feedback'),
      data: [
        {
          title: I18n.t('help.section.appFeedback'),
          leftIcon: 'pencil',
          onPress: () => {
            navigation.navigate(screenNames.AppFeedback);
          },
        },
        {
          title: I18n.t('help.section.spaceFeedback'),
          leftIcon: 'note',
          onPress: () => {
            navigation.navigate(screenNames.SpaceFeedback);
          },
        },
      ],
    },
    {
      title: I18n.t('help.section.onlineHelp'),
      data: [
        {
          title: I18n.t('help.section.faq'),
          leftIcon: 'question',
          onPress: () => {
            navigation.navigate(screenNames.FAQ);
          },
        },
      ],
    },
    {
      title: I18n.t('help.section.about'),
      data: [
        {
          title: I18n.t('help.section.version'),
          leftIcon: 'info',
          onPress: () => {
            navigation.navigate(screenNames.AboutApp);
          },
        },
        {
          title: I18n.t('help.section.tap'),
          leftIcon: 'eye',
          onPress: () => {
            navigation.navigate(screenNames.ToSaP);
          },
        },
        {
          title: I18n.t('help.section.opLicense'),
          leftIcon: 'docs',
          onPress: () => {
            navigation.navigate(screenNames.OSSLicense);
          },
        },
      ],
    },
  ];

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('help.title'),
        description: I18n.t('help.subtitle'),
        icon: 'phone',
        button: {
          label: I18n.t('help.reportBtn'),
          onPress: () => {
            mp?.track('Call support phone', { url: I18n.t('help.supportPhoneUrl') });
            Linking.openURL(I18n.t('help.supportPhoneUrl'));
          },
        },
      }}
    >
      {/* TODO: Should not use a virtualized list here as the whole page is scrollable */}
      <SectionList<HelpItemType, HelpSectionType>
        sections={HELP_DATA}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item }) => (
          <ListItemRow title={item.title} leftIcon={item.leftIcon} onPress={item.onPress} type="navigation" />
        )}
        stickySectionHeadersEnabled={false}
        renderSectionHeader={({ section: { title } }) => {
          return <List.Subheader accessibilityRole={'header'}>{title}</List.Subheader>;
        }}
      />
    </ScrollablePage>
  );
};

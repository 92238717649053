import React, { useLayoutEffect, useState, useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { ActivityIndicator, Button, useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useMutation, useQueryClient } from 'react-query';
import type { CardFieldInput } from '@stripe/stripe-react-native';
import { CardField, StripeContainer, useStripe } from '../utils/stripe';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import Images from '../../../assets/images';
import { useI18n } from '../../context/I18nContext';
import { useModal } from '../../context/ModalContext';
import TextModal, { ModalAlertType } from '../../components/modal/TextModal';
import { addStripePayment, getStripePayment } from '../paymentApis';
import ScrollablePage from '../../components/ui/ScrollablePage';
import Container from '../../components/Container';
import { flattenDict } from '../../utils/helpers';
import { AppDrawerNavigationProp } from '../../components/DrawerMenuContent';
import { fontVariantStylesheet } from '../../components/Typography';

const AddPaymentMethod = () => {
  const { colors, roundness, fonts } = useTheme();
  const { I18n } = useI18n();
  const navigation = useNavigation<AppDrawerNavigationProp>();
  const [invalid, setInvalid] = useState<boolean>(true);
  const [cardInfo, setCardInfo] = useState<CardFieldInput.Details | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const stripe = useStripe();

  const mp = useMixpanel();
  const modal = useModal();
  const queryClient = useQueryClient();

  const getClientSecretForAddPayment = useMutation(async () => {
    return addStripePayment();
  });

  const handleGoToHelp = useCallback(() => {
    return navigation.navigate('help', { screen: 'Help' });
  }, [navigation]);

  const handleAddCardError = useCallback(() => {
    navigation.goBack();
    modal.openModal({
      content: (
        <TextModal
          type={ModalAlertType.ERROR}
          title={I18n.t('payment.addError')}
          description={I18n.t('payment.addErrorDescription')}
          buttons={[
            {
              title: I18n.t('general.dismiss'),
              onPress: modal.closeModal,
            },
            {
              title: I18n.t('payment.addCardHelp'),
              onPress: () => {
                handleGoToHelp();
                modal.closeModal();
              },
            },
          ]}
        />
      ),
    });
  }, [I18n, handleGoToHelp, modal, navigation]);

  const handleAddPayment = useCallback(async () => {
    if (cardInfo) {
      try {
        setLoading(true);
        /* This asks the server to create a card setup intent and five the id */
        const clientSecretResponse = await getClientSecretForAddPayment.mutateAsync(undefined);
        const secret: string = clientSecretResponse?.data?.addStripePayment ?? '';
        /* This makes the client confirm and add the details, matching the setup intent id */
        const confirmSetupResult = await stripe.confirmSetupIntent(secret, { paymentMethodType: 'Card' }, {});
        if (confirmSetupResult.setupIntent?.status === 'Succeeded') {
          mp?.track('Payment method added');
          /* Start refreshing payment methods */
          queryClient.invalidateQueries(getStripePayment.id);
        } else {
          mp?.track('Payment method verification failed', flattenDict('setupIntent', confirmSetupResult));
          handleAddCardError();
        }
        setLoading(false);
        navigation.goBack();
      } catch (e) {
        console.log('ERROR>>> ', e);
        setLoading(false);
        handleAddCardError();
      }
    }
  }, [navigation, cardInfo, mp, getClientSecretForAddPayment, queryClient, stripe, handleAddCardError]);

  useLayoutEffect(() => {
    const disabled = invalid || loading;
    navigation.setOptions({
      headerRight: () =>
        loading ? (
          <ActivityIndicator color={colors.onSurface} />
        ) : (
          <Button
            disabled={disabled}
            color={disabled ? colors.onSurfaceDisabled : colors.secondary}
            onPress={handleAddPayment}
          >
            {I18n.t('payment.done')}
          </Button>
        ),
    });
  }, [navigation, I18n, colors, invalid, loading, handleAddPayment]);

  const cardStyle = useMemo(() => {
    return {
      borderWidth: 1,
      backgroundColor: colors.background,
      borderColor: colors.onSurface,
      borderRadius: roundness,
      textColor: colors.onSurface,
      fontSize: fontVariantStylesheet.body2.fontSize,
      fontFamily: fonts.bodyMedium.fontFamily,
      placeholderColor: colors.onSurfaceDisabled,
      cursorColor: colors.secondary,
      textErrorColor: colors.error,
    };
  }, [colors, roundness, fonts]);

  return (
    <StripeContainer style={{ flexGrow: 1 }}>
      <ScrollablePage>
        <Container>
          <View style={styles.header}>
            <Images.ImageCard />
          </View>

          <CardField
            autofocus={true}
            cardStyle={cardStyle}
            postalCodeEnabled={false}
            onCardChange={(cardDetails) => {
              setInvalid(!cardDetails.complete);
              setCardInfo(cardDetails.complete ? cardDetails : null);
            }}
            style={{
              paddingHorizontal: 8,
              paddingVertical: 16,
              flexGrow: 0,
            }}
          />
        </Container>
      </ScrollablePage>
    </StripeContainer>
  );
};

const styles = StyleSheet.create({
  header: {
    width: '100%',
    height: 172,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default AddPaymentMethod;

import React, {
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useMemo,
  useRef,
  createElement,
  Component,
} from 'react';
import type { WebView as WebViewNative, WebViewProps } from 'react-native-webview';
import type {
  WebViewMessageEvent,
  WebViewSource,
  WebViewSourceHtml,
  WebViewSourceUri,
} from 'react-native-webview/lib/WebViewTypes';
import { NativeMethods, StyleSheet } from 'react-native';

type IFrameProps = Partial<HTMLIFrameElement> & React.ClassAttributes<HTMLIFrameElement>;

function splitSource(
  source: WebViewSource | undefined,
): [WebViewSourceUri, undefined] | [undefined, WebViewSourceHtml] | [undefined, undefined] {
  if (source) {
    if ((source as WebViewSourceHtml).html) {
      return [undefined, source as WebViewSourceHtml];
    }
    if ((source as WebViewSourceUri).uri) {
      return [source as WebViewSourceUri, undefined];
    }
  }
  return [undefined, undefined];
}

const TO_IFRAME_EVAL_IDENTIFIER = ' TO IFRAME FOR EVAL ';
const FROM_IFRAME_MSG_IDENTIFIER = ' FROM IFRAME ';

function generateInjectedHtml(html: string | undefined, injectedJavaScript: string | undefined) {
  if (html !== undefined) {
    const code = `
      <script>
        // console.log("iframe: Initialising message handler");
        
        window.addEventListener(
          "message",
          function (evt) {
            if(!evt.data) return;
            const evalPayload = evt.data["${TO_IFRAME_EVAL_IDENTIFIER}"];
            if(evalPayload) {
              eval(evalPayload);
            }
          },
          true
        );
        window.ReactNativeWebView = {
          postMessage: function (msg) {
            window.postMessage({"${FROM_IFRAME_MSG_IDENTIFIER}": msg}, "*");
          }
        };
      </script>
      <script>
        /* Injected javascript from react-native-webview props */
        ${injectedJavaScript ?? ''}
      </script>
    `;
    if (html.indexOf('</body>') !== -1) {
      return html.replace('</body>', `${code}</body>`);
    }
    return html + code;
  }
  return html;
}

function convertMessageEventToWebviewEvent(iframeEvent: MessageEvent<unknown>): WebViewMessageEvent | undefined {
  const payload = (iframeEvent.data as unknown as any)[FROM_IFRAME_MSG_IDENTIFIER];
  if (!payload) return;
  return {
    nativeEvent: {
      data: payload ?? iframeEvent.data,
      url: '' + iframeEvent.source, // TODO
      loading: false, // TODO
      title: 'react-native-webview', // TODO
      canGoBack: false, // TODO
      canGoForward: false, // TODO
      lockIdentifier: 0, // TODO
    },
    currentTarget: iframeEvent.currentTarget as unknown as Component<unknown, {}, any> & Readonly<NativeMethods>,
    target: iframeEvent.target as unknown as Component<unknown, {}, any> & Readonly<NativeMethods>,
    bubbles: iframeEvent.bubbles,
    cancelable: iframeEvent.cancelable,
    defaultPrevented: iframeEvent.defaultPrevented,
    isDefaultPrevented: () => iframeEvent.defaultPrevented,
    isTrusted: iframeEvent.isTrusted,
    eventPhase: iframeEvent.eventPhase,
    preventDefault: iframeEvent.preventDefault,
    isPropagationStopped: () => false, // TODO
    stopPropagation: iframeEvent.stopPropagation,
    persist: () => false, // TODO
    timeStamp: iframeEvent.timeStamp,
    type: iframeEvent.type,
  };
}

type WebViewMethods = Omit<WebViewNative, keyof React.Component>;

const WebViewImpl: ForwardRefRenderFunction<WebViewMethods, WebViewProps> = (
  { onLoad, onLoadEnd, source, style, injectedJavaScript, onMessage, scrollEnabled },
  forwardedRef,
) => {
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const [iFrameOnLoadEvent, setIFrameOnLoadEvent] = useState<any>(null);
  const [srcUri, originalSrcDoc] = splitSource(source);

  const styleData = useMemo(
    () =>
      StyleSheet.flatten([styles.iframe, scrollEnabled && styles.noScroll, style]) as unknown as CSSStyleDeclaration,
    [scrollEnabled, style],
  );
  const injectedSrcDoc = useMemo(
    () => generateInjectedHtml(originalSrcDoc?.html, injectedJavaScript),
    [injectedJavaScript, originalSrcDoc?.html],
  );

  useImperativeHandle(forwardedRef, () => ({
    goBack: () => {},
    goForward: () => {},
    reload: () => {
      if (iFrameRef.current?.contentWindow) {
        iFrameRef.current.contentWindow.location.reload();
      } else {
        console.warn('Attempting to reload() before contentWindow exists');
      }
    },
    stopLoading: () => {},
    injectJavaScript: (script: string) => {
      if (iFrameRef.current?.contentWindow) {
        iFrameRef.current?.contentWindow?.postMessage({ [TO_IFRAME_EVAL_IDENTIFIER]: script }, '*');
      } else {
        console.warn('Attempting to injectJavaScript() before contentWindow exists');
      }
    },
    requestFocus: () => {},
    postMessage: (message: string) => {
      if (iFrameRef.current?.contentWindow) {
        iFrameRef.current.contentWindow.postMessage(message, '*');
      } else {
        console.warn('Attempting to postMessage() before contentWindow exists');
      }
    },
    clearFormData: () => {},
    clearCache: () => {},
    clearHistory: () => {},
  }));

  /* Register message listeners only once iFrame onLoad has completed */
  /* We use a useEffect so we can remove the message handler as well */
  useEffect(() => {
    if (iFrameOnLoadEvent && onMessage) {
      const iFrameMessageHandler = (iframeMessage: MessageEvent<unknown>) => {
        const validMessage = convertMessageEventToWebviewEvent(iframeMessage);
        if (validMessage) {
          onMessage(validMessage);
        }
      };

      /* Store current into variable, so it can be used when unmounting useEffect */
      const elem = iFrameRef.current;
      elem?.contentWindow?.addEventListener('message', iFrameMessageHandler, false);
      return () => {
        elem?.contentWindow?.removeEventListener('message', iFrameMessageHandler, false);
      };
    }
  }, [iFrameOnLoadEvent, onMessage]);

  useEffect(() => {
    if (iFrameOnLoadEvent && onLoad) {
      onLoad(iFrameOnLoadEvent);
    }
  }, [iFrameOnLoadEvent, onLoad]);

  useEffect(() => {
    if (iFrameOnLoadEvent && onLoadEnd) {
      onLoadEnd(iFrameOnLoadEvent);
    }
  }, [iFrameOnLoadEvent, onLoadEnd]);

  const iframe = useMemo(() => {
    const iFrameProps = {
      title: 'react-native-webview',
      ref: iFrameRef,
      src: srcUri?.uri,
      srcDoc: injectedSrcDoc,
      onLoad: setIFrameOnLoadEvent,
      onError: (e: any) => console.error(e),
      width: styleData.width as string,
      height: styleData.height as string,
      style: styleData,
      allowFullScreen: true,
      scrolling: scrollEnabled ?? true ? 'yes' : 'no',
      allowpaymentrequest: 'true',
      frameBorder: '0',
      seamless: true,
    };
    return createElement<IFrameProps>('iframe', iFrameProps);
  }, [injectedSrcDoc, scrollEnabled, srcUri?.uri, styleData]);

  return iframe;
};

const styles = StyleSheet.create({
  iframe: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
  },
  noScroll: {
    overflow: 'hidden',
  },
});

const WebView = forwardRef(WebViewImpl);

export default WebView;

import Style from 'maplibre-gl';
import { IndoorAreaLayer, IndoorLayerFullTheme, IndoorPoiLayer } from './IndoorLayers';
import {
  AreaColorType,
  ColorSettings,
  FillType,
  IndoorSettings,
  TextColorType,
  TextType,
} from './IndoorThemeGenerator';

enum PoiCategories {
  DETAILS = 'details',
  TERTIARY = 'tertiary',
  SECONDARY = 'secondary',
  PRIMARY = 'primary',
  CARPARK = 'carpark',
}

enum WingAndDepartmenCategories {
  WING = 'wing',
  DEPT = 'dept',
}

enum CarparkDetails {
  MARKING = 'marking',
  KERB = 'kerb',
  WATER_AND_GRASS = 'waterAndGrass',
}

enum ColorEnum {
  FILL = 'fill',
  OUTLINE = 'outline',
  LINES = 'lines',
  FILL_3D = 'fill3d',
  HALO = 'halo',
  TEXT = 'text',
}

// pois
const getPoiTheme = (settings: IndoorSettings, colors: ColorSettings, category: PoiCategories): IndoorPoiLayer => {
  const theme: IndoorPoiLayer = {
    minzoom: Math.floor(settings[category].fill.start),
    maxzoom: Math.floor(settings[category].fill.end),
    fill: {
      paint: {
        'fill-color': {
          base: 1,
          type: 'categorical',
          property: 'css_class',
          stops: getCategoricalColors(colors, ColorEnum.FILL),
          default: colors.default.fill,
        },
        'fill-opacity': {
          stops: [
            [settings[category].fill.start, 0],
            [settings[category].fill.start + 0.5, 0.99],
          ],
        },
      },
    },
    fill3d: {
      paint: {
        'fill-extrusion-color': {
          base: 1,
          type: 'categorical',
          property: 'css_class',
          stops: getCategoricalColors(colors, ColorEnum.FILL),
          default: colors.default.fill,
        },
        'fill-extrusion-height': {
          base: 1,
          type: 'categorical',
          property: 'extrusionType',
          default: settings[category].fill.height3d,
          stops: [
            ['none', 0],
            ['low', 0.25],
            ['normal', 0.55],
            ['high', 1.2],
          ],
        },
        'fill-extrusion-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          settings[category].fill.start,
          0,
          settings[category].fill.start + 0.5,
          settings[category].fill.opacity3d,
        ],
      },
    },
    outline: {
      paint: {
        'line-color': {
          base: 1,
          type: 'categorical',
          property: 'css_class',
          stops: getCategoricalColors(colors, ColorEnum.OUTLINE),
          default: colors.default.outline,
        },
        'line-width': 2,
        'line-opacity': {
          stops: [
            [settings[category].fill.start, 0],
            [settings[category].fill.start + 0.5, 1],
          ],
        },
      },
    },
    lines: {
      paint: {
        'line-color': {
          base: 1,
          type: 'categorical',
          property: 'css_class',
          stops: getCategoricalColors(colors, ColorEnum.LINES),
          default: colors.default.lines,
        },
        'line-opacity': {
          stops: [
            [settings[category].lines.start, 0],
            [settings[category].lines.start + 0.5, 1],
          ],
        },
        'line-width': 2,
      },
    },
    symbol: {
      paint: {
        'icon-opacity': {
          stops: [
            [settings[category].icon.start, 0],
            [settings[category].icon.start + 0.5, 1],
          ],
        },
        'text-opacity': {
          stops: [
            [settings[category].text.start, 0],
            [settings[category].text.start + 0.5, 1],
          ],
        },
        'text-color': {
          base: 1,
          type: 'categorical',
          property: 'css_class',
          stops: getCategoricalColors(colors, ColorEnum.TEXT),
          default: colors.default.text,
        },
        'text-halo-width': 0.5,
        'text-halo-blur': 0,
        'text-halo-color': {
          base: 1,
          type: 'categorical',
          property: 'css_class',
          stops: getCategoricalColors(colors, ColorEnum.HALO),
          default: colors.default.halo,
        },
      },
      layout: {
        'icon-image': category === 'tertiary' ? '' : '{css_class}',
        'icon-size': {
          stops: [
            [settings[category].icon.start + 1, 0.5],
            [settings[category].icon.start + 2, 0.5],
            [settings[category].icon.start + 3, 0.75],
            [settings[category].icon.start + 4, 1],
          ],
        },
        'icon-optional': false,
        'text-optional': true,
        'icon-padding': 0,
        'text-padding': 0,
        'text-max-width': ['number', ['get', 'textMaxWidth'], 4],
        'text-line-height': 0.8,
        'text-field': '{title}',
        'text-font': ['default'],
        'icon-offset': [0, -24],
        'text-offset': [0, 0],
        'text-anchor': 'top',
        'text-pitch-alignment': category === 'tertiary' ? 'map' : 'viewport',
        'text-size': {
          stops: [
            [settings[category].text.start, settings[category].text.sizeMin], // 18
            [settings[category].text.start + 4, settings[category].text.sizeMax],
          ],
        },
      },
    },
  };
  if (category === 'details' || category === 'tertiary' || category === 'secondary' || category === 'primary') {
    theme.classes = settings[category].classes;
  }
  return theme;
};

// marking, kerb, water and grass
const getCarparkDetailsTheme = (
  settings: IndoorSettings,
  colors: ColorSettings,
  category: CarparkDetails,
): IndoorAreaLayer => {
  return {
    minzoom: Math.floor(settings[category].fill.start),
    maxzoom: Math.floor(settings[category].fill.end),
    fill: {
      paint: {
        'fill-color':
          category === 'waterAndGrass'
            ? {
                base: 1,
                type: 'categorical',
                property: 'css_class',
                default: colors.default.fill,
                stops: [
                  ['infrastructure_garden_outdoor', colors.garden.fill],
                  ['infrastructure_water_outdoor', colors.water.fill],
                ],
              }
            : ['string', ['get', 'fill_color'], colors[category].fill],
        'fill-opacity': {
          stops: [
            [settings[category].fill.start, 0],
            [settings[category].fill.start + 0.5, 0.99],
          ],
        },
      },
    },
    lines: {
      paint: {
        'line-color': ['string', ['get', 'lineColor'], colors[category].lines],
        'line-opacity': {
          stops: [
            [settings[category].lines.start, 0],
            [settings[category].lines.start + 0.5, 1],
          ],
        },
        'line-width': 2,
      },
    },
    outline: {
      paint: {
        'line-color': {
          base: 1,
          type: 'categorical',
          property: 'css_class',
          default: colors.default.outline,
          stops: [
            ['infrastructure_garden', colors.garden.outline],
            ['infrastructure_water', colors.water.outline],
          ],
        },
        'line-width': 2,
        'line-opacity': {
          stops: [
            [settings.outdoor.fill.start, 0],
            [settings.outdoor.fill.start + 0.5, 1],
          ],
        },
      },
    },
  };
};
// outdoor, cover
const getOutdoorAndCoverTheme = (
  settings: IndoorSettings['cover'],
  colors: AreaColorType & TextColorType,
  outdoor?: IndoorSettings['outdoor'],
): IndoorPoiLayer => {
  const theme: IndoorPoiLayer = {
    minzoom: Math.floor(settings.fill.start),
    maxzoom: Math.floor(settings.fill.end),
    fill: {
      paint: {
        'fill-color': ['string', ['get', 'fill_color'], colors.fill],
        'fill-opacity': {
          stops: [
            [settings.fill.start, 0],
            [settings.fill.start + 0.5, 0.99],
          ],
        },
      },
      layout: {},
    },
    outline: {
      paint: {
        'line-color': ['string', ['get', 'outlineColor'], colors.outline],
        'line-opacity': {
          stops: [
            [settings.fill.start, 0],
            [settings.fill.start + 0.5, 1],
            [settings.fill.end - 0.5, 1],
            [settings.fill.end, 0],
          ],
        },
      },
    },
    fill3d: {
      paint: {
        'fill-extrusion-base': 0,
        'fill-extrusion-color': ['string', ['get', 'fillExtrusionColor'], colors.fill3d],
        'fill-extrusion-height': outdoor
          ? {
              base: 1,
              type: 'categorical',
              property: 'extrusionType',
              default: 0.55,
              stops: [
                ['none', 0],
                ['low', 0.25],
                ['normal', 0.55],
                ['high', 1.2],
              ],
            }
          : {
              // cover
              stops: [
                [settings.fill.start, 0],
                [settings.fill.start + 0.5, settings.fill.height3d],
              ],
            },
        'fill-extrusion-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          settings.fill.start,
          0,
          settings.fill.start + 0.5,
          settings.fill.opacity3d,
        ],
      },
    },
    symbol: {
      paint: {
        ...(outdoor && {
          'icon-opacity': {
            stops: [
              [outdoor.icon.start, 0],
              [outdoor.icon.start + 0.5, 1],
            ],
          },
        }),
        'text-opacity': {
          stops: [
            [settings.text.start, 0],
            [settings.text.start + 0.5, 1],
          ],
        },
        'text-color': ['string', ['get', 'textColor'], colors.text],
        'text-halo-width': 1,
        'text-halo-color': ['string', ['get', 'textHaloColor'], colors.halo],
      },
      layout: {
        'icon-image': ['string', ['get', 'iconImage'], ''],
        ...(outdoor && {
          'icon-size': {
            stops: [
              [outdoor.icon.start + 2, 0.5],
              [outdoor.icon.start + 3, 0.75],
              [outdoor.icon.start + 4, 1],
            ],
          },
        }),
        'icon-optional': true,
        'text-field': '{title}',
        'text-max-width': ['number', ['get', 'textMaxWidth'], 4],
        'text-padding': 0,
        'text-line-height': 0.8,
        'text-optional': true,
        'text-font': ['default-bold'],
        'icon-offset': [0, -16],
        'text-offset': [0, 0.2],
        'text-anchor': outdoor ? 'bottom' : 'top',
        'text-size': {
          stops: [
            [settings.text.start, settings.text.sizeMin],
            [settings.text.start + 2, settings.text.sizeMax],
          ],
        },
      },
    },
  };
  return theme;
};

// wing, departments
const getWingAndDepartmentTheme = (
  settings: IndoorSettings,
  colors: ColorSettings,
  category: WingAndDepartmenCategories,
): IndoorPoiLayer => {
  return {
    minzoom: Math.floor(settings[category].fill.start),
    maxzoom: Math.floor(settings[category].fill.end),
    fill: {
      paint: {
        'fill-opacity': {
          stops: [
            [settings[category].fill.start, 0],
            [settings[category].fill.start + 0.5, 0.99],
            [settings[category].fill.end - 0.5, 0.99],
            [settings[category].fill.end, 0],
          ],
        },
        'fill-color': ['string', ['get', 'fill_color'], colors[category].fill],
      },
    },
    outline: {
      paint: {
        'line-color': ['string', ['get', 'outlineColor'], colors[category].outline],
        'line-opacity': {
          stops: [
            [settings[category].fill.start, 0],
            [settings[category].fill.start + 0.5, 1],
            [settings[category].fill.end - 0.5, 1],
            [settings[category].fill.end, 0],
          ],
        },
      },
    },
    fill3d: {
      paint: {
        'fill-extrusion-base': 0,
        'fill-extrusion-color': ['string', ['get', 'fillExtrusionColor'], colors[category].fill3d],
        'fill-extrusion-height': {
          stops: [
            [settings[category].fill.start, 0],
            [settings[category].fill.start + 0.5, settings[category].fill.height3d],

            [settings[category].fill.end - 0.5, settings[category].fill.height3d],
            [settings[category].fill.end, 0],
          ],
        },
        'fill-extrusion-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          settings[category].fill.start,
          0,
          settings[category].fill.start + 0.5,
          settings[category].fill.opacity3d,

          settings[category].fill.end - 0.5,
          settings[category].fill.opacity3d,

          settings[category].fill.end,
          0,
        ],
      },
    },
    symbol: {
      layout: {
        'text-field': '{title}',
        'text-max-width': 4,
        'text-padding': 0,
        'text-line-height': 0.8,
        'text-font': ['default-bold'],
        'text-allow-overlap': true,
        'text-ignore-placement': true,
        'text-offset': [0, 0],
        'text-anchor': 'center',
        'text-size': {
          stops: [
            [settings[category].text.start, settings[category].text.sizeMin],
            [settings[category].text.start + 2, settings[category].text.sizeMax],
          ],
        },
      },
      paint: {
        'text-color': ['string', ['get', 'textColor'], colors[category].text],
        'text-halo-color': ['string', ['get', 'textHaloColor'], colors[category].halo],
        'text-halo-width': 1,
        'text-opacity': {
          stops: [
            [settings[category].text.start, 0],
            [settings[category].text.start + 0.5, 1],
            [settings[category].text.end - 0.5, 1],
            [settings[category].text.end, 0],
          ],
        },
      },
    },
  };
};

const getCommonFillSetting = (settings: FillType, colors: AreaColorType): { paint: Style.FillPaint } => {
  return {
    paint: {
      'fill-color': ['string', ['get', 'fill_color'], colors.fill],
      'fill-opacity': {
        stops: [
          [settings.start, 0],
          [settings.start + 0.5, 0.5],
        ],
      },
    },
  };
};
const getCommonOutlineSetting = (settings: FillType, colors: AreaColorType): { paint: Style.LinePaint } => {
  return {
    paint: {
      'line-color': ['string', ['get', 'outlineColor'], colors.outline],
      'line-width': 2,
      'line-opacity': {
        stops: [
          [settings.start, 0],
          [settings.start + 0.5, 1],
        ],
      },
    },
  };
};

const getCommonTextSetting = (
  settings: TextType,
  colors: TextColorType,
  isTexts?: boolean,
): { paint: Style.SymbolPaint; layout: Style.SymbolLayout } => {
  return {
    paint: {
      'text-halo-width': 1,
      'text-color': ['string', ['get', 'textColor'], colors.text],
      'text-halo-color': ['string', ['get', 'textHaloColor'], colors.halo],
      'text-opacity': {
        stops: [
          [settings.start, 0],
          [settings.start + 0.5, 1],
        ],
      },
    },
    layout: {
      'text-field': '{title}',
      'text-max-width': 4,
      'text-padding': 0,
      'text-line-height': 0.8,
      'text-allow-overlap': false,
      'text-ignore-placement': false,
      'text-font': ['default-bold'],
      'text-offset': [0, 0],
      'text-anchor': 'center',
      'text-size': isTexts
        ? {
            base: 1,
            type: 'categorical',
            property: 'css_class',
            default: 12,
            stops: [
              ['text_small', 10],
              ['text_medium', 12],
              ['text_large', 14],
            ],
          }
        : {
            stops: [
              [settings.start, settings.sizeMin],
              [settings.start + 2, settings.sizeMax],
            ],
          },
    },
  };
};

const getCommonFill3dSetting = (settings: FillType, colors: AreaColorType): { paint: Style.FillExtrusionPaint } => {
  return {
    paint: {
      'fill-extrusion-base': 0,
      'fill-extrusion-color': ['string', ['get', 'fillExtrusionColor'], colors.fill3d],
      'fill-extrusion-height': {
        stops: [
          [settings.start, 0],
          [settings.start + 0.5, settings.height3d],
        ],
      },
      'fill-extrusion-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        settings.start,
        0,
        settings.start + 0.5,
        settings.opacity3d,
      ],
    },
  };
};
// TODO make expression to support case where override is supported
export const getCategoricalColors = (colors: ColorSettings, type: ColorEnum) => {
  return [
    ['food', colors.food[type]],
    ['category_cafe', colors.food[type]],
    ['category_bar', colors.food[type]],
    ['category_restaurant', colors.food[type]],
    ['category_food', colors.food[type]],
    ['category_pub', colors.food[type]],
    ['category_kitchen', colors.food[type]],

    ['retail', colors.retail[type]],
    ['category_retail', colors.retail[type]],
    ['category_retail_flower', colors.retail[type]],
    ['category_grocery', colors.retail[type]],
    ['category_wine_shop', colors.retail[type]],

    ['toilet', colors.facilities[type]],
    ['service_clinic', colors.facilities[type]],
    ['service_shower', colors.facilities[type]],
    ['service_toilet', colors.facilities[type]],
    ['service_baby_care', colors.facilities[type]],
    ['service_toilet_disabled', colors.facilities[type]],
    ['category_appointment', colors.facilities[type]],
    ['category_laboratory', colors.facilities[type]],
    ['service_toilet_women', colors.facilities[type]],
    ['service_toilet_men', colors.facilities[type]],
    ['service_orthosis', colors.facilities[type]],
    ['category_emergency_room', colors.emergency[type]],

    ['service_movie_theatre', colors.fun[type]],
    ['category_fun', colors.fun[type]],
    ['category_games', colors.fun[type]],
    ['category_theatre', colors.fun[type]],
    ['category_library', colors.fun[type]],
    ['category_art', colors.fun[type]],
    ['category_gymnasium', colors.fun[type]],
    ['category_exercise', colors.fun[type]],

    ['category_room', colors.room[type]],
    ['room', colors.room[type]],
    ['infrastructure_carpark', colors.default[type]],
    ['infrastructure_disabled_parking', '#c0c0c0'],

    ['transport_train', colors.transport[type]],
    ['transport_taxi', colors.transport[type]],
    ['category_gate_airplane', colors.transport[type]],
    ['category_accommodation', colors.accommodation[type]],
    ['service_airline_lounge', colors.accommodation[type]],
    ['infrastructure_construction', '#b3b3b3'],

    ['service_fire_extinguisher', colors.fire[type]],
    ['service_fire_hose', colors.fire[type]],
    ['service_first_aid', colors.emergency[type]],
    ['service_defibrillator', colors.emergency[type]],
    ['service_emergency_exit', colors.emergency[type]],
    ['service_revive_equipment', colors.emergency[type]],

    ['transport_skytrain', colors.transport_skytrain[type]],
    ['category_custom', colors.default[type]],
  ];
};

export function generateIndoorTheme(settings: IndoorSettings, colors: ColorSettings): IndoorLayerFullTheme {
  const styles: IndoorLayerFullTheme = {
    background: {
      layout: {
        visibility: 'none',
      },
      paint: {
        'background-color': colors.underground.fill,
        'background-opacity': settings.underground.fill.opacity,
      },
    },
    carpark: getPoiTheme(settings, colors, PoiCategories.CARPARK),
    wingFloor: getWingAndDepartmentTheme(settings, colors, WingAndDepartmenCategories.WING),
    wing: getWingAndDepartmentTheme(settings, colors, WingAndDepartmenCategories.WING),
    dept: getWingAndDepartmentTheme(settings, colors, WingAndDepartmenCategories.WING),

    deptSticky: {
      minzoom: Math.floor(settings.deptSticky.fill.start),
      maxzoom: Math.floor(settings.deptSticky.fill.end),
      fill: getCommonFillSetting(settings.deptSticky.fill, colors.deptSticky),
      outline: getCommonOutlineSetting(settings.deptSticky.fill, colors.deptSticky),
      fill3d: getCommonFill3dSetting(settings.deptSticky.fill, colors.deptSticky),
      symbol: getCommonTextSetting(settings.deptSticky.text, colors.deptSticky),
    },
    floor: {
      minzoom: Math.floor(settings.floor.fill.start),
      maxzoom: Math.floor(settings.floor.fill.end),
      fill: getCommonFillSetting(settings.floor.fill, colors.floor),
      outline: getCommonOutlineSetting(settings.floor.fill, colors.floor),
      lines: {
        paint: {},
      },
    },
    wall: {
      minzoom: Math.floor(settings.wall.fill.start),
      maxzoom: Math.floor(settings.wall.fill.end),
      fill: getCommonFillSetting(settings.wall.fill, colors.wall),
      outline: getCommonOutlineSetting(settings.wall.fill, colors.wall),
      fill3d: getCommonFill3dSetting(settings.wall.fill, colors.wall),
      lines: {
        paint: {},
      },
    },

    details: getPoiTheme(settings, colors, PoiCategories.DETAILS),
    tertiary: getPoiTheme(settings, colors, PoiCategories.TERTIARY),
    secondary: getPoiTheme(settings, colors, PoiCategories.SECONDARY),
    primary: getPoiTheme(settings, colors, PoiCategories.PRIMARY),
    texts: {
      minzoom: Math.floor(settings.texts.text.start),
      maxzoom: Math.floor(settings.texts.text.end),
      symbol: getCommonTextSetting(settings.texts.text, colors.texts, true),
    },
    cover: getOutdoorAndCoverTheme(settings.cover, colors.cover),
    outdoor: getOutdoorAndCoverTheme(settings.outdoor, colors.outdoor, settings.outdoor),

    marking: getCarparkDetailsTheme(settings, colors, CarparkDetails.MARKING),
    kerb: getCarparkDetailsTheme(settings, colors, CarparkDetails.KERB),
    waterAndGrass: getCarparkDetailsTheme(settings, colors, CarparkDetails.WATER_AND_GRASS),
    building: {
      minzoom: Math.floor(settings.building.icon.start),
      maxzoom: Math.floor(settings.building.icon.end!),
      fill: {
        paint: {
          'fill-color': colors.building.fill,
          'fill-opacity': settings.building.fill.opacity,
        },
      },
      outline: {
        paint: {},
      },
      fill3d: {
        paint: {},
      },
      symbol: {
        layout: {
          'icon-image': ['string', ['get', 'iconImage'], 'building'],
          'icon-anchor': 'bottom',
          'icon-offset': [0, 1],
          'icon-size': {
            stops: [
              [settings.building.icon.start + 9, 0.5],
              [settings.building.icon.start + 10, 0.75],
            ],
          },
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-optional': true,
          'text-optional': true,

          'text-field': '{title}',
          'text-line-height': 0.8,
          'text-size': {
            stops: [
              [settings.building.text.start, settings.building.text.sizeMin],
              [settings.building.text.start + 2, settings.building.text.sizeMax],
            ],
          },
          'text-anchor': 'bottom',
          'text-offset': [0, -1.8],
          'text-font': ['default-bold'],
          'text-max-width': 10,
          'text-letter-spacing': 0,
          'text-padding': 0,
          visibility: 'none',
        },
        paint: {
          'icon-opacity': {
            base: 1,
            stops: [
              [1, 1],
              [settings.building.icon.start, 0],
              [settings.building.icon.start + 0.5, 1],
              [settings.building.icon.end! - 0.5, 1],
              [settings.building.icon.end, 0],
            ],
          },
          'text-color': ['string', ['get', 'textColor'], colors.building.text],
          'text-halo-color': ['string', ['get', 'textHaloColor'], colors.building.halo],
          'text-opacity': {
            base: 1,
            stops: [
              [settings.building.text.start, 0],
              [settings.building.text.start + 0.5, 1],
              [settings.building.text.end - 0.5, 0],
              [settings.building.text.end, 0],
            ],
          },
          'text-halo-width': 1,
          'text-halo-blur': 0,
          'text-translate': [-2, 0],
        },
      },
    },
    buildingAddress: {
      minzoom: Math.floor(settings.building.icon.start),
      maxzoom: Math.floor(settings.building.icon.end!),
      symbol: {
        layout: {
          'text-max-width': 10,
          'text-line-height': 0.8,
          'text-field': '{address}',
          'text-font': ['default'],
          'text-anchor': 'top',
          'text-justify': 'center',
          'text-offset': [0, 0],
          'text-size': 12,
          'text-allow-overlap': true,
        },
        paint: {
          'text-color': ['string', ['get', 'textColor'], colors.building.text],
          'text-halo-color': ['string', ['get', 'textHaloColor'], colors.building.halo],
          'text-halo-width': 2,
          'text-opacity': {
            base: 1,
            stops: [
              [settings.building.text.start + 10, 0],
              [settings.building.text.start + 10.5, 1],
              [settings.building.text.start + 15, 1],
              [settings.building.text.start + 15.5, 0],
            ],
          },
        },
      },
    },
    stickyTexts: {
      minzoom: Math.floor(settings.texts.text.start),
      maxzoom: Math.floor(settings.texts.text.end!),
      symbol: {
        paint: {
          'icon-opacity': {
            stops: [
              [settings.secondary.icon.start, 0],
              [settings.secondary.icon.start + 0.5, 1],
            ],
          },
          'text-color': ['string', ['get', 'textColor'], colors.default.text],
          'text-halo-width': 1,
          'text-halo-color': ['string', ['get', 'textHaloColor'], colors.default.halo],
          'text-opacity': {
            stops: [
              [settings.secondary.text.start, 0],
              [settings.secondary.text.start + 0.5, 1],
            ],
          },
        },
        layout: {
          'icon-image': ['string', ['get', 'iconImage'], 'direction_fill'],
          'icon-text-fit': 'both',
          'icon-text-fit-padding': [10, 8, 8, 8],
          'icon-size': 1.0,
          'icon-rotate': ['number', ['get', 'textRotation'], 0],
          'icon-rotation-alignment': 'map',
          'text-field': '{title}',
          'text-max-width': ['number', ['get', 'textMaxWidth'], 4],
          'text-line-height': 0.8,
          'text-rotate': ['number', ['get', 'textRotation'], 0],
          'text-rotation-alignment': 'map',
          'text-font': ['default-bold'],
          'text-anchor': 'bottom',
          'text-size': {
            stops: [
              [settings.secondary.text.start + 2, settings.secondary.text.sizeMin],
              [settings.secondary.text.start + 3, settings.secondary.text.sizeMax],
            ],
          },
        },
      },
    },
    overlay: {
      minzoom: Math.floor(settings.overlay.icon.start),
      maxzoom: Math.floor(settings.overlay.icon.end!),
      symbol: {
        paint: {
          'icon-opacity': {
            stops: [
              [settings.overlay.icon.start, 0],
              [settings.overlay.icon.start + 0.5, 1],
            ],
          },
        },
        layout: {
          'icon-image': '{overlay}',
          'icon-offset': [12, -32],
          'icon-size': {
            stops: [
              [16, 0.5],
              [settings.overlay.icon.start + 2, 0.75],
              [settings.overlay.icon.start + 4, 1],
            ],
          },
          'icon-allow-overlap': false,
          'icon-ignore-placement': true,
        },
      },
    },
  };
  return styles;
}

import { Platform } from 'react-native';
import moment from 'moment';
import { permissions } from '../components/permissions/permissions';
import { BitwardsCredentials } from './BitwardsContext';
import {
  BitwardsAccessValidity,
  BitwardsAPIResult,
  BitwardsResource,
  BitwardsSDKManagerType,
} from './BitwardsSDK.types';
import { BitwardsSDKManager } from './BitwardsSDKManager';

export const PERMISSION_ANDROID_FINE_LOCATION = permissions.PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION;
export const PERMISSION_ANDROID_COARSE_LOCATION = permissions.PERMISSIONS.ANDROID.ACCESS_COARSE_LOCATION;
export const PERMISSION_ANDROID_BLUETOOTH_CONNECT = permissions.PERMISSIONS.ANDROID.BLUETOOTH_CONNECT;
export const PERMISSION_ANDROID_BLUETOOTH_SCAN = permissions.PERMISSIONS.ANDROID.BLUETOOTH_SCAN;
export const PERMISSION_IOS_BLUETOOTH_PERIPHERAL = permissions.PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL;
export const { GRANTED, BLOCKED, DENIED } = permissions.RESULTS;

export function randomChoice<T>(arr: T[]): T {
  return arr[Math.floor(arr.length * Math.random())];
}

export async function sleepAsync(timeoutInMillis: number): Promise<void> {
  return new Promise((res, _rej) => {
    setTimeout(() => res(), timeoutInMillis);
  });
}

export type UnlockError =
  | {
      source: 'APP';
      error:
        | 'NO_CREDENTIALS'
        | 'BITWARDS_NOT_CONNECTED_AFTER_SUCCESSFUL_AUTH'
        | 'USER_RIGHTS_NOT_VALID_FOR_RESOURCE'
        | 'NO_DEVICES_SEEN_DURING_SCAN'
        | 'SPECIFIC_DEVICE_NOT_SEEN_DURING_SCAN'
        | 'BLUETOOTH_ENABLE_TIMEOUT'
        | 'SCAN_NOT_STARTED'
        | 'IOS_BLUETOOTH_NO_PERMISSION'
        | 'BLUETOOTH_DISABLED'
        | 'ANDROID_LOCATION_NO_PERMISSION'
        | 'ANDROID_LOCATION_DISABLED'
        | 'ANDROID_NO_DEVICE_LOCK'
        | 'ANDROID_BLUETOOTH_NO_PERMISSION';
    }
  | {
      source: 'APP';
      error: 'EXCEPTION_WHILE_INTIALIZING' | 'EXCEPTION_WHILE_ACCESSING_RESOURCE';
      exception: unknown;
    }
  | {
      source: 'BITWARDS';
      bitwardsMethod: keyof BitwardsSDKManagerType;
      bitwardsError: BitwardsAPIResult;
    };

export type TroubleshootingDialog =
  | 'NO_PIN_CODE'
  | 'BLUETOOTH_PERMISSION_IOS'
  | 'BLUETOOTH_PERMISSION_ANDROID'
  | 'BLUETOOTH_HW_OFF'
  | 'LOCATION_PERMISSION'
  | 'LOCATION_HW_OFF'
  | 'LOCK_NOT_SEEN'
  | 'OTHER';

function hasAccess(validity: BitwardsAccessValidity, time: Date) {
  const startTime = moment(validity.notBefore).toDate();
  const endTime = moment(validity.notAfter).toDate();
  if (time >= startTime && time <= endTime) return true;
  return false;
}

export function resourceAccessIsValidNow(res?: BitwardsResource): boolean {
  const now = new Date();
  const validAccess = res?.validityList.find((validity) => hasAccess(validity, now));
  if (validAccess) {
    return true;
  }
  return false;
}

export function userHasAccessToResource(resourceId: string | undefined, resList: BitwardsResource[] | undefined) {
  if (Platform.OS === 'web') return true;
  const resourceData = resList?.find((res) => res.id === resourceId);
  return resourceAccessIsValidNow(resourceData);
}

export function selectTroubleshootingDialog(err: UnlockError | null): TroubleshootingDialog | null {
  if (err === null) return null;
  if (err.source === 'APP') {
    /* Convert app errors to trouble shooting dialogs */
    switch (err.error) {
      case 'BLUETOOTH_ENABLE_TIMEOUT':
        return 'BLUETOOTH_HW_OFF';
      case 'BLUETOOTH_DISABLED':
        return 'BLUETOOTH_HW_OFF';
      case 'IOS_BLUETOOTH_NO_PERMISSION':
        return 'BLUETOOTH_PERMISSION_IOS';
      case 'ANDROID_LOCATION_DISABLED':
        return 'LOCATION_HW_OFF';
      case 'ANDROID_LOCATION_NO_PERMISSION':
        return 'LOCATION_PERMISSION';
      case 'NO_DEVICES_SEEN_DURING_SCAN':
        return 'LOCK_NOT_SEEN';
      case 'SPECIFIC_DEVICE_NOT_SEEN_DURING_SCAN':
        return 'LOCK_NOT_SEEN';
      case 'ANDROID_NO_DEVICE_LOCK':
        return 'NO_PIN_CODE';
      case 'ANDROID_BLUETOOTH_NO_PERMISSION':
        return 'BLUETOOTH_PERMISSION_ANDROID';
    }
  }
  if (err.source === 'BITWARDS') {
    switch (err.bitwardsError) {
      case 'ANDROID_ERROR_BLUETOOTH_NOT_AVAILABLE':
      case 'ANDROID_ERROR_BLUETOOTH_NOT_ENABLED':
      case 'ANDROID_ERROR_BLUETOOTH_WAS_DISABLED':
        return 'BLUETOOTH_HW_OFF';
      case 'ANDROID_ERROR_NO_BLUETOOTH_SCAN_PERMISSION':
        return 'BLUETOOTH_PERMISSION_ANDROID';
      case 'ANDROID_ERROR_NO_BLUETOOTH_CONNECT_PERMISSION':
        return 'BLUETOOTH_PERMISSION_ANDROID';
      case 'ANDROID_ERROR_LOCATION_SERVICE_DISABLED':
        return 'LOCATION_HW_OFF';
      case 'ANDROID_ERROR_NO_LOCATION_PERMISSION':
        return 'LOCATION_PERMISSION';
      case 'ANDROID_ERROR_SECURE_DEVICE_LOCK_NOT_ENABLED':
        return 'NO_PIN_CODE';
      case 'RESOURCE_NOT_FOUND':
        return 'LOCK_NOT_SEEN';
    }
  }
  return 'OTHER';
}

type SyncResult =
  | { resources: BitwardsResource[]; error: undefined }
  | {
      resources: undefined;
      error: {
        source: 'BITWARDS';
        bitwardsMethod: 'getResourceList' | 'synchronize';
        bitwardsError: BitwardsAPIResult;
      };
    };

export async function synchronizeResourceList(): Promise<SyncResult> {
  /* User has no access to this resource, need to synchronize */
  const didSync = await BitwardsSDKManager.synchronize();
  if (didSync !== 'SUCCESS') {
    return {
      resources: undefined,
      error: {
        source: 'BITWARDS',
        bitwardsMethod: 'synchronize',
        bitwardsError: didSync,
      },
    };
  }
  const newList = await BitwardsSDKManager.getResourceList();
  if (newList.status !== 'SUCCESS') {
    return {
      resources: undefined,
      error: {
        source: 'BITWARDS',
        bitwardsMethod: 'getResourceList',
        bitwardsError: newList.status,
      },
    };
  }
  return {
    resources: newList.resourceList,
    error: undefined,
  };
}

export async function verifyBitwardsConnected(creds: BitwardsCredentials): Promise<UnlockError | null> {
  /* Make sure bitwards is intialized and connected */
  const initResult = await BitwardsSDKManager.init();
  if (initResult !== 'SUCCESS') {
    return { source: 'BITWARDS', bitwardsMethod: 'init', bitwardsError: initResult };
  }

  let isConnected = await BitwardsSDKManager.isConnected();
  const isTokenValid = await BitwardsSDKManager.isAuthenticationTokenValid();

  if (isConnected && !isTokenValid) {
    await BitwardsSDKManager.disconnect();
    isConnected = false;
  }

  if (isConnected) {
    return null;
  }

  /* Not "connected", need to authenticate */
  if (creds.type === 'basic') {
    const didConnect = await BitwardsSDKManager.connectWithBasicAuth(creds.username, creds.password);
    if (didConnect !== 'SUCCESS') {
      return {
        source: 'BITWARDS',
        bitwardsMethod: 'connectWithBasicAuth',
        bitwardsError: didConnect,
      };
    }
  } else if (creds.type === 'oauth2') {
    const didConnect = await BitwardsSDKManager.connectWithOAuth(
      creds.sessionToken,
      creds.refreshToken,
      creds.authDomain,
    );
    if (didConnect !== 'SUCCESS') {
      return {
        source: 'BITWARDS',
        bitwardsMethod: 'connectWithBasicAuth',
        bitwardsError: didConnect,
      };
    }
  }
  if (!(await BitwardsSDKManager.isConnected())) {
    return { source: 'APP', error: 'BITWARDS_NOT_CONNECTED_AFTER_SUCCESSFUL_AUTH' };
  }
  return null;
}

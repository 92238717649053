import React, { useLayoutEffect } from 'react';
import { List } from 'react-native-paper';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { useI18n } from '../../context/I18nContext';
import { useActivePayment } from '../MapViewActivePaymentContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { InformationStackRouteParams } from '../../navigators/InformationStackNavigator';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { PaymentMethodOption } from '../../types/misc.types';

export type WalletPaymentDetailRouteParams = {
  paymentMethodType: PaymentMethodOption.GOOGLEPAY | never;
};

type Props = CompositeScreenProps<StackScreenProps<InformationStackRouteParams, 'WalletDetail'>, AppDrawerScreenProp>;

const WalletPaymentDetail: React.FC<Props> = ({ navigation, route }) => {
  const { paymentMethodType } = route.params;
  const { I18n } = useI18n();
  const { activePaymentMethod, setActivePaymentMethod } = useActivePayment();

  function setAsDefault() {
    setActivePaymentMethod({
      paymentMethodType,
    });
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Google Pay',
    });
  }, [navigation]);

  const isCurrentWalletDefault =
    activePaymentMethod !== null && activePaymentMethod.paymentMethodType === paymentMethodType;

  const defaultText = isCurrentWalletDefault ? I18n.t('payment.isDefaultPaymentMethod') : '';

  return (
    <ScrollablePage
      summary={{
        title: 'Google Pay',
        description: defaultText,
        icon: 'social-google',
        button: {
          label: I18n.t('payment.setDefaultButton'),
          onPress: setAsDefault,
          disabled: isCurrentWalletDefault,
        },
      }}
    >
      <List.Subheader>{I18n.t('payment.recentTransaction')}</List.Subheader>
    </ScrollablePage>
  );
};

export default WalletPaymentDetail;

import React from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import { Portal, Dialog, useTheme } from 'react-native-paper';
import Typography from '../Typography';

export type Item = {
  label: string;
  onPress: () => void;
};

type MenuRightProps = {
  items: Item[];
  isVisible: boolean;
  setVisible: (value: React.SetStateAction<boolean>) => void;
};

export default function ContextMenuModal({ items, isVisible, setVisible }: MenuRightProps) {
  const { colors } = useTheme();
  return (
    <Portal>
      <Dialog
        visible={isVisible}
        onDismiss={() => setVisible(false)}
        style={{
          backgroundColor: '#444',
          minWidth: 180,
          top: 80,
          flexDirection: 'row',
          alignSelf: 'flex-end',
        }}
      >
        <View>
          <FlatList
            data={items}
            keyExtractor={(i) => i.toString()}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                key={index}
                onPress={() => item.onPress()}
                style={{ paddingHorizontal: 20, paddingVertical: 15 }}
              >
                <Typography variant="body2" color={colors.secondary}>
                  {item.label}
                </Typography>
              </TouchableOpacity>
            )}
          />
        </View>
      </Dialog>
    </Portal>
  );
}

import { EmitterSubscription } from 'react-native';

export type ResourceUpdateEvent = (resource: BitwardsResource) => void;

export interface BitwardsAccessValidity {
  notBefore: string; // String representation of Date
  notAfter: string; // String representation of Date
}
export interface BitwardsResource {
  id: string;
  rssi?: number;
  validityList: BitwardsAccessValidity[];
  batteryLevelPercent: number;
  locationName: string;
  floor?: string;
  address?: string;
  doorId?: string;
  latitude?: number;
  longitude?: number;
  resourceDescription?: string;
  isSoftToken: boolean;
  additionalInfo?: string;
  macAddress: string;
}

export enum BitwardsEvent {
  RESOURCE_FOUND = 'RESOURCE_FOUND',
  RESOURCE_UPDATED = 'RESOURCE_UPDATED',
  RESOURCE_LOST = 'RESOURCE_LOST',
  USER_UNAUTHORIZED = 'USER_UNAUTHORIZED',
  AUTHENTICATION_TOKEN_EXPIRED = 'AUTHENTICATION_TOKEN_EXPIRED',
  /**
   * Relevevant only for iOS. Bitwards SDK internal DB has corrupted
   * after app update. Logout/login will help
   */
  IOS_FORCE_LOGOUT = 'IOS_FORCE_LOGOUT',
  IOS_USER_UNAUTHENTICATED = 'IOS_USER_UNAUTHENTICATED',
}

// See BitwardsService
export enum BitwardsError {
  ERROR_AUTH_TOKEN_EXPIRED = 29,
  ERROR_ACCESS_FORBIDDEN = 57,
}

export interface BitwardsUser {
  city?: string;
  country?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  streetAddress?: string;
  zipCode?: string;
}

export type BitwardsAPIResult =
  | 'SUCCESS'
  | 'RESOURCE_NOT_FOUND'
  | 'ANDROID_ERROR_UNKNOWN'
  | 'ANDROID_ERROR_BLUETOOTH_NO_PERMISSION'
  | 'ANDROID_ERROR_BLUETOOTH_NOT_ENABLED'
  | 'ANDROID_ERROR_BLE_SCAN_FAILED'
  | 'ANDROID_ERROR_BAD_RESOURCE'
  | 'ANDROID_ERROR_GATT_ERROR'
  | 'ANDROID_ERROR_DEVICE_CONNECTION_ACTIVE'
  | 'ANDROID_ERROR_BLE_SCAN_ALREADY_ACTIVE'
  | 'ANDROID_ERROR_BLE_NOT_RESOURCE'
  | 'ANDROID_ERROR_NFC_NO_PERMISSION'
  | 'ANDROID_ERROR_BLUETOOTH_WAS_DISABLED'
  | 'ANDROID_ERROR_BAD_MAC_ADDRESS'
  | 'ANDROID_ERROR_RESOURCE_OUT_OF_RANGE'
  | 'ANDROID_ERROR_RESOURCE_DISCONNECTED'
  | 'ANDROID_ERROR_RESOURCE_TIMEOUT'
  | 'ANDROID_ERROR_RESOURCE_UNKNOWN_ERROR'
  | 'ANDROID_ERROR_NO_INTERNET'
  | 'ANDROID_ERROR_NOT_LOGGED_IN'
  | 'ANDROID_ERROR_ALREADY_LOGGED_IN'
  | 'ANDROID_ERROR_LOGIN_FAILED'
  | 'ANDROID_ERROR_LOGOUT_FAILED'
  | 'ANDROID_ERROR_CHANGE_PASSWORD_FAILED'
  | 'ANDROID_ERROR_GET_USER_PROFILE_FAILED'
  | 'ANDROID_ERROR_CHANGE_USER_PROFILE_FAILED'
  | 'ANDROID_ERROR_GET_RESOURCE_LIST_FAILED'
  | 'ANDROID_ERROR_INITIALIZATION_FAILED'
  | 'ANDROID_ERROR_NOT_INITIALIZED'
  | 'ANDROID_ERROR_ALREADY_INITIALIZED'
  | 'ANDROID_ERROR_NOT_CONNECTED'
  | 'ANDROID_ERROR_BLE_ALREADY_ACTIVE'
  | 'ANDROID_ERROR_LOGIN_FAILED_DUE_TO_SERVER_ERROR'
  | 'ANDROID_ERROR_CALLBACK_NULL'
  | 'ANDROID_ERROR_NO_LOCATION_PERMISSION'
  | 'ANDROID_ERROR_NO_NFC_PERMISSION'
  | 'ANDROID_ERROR_NO_READ_DEVICE_STATE_PERMISSION'
  | 'ANDROID_ERROR_BLUETOOTH_NOT_AVAILABLE'
  | 'ANDROID_ERROR_NFC_NOT_AVAILABLE'
  | 'ANDROID_ERROR_BLE_SCAN_NOT_ACTIVE'
  | 'ANDROID_ERROR_SECURE_DEVICE_LOCK_NOT_ENABLED'
  | 'ANDROID_ERROR_ANOTHER_OPERATION_ONGOING'
  | 'ANDROID_ERROR_TIMEOUT'
  | 'ANDROID_ERROR_RESOURCE_NULL'
  | 'ANDROID_ERROR_AUTH_TOKEN_EXPIRED'
  | 'ANDROID_ERROR_UNKNOWN_BACKGROUND_SCAN_MODE'
  | 'ANDROID_ERROR_INSTALLATION_NOT_ACTIVE'
  | 'ANDROID_ERROR_INSTALLATION_ACTIVE'
  | 'ANDROID_ERROR_INSTALLATION_FAILED'
  | 'ANDROID_ERROR_RESOURCE_ALREADY_INSTALLED'
  | 'ANDROID_ERROR_INSTALLATION_TASK_NOT_PENDING'
  | 'ANDROID_ERROR_WRONG_INSTALLATION_TASK_CANCELLED'
  | 'ANDROID_ERROR_INSTALLATION_TASK_CANCELLED'
  | 'ANDROID_ERROR_SERVER_RESPONSE_RESOURCE_ALREADY_INSTALLED'
  | 'ANDROID_ERROR_SERVER_RESPONSE_INTERNAL_ERROR'
  | 'ANDROID_ERROR_SERVER_RESPONSE_FAILED_TO_ADD_NEW_RESOURCE_IN_SERVER'
  | 'ANDROID_ERROR_SERVER_RESPONSE_SERIAL_NUMBER_ALREADY_EXISTS'
  | 'ANDROID_ERROR_SERVER_RESPONSE_CANCEL_RESOURCE_RESERVATION_FAILED'
  | 'ANDROID_ERROR_CANCEL_RESOURCE_RESERVATION_SERVER_COMMUNICATION'
  | 'ANDROID_ERROR_SERVER_RESPONSE_RESOURCE_RESERVATION_FAILED'
  | 'ANDROID_ERROR_RESOURCE_RESERVATION_SERVER_COMMUNICATION'
  | 'ANDROID_ERROR_SERVER_RESPONSE_GET_LOCATION_LIST_FAILED'
  | 'ANDROID_ERROR_GET_LOCATION_LIST_SERVER_COMMUNICATION'
  | 'ANDROID_ERROR_REFRESH_FAILED'
  | 'ANDROID_ERROR_INSTALLATION_TEST_FAILED'
  | 'ANDROID_ERROR_LOCATION_SERVICE_DISABLED'
  | 'ANDROID_ERROR_RESOURCE_UPDATE_FAILED'
  | 'ANDROID_ERROR_RESOURCE_UPDATE_ACTIVE'
  | 'ANDROID_ERROR_RESOURCE_UPDATE_TASK_CANCELLED'
  | 'ANDROID_ERROR_RESOURCE_UPDATE_NOT_ACTIVE'
  | 'ANDROID_ERROR_WRONG_RESOURCE_UPDATE_TASK_CANCELLED'
  | 'ANDROID_ERROR_BACKGROUND_SCAN_STOPPED'
  | 'ANDROID_ERROR_ACCESS_FORBIDDEN'
  | 'ANDROID_ERROR_RESOURCE_REQUIRES_APP_UPDATE'
  | 'ANDROID_ERROR_BITWARDS_REQUESTED_SELECT_RESOURCE'
  | 'ANDROID_ERROR_NO_BLUETOOTH_SCAN_PERMISSION'
  | 'ANDROID_ERROR_NO_BLUETOOTH_CONNECT_PERMISSION'
  | 'ANDROID_REASON_BAD_DATA'
  | 'ANDROID_REASON_WRONG_SIGNATURE'
  | 'ANDROID_REASON_WRONG_DELEGATION'
  | 'ANDROID_REASON_DELEGATION_USED'
  | 'ANDROID_REASON_DELEGATION_CACHE_OVERRUN'
  | 'ANDROID_REASON_TOKEN_BLACKLISTED'
  | 'ANDROID_REASON_WRONG_TIME_WINDOW'
  | 'ANDROID_REASON_CREATIONTIME_IN_FUTURE'
  | 'ANDROID_REASON_RESOURCE_ALREADY_RESERVED'
  | 'ANDROID_REASON_ILLEGAL_USER_ROLE'
  | 'ANDROID_REASON_PERIPHERAL_FAILURE'
  | 'ANDROID_REASON_PERIPHERAL_COMMUNICATION_FAILURE'
  | 'ANDROID_REASON_OPERATION_FAILED_BATTERY_CRITICAL'
  | 'ANDROID_REASON_OPERATION_DENIED_BY_REGULAR_SCHEDULE'
  | 'ANDROID_REASON_OPERATION_DENIED_BY_EXCEPTION_SCHEDULE'
  | 'ANDROID_REASON_NO_VALID_TOKEN'
  | 'IOS_FORCE_LOGOUT'
  | 'ERROR_NO_WEB_IMPLEMENTATION'
  | 'IOS_ACCESS_FAILED'
  | 'IOS_NO_RESOURCES'
  | 'IOS_BLE_TIMEOUT'
  | 'IOS_NO_CREDENTIALS'
  | 'IOS_SERVICE_TIMED_OUT'
  | 'IOS_INTERNAL_ERROR'
  | 'IOS_UNKNOWN_ERROR'
  | 'IOS_RESOURCE_ERROR'
  | 'IOS_RESOURCE_TIMEOUT'
  | 'IOS_RESOURCE_BAD_TOKEN'
  | 'IOS_RESOURCE_TOKEN_MISMATCH'
  | 'RESOURCE_TOKEN_BLACKLISTED'
  | 'RESOURCE_TOKEN_EXPIRED'
  | 'RESOURCE_UNEXPECTED_ERROR'
  | 'IOS_ACCESS_DENIED'
  | 'IOS_ACCESS_CONNECTED'
  | 'IOS_ACCESS_DISCONNECTED'
  | 'IOS_ACCESS_TIMEOUT'
  | 'IOS_ACCESS_NOT_FOUND';

export type BitwardsUserResponse =
  | {
      status: 'SUCCESS';
      bitwardsUser: BitwardsUser;
    }
  | {
      status: Exclude<BitwardsAPIResult, 'SUCCESS'>;
    };

export type BitwardsResourceListResponse =
  | {
      status: Exclude<BitwardsAPIResult, 'SUCCESS'>;
    }
  | {
      status: 'SUCCESS';
      resourceList: BitwardsResource[];
    };

export interface BitwardsSDKManagerType {
  addListener(event: BitwardsEvent, callback: ResourceUpdateEvent): EmitterSubscription | null;
  removeListener(subscription: EmitterSubscription): void;

  init(): Promise<BitwardsAPIResult>;

  /* "Connection" */
  isConnected(): Promise<boolean>;
  connectWithBasicAuth(username: string, password: string): Promise<BitwardsAPIResult>;
  connectWithOAuth(oAuthToken: string, refreshToken: string, authDomain: string): Promise<BitwardsAPIResult>;
  disconnect(): Promise<BitwardsAPIResult>;

  /* Fetches new lock opening credentials */
  synchronize(): Promise<BitwardsAPIResult>;

  /* Retrieve credentials for resource */
  getResourceList(): Promise<BitwardsResourceListResponse>;
  accessResource(resourceId: string): Promise<BitwardsAPIResult>;

  /* Bitwards user management */
  getCurrentBitwardsUser(): Promise<BitwardsUserResponse>;
  updateCurrentBitwardsUser(user: BitwardsUser): Promise<BitwardsAPIResult>;

  /* Background scanning (Android only) */
  startBackgroundScan(): Promise<BitwardsAPIResult>;
  stopBackgroundScan(): Promise<BitwardsAPIResult>;
  isAuthenticationTokenValid(): Promise<boolean>;
}

import { StyleSheet } from 'react-native';

export const CARD_DATE_FMT_OPTS = {
  showDate: true,
  dropDateIfRelative: true,
  showWeekday: true,
  dropWeekdayIfRelative: true,
};

export const styles = StyleSheet.create({
  scheduleSection: {
    marginTop: 14,
    minHeight: 76,
    flexDirection: 'row',
    alignItems: 'center',
  },
  creditCard: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  creditCardInfo: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  arrow: {
    flexShrink: 1,
  },
  separator: {
    marginTop: 8,
  },
  cardAction: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -12,
    marginTop: 10,
  },
  payButton: {
    width: '100%',
    minHeight: 47.5,
  },
});

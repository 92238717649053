import React, { FC, useCallback, useMemo, useState } from 'react';
import { Image, View, Share, ScrollView, PixelRatio, StyleSheet, ViewProps } from 'react-native';
import { TouchableRipple, useTheme } from 'react-native-paper';
import { addSeconds, format as formatDateFns, isBefore, differenceInMinutes } from 'date-fns';
import { useNavigation } from '@react-navigation/native';
import Typography from '../../components/Typography';
import { getSpaceType, getSpaceTypeInfo } from '../../../assets/other/spacetypes';
import { useMapViewFilter } from '../../context/MapViewFilterContext';
import { useExtractedReservationData, useSubscribeToSinglePodReservationChange } from '../../apis/reservationApis';
import { AuthState, useAuth } from '../../context/AuthContext';
import { BookedCardContent } from './BookedCardContent';
import { BookedNotNowCardContent } from './BookedNotNowCardContent';
import { AvailableCardContent } from './AvailableCardContent';
import { UnauthenticatedCardContent } from './UnauthenticatedCardContent';
import { useGetSpaceInfoWithPricing } from '../../payment/paymentApis';
import { useI18n } from '../../context/I18nContext';
import { screenNames } from '../../navigators/screenNames';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import { generateDeepLinkForSpace } from '../../utils/urlHandler';
import { useNowMinutes } from '../../hooks/useNow';
import { Pod } from '../../types/misc.types';
import useScreenSize from '../../hooks/useScreenSize';
import Spacer from '../../components/Spacer';
import Container from '../../components/Container';
import { UiIcon } from '../../components/SimpleListIcon';
import { AppDrawerNavigationProp } from '../../components/DrawerMenuContent';
import ContextMenuModal from '../../components/modal/ContextMenuModal';
import { PodStatus, ReservationStatus, SubscriptionOnUpdateReservationArgs } from '../../types/appsync-types';

export const isAfterOrEqual = (a: Date, b: Date) => !isBefore(a, b);

export const CardTitleRow = ({
  spaceType,
  spaceId,
  showCalendarIcon,
}: {
  spaceType: any;
  spaceId: string;
  showCalendarIcon?: boolean;
}) => {
  const { colors } = useTheme();
  const settings = getSpaceTypeInfo(spaceType);

  const titleRowStyles = useMemo(
    () =>
      StyleSheet.create({
        amenityItem: {
          paddingEnd: 12,
        },
        amenityRow: { flexDirection: 'row', alignItems: 'center' },
        imagePodDetail: {
          width: 80,
          height: 80,
        },
        titleSection: {
          flexDirection: 'row',
        },
        title: {
          marginLeft: 16,
          flex: 1,
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'flex-start',
        },
        badgeRow: {
          flex: 1,
          flexDirection: 'row',
          alignItems: 'flex-start',
        },
      }),

    [],
  );

  return (
    <View style={titleRowStyles.titleSection}>
      {/* Icon image */}
      <Image source={settings.image} style={titleRowStyles.imagePodDetail} />
      {/* Content */}
      <View style={[titleRowStyles.title]}>
        {/* Space id badge */}
        <View style={titleRowStyles.badgeRow}>
          <CardBadge text={`#${spaceId}`} />
          <Spacer type="column" />
          <CardBadge text={settings.manufacturer} />
        </View>
        {/* Title row */}
        <Typography variant={'h5'} color={colors.onSurface}>
          {settings.title}
        </Typography>
        {/* Amenities row */}
        <View style={titleRowStyles.amenityRow}>
          <AmenitySeating count={settings.seating} style={titleRowStyles.amenityItem} />
          {settings.screen ? <AmenityIcon name={'screen'} style={titleRowStyles.amenityItem} /> : null}
          {settings.powerOutlets ? <AmenityIcon name={'powerOutlet'} style={titleRowStyles.amenityItem} /> : null}
          {settings.whiteboard ? <AmenityIcon name={'whiteboard'} style={titleRowStyles.amenityItem} /> : null}
          {settings.usbAPower || settings.usbCPower ? (
            <AmenityIcon name={'usbPower'} style={titleRowStyles.amenityItem} />
          ) : null}
        </View>
      </View>
      {showCalendarIcon ? <UiIcon name="calendar" color={colors.primary} size={32} style={{ paddingTop: 4 }} /> : null}
    </View>
  );
};
const CardBadge: FC<ViewProps & { text: string | undefined }> = ({ text, style, ...other }) => {
  const { colors, roundness } = useTheme();
  const s = useMemo(() => {
    return StyleSheet.create({
      container: {
        borderWidth: 1,
        borderColor: colors.onSurfaceDisabled,
        borderRadius: roundness,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 4,
        flexGrow: 0,
      },
    });
  }, [colors.onSurfaceDisabled, roundness]);

  if (!text) {
    return null;
  }

  return (
    <View style={[s.container, style]} {...other}>
      <Typography variant={'caption'} color={colors.onSurface}>
        {text}
      </Typography>
    </View>
  );
};

export const AmenitySeating: FC<ViewProps & { count: number | string | undefined }> = ({ count, style, ...other }) => {
  const { colors } = useTheme();
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },
        style,
      ]}
      {...other}
    >
      <Typography variant={'h6'} color={colors.onSurface}>
        {count ?? '?'}
      </Typography>
      <UiIcon style={{ marginLeft: 1 }} name={'people'} color={colors.onSurface} size={16} />
    </View>
  );
};

export const AmenityIcon: FC<ViewProps & { name: 'seating' | 'screen' | 'whiteboard' | 'usbPower' | 'powerOutlet' }> =
  ({ name, style, ...other }) => {
    const { colors } = useTheme();
    if (name === 'screen') {
      return <UiIcon name={'screen-desktop'} color={colors.onSurface} size={18} style={style} {...other} />;
    }
    if (name === 'powerOutlet') {
      return (
        <View
          style={[
            {
              flexDirection: 'row',
            },
            style,
          ]}
          {...other}
        >
          <UiIcon set="material-community" name="power-plug-outline" color={colors.onSurface} size={18} />
          <UiIcon
            name="lightning-bolt-outline"
            set="material-community"
            color={colors.onSurface}
            size={18}
            style={{
              marginLeft: -9,
              marginRight: -9,
            }}
          />
        </View>
      );
    }
    if (name === 'whiteboard') {
      return <UiIcon name="easel-outline" set="ion" color={colors.onSurface} size={18} style={style} />;
    }
    if (name === 'usbPower') {
      return (
        <View
          style={[
            {
              flexDirection: 'row',
            },
            style,
          ]}
          {...other}
        >
          <UiIcon set="material-community" name="usb-port" color={colors.onSurface} size={18} />
          <UiIcon
            set="material-community"
            name={'lightning-bolt-outline'}
            color={colors.onSurface}
            size={18}
            style={{
              marginLeft: -9,
              marginRight: -9,
            }}
          />
        </View>
      );
    }
    if (name === 'seating') {
      return <UiIcon name={'people'} color={colors.onSurface} size={18} />;
    }
    return null;
  };

const PodInformationCard = ({ pod }: { pod: Pod }) => {
  const { colors } = useTheme();
  const { I18n } = useI18n();
  const mp = useMixpanel();

  const navigation = useNavigation<AppDrawerNavigationProp>();
  const now = useNowMinutes();

  const { filter } = useMapViewFilter();
  const { state } = useAuth();
  const { windowHeight } = useScreenSize();

  const currentFilter: { from: string; to: string } = useMemo(() => {
    return {
      from: filter.date.toISOString(),
      to: addSeconds(filter.date, filter.durationInSecond).toISOString(),
    };
  }, [filter.durationInSecond, filter.date]);

  const parameterForSubscription: SubscriptionOnUpdateReservationArgs = useMemo(() => {
    return {
      poisId: pod.id,
      reservedDay: formatDateFns(filter.date, 'yyyy-MM-dd'),
    };
  }, [filter.date, pod.id]);

  const { ongoingReservations, upcomingReservations } = useExtractedReservationData();

  const ongoingReservationForPod = useMemo(
    () => ongoingReservations.find((res) => res?.spaceId === pod.localRef),
    [ongoingReservations, pod.localRef],
  );
  const overlappingReservationForPod = useMemo(() => {
    return upcomingReservations.find((res) => {
      const t = filter.date;
      return res?.spaceId === pod.localRef && res.from <= t && res.to >= t;
    });
  }, [filter.date, pod.localRef, upcomingReservations]);

  useSubscribeToSinglePodReservationChange(parameterForSubscription, currentFilter);

  const podInformationQuery = useGetSpaceInfoWithPricing({
    input: {
      ...currentFilter,
      spaceId: pod.localRef!,
    },
  });

  const [isMenuRightVisible, setIsMenuRightVisible] = useState(false);

  const menuRightItems = [
    {
      label: I18n.t('share.shareMenu'),
      onPress: async () => {
        const deepLinkUrl = generateDeepLinkForSpace(pod.localRef!);
        setIsMenuRightVisible(false);
        const result = await Share.share({
          title: I18n.t('share.share'),
          message: `Spacehub #${pod.localRef!} ${deepLinkUrl}`,
        });

        if (result.action === Share.sharedAction) {
          mp?.track('Shared spacehub link', {
            deepLinkUrl,
            pod: pod.localRef,
            activityType: result.activityType,
          });
          mp?.getPeople().increment('Spacehub links shared', 1);
        }
      },
    },
  ];

  /* Only switch card status when pod information query has succeeded */
  const bookingCard = useMemo(() => {
    if (state === AuthState.GUEST_LOGIN) {
      return 'LOGIN_CARD';
    }
    if (!podInformationQuery.isLoading) {
      if (podInformationQuery.data?.data?.getPoisByID?.availabilityStatus?.status === PodStatus.RESERVED_BY_ME) {
        if (
          filter.date <= now &&
          now <= addSeconds(filter.date, filter.durationInSecond) &&
          ongoingReservationForPod?.status === ReservationStatus.RESERVED
        ) {
          return 'USER_BOOKED_FOR_CURRENT_TIME_CARD';
        }
        return 'USER_BOOKED_FOR_OTHER_TIME_CARD';
      }
    }
    return 'DEFAULT';
  }, [
    filter,
    now,
    ongoingReservationForPod?.status,
    podInformationQuery.data?.data?.getPoisByID?.availabilityStatus?.status,
    podInformationQuery.isLoading,
    state,
  ]);

  const renderCardContent = useCallback(() => {
    const spaceTypeInfo = getSpaceTypeInfo(podInformationQuery.data?.data?.getPoisByID?.type);
    const minBookingTimeMins = spaceTypeInfo.minimumBookingMinutes;

    let freeForMinutes: number | undefined;
    const avail = podInformationQuery.data?.data?.getPoisByID?.availabilityStatus;
    if (avail) {
      if (avail.status === PodStatus.FREE && avail.nextFreeEnd) {
        const freeEndExclusive = new Date(avail.nextFreeEnd);
        freeForMinutes = differenceInMinutes(freeEndExclusive, filter.date);
      } else {
        freeForMinutes = 0;
      }
    }
    switch (bookingCard) {
      case 'LOGIN_CARD':
        return (
          <UnauthenticatedCardContent
            spaceTimezone={pod.building?.timezone}
            minBookingMins={minBookingTimeMins}
            maxBookingMins={freeForMinutes}
          />
        );
      case 'USER_BOOKED_FOR_CURRENT_TIME_CARD':
        return <BookedCardContent ongoingReservation={ongoingReservationForPod!} />;
      case 'USER_BOOKED_FOR_OTHER_TIME_CARD':
        return (
          <BookedNotNowCardContent
            minBookingMins={minBookingTimeMins}
            maxBookingMins={freeForMinutes}
            reservation={overlappingReservationForPod}
          />
        );
      default:
        return (
          <AvailableCardContent
            space={pod}
            minBookingMins={minBookingTimeMins}
            maxBookingMins={freeForMinutes}
            isLoading={podInformationQuery.isLoading}
            estimatedPricing={podInformationQuery.data?.data?.estimatePriceAndHoldAmount}
          />
        );
    }
  }, [
    bookingCard,
    filter.date,
    ongoingReservationForPod,
    overlappingReservationForPod,
    pod,
    podInformationQuery.data?.data?.estimatePriceAndHoldAmount,
    podInformationQuery.data?.data?.getPoisByID?.availabilityStatus,
    podInformationQuery.data?.data?.getPoisByID?.type,
    podInformationQuery.isLoading,
  ]);

  const handleInfoButton = useCallback(() => {
    if (pod.localRef) {
      navigation.navigate('map', {
        screen: screenNames.SpaceInfo,
        params: {
          spaceId: pod.localRef,
          spaceType: getSpaceType(pod.type),
        },
      });
    }
  }, [navigation, pod.localRef, pod.type]);

  return (
    <View style={{ backgroundColor: 'rgba(25,25,25,0.5)' }}>
      <ScrollView
        pointerEvents="auto"
        bounces={false}
        style={PixelRatio.getFontScale() > 1.5 ? { maxHeight: windowHeight / 2 } : {}}
      >
        <View style={[cardStyle.container, { backgroundColor: colors.surface }]}>
          <TouchableRipple
            accessibilityLabel={`${pod.title} #${pod.localRef}`}
            accessibilityRole="button"
            onPress={handleInfoButton}
            style={{
              paddingTop: 10,
              paddingHorizontal: 16,
            }}
          >
            <CardTitleRow spaceType={pod.type} spaceId={pod.localRef!} showCalendarIcon={true} />
          </TouchableRipple>
          <Container>
            {renderCardContent()}
            <Spacer type={'section'} />
          </Container>
          <ContextMenuModal isVisible={isMenuRightVisible} setVisible={setIsMenuRightVisible} items={menuRightItems} />
        </View>
      </ScrollView>
    </View>
  );
};

const cardStyle = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: 400,
    width: '100%',
    marginVertical: 8,
    marginHorizontal: 8,
    zIndex: 1,
    borderRadius: 4,
  },
  imagePodDetail: {
    marginTop: -24,
    width: 80,
    height: 80,
  },
  titleSection: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingTop: 8,
  },
  title: {
    marginLeft: 20,
    flex: 1,
  },
  infoIcon: {
    marginRight: 0,
    marginTop: 0,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    borderRadius: 0,
  },
});

export default PodInformationCard;

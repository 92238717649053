const PERMISSIONS = {
  ANDROID: {
    ACCESS_COARSE_LOCATION: 'android.permission.ACCESS_COARSE_LOCATION',
    ACCESS_FINE_LOCATION: 'android.permission.ACCESS_FINE_LOCATION',
  },
  IOS: {
    BLUETOOTH_PERIPHERAL: 'ios.permission.BLUETOOTH_PERIPHERAL',
  },
};
const RESULTS = {
  UNAVAILABLE: 'unavailable',
  BLOCKED: 'blocked',
  DENIED: 'denied',
  GRANTED: 'granted',
  LIMITED: 'limited',
};

export const permissions = {
  PERMISSIONS,
  RESULTS,
  requestMultiple: (d: string[]) =>
    d.reduce((r, k) => {
      /* eslint-disable no-param-reassign */
      r[k] = RESULTS.GRANTED;
      return r;
    }, {} as Record<string, string>),
};

import React, { FC } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

interface Props {
  type?: 'row' | 'section' | 'column' | 'column-large' | 'filler';
}

const Spacer: FC<ViewProps & Props> = ({ type = 'row', style, ...other }) => {
  const spacerMap = {
    row: styles.rowSpacer,
    section: styles.sectionSpacer,
    column: styles.columnSpacer,
    'column-large': styles.columnLargeSpace,
    filler: styles.filler,
  };

  return <View style={[styles.container, spacerMap[type], style]} {...other} />;
};

const styles = StyleSheet.create({
  container: {},
  columnLargeSpace: {
    width: 32,
    height: '100%',
  },
  columnSpacer: {
    width: 8,
    height: '100%',
  },
  rowSpacer: {
    height: 8,
    width: '100%',

    // backgroundColor: "red",
  },
  sectionSpacer: {
    height: 16,
    width: '100%',

    // backgroundColor: "green",
  },
  filler: {
    flexDirection: 'row',
    flex: 1,
  },
});

export default Spacer;

/* eslint-disable prefer-destructuring, no-empty-function */

// Stub for web version
import { EmitterSubscription } from 'react-native';
import {
  BitwardsAPIResult,
  BitwardsEvent,
  BitwardsResourceListResponse,
  BitwardsSDKManagerType,
  BitwardsUser,
  BitwardsUserResponse,
  ResourceUpdateEvent,
} from './BitwardsSDK.types';

const DELAY_MS = 1000;
export const BitwardsSDKManager: BitwardsSDKManagerType = {
  addListener(_event: BitwardsEvent, _callback: ResourceUpdateEvent) {
    return null;
  },
  removeListener(_subscription: EmitterSubscription) {},

  async init(): Promise<BitwardsAPIResult> {
    console.log('init: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => setTimeout(() => resolve('SUCCESS'), DELAY_MS));
  },
  async connectWithBasicAuth(_username: string, _password: string): Promise<BitwardsAPIResult> {
    // console.log('connectWithBasicAuth: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => setTimeout(() => resolve('SUCCESS'), DELAY_MS));
  },
  async connectWithOAuth(_oAuthToken: string, _refreshToken: string, _authDomain: string) {
    // console.log('connectWithOAuth: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => setTimeout(() => resolve('SUCCESS'), DELAY_MS));
  },
  async isConnected(): Promise<boolean> {
    // console.log('isConnected: No web implementation available for Bitwards SDK');
    return true;
  },
  async accessResource(_resourceId: string): Promise<BitwardsAPIResult> {
    // console.log('accessResource: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => setTimeout(() => resolve('SUCCESS'), DELAY_MS));
  },
  async getResourceList(): Promise<BitwardsResourceListResponse> {
    // console.log('getResourceList: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => setTimeout(() => resolve({ status: 'SUCCESS', resourceList: [] }), DELAY_MS));
  },
  async disconnect(): Promise<BitwardsAPIResult> {
    // console.log('disconnect: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => setTimeout(() => resolve('SUCCESS'), DELAY_MS));
  },
  async synchronize(): Promise<BitwardsAPIResult> {
    // console.log('synchronize: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => setTimeout(() => resolve('SUCCESS'), DELAY_MS));
  },
  async getCurrentBitwardsUser(): Promise<BitwardsUserResponse> {
    // console.log('getCurrentBitwardsUser: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => resolve({ status: 'ERROR_NO_WEB_IMPLEMENTATION' }));
  },
  async updateCurrentBitwardsUser(user: BitwardsUser): Promise<BitwardsAPIResult> {
    // console.log('updateCurrentBitwardsUser: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => setTimeout(() => resolve('SUCCESS'), DELAY_MS));
  },
  async startBackgroundScan(): Promise<BitwardsAPIResult> {
    // console.log('startBackgroundScan: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => setTimeout(() => resolve('SUCCESS'), DELAY_MS));
  },
  async stopBackgroundScan(): Promise<BitwardsAPIResult> {
    // console.log('stopBackgroundScan: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => setTimeout(() => resolve('SUCCESS'), DELAY_MS));
  },
  async isAuthenticationTokenValid(): Promise<boolean> {
    // console.log('stopBackgroundScan: No web implementation available for Bitwards SDK');
    return new Promise((resolve) => resolve(true));
  },
};

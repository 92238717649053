import React, { useCallback } from 'react';
import { HelperText, List, useTheme } from 'react-native-paper';
import { useActivePayment } from '../MapViewActivePaymentContext';
import Loading from '../../components/Loading';
import { useI18n } from '../../context/I18nContext';
import { getExpireStatusName } from '../../utils/helpers';
import Container from '../../components/Container';
import { StripeCard } from '../../types/appsync-types';
import { AppsyncData } from '../../apis/appsyncHelper';
import { PaymentMethodOption } from '../../types/misc.types';
import { listIconGenerator } from '../../components/SimpleListIcon';
import { ListItemRow } from '../../components/ListItemRow';

interface PaymentCardListProps {
  cardList: AppsyncData<'getStripePayment'>['getStripePayment'];
  isLoading?: boolean;
  hasArrows?: boolean;
  onPress: (e: StripeCard) => void;
}

export default function PaymentCardList({ cardList, isLoading, hasArrows, onPress }: PaymentCardListProps) {
  const { I18n } = useI18n();
  const { colors } = useTheme();

  const { activePaymentMethod } = useActivePayment();

  const rightIcon = useCallback(
    (card: StripeCard | null, color?: string) => {
      if (!card) return;
      if (hasArrows) {
        // TODO @Mikko: listIconGenerator should fallback to colors.onSurface
        return listIconGenerator({ name: 'arrow-right', color: color ?? colors.onSurface });
      }
    },
    [hasArrows, colors],
  );
  const renderCardList = useCallback((): JSX.Element => {
    return (
      <>
        {cardList?.map((card) => {
          const cardStatus = getExpireStatusName(card!.exp_month!, card!.exp_year!);
          const color = cardStatus === 'ban' || cardStatus === 'exclamation' ? colors.error : undefined;
          const description = `**** ${card?.last4 ?? ''}  ${card?.exp_month}/${card?.exp_year} `;

          const isCurrentCardDefault =
            activePaymentMethod &&
            activePaymentMethod.paymentMethodType === PaymentMethodOption.CARD &&
            activePaymentMethod.stripeCard.id === card?.id;

          return (
            <ListItemRow
              key={card?.id}
              title={
                `${card?.brand?.toUpperCase() || ''}` +
                (isCurrentCardDefault ? ` ${I18n.t('payment.markedDefault')}` : '')
              }
              titleStyle={{
                fontWeight: '500',
              }}
              description={description}
              leftIcon={'credit-card'}
              right={rightIcon(card, color)}
              descriptionStyle={color ? { color } : undefined}
              onPress={() => {
                if (card) onPress(card);
              }}
              type="navigation"
            />
          );
        })}
      </>
    );
  }, [cardList, I18n, colors, onPress, rightIcon, activePaymentMethod]);

  const renderContent = () => {
    /* Loading */
    if (isLoading) {
      return <Loading />;
    }

    /* No cards */
    if ((cardList ?? []).length === 0) {
      return (
        <Container>
          <HelperText type={'info'}>{I18n.t('payment.noCardDescription')}</HelperText>
        </Container>
      );
    }

    /* Cards */
    return renderCardList();
  };

  return (
    <>
      <List.Subheader>{I18n.t('payment.cards')}</List.Subheader>
      {renderContent()}
    </>
  );
}

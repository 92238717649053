import React from 'react';
import { ViewStyle } from 'react-native';

import Images from '../../assets/images';

export function GooglePayIcon({ style }: { style?: ViewStyle }) {
  // when I set the value of widthSize and heightSize as below, the height of
  // TouchableRipple component in PaymentMethodRow component is unchanged, and it looks good.
  const widthSize = 64;
  const heightSize = 46;

  return (
    <Images.iconGooglePayMark
      width={widthSize}
      height={heightSize}
      style={{ width: widthSize, height: heightSize, ...style }}
    />
  );
}

import React, { ComponentProps, FC } from 'react';
import { RadioButton as RNPaperRadio } from 'react-native-paper';
import { StyleSheet } from 'react-native';

const MyRadio: FC<ComponentProps<typeof RNPaperRadio.Item>> = ({ style, labelStyle, ...other }) => {
  return (
    <RNPaperRadio.Item
      style={[styles.RadioItem, style]}
      labelStyle={[styles.RadioLabel, labelStyle]}
      mode="android"
      {...other}
    />
  );
};
export default MyRadio;

const styles = StyleSheet.create({
  RadioItem: {
    paddingLeft: 2,
    paddingRight: 0,
    flexDirection: 'row-reverse',
  },
  RadioLabel: {
    paddingLeft: 8,
  },
});

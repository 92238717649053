import {
  ConfirmAuBecsDebitSetupData,
  ConfirmBancontactSetupData,
  ConfirmCardSetupData,
  ConfirmIdealSetupData,
  ConfirmSepaDebitSetupData,
  ConfirmSofortSetupData,
  SetupIntentResult,
  Stripe as StripeJS,
  StripeElements,
} from '@stripe/stripe-js';
import { AuBankAccountElement, CardElement, IbanElement, IdealBankElement } from '@stripe/react-stripe-js';
import type { ConfirmSetupIntentResult, SetupIntent } from '@stripe/stripe-react-native';
import { billingDetailsToJS, convertConfirmSetupIntentResult } from './stripe-converters.web';

export function confirmSetupIntentWrapper(
  stripeJS: StripeJS,
  elements: StripeElements | null,
  paymentIntentClientSecret: string,
  data: SetupIntent.ConfirmParams,
  options: SetupIntent.ConfirmOptions | undefined,
): Promise<ConfirmSetupIntentResult> {
  let p: Promise<SetupIntentResult> | undefined;
  if (data.paymentMethodType === 'Card') {
    const cardElement = elements?.getElement(CardElement);
    if (!cardElement) {
      throw new Error('Card element not found');
    }
    const params: ConfirmCardSetupData = {
      payment_method: {
        card: cardElement,
      },
    };
    p = stripeJS.confirmCardSetup(paymentIntentClientSecret, params);
  } else if (data.paymentMethodType === 'SepaDebit') {
    const ibanElement = elements?.getElement(IbanElement);
    const bdJs = billingDetailsToJS(data.paymentMethodData.billingDetails);
    const params: ConfirmSepaDebitSetupData = {
      payment_method: {
        sepa_debit: ibanElement ?? { iban: data.paymentMethodData.iban },
        billing_details: {
          ...bdJs,
          name: bdJs!.name!,
          email: bdJs!.email!,
        },
      },
    };
    p = stripeJS.confirmSepaDebitSetup(paymentIntentClientSecret, params);
  } else if (data.paymentMethodType === 'Ideal') {
    const idealElement = elements?.getElement(IdealBankElement);
    const params: ConfirmIdealSetupData = {
      payment_method: {
        ideal: idealElement ?? { bank: data.paymentMethodData?.bankName },
        billing_details: billingDetailsToJS(data.paymentMethodData?.billingDetails),
      },
    };
    p = stripeJS.confirmIdealSetup(paymentIntentClientSecret, params);
  } else if (data.paymentMethodType === 'Sofort') {
    const params: ConfirmSofortSetupData = {
      payment_method: {
        sofort: {
          country: data.paymentMethodData.country,
        },
        billing_details: billingDetailsToJS(data.paymentMethodData.billingDetails),
      },
    };
    p = stripeJS.confirmSofortSetup(paymentIntentClientSecret, params);
  } else if (data.paymentMethodType === 'AuBecsDebit') {
    const auBankElement = elements?.getElement(AuBankAccountElement);
    const params: ConfirmAuBecsDebitSetupData = {
      payment_method: {
        au_becs_debit: auBankElement ?? {
          account_number: data.paymentMethodData.formDetails.accountNumber,
          bsb_number: data.paymentMethodData.formDetails.bsbNumber,
        },
        billing_details: {
          name: data.paymentMethodData.formDetails.name,
          email: data.paymentMethodData.formDetails.email,
        },
      },
    };
    p = stripeJS.confirmAuBecsDebitSetup(paymentIntentClientSecret, params);
  } else if (data.paymentMethodType === 'Bancontact') {
    const bdJs = billingDetailsToJS(data.paymentMethodData.billingDetails);
    const params: ConfirmBancontactSetupData = {
      payment_method: {
        billing_details: {
          ...bdJs,
          name: bdJs!.name!,
        },
      },
    };
    p = stripeJS.confirmBancontactSetup(paymentIntentClientSecret, params);
  } else {
    throw new Error('Unsupported payment setup');
  }
  return p.then(convertConfirmSetupIntentResult);
}

import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Insets,
  LayoutChangeEvent,
  LayoutRectangle,
  View,
  ViewProps,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from 'react-native';
import type maplibregl from 'maplibre-gl';
import I18n from 'i18n-js';
import { PermissionStatus } from 'expo-modules-core';
import { useAppState } from '@react-native-community/hooks';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import { useTheme } from 'react-native-paper';
import { HasBuildingChangedResult } from './content/helpers/CenterBuildingMonitor';
import FloorSwitcher, { FloorBadge, FloorInfo } from '../ui/FloorSwitcher';
import LocateMeButton from '../ui/LocateMeButton';
import { BaseMap, BaseMapChildren } from './content/BaseMap';
import { MoveToOptions } from './content/helpers/MovementHandling';
import { EnrichedMapEvent } from './content/helpers/ClickMonitor';
import { MapProvider } from './MapContext';
import { Bluedot } from './content/Bluedot';
import { BluedotBearing, BluedotLocation } from './content/helpers/AnimatedBluedot';
import { LocationPermissionStatus } from '../../context/UserLocationContext';
import Typography from '../Typography';

export type MapCamera = {
  lat: number;
  lng: number;
  zoom: number;
  pitch: number;
  bearing: number;
  buildingRef?: string;
  floorIndex?: number;
};
export type FocusLocation = {
  lat: number;
  lng: number;
  zoom?: number;
  floorIndex?: number;
  buildingRef?: string;
  animate?: boolean;
};

export type SmartMapProps = ViewProps & {
  bluedotLocation?: BluedotLocation;
  bluedotAccuracy?: number;
  bluedotHeading?: BluedotBearing;
  bluedotStatus?: PermissionStatus;
  onBluedotPermissionNeeded?: (() => Promise<LocationPermissionStatus>) | null;
  insets?: Insets;
  onClick?: (e: EnrichedMapEvent) => void;
  onFloorChanged?: (building?: string, floor?: number) => void;
  onCameraMoved?: (cam: MapCamera) => void;
  isHidden?: boolean;
  leftContent?: ReactNode;
  bottomContent?: ReactNode;
  topContent?: ReactNode;
  children?: BaseMapChildren;
  floorBadges?: Record<number, FloorBadge>;
  focusLocation?: FocusLocation;
};

function pitchForZoom(z: number, inBuilding: boolean): number {
  if (z >= 17.5 && inBuilding) {
    return 60;
  }
  if (z > 14) {
    return 0;
  }
  return 0;
}

function bearingForZoom(z: number, inBuilding: boolean, buildingDir: number): number {
  if (z > 14 && inBuilding) {
    return buildingDir;
  }
  return 0;
}

/**
 * Idea of the smart map is that it adds some normal features on top of the base map,
 * such as showing a floor switcher, decides when to switch to another building etc.
 *
 * Basically any react component that is necessary for a good map experience goes into this
 * view or its subviews.
 */

export type TrackingStatus =
  | 'initial-state'
  | 'not-tracking'
  | 'tracking-location'
  | 'tracking-location-and-heading'
  | 'location-unavailable';

const SmartMap: FC<SmartMapProps> = ({
  bluedotLocation,
  bluedotHeading,
  bluedotAccuracy,
  bluedotStatus,
  focusLocation,
  insets,
  onBluedotPermissionNeeded,
  onFloorChanged,
  onCameraMoved,
  children,
  onClick,
  style,
  topContent,
  leftContent,
  bottomContent,
  isHidden,
  floorBadges,
}) => {
  const appState = useAppState();

  const [selectedBuildingAndFloor, setSelectedBuildingAndFloor] = useState<{
    building: string;
    floor: number;
  }>();

  const [suggestedOrientation, setSuggestedOrientation] = useState<number>(0);
  const [mapTarget, setMapTarget] = useState<MoveToOptions>();
  const [selectedBuildingFloors, setSelectedBuildingFloors] = useState<FloorInfo[]>([]);
  const [desiredTrackingMode, setDesiredTrackingMode] = useState<TrackingStatus>('initial-state');
  const [mapContentPadding, setMapContentPadding] = useState<maplibregl.PaddingOptions>();
  const [currentCamera, setCurrentCamera] = useState<maplibregl.CameraOptions>();
  const [suggestedCamera, setSuggestedCamera] = useState<maplibregl.CameraOptions>();
  const [isResetVisible, setResetVisible] = useState<boolean>(false);

  const bottomAreaRef = useRef<View>(null);
  const topAreaRef = useRef<View>(null);
  const visibleMapAreaRef = useRef<View>(null);
  const [mapSize, setMapSize] = useState<LayoutRectangle>();
  const [visibleRect, setVisibleRect] = useState<LayoutRectangle>();
  const [bottomAreaHeight, setBottomAreaHeight] = useState<number>(0);
  const [topAreaHeight, setTopAreaHeight] = useState<number>(0);

  const { colors } = useTheme();

  const isTrackingLocation = ['initial-state', 'tracking-location', 'tracking-location-and-heading'].includes(
    desiredTrackingMode,
  );

  useEffect(() => {
    if (focusLocation) {
      setSuggestedCamera(undefined);
      if (focusLocation.animate) {
        setMapTarget({
          movementType: 'ease',
          allowInterrupting: true,
          options: {
            center: [focusLocation.lng, focusLocation.lat],
            zoom: focusLocation.zoom,
          },
        });
      } else {
        setMapTarget({
          movementType: 'jump',
          allowInterrupting: false,
          options: {
            center: [focusLocation.lng, focusLocation.lat],
            zoom: focusLocation.zoom,
          },
        });
      }
      if (focusLocation.buildingRef && focusLocation.floorIndex !== undefined) {
        setSelectedBuildingAndFloor({
          building: focusLocation.buildingRef,
          floor: focusLocation.floorIndex,
        });
      }
    }
  }, [focusLocation]);

  const onCenterBuildingChanged = useCallback((r: HasBuildingChangedResult) => {
    if (r.buildingInView === 'none') {
      setSelectedBuildingFloors([]);
      setSelectedBuildingAndFloor(undefined);
    } else if (r.buildingInView === 'new' && r.newBuilding) {
      setSelectedBuildingAndFloor((prev) => {
        if (r.newBuilding) {
          setSelectedBuildingFloors(r.newBuilding.floors);
          setSuggestedOrientation(r.newBuilding.feature.properties?.naturalOrientation || 0);

          /*
          Sometimes, the building in view has changed because we changed it,
          and in such cases we also set the new floor and the default floor should not be used
          */
          const buildingChanged = r.newBuilding.buildingRef !== prev?.building;
          const defaultFloor = r.newBuilding.feature.properties?.defaultLayerIndex || 0;
          return {
            building: r.newBuilding.buildingRef,
            floor: buildingChanged ? defaultFloor : prev?.floor ?? 0,
          };
        }
      });
    }
  }, []);

  // If zoom, building or suggested orientation changes, recalculate suggested camera
  useEffect(() => {
    setSuggestedCamera((prev) => {
      if (currentCamera?.zoom === undefined) {
        return prev;
      }
      const pitch = pitchForZoom(currentCamera.zoom, selectedBuildingAndFloor !== undefined);
      const bearing = bearingForZoom(currentCamera.zoom, selectedBuildingAndFloor !== undefined, suggestedOrientation);
      if (prev?.pitch !== pitch || prev.bearing !== bearing) {
        return { pitch, bearing };
      }
      return prev;
    });
  }, [currentCamera?.zoom, selectedBuildingAndFloor, suggestedOrientation]);

  useEffect(() => {
    let showReset = false;

    if (
      suggestedCamera?.pitch !== undefined &&
      Math.round(suggestedCamera.pitch) !== Math.round(currentCamera?.pitch ?? 0)
    ) {
      showReset = true;
    }
    if (
      suggestedCamera?.bearing !== undefined &&
      Math.round(suggestedCamera.bearing) !== Math.round(currentCamera?.bearing ?? 0)
    ) {
      showReset = true;
    }
    // TODO: Add all the other ones, like center and zoom?
    setResetVisible(showReset);
  }, [currentCamera?.pitch, currentCamera?.bearing, suggestedCamera?.pitch, suggestedCamera?.bearing]);

  useEffect(() => {
    if (suggestedCamera) {
      setResetVisible(false);
      setMapTarget({
        allowInterrupting: true,
        movementType: 'ease',
        options: {
          center: suggestedCamera?.center,
          bearing: suggestedCamera?.bearing,
          pitch: suggestedCamera?.pitch,
          zoom: suggestedCamera?.zoom,
        },
      });
    }
  }, [suggestedCamera]);

  useEffect(() => {
    if (onFloorChanged) onFloorChanged(selectedBuildingAndFloor?.building, selectedBuildingAndFloor?.floor);
  }, [selectedBuildingAndFloor, onFloorChanged]);

  const visualisedBluedotAccuracy = useMemo(() => {
    if (selectedBuildingAndFloor && selectedBuildingAndFloor?.building !== bluedotLocation?.building) {
      /*
      If we are looking at indoor maps, but the bluedot is not in the same building or indoors,
      then just hide the accuracy so it doesn't cover our indoor maps
      */
      return undefined;
    }
    return bluedotAccuracy;
  }, [bluedotAccuracy, bluedotLocation?.building, selectedBuildingAndFloor]);

  useEffect(() => {
    if (visibleRect && mapSize) {
      const offX = Math.round(visibleRect.x);
      const newPadding = {
        top: Math.round(topAreaHeight + (insets?.top ?? 0)),
        left: offX,
        right: Math.round(Math.max(0, mapSize.width - (offX + visibleRect.width))),
        bottom: Math.round(bottomAreaHeight + (insets?.bottom ?? 0)),
      };
      setMapContentPadding((prev) => {
        if (
          prev?.top === newPadding.top &&
          prev.bottom === newPadding.bottom &&
          prev.left === newPadding.left &&
          prev.right === newPadding.right
        ) {
          return prev;
        }
        // console.log('Updating padding', newPadding);
        return newPadding;
      });
    }
  }, [mapSize, insets?.top, insets?.bottom, topAreaHeight, bottomAreaHeight, visibleRect]);

  const onVisibleAreaChange = useCallback(
    (evt: LayoutChangeEvent) => {
      /* When map is hidden or something else funky is going on, window height and width keep going to 0 annoyingly */
      if (!isHidden && evt.nativeEvent.layout.height && evt.nativeEvent.layout.width) {
        setVisibleRect(evt.nativeEvent.layout);
        // console.log('Visible rect', evt.nativeEvent.layout);
      }
    },
    [isHidden],
  );

  const updateBottomHeight = useCallback(
    (evt: LayoutChangeEvent) => {
      if (!isHidden) {
        setBottomAreaHeight(Math.round(evt.nativeEvent.layout.height));
        if (bottomAreaRef.current) {
          bottomAreaRef.current.measureInWindow((_x, _y, _w, h) => {
            setBottomAreaHeight(Math.round(h));
          });
        }
      }
    },
    [isHidden, bottomAreaRef],
  );
  const updateTopHeight = useCallback(
    (evt: LayoutChangeEvent) => {
      if (!isHidden) {
        setTopAreaHeight(Math.round(evt.nativeEvent.layout.height));
        if (topAreaRef.current) {
          topAreaRef.current.measureInWindow((_x, _y, _w, h) => {
            setTopAreaHeight(Math.round(h));
          });
        }
      }
    },

    [isHidden, topAreaRef],
  );

  useEffect(() => {
    if (currentCamera && onCameraMoved) {
      const lat = (currentCamera.center as maplibregl.LngLat).lat ?? (currentCamera.center as [number, number])[1];
      const lon =
        (currentCamera.center as maplibregl.LngLat).lng ??
        (currentCamera.center as { lat: number; lon: number }).lon ??
        (currentCamera.center as [number, number])[0];

      onCameraMoved({
        lat,
        lng: lon,
        zoom: currentCamera.zoom!,
        pitch: currentCamera.pitch!,
        bearing: currentCamera.bearing!,
        buildingRef: selectedBuildingAndFloor?.building,
        floorIndex: selectedBuildingAndFloor?.floor,
      });
    }
  }, [currentCamera, onCameraMoved, selectedBuildingAndFloor]);

  const styles = useMemo<any>(
    () =>
      StyleSheet.create({
        controlContainer: {
          display: 'flex',
          position: 'absolute',
          height: '100%',
          width: '100%',
          flexDirection: 'row',
        },
        topBottomContainer: {
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'absolute',
          width: '100%',
          top: insets?.top ?? 0,
          bottom: insets?.bottom ?? 0,
        },
        topArea: {},
        topAreaResetButton: {
          position: 'absolute',
          top: 5 + topAreaHeight + (insets?.top ?? 0),
          minWidth: 100,
          alignItems: 'center',
          alignSelf: 'center',
          paddingVertical: 12,
        },
        bottomArea: {},
        centerArea: {
          flexDirection: 'row',
          position: 'absolute',
          top: topAreaHeight + (Platform.OS === 'ios' ? getStatusBarHeight() + (insets?.top ?? 0) : insets?.top ?? 0),
          bottom: bottomAreaHeight,
          width: '100%',
        },
        floorSwitcherContainer: {
          justifyContent: 'flex-end',
          flexGrow: 1,
          flexShrink: 1,
          flexDirection: 'column',
          alignItems: 'center',
          paddingVertical: 4,
        },
        LocateMeButton: {
          margin: 4,
        },
        leftSideBar: {
          marginLeft: (insets?.left ?? 0) + 16,
          marginBottom: bottomAreaHeight === 0 ? 40 : 8 + (insets?.bottom ?? 0),
          flexDirection: 'column',
          flexShrink: 1,
          justifyContent: 'flex-end',
        },
        visibleMapArea: {
          flexGrow: 1,
          flexShrink: 1,
        },
        rightSideBar: {
          marginTop: 8,
          marginRight: (insets?.right ?? 0) + 16,
          marginBottom: bottomAreaHeight === 0 ? 40 : 8 + (insets?.bottom ?? 0),
          flexDirection: 'column',
          flexShrink: 1,
        },
        outerContainer: { flexDirection: 'column' },
      }),
    [insets, topAreaHeight, bottomAreaHeight],
  );
  const handleFloorSelectorClick = useCallback((floor: number) => {
    setSelectedBuildingAndFloor((prev) => {
      return {
        building: prev!.building,
        floor,
      };
    });
  }, []);

  const handleMapContainerLayout = useCallback(
    (evt: LayoutChangeEvent) => {
      /* When map is hidden or something else funky is going on, window height and width keep going to zero annoyingly */
      if (!isHidden && evt.nativeEvent.layout.width && evt.nativeEvent.layout.height) {
        setMapSize(evt.nativeEvent.layout);
      }
    },
    [isHidden],
  );

  useEffect(() => {
    /**
     * To prevent setting camera to bluedot when pod location is focused through a deep link.
     * This only applies right after the app launch.
     */
    if (focusLocation && desiredTrackingMode === 'initial-state') {
      console.log('skipping focus to bluedot');
      return;
    }

    /**
     * Call only when the MapView is focused. Otherwise this causes following warning: MoveTo: Many animations (x) queued up
     * and the map becomes totally unusable for a while.
     */
    if (isTrackingLocation && bluedotLocation && !isHidden) {
      setMapTarget({
        movementType: 'fly',
        allowInterrupting: true,
        options: {
          duration: 1500,
          animate: true,
          center: [bluedotLocation.lng, bluedotLocation.lat],
          minZoom: desiredTrackingMode === 'initial-state' ? 9 : 16,
        },
      });
    }
  }, [bluedotLocation, desiredTrackingMode, isTrackingLocation, focusLocation, isHidden]);

  useEffect(() => {
    if (desiredTrackingMode === 'tracking-location-and-heading') {
      if (bluedotHeading) {
        setMapTarget({
          movementType: 'jump',
          allowInterrupting: true,
          options: {
            // animate: false,
            bearing: bluedotHeading.valueDeg,
          },
        });
      }
    }
  }, [bluedotHeading, desiredTrackingMode]);

  const handleLocatePressed = useCallback(() => {
    setDesiredTrackingMode((prev) => {
      switch (prev) {
        case 'initial-state': /* Intentional fall through */
        case 'not-tracking': {
          /* User pressed locate button, but permission hasn't been granted, let's see if we can get it granted */
          if (bluedotStatus !== PermissionStatus.GRANTED && onBluedotPermissionNeeded) {
            onBluedotPermissionNeeded();
          }
          return 'tracking-location';
        }
        case 'tracking-location': {
          // TODO: Map rotation not giving nice smooth experience, disabling for now by always going to 'not-tracking'
          // return bluedotHeading !== undefined ? 'tracking-location-and-heading' : 'not-tracking';
          return 'not-tracking';
        }
        case 'tracking-location-and-heading':
          return 'not-tracking';
      }
      /* Fallback, shouldn't really happen */
      return 'not-tracking';
    });
  }, [bluedotStatus, onBluedotPermissionNeeded]);

  const onUserInteraction = useCallback(() => {
    setDesiredTrackingMode('not-tracking');
  }, []);

  const actualTrackingStatus = useMemo<TrackingStatus>(() => {
    switch (bluedotStatus) {
      case PermissionStatus.DENIED:
        return 'location-unavailable';
      case PermissionStatus.UNDETERMINED:
        return 'location-unavailable';
      case PermissionStatus.GRANTED:
      default:
        return desiredTrackingMode;
    }
  }, [bluedotStatus, desiredTrackingMode]);

  return (
    <View style={[style, styles.outerContainer]} onLayout={handleMapContainerLayout}>
      <MapProvider selectedBuildingAndFloor={selectedBuildingAndFloor} onStoppedCamera={setCurrentCamera}>
        <BaseMap
          moveTarget={mapTarget}
          onCenterBuildingChanged={onCenterBuildingChanged}
          contentPadding={mapContentPadding}
          onClick={onClick}
          /* Only interested in user interaction if we are tracking location, otherwise we set this to undefined to improve performance */
          onUserInteraction={isTrackingLocation ? onUserInteraction : undefined}
        />
        <Bluedot
          isAnimating={appState === 'active'}
          location={bluedotLocation}
          locationAccuracy={visualisedBluedotAccuracy}
          heading={bluedotHeading}
        />
        {children}
      </MapProvider>

      <View style={styles.topBottomContainer} pointerEvents={'box-none'}>
        <View ref={topAreaRef} style={styles.topArea} onLayout={updateTopHeight}>
          {topContent}
        </View>

        <View ref={bottomAreaRef} style={styles.bottomArea} onLayout={updateBottomHeight}>
          {bottomContent}
        </View>
      </View>

      <View style={styles.centerArea} pointerEvents={'box-none'}>
        <View style={styles.leftSideBar} pointerEvents={'box-none'}>
          {leftContent ?? null}
        </View>

        <View
          ref={visibleMapAreaRef}
          style={styles.visibleMapArea}
          pointerEvents={'box-none'}
          onLayout={onVisibleAreaChange}
        />

        <View style={styles.rightSideBar} pointerEvents={'box-none'}>
          <View style={styles.floorSwitcherContainer} pointerEvents={'box-none'}>
            <FloorSwitcher
              pointerEvents={'auto'}
              floors={selectedBuildingFloors}
              badges={floorBadges}
              selectedFloor={selectedBuildingAndFloor?.floor}
              onClick={handleFloorSelectorClick}
            />
          </View>
          <LocateMeButton
            style={styles.locateMeButton}
            disabled={false}
            trackingStatus={actualTrackingStatus}
            onPress={handleLocatePressed}
          />
        </View>
      </View>
      {isResetVisible ? (
        <TouchableOpacity
          style={styles.topAreaResetButton}
          onPress={() => {
            setMapTarget({
              allowInterrupting: false,
              movementType: 'ease',
              options: {
                center: suggestedCamera?.center,
                bearing: suggestedCamera?.bearing,
                pitch: suggestedCamera?.pitch,
                zoom: suggestedCamera?.zoom,
              },
            });
          }}
        >
          <View
            style={{
              paddingHorizontal: 24,
              borderRadius: 20,
              backgroundColor: colors.secondaryContainer,
            }}
          >
            <Typography variant={'sub2'} style={{ lineHeight: 20 }}>
              {I18n.t('mapview.banner.reset').toUpperCase()}
            </Typography>
          </View>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default SmartMap;

import React, { FC, useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { StyleSheet, ViewProps, Animated, View } from 'react-native';
import { ProgressBar, useTheme } from 'react-native-paper';
import LottieView from 'lottie-react-native';
import animationData from '../../assets/animation/unlock.json';

const TOTAL_TIME_UNLOCKED_MS = 10 * 1000;
const LOCK_OPEN_ANIMATION_DURATION_MS = 1.5 * 1000;
const LOCK_CLOSE_ANIMATION_DURATION_MS = 1.5 * 1000;

const ANIMATION_LOCKED = 0;
const ANIMATION_UNLOCKED = 1;

export type LockAnimationStatus = 'LOCKED' | 'UNLOCKING' | 'UNLOCK_SUCCESS' | 'UNLOCK_FAIL';
type LockAnimationProps = ViewProps & {
  onUnlockCompleted: () => void;
  unlockStatus: LockAnimationStatus;
};

const AnimatedLottieView = Animated.createAnimatedComponent(LottieView);

const LockAnimation: FC<LockAnimationProps> = ({ unlockStatus, onUnlockCompleted, ...other }) => {
  const { colors } = useTheme();
  const [progressbarValue, setProgressbarValue] = useState<number>(1);
  const lockAnimationProgress = useRef<Animated.Value>(new Animated.Value(ANIMATION_LOCKED));
  const [timeoutHandle, setTimeoutHandle] = useState<number | undefined>();
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  // Updating once per 200ms, as the progress bar element is already animated
  const updateProgressbarValue = useCallback((start: Date) => {
    const elapsed = Date.now() - start.getTime();
    if (elapsed <= TOTAL_TIME_UNLOCKED_MS) {
      setProgressbarValue(1 - elapsed / TOTAL_TIME_UNLOCKED_MS);
      const handle = window.setTimeout(() => updateProgressbarValue(start), 200);
      setTimeoutHandle(handle);
    } else {
      setProgressbarValue(0);
    }
  }, []);

  /* Clean up progress bar timer */
  useEffect(() => {
    return () => clearTimeout(timeoutHandle);
  }, [timeoutHandle]);

  /* Clean up by stopping lock animation */
  useEffect(() => {
    lockAnimationProgress.current.stopAnimation();
    setIsAnimating(false);
  }, [lockAnimationProgress]);

  useEffect(() => {
    if (unlockStatus === 'UNLOCK_SUCCESS' && !isAnimating) {
      setIsAnimating(true);

      /* Start Lock animation by having it locked */
      lockAnimationProgress.current.setValue(ANIMATION_LOCKED);

      /* Set up a sequence of lock animations */
      const anims = Animated.sequence([
        /* Animate lock opening */
        Animated.timing(lockAnimationProgress.current, {
          toValue: ANIMATION_UNLOCKED,
          duration: LOCK_OPEN_ANIMATION_DURATION_MS,
          useNativeDriver: true,
        }),
        /* Wait */
        Animated.delay(
          TOTAL_TIME_UNLOCKED_MS - LOCK_OPEN_ANIMATION_DURATION_MS - 0.5 * LOCK_CLOSE_ANIMATION_DURATION_MS,
        ),
        /* Animate lock closing */
        Animated.timing(lockAnimationProgress.current, {
          toValue: ANIMATION_LOCKED,
          duration: LOCK_CLOSE_ANIMATION_DURATION_MS,
          useNativeDriver: true,
        }),
      ]);

      /* Start the lock animation sequence and once completed report it has completed */
      // console.log("Animation starting");
      anims.start(() => {
        onUnlockCompleted();
        setIsAnimating(false);
      });

      /* Trigger start of progress bar animation */
      updateProgressbarValue(new Date());
    } else if (unlockStatus !== 'UNLOCK_SUCCESS') {
      /* This makes it look locked */
      lockAnimationProgress.current.setValue(ANIMATION_LOCKED);
    }
  }, [unlockStatus, updateProgressbarValue, onUnlockCompleted, isAnimating]);

  const color = useMemo(() => {
    if (unlockStatus !== 'UNLOCK_SUCCESS') return colors.primary;
    if (progressbarValue < 0.1) return colors.error;
    return '#33FF33';
  }, [unlockStatus, colors, progressbarValue]);

  return (
    <View {...other}>
      <AnimatedLottieView source={animationData} style={styles.lottie} progress={lockAnimationProgress.current} />

      {/* Progress bar is shown if there is something going on */}
      {unlockStatus === 'UNLOCKING' || unlockStatus === 'UNLOCK_SUCCESS' ? (
        <View style={{ width: 260, marginBottom: 24 }}>
          <ProgressBar indeterminate={unlockStatus !== 'UNLOCK_SUCCESS'} color={color} progress={progressbarValue} />
        </View>
      ) : null}
    </View>
  );
};

export default LockAnimation;

// to avoid Lottie animation from re-render and blinking use the Stylesheet to set the animation
const styles = StyleSheet.create({
  lottie: { width: 160, height: 160 },
});

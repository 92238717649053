import Style from 'maplibre-gl';

type layerTypes =
  | Style.LineLayer
  | Style.FillLayer
  | Style.SymbolLayer
  | Style.FillExtrusionLayer
  | Style.BackgroundLayer;

export type IndoorAreaLayer = {
  minzoom: number;
  maxzoom: number;
  classes?: Array<string>;
  fill: {
    paint: Style.FillPaint;
    layout?: Style.FillLayout;
  };
  lines: {
    paint: Style.LinePaint;
    layout?: Style.LineLayout;
  };
  outline: {
    paint: Style.LinePaint;
    layout?: Style.LineLayout;
  };
  fill3d?: {
    paint: Style.FillExtrusionPaint;
    layout?: Style.FillExtrusionLayer;
  };
};

export type IndoorSymbolLayer = {
  minzoom: number;
  maxzoom: number;
  symbol: {
    paint: Style.SymbolPaint;
    layout?: Style.SymbolLayout;
  };
};

export type IndoorPoiLayer = {
  minzoom: number;
  maxzoom: number;
  classes?: Array<string>;
  fill: {
    paint: Style.FillPaint;
    layout?: Style.FillLayout;
  };
  fill3d: {
    paint: Style.FillExtrusionPaint;
    layout?: Style.FillExtrusionLayer;
  };
  outline: {
    paint: Style.LinePaint;
    layout?: Style.LineLayout;
  };
  lines?: {
    paint: Style.LinePaint;
    layout?: Style.LineLayout;
  };
  symbol: {
    paint: Style.SymbolPaint;
    layout?: Style.SymbolLayout;
  };
};

export type IndoorLayerFullTheme = {
  background: {
    layout: Style.BackgroundLayout;
    paint: Style.BackgroundPaint;
  };

  marking: IndoorAreaLayer;
  kerb: IndoorAreaLayer;
  waterAndGrass: IndoorAreaLayer;
  floor: IndoorAreaLayer;
  wall: IndoorAreaLayer;

  building: IndoorPoiLayer;

  details: IndoorPoiLayer;
  tertiary: IndoorPoiLayer;
  secondary: IndoorPoiLayer;
  primary: IndoorPoiLayer;

  outdoor: IndoorPoiLayer;
  carpark: IndoorPoiLayer;
  cover: IndoorPoiLayer;

  wingFloor: IndoorPoiLayer;
  wing: IndoorPoiLayer;
  deptSticky: IndoorPoiLayer;
  dept: IndoorPoiLayer;

  texts: IndoorSymbolLayer;
  stickyTexts: IndoorSymbolLayer;
  overlay: IndoorSymbolLayer;
  buildingAddress: IndoorSymbolLayer;
};

export function getIndoorLayers(theme: IndoorLayerFullTheme): layerTypes[] {
  return [
    {
      id: 'underground_background',
      type: 'background',
      layout: theme.background.layout,
      paint: theme.background.paint,
    },
    {
      id: 'Outdoor Fill',
      minzoom: theme.outdoor.minzoom,
      type: 'fill',
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'css_class', 'category_outdoor'],
        ['has', 'pattern'],
        ['==', 'extrusionType', 'none'],
      ],
      paint: theme.outdoor.fill.paint,
    },
    {
      id: 'Outdoor Extrude',
      minzoom: theme.outdoor.minzoom,
      type: 'fill-extrusion',
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'css_class', 'category_outdoor'], ['has', 'extrusionType']],
      paint: theme.outdoor.fill3d.paint,
    },
    {
      id: 'Outdoor Icon and Text',
      minzoom: theme.outdoor.minzoom,
      type: 'symbol',
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'css_class', 'category_outdoor']],
      layout: theme.outdoor.symbol.layout,
      paint: theme.outdoor.symbol.paint,
    },
    {
      id: 'Water and grass area outdoor',
      minzoom: theme.waterAndGrass.minzoom,
      type: 'fill',
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['in', 'css_class', 'infrastructure_water_outdoor', 'infrastructure_garden_outdoor'],
      ],
      paint: theme.waterAndGrass.fill.paint,
      layout: theme.waterAndGrass.fill.layout,
    },
    {
      id: 'Water and grass outline outdoor',
      type: 'line',
      minzoom: theme.waterAndGrass.minzoom,
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['in', 'css_class', 'infrastructure_water_outdoor', 'infrastructure_garden_outdoor'],
      ],
      paint: theme.waterAndGrass.outline.paint,
      layout: theme.waterAndGrass.outline.layout,
    },
    {
      id: 'Carpark fill outdoor',
      minzoom: theme.outdoor.minzoom,
      type: 'fill',
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],

        [
          'in',
          'css_class',
          'infrastructure_carpark_outdoor',
          'infrastructure_disabled_parking_outdoor',
          'infrastructure_carpark_house',
          'infrastructure_parking_bike',
          'infrastructure_parking_motorcycle',
          'category_outdoor',
        ],
      ],
      paint: theme.carpark.fill.paint,
      layout: theme.carpark.fill.layout,
    },
    {
      id: 'Carpark outline outdoor',
      type: 'line',
      minzoom: theme.outdoor.minzoom,
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        [
          'in',
          'css_class',
          'infrastructure_carpark_outdoor',
          'infrastructure_disabled_parking_outdoor',
          'infrastructure_carpark_house',
          'infrastructure_parking_bike',
          'infrastructure_parking_motorcycle',
          'category_outdoor',
        ],
      ],
      paint: theme.carpark.outline.paint,
      layout: theme.carpark.outline.layout,
    },
    {
      id: 'Road marking fill outdoor',
      minzoom: theme.outdoor.minzoom,
      type: 'fill',
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: ['all', ['in', '$type', 'Polygon'], ['==', 'css_class', 'road_marking_fill_outdoor']],
      paint: theme.marking.fill.paint,
      layout: theme.marking.fill.layout,
    },
    {
      id: 'Road kerb outdoor',
      type: 'line',
      minzoom: theme.outdoor.minzoom,
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: ['all', ['in', '$type', 'LineString', 'Polygon'], ['==', 'css_class', 'road_kerb_outdoor']],
      paint: theme.kerb.lines.paint,
      layout: theme.kerb.lines.layout,
    },
    {
      id: 'Road marking line outdoor',
      type: 'line',
      minzoom: theme.outdoor.minzoom,
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: ['all', ['==', 'css_class', 'road_marking_line_outdoor']],
      paint: theme.marking.lines.paint,
      layout: theme.marking.lines.layout,
    },

    {
      id: 'building_outline',
      type: 'fill',
      minzoom: theme.building.minzoom,
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'subType', 'building_outline']],
      paint: theme.building.fill.paint,
      layout: theme.building.fill.layout,
    },
    {
      id: 'Floor Fill (base)',
      minzoom: theme.floor.minzoom,
      type: 'fill',
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'infrastructure_floor', 'floor'],
      ],
      paint: theme.floor.fill.paint,
      layout: theme.floor.fill.layout,
    },
    {
      id: 'Floor Fill (floor tiles)',
      minzoom: theme.floor.minzoom,
      type: 'fill',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'infrastructure_floor', 'floor'],
      ],
      paint: theme.floor.fill.paint,
      layout: theme.floor.fill.layout,
    },
    {
      id: 'Floor Outline',
      minzoom: theme.floor.minzoom,
      type: 'line',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'infrastructure_floor', 'floor'],
      ],
      paint: theme.floor.outline.paint,
      layout: theme.floor.outline.layout,
    },
    {
      id: 'Carpark fill indoor',
      minzoom: theme.carpark.minzoom,
      type: 'fill',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'infrastructure_carpark', 'infrastructure_disabled_parking'],
      ],
      paint: theme.carpark.fill.paint,
      layout: theme.carpark.fill.layout,
    },
    {
      id: 'Hole fill',
      type: 'fill',
      minzoom: theme.tertiary.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', 'test-building'],
        ['==', 'css_class', 'infrastructure_hole'],
      ],
      paint: theme.tertiary.fill.paint,
    },
    {
      id: 'water and grass area indoor',
      minzoom: theme.waterAndGrass.minzoom,
      type: 'fill',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['in', 'css_class', 'infrastructure_water', 'infrastructure_garden'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
      ],
      paint: theme.waterAndGrass.fill.paint,
      layout: theme.waterAndGrass.fill.layout,
    },
    {
      id: 'Water and grass outline indoor',
      type: 'line',
      minzoom: theme.waterAndGrass.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['in', 'css_class', 'infrastructure_water', 'infrastructure_garden'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
      ],
      paint: theme.waterAndGrass.outline.paint,
      layout: theme.waterAndGrass.outline.layout,
    },
    {
      id: 'Detail Fill',
      type: 'fill',
      minzoom: theme.details.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class'].concat(theme.details.classes!),
      ],
      paint: theme.details.fill.paint,
    },
    {
      id: 'Tertiary Fill',
      minzoom: theme.tertiary.minzoom,
      type: 'fill',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['any', ['!has', 'fill_color'], ['!has', 'fillColor_dark']],
        ['in', 'css_class'].concat(theme.tertiary.classes!),
      ],
      paint: theme.tertiary.fill.paint,
    },
    {
      id: 'Secondary Fill',
      minzoom: theme.secondary.minzoom,
      type: 'fill',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['any', ['!has', 'fill_color'], ['!has', 'fillColor_dark']],
        ['in', 'css_class'].concat(theme.secondary.classes!),
      ],
      paint: theme.secondary.fill.paint,
    },
    {
      id: 'Primary fill',
      minzoom: theme.primary.minzoom,
      type: 'fill',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['any', ['!has', 'fill_color'], ['!has', 'fillColor_dark']],
        ['in', 'css_class'].concat(theme.primary.classes!),
      ],
      paint: theme.primary.fill.paint,
    },
    {
      id: 'Department Fill Sticky',
      type: 'fill',
      minzoom: theme.deptSticky.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'department_sticky', 'infrastructure_department_sticky'],
        ['==', 'extrusionType', 'none'],
      ],
      paint: theme.deptSticky.fill.paint,
      layout: theme.deptSticky.fill.layout,
    },
    {
      id: 'Deparment Outline Sticky',
      type: 'line',
      minzoom: theme.deptSticky.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'department_sticky', 'infrastructure_department_sticky'],
        ['==', 'extrusionType', 'none'],
      ],
      paint: theme.deptSticky.outline.paint,
      layout: theme.deptSticky.outline.layout,
    },
    {
      id: 'Carpark outline indoor',
      type: 'line',
      minzoom: theme.carpark.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'infrastructure_carpark', 'infrastructure_disabled_parking'],
      ],
      paint: theme.carpark.outline.paint,
    },
    {
      id: 'Hole outline',
      type: 'line',
      minzoom: theme.tertiary.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['==', 'css_class', 'infrastructure_hole'],
      ],
      paint: theme.tertiary.outline.paint,
    },
    {
      id: 'Tertiary Outline',
      minzoom: theme.tertiary.minzoom,
      type: 'line',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['any', ['!has', 'outlineColor'], ['!has', 'outlineColor_dark']],
        ['in', 'css_class'].concat(theme.tertiary.classes!),
      ],
      paint: theme.tertiary.outline.paint,
    },
    {
      id: 'Secondary Outline',
      type: 'line',
      minzoom: theme.secondary.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',

      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['any', ['!has', 'outlineColor'], ['!has', 'outlineColor_dark']],
        ['in', 'css_class'].concat(theme.secondary.classes!),
      ],
      paint: theme.secondary.outline.paint,
    },
    {
      id: 'Primary Outline',
      type: 'line',
      minzoom: theme.primary.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['any', ['!has', 'outlineColor'], ['!has', 'outlineColor_dark']],
        ['in', 'css_class'].concat(theme.primary.classes!),
      ],
      paint: theme.primary.outline.paint,
    },
    {
      id: 'Detail Outline',
      type: 'line',
      minzoom: theme.details.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',

      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class'].concat(theme.details.classes!),
      ],
      paint: theme.details.outline.paint,
    },
    {
      id: 'Detail Lines',
      type: 'line',
      minzoom: theme.details.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['in', '$type', 'LineString'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class'].concat(theme.details.classes!),
      ],
      paint: theme?.details?.lines?.paint,
    },
    {
      id: 'Road marking fill indoor',
      type: 'fill',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      minzoom: theme.marking.minzoom,
      filter: [
        'all',
        ['in', '$type', 'Polygon'],
        ['==', 'layerIndex', -1],
        ['==', 'buildingRef', 'building-test'],
        ['==', 'css_class', 'road_marking_fill'],
      ],
      paint: theme.marking.fill.paint,
    },
    {
      id: 'Road kerb indoor',
      type: 'line',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      minzoom: theme.kerb.minzoom,
      filter: [
        'all',
        ['in', '$type', 'LineString', 'Polygon'],
        ['==', 'layerIndex', -1],
        ['==', 'buildingRef', 'building-test'],
        ['==', 'css_class', 'road_kerb'],
      ],
      paint: theme.kerb.lines.paint,
    },
    {
      id: 'Road marking line indoor',
      type: 'line',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      minzoom: theme.marking.minzoom,
      filter: [
        'all',
        ['==', 'layerIndex', -1],
        ['==', 'buildingRef', 'building-test'],
        ['==', 'css_class', 'road_marking_line'],
      ],
      paint: theme.marking.lines.paint,
    },
    {
      id: 'Wall Fill',
      type: 'fill',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      minzoom: theme.wall.minzoom,
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['any', ['in', 'css_class', 'wall', 'infrastructure_wall'], ['==', 'subType', 'wall']],
      ],
      paint: theme.wall.fill.paint,
    },
    {
      id: 'Wall outline',
      type: 'line',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      minzoom: theme.wall.minzoom,
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'wall', 'infrastructure_wall'],
      ],
      paint: theme.wall.outline.paint,
    },
    {
      id: 'POI Extrude',
      minzoom: theme.primary.minzoom,
      type: 'fill-extrusion',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class'].concat(theme.primary.classes!).concat(theme.secondary.classes!),
      ],
      paint: theme.primary.fill3d.paint,
    },
    {
      id: 'Details Extrude',
      type: 'fill-extrusion',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      minzoom: theme.details.minzoom,
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'furniture', 'infrastructure_railing'],
        ['!=', 'extrusionType', 'none'],
      ],
      paint: theme.details.fill3d.paint,
    },
    {
      id: 'Tertiary icons and texts',
      type: 'symbol',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      minzoom: theme.tertiary.minzoom,
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        [
          'any',
          ['!has', 'textColor'],
          ['!has', 'textHaloColor'],
          ['!has', 'textColor_dark'],
          ['!has', 'textHaloColor_dark'],
        ],
        ['in', 'css_class'].concat(theme.tertiary.classes!),
      ],
      layout: theme.tertiary.symbol.layout,
      paint: theme.tertiary.symbol.paint,
    },
    {
      id: 'Wall Extrude',
      type: 'fill-extrusion',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      minzoom: theme.wall.minzoom,
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'wall', 'infrastructure_wall'],
        ['!=', 'extrusionType', 'none'],
      ],
      paint: theme?.wall?.fill3d?.paint,
    },
    {
      id: 'Texts',
      type: 'symbol',
      minzoom: theme.texts.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'text_small', 'text_medium', 'text_large'],
      ],
      layout: theme.texts.symbol.layout,
      paint: theme.texts.symbol.paint,
    },
    {
      id: 'Sticky icons and texts',
      type: 'symbol',
      minzoom: theme.stickyTexts.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['==', 'css_class', 'service_direction'],
      ],
      layout: theme.stickyTexts.symbol.layout,
      paint: theme.stickyTexts.symbol.paint,
    },
    {
      id: 'Department Extrude',
      type: 'fill-extrusion',
      minzoom: theme.dept.minzoom,
      maxzoom: theme.dept.maxzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'department', 'infrastructure_department'],
      ],
      paint: theme.dept.fill3d.paint,
    },
    {
      id: 'Department Extrude Sticky',
      type: 'fill-extrusion',
      minzoom: theme.dept.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'department_sticky', 'infrastructure_department_sticky'],
        ['!=', 'extrusionType', 'none'],
      ],
      paint: theme.deptSticky.fill3d.paint,
    },
    {
      id: 'Secondary Icons and Texts',
      type: 'symbol',
      minzoom: theme.secondary.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        [
          'any',
          ['!has', 'textColor'],
          ['!has', 'textHaloColor'],
          ['!has', 'textColor_dark'],
          ['!has', 'textHaloColor_dark'],
        ],
        ['in', 'css_class'].concat(theme.secondary.classes!),
      ],
      layout: theme.secondary.symbol.layout,
      paint: theme.secondary.symbol.paint,
    },
    {
      id: 'Primary Icons and Texts',
      type: 'symbol',
      minzoom: theme.primary.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        [
          'any',
          ['!has', 'textColor'],
          ['!has', 'textHaloColor'],
          ['!has', 'textColor_dark'],
          ['!has', 'textHaloColor_dark'],
        ],
        ['in', 'css_class'].concat(theme.primary.classes!),
      ],
      layout: theme.primary.symbol.layout,
      paint: theme.primary.symbol.paint,
    },
    {
      id: 'Cover Fill',
      type: 'fill',
      minzoom: theme.cover.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['==', 'css_class', 'category_cover_poi'],
      ],
      paint: theme.cover.fill.paint,
    },
    {
      id: 'Cover Extrude',
      type: 'fill-extrusion',
      minzoom: theme.cover.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['==', 'css_class', 'category_cover_poi'],
      ],
      paint: theme.cover.fill3d.paint,
    },
    {
      id: 'Cover Icon and text',
      minzoom: theme.cover.minzoom,
      type: 'symbol',
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['==', 'css_class', 'category_cover_poi'],
      ],
      layout: theme.cover.symbol.layout,
      paint: theme.cover.symbol.paint,
    },
    {
      id: 'Department Text',
      type: 'symbol',
      minzoom: theme.dept.minzoom,
      maxzoom: theme.dept.maxzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',

      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'department', 'infrastructure_department'],
      ],
      layout: theme.dept.symbol.layout,
      paint: theme.dept.symbol.paint,
    },
    {
      id: 'Department Text Sticky',
      type: 'symbol',
      minzoom: theme.deptSticky.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'department_sticky', 'infrastructure_department_sticky'],
      ],
      layout: theme.deptSticky.symbol.layout,
      paint: theme.deptSticky.symbol.paint,
    },
    {
      id: 'Wing Floor Fill',
      type: 'fill',
      layout: { visibility: 'none' }, // HIDE WING
      minzoom: theme.wingFloor.minzoom,
      maxzoom: theme.wingFloor.maxzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'floor', 'infrastructure_floor'],
      ],
      paint: theme.wingFloor.fill.paint,
    },
    {
      id: 'Wing Floor Outline',
      type: 'line',
      layout: { visibility: 'none' }, // HIDE WING
      minzoom: theme.wingFloor.minzoom,
      maxzoom: theme.wingFloor.maxzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'floor', 'infrastructure_floor'],
      ],
      paint: theme.wingFloor.outline.paint,
    },
    {
      id: 'Wing Fill',
      type: 'fill',
      layout: { visibility: 'none' }, // HIDE WING
      minzoom: theme.wing.minzoom,
      maxzoom: theme.wing.maxzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'infrastructure_wing', 'wing'],
      ],
      paint: theme.wing.fill.paint,
    },
    {
      id: 'Wing Outline',
      type: 'line',
      layout: { visibility: 'none' }, // HIDE WING
      minzoom: theme.wing.minzoom,
      maxzoom: theme.wing.maxzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'infrastructure_wing', 'wing'],
      ],
      paint: theme.wing.outline.paint,
    },
    {
      id: 'Wing Extrude',
      type: 'fill-extrusion',
      layout: { visibility: 'none' }, // HIDE WING
      minzoom: theme.wing.minzoom,
      maxzoom: theme.wing.maxzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'infrastructure_wing', 'wing'],
        ['!=', 'extrusionType', 'none'],
      ],
      paint: theme.wing.fill3d.paint,
    },
    {
      id: 'Carpark Icon and Text Outdoor',
      type: 'symbol',
      minzoom: theme.outdoor.minzoom,
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        [
          'in',
          'css_class',
          'infrastructure_carpark_outdoor',
          'infrastructure_disabled_parking_outdoor',
          'infrastructure_carpark_house',
          'infrastructure_parking_bike',
          'infrastructure_parking_motorcycle',
        ],
      ],
      layout: theme.carpark.symbol.layout,
      paint: theme.carpark.symbol.paint,
    },
    {
      id: 'Icon Overlay',
      type: 'symbol',
      minzoom: theme.overlay.minzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['!=', 'css_class', 'category_outdoor'],
        ['has', 'overlay'],
      ],
      layout: theme.overlay.symbol.layout,
      paint: theme.overlay.symbol.paint,
    },
    {
      id: 'Wing Text',
      type: 'symbol',
      minzoom: theme.wing.minzoom,
      maxzoom: theme.wing.maxzoom,
      source: 'steerpath_floor_tiles',
      'source-layer': 'steerpath_floor_tiles',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'layerIndex', 0],
        ['==', 'buildingRef', '65'],
        ['in', 'css_class', 'infrastructure_wing', 'wing'],
      ],
      layout: theme.wing.symbol.layout,
      paint: theme.wing.symbol.paint,
    },
    {
      id: 'Building Address',
      type: 'symbol',
      minzoom: theme.building.minzoom,
      maxzoom: theme.building.maxzoom,
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: ['all', ['==', '$type', 'Point'], ['has', 'address']],
      paint: theme.buildingAddress.symbol.paint,
      layout: theme.buildingAddress.symbol.layout,
    },
    {
      id: 'Building Icon and Title',
      type: 'symbol',
      minzoom: theme.building.minzoom,
      maxzoom: theme.building.maxzoom,
      source: 'steerpath_base_tiles',
      'source-layer': 'steerpath_base_tiles',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'subType', 'building_outline']],
      paint: theme.building.symbol.paint,
      layout: theme.building.symbol.layout,
    },
  ];
}

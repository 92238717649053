import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import * as RNLocalize from 'react-native-localize';
import { useMutation, useQuery } from 'react-query';
import I18n, { Scope, TranslateOptions } from 'i18n-js';
import { I18nManager } from 'react-native';
import once from 'once';
import { enGB as enGBLocale, fi as fiLocale, sv as svLocale } from 'date-fns/locale';
import numeral from 'numeral';
import {
  CountryCodeList as PhoneCountryCodeList,
  CountryCode as PhoneCountryCode,
} from 'react-native-country-picker-modal';
import { getTimezone, CountryCode as TzCountryCode } from 'countries-and-timezones';
import { getData, InternalStorageItemKey, storeData } from '../utils/internalStorage';
import enTranslations from '../utils/localize/en.json';
import fiTranslations from '../utils/localize/fi.json';
import svTranslations from '../utils/localize/sv.json';
import svSETranslations from '../utils/localize/sv-se.json';
import enSETranslations from '../utils/localize/en-se.json';
import { AuthState, useAuth } from './AuthContext';
import { fetchMyProfile, updatePreferredLanguage } from '../apis/appsyncApis';
import { SupportLanguage, MutationUpdateProfileArgs } from '../types/appsync-types';
import {
  DateFormats,
  formatDate,
  formatDuration,
  formatDurationBetween,
  formatDateRange,
  relativeWithDate,
} from '../utils/localeHelpers';

const FOLLOW_SYSTEM_LANGUAGE_LOCALE = 'DEFAULT_LANG_SETTINGS';

export enum SupportedTranslation {
  en = 'en',
  fi = 'fi',
  sv = 'sv',
  svSE = 'sv-SE',
  enSE = 'en-SE',
}

const TRANSLATION_LOCALES: { [key in SupportedTranslation]: Locale } = {
  en: enGBLocale,
  fi: fiLocale,
  sv: svLocale,
  'sv-SE': svLocale,
  'en-SE': enGBLocale,
} as const;

const TRANSLATIONS: Record<SupportedTranslation, any> = {
  en: enTranslations,
  fi: fiTranslations,
  sv: svTranslations,
  'sv-SE': svSETranslations,
  'en-SE': enSETranslations,
};

export function isSupportedTranslation(s: string | null | undefined): s is SupportedTranslation {
  if (typeof s === 'string' && Object.values(SupportedTranslation).includes(s as SupportedTranslation)) {
    return true;
  }
  return false;
}

export const getCountryCode = (): PhoneCountryCode => {
  const tz = RNLocalize.getTimeZone();
  // tzCountries is a list of countries that use this timezone
  const tzCountries = getTimezone(tz)?.countries;
  // osInputCountries is a list of countries for the keyboards on this device
  const osInputCountries = RNLocalize.getLocales().map((locale) => locale.countryCode);

  /* Try using the timezone */
  if (tzCountries) {
    /* let's iterate through the keyboards, and see if one of them matches our current timezone */
    const matchingCountry = osInputCountries.find((osCountry): osCountry is PhoneCountryCode => {
      if (tzCountries.includes(osCountry as TzCountryCode) && isPhoneCountryCode(osCountry)) {
        /* Timezone used in multiple countries, using the one that matches osInputCountry */
        return true;
      }
      return false;
    });
    if (matchingCountry) return matchingCountry;

    /* No keyboard matches timezones, so trying to use first possible timezone in list */
    const tzCountry = tzCountries.find(isPhoneCountryCode);
    if (tzCountry) return tzCountry;
  }
  /* Ok, so no usable timezones, so try using the keyboard ones, or fall back to Finland */
  const keybCountry = osInputCountries.find(isPhoneCountryCode);
  return keybCountry ?? 'FI';
};

export function isSystemLanguageSupported(): boolean {
  const defaultWithCountry = `${RNLocalize.getLocales()[0].languageCode.split('-')[0]}-${getCountryCode()}`;
  return isSupportedTranslation(defaultWithCountry);
}
export function getSupportedSystemLanguage(): SupportedTranslation {
  const defaultLanguage = RNLocalize.findBestAvailableLanguage(Object.keys(TRANSLATIONS))?.languageTag;
  const defaultLocale = defaultLanguage ? `${defaultLanguage.split('-')[0]}-${getCountryCode()}` : undefined;
  if (isSupportedTranslation(defaultLocale)) {
    return defaultLocale;
  }
  if (isSupportedTranslation(defaultLanguage)) {
    return defaultLanguage;
  }
  return SupportedTranslation.en;
}

/* Type guard to make typescript know that c is of CountryCode type, if this returns true */
function isPhoneCountryCode(c: string): c is PhoneCountryCode {
  return PhoneCountryCodeList.includes(c as PhoneCountryCode);
}

function registerCurrencyLocale() {
  (Object.keys(TRANSLATIONS) as SupportedTranslation[]).forEach((translationLocale) => {
    /* Numeral doesn't support fallbacks so we do it manually */
    const key = TRANSLATIONS[translationLocale].currency?.delimiters?.thousands
      ? translationLocale
      : (translationLocale.split('-')[0] as SupportedTranslation);
    const localeLowercase = (translationLocale as string).toLowerCase();
    if (translationLocale !== 'en' && !numeral.locales[localeLowercase]) {
      numeral.register('locale', localeLowercase, {
        delimiters: {
          thousands: TRANSLATIONS[key].currency.delimiters.thousands,
          decimal: TRANSLATIONS[key].currency.delimiters.decimal,
        },
        abbreviations: {
          thousand: TRANSLATIONS[key].currency.abbreviations.thousand,
          million: TRANSLATIONS[key].currency.abbreviations.million,
          billion: TRANSLATIONS[key].currency.abbreviations.billion,
          trillion: TRANSLATIONS[key].currency.abbreviations.trillion,
        },
        ordinal: (number: number) => {
          return number === 1 ? TRANSLATIONS[key].currency.ordinal.first : TRANSLATIONS[key].currency.ordinal.other;
        },
        currency: {
          symbol: TRANSLATIONS[key].currency.symbol,
        },
      });
    }
  });
}

once(registerCurrencyLocale)();

export type ContentValues = {
  I18n: typeof I18n;
  changeLocale: (locale: SupportedTranslation | null) => void;
  currentLocale: SupportedTranslation;
  followingSystemLanguage: boolean;
  formatDuration: (durationLength: number, unit: 'seconds' | 'minutes' | 'milliseconds', format?: 'short') => string;
  formatDurationBetween: (date: Date, baseDate: Date, format?: 'short') => string;
  formatDateRange: (start: Date, end: Date, format: DateFormats, tz: string | undefined | null) => string;
  formatDate: (date: Date, format: DateFormats, tz: string | undefined | null) => string;
  formatRelative: (
    date: Date,
    baseDate: Date,
    tz: string | null | undefined,
    options?: {
      showWeekday?: boolean;
      dropWeekdayIfRelative?: boolean;
      showDate?: boolean;
      dropDateIfRelative?: boolean;
      showYear?: boolean;
      showTime?: boolean;
    },
  ) => string;
  formatCurrency: (amount: number | undefined | null, currency: string | undefined | null) => string;
  parseDecimal: (amount: string | number) => number | null;
};

const I18nContext = React.createContext<undefined | ContentValues>(undefined);

export const useI18n = () => {
  const context = React.useContext(I18nContext);

  if (!context) {
    throw new Error('I18n context hook is not used correctly');
  }
  return context;
};

const I18nProvider: FC<PropsWithChildren> = ({ children }) => {
  const [initializing, setInitializing] = useState(true);
  const { state: authState } = useAuth();
  const [currentLocaleData, setCurrentLocaleData] = useState<{
    locale: SupportedTranslation;
    followSystem: boolean;
  }>({
    locale: getSupportedSystemLanguage(),
    followSystem: true,
  });
  const [hasHydrated, setHasHydrated] = useState<boolean>(false);

  const maybeProfileResult = useQuery(fetchMyProfile.id, fetchMyProfile, {
    enabled: authState === AuthState.AUTHENTICATED,
  });

  const saveNewLanguage = useMutation(
    async (form: Pick<MutationUpdateProfileArgs, 'language'>) => {
      await updatePreferredLanguage(form);
    },
    {
      onSuccess: () => {
        maybeProfileResult.refetch();
      },
    },
  );

  /* Initializse I18n and try to load currentLocale from storage */
  useEffect(() => {
    I18n.fallbacks = true;
    I18n.defaultLocale = getSupportedSystemLanguage();
    I18nManager.forceRTL(false);
    I18n.translations = TRANSLATIONS;
    // Don't set state if we have unmounted, but ideally we would actually cancel the fetch.
    let isMounted = true;
    getData<string>(InternalStorageItemKey.CURRENT_LOCALE)
      .then((res) => {
        if (!isMounted) return;
        if (isSupportedTranslation(res)) {
          setCurrentLocaleData({ locale: res, followSystem: false });
        } else {
          setCurrentLocaleData({ locale: getSupportedSystemLanguage(), followSystem: true });
        }
      })
      .finally(() => {
        if (!isMounted) return;
        setInitializing(false);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  /* Check language from server */
  useEffect(() => {
    if (authState === AuthState.AUTHENTICATED && maybeProfileResult.isSuccess && !maybeProfileResult.isFetching) {
      let isMounted = true;
      const hydrate = async () => {
        const localSavedLanguage = await getData<string>(InternalStorageItemKey.CURRENT_LOCALE);
        const hasLocalSavedLanguage =
          localSavedLanguage &&
          (localSavedLanguage === FOLLOW_SYSTEM_LANGUAGE_LOCALE || isSupportedTranslation(localSavedLanguage));

        if (!isMounted) return;
        setHasHydrated((wasHydrated) => {
          if (wasHydrated) return wasHydrated; // Only do this once
          const langInBackend = maybeProfileResult.data?.data?.getMyProfile?.language;
          /* Check if we should use the language from server */
          if (!hasLocalSavedLanguage && isSupportedTranslation(langInBackend)) {
            /* Using server provided language */
            if (langInBackend === getSupportedSystemLanguage()) {
              /* Looks like the server stored language is the default language for this device */
              setCurrentLocaleData({ locale: langInBackend, followSystem: true });
              storeData(InternalStorageItemKey.CURRENT_LOCALE, FOLLOW_SYSTEM_LANGUAGE_LOCALE);
            } else {
              /* Use the one from the server */
              setCurrentLocaleData({ locale: langInBackend, followSystem: false });
              storeData(InternalStorageItemKey.CURRENT_LOCALE, langInBackend);
            }
          }
          return true;
        });
      };
      hydrate();
      return () => {
        isMounted = false;
      };
    }
  }, [authState, maybeProfileResult, saveNewLanguage]);

  useEffect(() => {
    // Store to server when ever actual language changes
    const profileData = maybeProfileResult.data?.data?.getMyProfile;
    const isReady =
      hasHydrated &&
      authState === AuthState.AUTHENTICATED &&
      !maybeProfileResult.isFetching &&
      !saveNewLanguage.isLoading &&
      !saveNewLanguage.isError && // Don't re-attempt if there is an error.
      profileData;

    // TODO: Convert actual language to something the server understands
    const langForServer = currentLocaleData.locale.slice(0, 2) as SupportLanguage;
    if (isReady && profileData?.language !== langForServer) {
      saveNewLanguage.mutate({ language: langForServer });
    }
  }, [authState, saveNewLanguage, currentLocaleData, hasHydrated, maybeProfileResult]);

  const values = useMemo<ContentValues>(() => {
    const uiLocale = TRANSLATION_LOCALES[currentLocaleData.locale];
    numeral.locale(currentLocaleData.locale);
    I18n.locale = currentLocaleData.locale;

    const changeLocale = (newLocale: SupportedTranslation | null) => {
      if (newLocale !== null && isSupportedTranslation(newLocale)) {
        setCurrentLocaleData({ locale: newLocale, followSystem: false });
        storeData(InternalStorageItemKey.CURRENT_LOCALE, newLocale);
      } else {
        setCurrentLocaleData({ locale: getSupportedSystemLanguage(), followSystem: true });
        storeData(InternalStorageItemKey.CURRENT_LOCALE, FOLLOW_SYSTEM_LANGUAGE_LOCALE);
      }
    };

    // console.log("Recomputed locale", currentLocale, uiLocale, I18n.locale);
    const MyI18n = {
      ...I18n,
      t: (scope: Scope, options?: TranslateOptions) => I18n.t(scope, options), // for new t function and I18n Object after change locale
      translate: (scope: Scope, options?: TranslateOptions) => I18n.translate(scope, options),
    };
    return {
      changeLocale,
      currentLocale: currentLocaleData.locale,
      followingSystemLanguage: currentLocaleData.followSystem,
      I18n: MyI18n,
      formatDate: (date, format, tz) => {
        return formatDate(MyI18n, uiLocale, date, format, tz);
      },
      formatDateRange: (d1, d2, format, tz) => {
        return formatDateRange(MyI18n, uiLocale, d1, d2, format, tz);
      },
      formatDuration: (duration, unit, format) => {
        return formatDuration(MyI18n, duration, unit, format ?? 'long');
      },
      formatDurationBetween: (date, baseDate, format) => {
        return formatDurationBetween(MyI18n, date, baseDate, format ?? 'long');
      },

      formatRelative: (date, baseDate, tz, options) => {
        return relativeWithDate(MyI18n, uiLocale, date, baseDate, tz, options ?? {});
      },

      formatCurrency: (amount, currency) => {
        if (amount === undefined || amount === null || currency === undefined || currency === null) return '';
        const amountString = numeral(amount).format(MyI18n.t('datetime.decimalFormat'));
        return MyI18n.t(`currency.amounts.${currency.toLowerCase()}`, {
          amount: amountString,
          defaultValue: `${amountString} ${currency.toUpperCase()}`,
        });
      },
      parseDecimal: (amount: string | number): number | null => {
        // TODO: This is not using numeral to parse the number
        // because the keyboard locale might be different than the app
        // locale.
        // TODO: Also given string "1this is not a valid float" parseFloat will just return 1, while you would expect to get some error.
        if (typeof amount === 'string') {
          const r = parseFloat(amount.replace(' ', '').replace(',', '.'));
          return isNaN(r) ? null : r;
        }
        return amount;
      },
    };
  }, [currentLocaleData]);
  return initializing ? null : ( // do not render content until initializing finish (avoid flick content)
    <I18nContext.Provider value={values}>{children}</I18nContext.Provider>
  );
};

export default I18nProvider;

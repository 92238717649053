import React, { FC, useLayoutEffect, useState, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button } from 'react-native-paper';
import { Platform } from 'react-native';
import { useIntroContext } from '../../context/IntroContext';
import IntroSlider, { IntroSlide } from '../../components/intro/IntroSlider';
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import Slide5 from './Slide5';
import Slide6 from './Slide6';
import { useI18n } from '../../context/I18nContext';

const Slides: IntroSlide[] = [
  { key: '1', screen: Slide1 },
  { key: '2', screen: Slide2 },
  { key: '3', screen: Slide3 },
  { key: '4', screen: Slide4 },
  { key: '5', screen: Slide5 },
  { key: '6', screen: Slide6 },
];
const Intro: FC = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const { I18n } = useI18n();
  const { handleHide } = useIntroContext();
  const navigation = useNavigation();
  const isLastSlide = slideIndex >= Slides.length - 1;
  const isFirstSlide = slideIndex <= 0;

  const renderRightButton = useCallback(() => {
    if (Platform.OS === 'web' && !isLastSlide) {
      return (
        <Button mode="text" onPress={() => setSlideIndex((prev) => prev + 1)}>
          {I18n.t('intro.next')}
        </Button>
      );
    }
    return (
      <Button mode="text" onPress={handleHide}>
        {isLastSlide ? I18n.t('intro.tryNow') : I18n.t('intro.skip')}
      </Button>
    );
  }, [I18n, handleHide, isLastSlide]);

  const renderLeftButton = useCallback(() => {
    if (Platform.OS === 'web' && !isFirstSlide) {
      return (
        <Button mode="text" onPress={() => setSlideIndex((prev) => prev - 1)}>
          {I18n.t('intro.previous')}
        </Button>
      );
    }
  }, [I18n, isFirstSlide]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: renderRightButton,
      headerLeft: renderLeftButton,
    });
  }, [navigation, renderLeftButton, renderRightButton]);

  return (
    <IntroSlider
      slide={Slides}
      index={slideIndex}
      onSlideChange={(index) => {
        setSlideIndex(index);
      }}
    />
  );
};

export default Intro;

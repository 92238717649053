import 'react-native-gesture-handler';
import 'react-native-get-random-values';
import 'intl';
import 'intl/locale-data/jsonp/en';
import { registerRootComponent } from 'expo';
import * as Sentry from './src/components/sentry/sentry';
import App from './App';

if (!__DEV__) {
  Sentry.init({
    dsn: 'https://7122835873804962aa9705e7cc62436b@o395500.ingest.sentry.io/5589415',
  });
}

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App);

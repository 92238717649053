import React, { FC, useCallback, useEffect, useState } from 'react';
import { AppState, Platform, View } from 'react-native';
import { Button, List } from 'react-native-paper';
import { useMutation, useQuery } from 'react-query';
import { openSettings } from 'react-native-permissions';
import { useFocusEffect } from '@react-navigation/native';
import { fetchMyNotificationPreference, updateNotificationPreference } from '../../apis/appsyncApis';
import { useI18n } from '../../context/I18nContext';
import useUnmount from '../../hooks/useUnmount';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import Switch from '../../components/ui/Switch';
import { MutationUpdateMyNotificationPreferenceArgs } from '../../types/appsync-types';
import { initAppPushNotification } from '../../utils/pushNotifications';

interface UpdateNotificationForm {
  beforeStartingTenMinutes: boolean;
  beforeStaringOneDay: boolean;
  startingNow: boolean;
  beforeEndingTenMinutes: boolean;
  completed: boolean;
  news: boolean;
}
const UpdateNotifications: FC = () => {
  const { I18n } = useI18n();
  const mp = useMixpanel();
  const [formValue, setFormValue] = useState<UpdateNotificationForm>({
    beforeStartingTenMinutes: false,
    beforeStaringOneDay: false,
    startingNow: false,
    beforeEndingTenMinutes: false,
    completed: false,
    news: true,
  });
  const result = useQuery(fetchMyNotificationPreference.id, fetchMyNotificationPreference);
  const data = result.data?.data?.getMyNotificationPreference;

  const [hasNotificationPermission, setHasNotificationPermission] = useState(Platform.OS === 'web');

  useEffect(() => {
    setFormValue({
      beforeStartingTenMinutes: data?.beforeStartingTenMinutes || false,
      beforeStaringOneDay: data?.beforeStaringOneDay || false,
      startingNow: data?.startingNow || false,
      beforeEndingTenMinutes: data?.beforeEndingTenMinutes || false,
      completed: data?.completed || false,
      news: data?.news ?? true,
    });
  }, [data]);

  const saveNotification = useMutation(
    async (input: MutationUpdateMyNotificationPreferenceArgs) => {
      await updateNotificationPreference(input);
      mp?.track('Notification preferences updated', input.input ?? undefined);
    },
    {
      onSuccess: () => {
        result.refetch();
      },
    },
  );

  useUnmount(() => {
    saveNotification.mutate({
      input: formValue,
    });
  });

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        initAppPushNotification().then((res) => {
          setHasNotificationPermission(res === 'success');
        });
        const appStateListener = AppState.addEventListener('change', (nextAppState) => {
          if (nextAppState === 'active') {
            initAppPushNotification().then((res) => {
              setHasNotificationPermission(res === 'success');
            });
          }
        });
        return () => {
          appStateListener.remove();
        };
      }
    }, []),
  );

  /* 
  Render a list item with a switch. Currently not used elsewhere so not its own component.
  */
  const renderSwitchItem = useCallback(
    (title: string, valueName: keyof UpdateNotificationForm, value: boolean) => {
      const handleChange = (n: keyof UpdateNotificationForm, v: boolean) => {
        setFormValue((p) => ({ ...p, [n]: v }));
      };
      return (
        <List.Item
          title={title}
          titleNumberOfLines={2}
          disabled={!hasNotificationPermission}
          right={() => {
            return (
              <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
                <Switch
                  disabled={!hasNotificationPermission}
                  value={value}
                  onValueChange={(v) => handleChange(valueName, v)}
                />
              </View>
            );
          }}
          onPress={() => handleChange(valueName, !value)}
        />
      );
    },
    [hasNotificationPermission],
  );
  return (
    <ScrollablePage
      summary={{
        title: I18n.t('updateNotifications.summaryTitle'),
        description: I18n.t('updateNotifications.summaryDescription'),
        icon: 'bell',
      }}
    >
      {!hasNotificationPermission && (
        <List.Item
          title={I18n.t('general.grantNotificationPermission.summaryTitle')}
          description={I18n.t('updateNotifications.grantPermission')}
          onPress={openSettings}
          right={() => <Button onPress={openSettings}>{I18n.t('updateNotifications.settings')}</Button>}
        />
      )}

      <List.Subheader>{I18n.t('updateNotifications.title.reminders')}</List.Subheader>
      {renderSwitchItem(I18n.t('updateNotifications.setting.startingNow'), 'startingNow', formValue.startingNow)}
      {renderSwitchItem(
        I18n.t('updateNotifications.setting.tenMinReminderStart'),
        'beforeStartingTenMinutes',
        formValue.beforeStartingTenMinutes,
      )}
      {renderSwitchItem(
        I18n.t('updateNotifications.setting.oneDayReminderStart'),
        'beforeStaringOneDay',
        formValue.beforeStaringOneDay,
      )}
      {renderSwitchItem(I18n.t('updateNotifications.setting.completed'), 'completed', formValue.completed)}
      {renderSwitchItem(
        I18n.t('updateNotifications.setting.tenMinReminderEnd'),
        'beforeEndingTenMinutes',
        formValue.beforeEndingTenMinutes,
      )}

      <List.Subheader>{I18n.t('updateNotifications.title.services')}</List.Subheader>
      {renderSwitchItem(I18n.t('updateNotifications.setting.news'), 'news', formValue.news)}
    </ScrollablePage>
  );
};

export default UpdateNotifications;

import React, { useEffect } from 'react';
import Constants from 'expo-constants';
import { Image, StyleSheet, View, Linking, PixelRatio } from 'react-native';
import { Button, HelperText, useTheme } from 'react-native-paper';
import { CompositeScreenProps } from '@react-navigation/native';
import { isBefore } from 'date-fns';
import { StackScreenProps } from '@react-navigation/stack';
import Hr from '../components/ui/Hr';
import { screenNames } from '../navigators/screenNames';
import { useI18n } from '../context/I18nContext';
import { BitwardLockButton } from '../components/BitwardLockButton';
import { useMyCompoundReservations } from '../apis/reservationApis';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import { useNowMinutes } from '../hooks/useNow';
import ScrollablePage from '../components/ui/ScrollablePage';
import { SelectSpaceAction } from '../utils/urlHandler';
import { CardTitleRow } from './MapView/PodInformationCard';
import { flattenDict } from '../utils/helpers';
import { AppDrawerScreenProp } from '../components/DrawerMenuContent';
import { InformationStackRouteParams } from '../navigators/InformationStackNavigator';
import { ReservationStatus } from '../types/appsync-types';
import { getSpaceTypeInfo } from '../../assets/other/spacetypes';
import { GetFromStoreButton } from '../components/GetFromStoreButton';
import Typography from '../components/Typography';

export type BookingDetailRouteParams = undefined | { bookingId: string };

type Props = CompositeScreenProps<StackScreenProps<InformationStackRouteParams, 'BookingDetail'>, AppDrawerScreenProp>;
const BookingDetail = ({ navigation, route }: Props) => {
  const now = useNowMinutes();
  const { I18n, formatDate, formatDurationBetween } = useI18n();
  const mp = useMixpanel();
  const { colors } = useTheme();
  const myReservationsQuery = useMyCompoundReservations();
  const hasLargeFont = PixelRatio.getFontScale() > 2;

  const currentReservation = React.useMemo(
    () =>
      myReservationsQuery.reservations.find((reservation) => {
        return reservation?.initialId === route?.params?.bookingId;
      }),
    [myReservationsQuery.reservations, route?.params?.bookingId],
  );

  /* If this reservation doesn't exist, go back from this screen */
  useEffect(() => {
    if (!myReservationsQuery.isLoading && myReservationsQuery.reservations) {
      if (!currentReservation) {
        navigation.goBack();
      }
    }
  }, [currentReservation, myReservationsQuery.isLoading, myReservationsQuery.reservations, navigation]);

  // Stripe may delay 1-2 minutes after reservation ended due to waiting for payment capture
  // Hence the reservation status might not transition to "completed" yet.
  const isReservationEnded =
    currentReservation?.status === ReservationStatus.COMPLETED ||
    (!!currentReservation?.to && isBefore(new Date(currentReservation.to), now));

  const isCurrentReservationInFuture = isBefore(now, new Date(currentReservation?.from!));

  function renderTimeIndicator() {
    if (isCurrentReservationInFuture && currentReservation?.status === ReservationStatus.RESERVED) {
      return (
        <>
          <Typography variant={'caption'}>{I18n.t('bookingDetail.startsIn')}</Typography>
          <Typography color={colors.secondary} variant={'h4'} style={{ marginTop: 10 }}>
            {formatDurationBetween(now, new Date(currentReservation.from!))}
          </Typography>
        </>
      );
    }
    if (isReservationEnded) {
      return (
        <>
          <Typography variant={'caption'}>{I18n.t('bookingDetail.endedAfter')}</Typography>
          <Typography color={colors.onBackground} variant={'h4'} style={{ marginTop: 10 }}>
            {formatDurationBetween(new Date(currentReservation?.from!), new Date(currentReservation?.to!))}
          </Typography>
        </>
      );
    }
    if (currentReservation?.status === ReservationStatus.RESERVED) {
      return (
        <>
          <Typography variant={'caption'}>{I18n.t('bookingDetail.timeRemain')}</Typography>
          <Typography color={colors.onBackground} variant={'h4'} style={{ marginTop: 10 }}>
            {formatDurationBetween(now, new Date(currentReservation.to!))}
          </Typography>
        </>
      );
    }
    return <></>;
  }

  return (
    <ScrollablePage
      summary={{
        title: `${currentReservation?.spaceInfo.building?.title} #${currentReservation?.spaceId}`,
        description: currentReservation
          ? I18n.t('bookingDetail.headerSubtitle', {
              date: formatDate(new Date(currentReservation.from!), 'dateWithYear-no-tz', currentReservation.timeZone),
              startTime: formatDate(new Date(currentReservation.from!), 'time-no-tz', currentReservation.timeZone),
              endTime: formatDate(new Date(currentReservation.to!), 'time', currentReservation.timeZone),
            })
          : '',
        icon: (props) => {
          return (
            <Image
              source={getSpaceTypeInfo(currentReservation?.spaceInfo.type).image}
              style={{ width: props.size, height: props.size }}
            />
          );
        },
        button: {
          label: I18n.t('bookings.menuAction.viewPodDetail'),
          onPress: () => {
            const params: { action: SelectSpaceAction } = {
              action: {
                id: currentReservation?.spaceId ?? '',
                type: 'SELECT',
              },
            };
            navigation.navigate('map', { screen: 'MapView', params });
          },
        },
      }}
    >
      <View style={[styles.card, { backgroundColor: colors.surface }]}>
        <CardTitleRow spaceId={currentReservation?.spaceId ?? '?'} spaceType={currentReservation?.spaceInfo.type} />
        <View style={styles.remainTime}>{renderTimeIndicator()}</View>
        <View style={[styles.scheduleTime, { flexDirection: hasLargeFont ? 'column' : 'row' }]}>
          <View style={styles.scheduleTimeItem}>
            <Typography variant={'caption'}>{I18n.t('bookingDetail.startTime')}</Typography>
            {!!currentReservation?.from && (
              <Typography variant={'h4'} style={{ marginTop: 10 }}>
                {formatDate(
                  new Date(currentReservation?.from),
                  'time-no-tz',
                  currentReservation.spaceInfo.building?.timezone,
                )}
              </Typography>
            )}
          </View>
          <View style={styles.scheduleTimeItem}>
            <Typography variant={'caption'}>{I18n.t('bookingDetail.endTime')}</Typography>
            {!!currentReservation?.to && (
              <Typography variant={'h4'} style={{ marginTop: 10 }}>
                {formatDate(
                  new Date(currentReservation?.to!),
                  'time-no-tz',
                  currentReservation.spaceInfo.building?.timezone,
                )}
              </Typography>
            )}
          </View>
        </View>
        <Hr style={{ marginVertical: 20 }} />
        <View style={[styles.address, { flexDirection: hasLargeFont ? 'column' : 'row' }]}>
          <View style={!hasLargeFont ? { flex: 1, marginRight: 15 } : {}}>
            <Typography variant={'body2'}>{currentReservation?.spaceInfo.building?.title}</Typography>
          </View>
          {Constants.manifest?.extra?.disableBitwards ? (
            <GetFromStoreButton
              onUnknownStore={() => {
                navigation.navigate('ChooseStoreDialog');
              }}
            >
              {I18n.t('storeDialog.installToUnlockButton')}
            </GetFromStoreButton>
          ) : (
            <BitwardLockButton
              resourceId={currentReservation?.spaceInfo?.bitwardId}
              disabled={isCurrentReservationInFuture || currentReservation?.status !== ReservationStatus.RESERVED}
              onPress={() => {
                mp?.track(
                  'Unlock attempt from Booking Detail card',
                  flattenDict('space', currentReservation?.spaceInfo),
                );
              }}
            >
              {I18n.t('bookingDetail.unlock')}
            </BitwardLockButton>
          )}
        </View>
        <View style={styles.cardAction}>
          {/* <Button>{I18n.t("bookingDetail.actions.direction")}</Button> */}

          {currentReservation?.status === ReservationStatus.RESERVED && !isReservationEnded && (
            <>
              <Button
                onPress={() =>
                  navigation.navigate(screenNames.ExtendReservationDialog, {
                    compoundReservation: currentReservation,
                  })
                }
              >
                {I18n.t('bookingDetail.actions.extend')}
              </Button>

              {isCurrentReservationInFuture ? (
                <Button
                  onPress={() => {
                    navigation.navigate('CancelReservationDialog', {
                      /* Cancel reservation must have the non-compound reservation id */
                      originalReservationId: currentReservation.originals[0].id,
                    });
                  }}
                >
                  {I18n.t('bookingDetail.actions.cancel')}
                </Button>
              ) : (
                <Button
                  onPress={() => navigation.navigate('EndReservationDialog', { reservation: currentReservation })}
                >
                  {I18n.t('bookingDetail.actions.end')}
                </Button>
              )}
            </>
          )}
          {isReservationEnded ? (
            <View>
              {currentReservation?.originals.map((res, index) => {
                const title =
                  currentReservation.originals.length === 1
                    ? I18n.t('bookingDetail.actions.viewReceipt')
                    : `${I18n.t('bookingDetail.actions.viewReceipt')} ${index + 1}/${
                        currentReservation.originals.length
                      }`;
                return (
                  <Button
                    key={res.id}
                    disabled={!res.receiptUrl}
                    onPress={() => res.receiptUrl && Linking.openURL(res.receiptUrl)}
                  >
                    {title}
                  </Button>
                );
              })}
            </View>
          ) : null}
          {isReservationEnded ? (
            <HelperText
              type="info"
              style={{ paddingHorizontal: 8 }}
              visible={currentReservation?.originals.some((v) => !v.receiptUrl)}
            >
              {I18n.t('bookingDetail.receipt.receiptNotReady')}
            </HelperText>
          ) : null}
        </View>
      </View>
      <View style={[styles.card, { backgroundColor: colors.surface }]}>
        <Typography variant={'h6'}>{currentReservation?.spaceInfo.building?.title}</Typography>
        <Typography variant={'body2'} style={{ marginTop: 5 }}>
          {currentReservation?.spaceInfo.building?.address}
        </Typography>
        <Image style={styles.addressImage} source={{ uri: currentReservation?.spaceInfo.building?.imageUrl! }} />
        <View style={styles.cardAction}>
          <Button
            onPress={() => {
              if (currentReservation?.spaceInfo.building?.websiteUrl) {
                Linking.openURL(currentReservation?.spaceInfo.building?.websiteUrl);
              }
            }}
          >
            {I18n.t('bookingDetail.actions.website')}
          </Button>
          <Button
            onPress={() => {
              if (currentReservation?.spaceInfo.building?.websiteUrl) {
                Linking.openURL(currentReservation?.spaceInfo.building?.websiteUrl);
              }
            }}
          >
            {I18n.t('bookingDetail.actions.openingHours')}
          </Button>
        </View>
      </View>
    </ScrollablePage>
  );
};

const styles = StyleSheet.create({
  card: {
    borderRadius: 4,
    padding: 20,
    marginTop: 26,
  },
  imagePodDetail: {
    width: 75,
    height: 75,
  },
  titleSection: {
    flexDirection: 'row',
  },
  title: {
    marginLeft: 20,
    flex: 1,
  },
  remainTime: {
    marginTop: 28,
  },
  scheduleTime: {
    marginTop: 30,
  },
  scheduleTimeItem: {
    flex: 1,
  },
  address: {
    marginBottom: 30,
  },
  cardAction: {
    flexDirection: 'row',
    marginHorizontal: -15,
    flexWrap: 'wrap',
  },
  addressImage: {
    marginHorizontal: -20,
    height: 150,
    backgroundColor: '#ddd',
    marginVertical: 20,
  },
});
export default BookingDetail;

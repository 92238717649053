import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Platform, TextStyle, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import PhoneTextInput from 'react-native-phone-number-input';
import { Country } from 'react-native-country-picker-modal';
import { getCountryCode } from '../../context/I18nContext';
import { UiIcon } from '../SimpleListIcon';

interface PhoneNumberInputProps {
  hasError: boolean;
  onChange: (phone: string, isValid: boolean) => void;
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({ hasError, onChange }) => {
  const { colors, fonts, roundness } = useTheme();
  const [rawPhoneInput, setRawPhoneInput] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const phoneRef = useRef<PhoneTextInput>(null);

  /*
    react-native-phone-number-input doesn't support "numbers only" on web
    so added properties textInputProps to parse input value into a number
    where empty spaces and letters are removed.
    */
  const cleanUpDigits = (numbers: string) => {
    // remove anything not a number and leding zeros
    return numbers.replace(/[^0-9]/g, '').replace(/^0+/, '');
  };

  const handleOnChangeText = useCallback(
    (num: string) => {
      setRawPhoneInput(num);
      const cleanNumber = cleanUpDigits(num);
      const callingCode = phoneRef.current?.getCallingCode();
      const isValid = (phoneRef.current?.isValidNumber(cleanNumber) ?? false) && !!callingCode;
      onChange(`+${callingCode ?? '<INVALID>'}${cleanUpDigits(cleanNumber)}`, isValid);
    },
    [onChange],
  );

  const handleOnChangeCountry = useCallback(
    (_country: Country) => {
      /* No special handling of changing country except triggering number parsing */
      handleOnChangeText(rawPhoneInput);
    },
    [handleOnChangeText, rawPhoneInput],
  );

  const borderColor = useMemo(() => {
    if (isFocused) return colors.primary;
    if (hasError) return colors.error;
    return colors.surfaceVariant;
  }, [colors.error, colors.surfaceVariant, colors.primary, hasError, isFocused]);

  return (
    <View style={{ flexDirection: 'row' }}>
      <PhoneTextInput
        withDarkTheme={true}
        autoFocus={true}
        ref={phoneRef}
        defaultCode={getCountryCode()}
        onChangeCountry={handleOnChangeCountry}
        onChangeText={handleOnChangeText}
        layout="first"
        placeholder={'1234567'}
        containerStyle={[
          {
            backgroundColor: 'transparent',
            borderWidth: 1,
            borderRadius: roundness,
            width: '100%',
            borderColor,
          },
          Platform.select({
            android: {
              height: 55,
            },
          }),
        ]}
        textContainerStyle={[
          {
            backgroundColor: 'transparent',
            flex: 1,
          },
        ]}
        textInputProps={{
          selectionColor: colors.secondary,
          keyboardType: 'number-pad',
          value: rawPhoneInput.replace(/[^0-9]/g, ''), // Drop non numbers
          onFocus: () => setIsFocused(true),
          onBlur: () => setIsFocused(false),
          placeholderTextColor: colors.surfaceVariant,
        }}
        textInputStyle={[
          {
            color: colors.onBackground,
            fontFamily: fonts.default.fontFamily,
            flexGrow: 1,
            backgroundColor: colors.background,
          },
          Platform.select({
            web: { outline: 0, paddingVertical: 16 } as TextStyle,
            android: { padding: 0 },
          }),
        ]}
        countryPickerButtonStyle={{
          paddingLeft: 16,
        }}
        codeTextStyle={{
          color: colors.onSurface,
          fontFamily: fonts.default.fontFamily,
          marginRight: 0,
          marginLeft: 16,
        }}
        renderDropdownImage={<UiIcon name="arrow-drop-down" set="material" size={18} color={colors.secondary} />}
      />
    </View>
  );
};

export default PhoneNumberInput;

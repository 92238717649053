import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import React, { createRef, useEffect, useRef } from 'react';
import { AppState, AppStateStatus, Linking, Platform } from 'react-native';
import { PaperProvider } from 'react-native-paper';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import { ModalContextProvider } from '../context/ModalContext';
import { clearData, getData, InternalStorageItemKey } from '../utils/internalStorage';
import { getInitialNotificationIOS, setOnNotificationOpenedListener } from '../utils/pushNotifications';
import { baseUrl, handleAppUrl } from '../utils/urlHandler';
import { RootStack, RootStackRouteParams } from './RootNavigator';
import { screenNames } from './screenNames';
import { useI18n } from '../context/I18nContext';
import { registerPaperDatesTranslations } from '../utils/localeHelpers';
import { getPaperTheme } from '../utils/paperTheme';

export const navigationRef = createRef<NavigationContainerRef<RootStackRouteParams>>();

export default () => {
  const prevAppStatus = useRef<AppStateStatus>('unknown');
  const mp = useMixpanel();
  const routeNameRef = useRef<string>();
  const { I18n } = useI18n();

  useEffect(() => {
    // Paper dates is only used on desktop version
    if (Platform.OS === 'web') registerPaperDatesTranslations(I18n);
  }, [I18n]);

  useEffect(() => {
    const handleAppStateChange = (newAppState: AppStateStatus): void => {
      if (newAppState === 'active') {
        mp?.timeEvent('EndForeground'); /* Start: app is active */
      } else if (prevAppStatus.current === 'active') {
        mp?.track('EndForeground', {
          page: routeNameRef.current,
          reason: newAppState,
        }); /* Start: app is active */
      }

      if (newAppState === 'active' || newAppState === 'background' || newAppState === 'inactive') {
        const prevPage = prevAppStatus.current === 'active' ? routeNameRef.current : `App is ${prevAppStatus.current}`;
        const nextPage = newAppState === 'active' ? routeNameRef.current : `App is ${newAppState}`;

        mp?.track('View', { page: prevPage, nextPage });
        mp?.timeEvent('View'); /* Start: Being on the next page */
        prevAppStatus.current = newAppState;
      }
    };

    const appStateListener = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      /* Cleanup */
      appStateListener.remove();
    };
  }, [mp]);

  const handleIncomingNotification = (notification: any) => {
    let url: string;
    if (Platform.OS === 'ios') {
      url = notification?._data?.data?.jsonBody?.url;
    } else {
      const data = JSON.parse(notification.data['pinpoint.jsonBody']);
      url = data.url;
    }

    if (navigationRef.current && url) {
      handleAppUrl(navigationRef.current, url, mp);
    }

    // Just to make sure there is now unintended stored notification
    clearData(InternalStorageItemKey.UNHANDLED_NOTIFICATION);
  };

  return (
    <NavigationContainer<RootStackRouteParams>
      ref={navigationRef}
      onReady={async () => {
        let handlingNotification = false;
        if (Platform.OS === 'ios') {
          await getInitialNotificationIOS((notification) => {
            if (notification) {
              handlingNotification = true;
              handleIncomingNotification(notification);
            }
          });
        } else {
          const storedNotification: any = await getData(InternalStorageItemKey.UNHANDLED_NOTIFICATION);
          if (storedNotification) {
            handlingNotification = true;
            handleIncomingNotification(storedNotification);
          }
        }

        if (!handlingNotification) {
          Linking.getInitialURL().then((url) => {
            if (navigationRef.current && url) handleAppUrl(navigationRef.current, url, mp);
          });
        }

        routeNameRef.current = navigationRef?.current?.getCurrentRoute()?.name;

        setOnNotificationOpenedListener((notification: any) => {
          handleIncomingNotification(notification);
        });
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name;
        if (previousRouteName !== currentRouteName && currentRouteName) {
          mp?.track('View', { page: previousRouteName, nextPage: currentRouteName });
          mp?.timeEvent('View');
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
      linking={{
        config: {
          screens: {
            [screenNames.Login]: '/',
            [screenNames.ConfirmCode]: '/confirm',
            [screenNames.CompleteRegistration]: '/signup',
            [screenNames.Home]: {
              path: '/Home',
            },
          },
        },
        prefixes: [baseUrl],
        subscribe() {
          const onReceiveURL = ({ url }: { url: string }) => {
            if (navigationRef.current && url) handleAppUrl(navigationRef.current, url, mp);
          };
          const sub = Linking.addEventListener('url', onReceiveURL);

          return () => {
            // Linking.removeEventListener('url', onReceiveURL);
            sub.remove();
          };
        },
      }}
    >
      <PaperProvider theme={getPaperTheme()}>
        <ModalContextProvider>
          <RootStack />
        </ModalContextProvider>
      </PaperProvider>
    </NavigationContainer>
  );
};

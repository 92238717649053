import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { View, Text, TextInput, StyleSheet, Platform, ScrollView } from 'react-native';
import { ActivityIndicator, useTheme } from 'react-native-paper';
import base64 from 'base-64';
import { useI18n } from '../context/I18nContext';

export default function OSSLicense() {
  const { colors } = useTheme();
  const [content, setContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const timer = useRef<number | null>();
  const { I18n } = useI18n();

  useEffect(() => {
    timer.current = window.setTimeout(() => {
      const licenses = require('../../license').default;
      setContent(base64.decode(licenses?.content));
      setLoading(false);
    }, 500);

    return () => {
      if (timer?.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const styles = useMemo(() => {
    return StyleSheet.create({
      licenseTextContainer: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingHorizontal: 24,
        flex: 1,
        color: colors.onSurface,
      },
      loading: { flex: 1, alignItems: 'center', justifyContent: 'center' },
      noContent: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingHorizontal: 24,
        textAlign: 'center',
        color: colors.onSurface,
      },
      scrollViewContentContainer: { flexGrow: 1, backgroundColor: colors.background },
      contentContainer: { flex: 1, backgroundColor: colors.background },
    });
  }, [colors.background, colors.onSurface]);

  /**
    TextInput can't be scrolled on Android without setting it as editable.
    On the other hand, iOS doesn't seem to render long texts in a <Text>
    component. So I decided to different implementations between platforms.
   */
  const renderContent = useCallback(() => {
    if (content) {
      return (
        <>
          {Platform.OS === 'android' ? (
            <Text style={styles.licenseTextContainer}>{content}</Text>
          ) : (
            <TextInput style={styles.licenseTextContainer} multiline editable={false}>
              {content}
            </TextInput>
          )}
        </>
      );
    }
    if (loading) {
      return (
        <View style={styles.loading}>
          <ActivityIndicator animating={true} color={colors.secondary} />
        </View>
      );
    }

    return <Text style={styles.noContent}>{I18n.t('license.noContent')}</Text>;
  }, [I18n, colors.secondary, content, loading, styles.licenseTextContainer, styles.loading, styles.noContent]);

  return (
    <>
      {Platform.OS === 'android' ? (
        <View style={{ flex: 1 }}>
          <ScrollView showsVerticalScrollIndicator contentContainerStyle={styles.scrollViewContentContainer}>
            {renderContent()}
          </ScrollView>
        </View>
      ) : (
        <View style={styles.contentContainer}>{renderContent()}</View>
      )}
    </>
  );
}

import React from 'react';
import { List } from 'react-native-paper';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useI18n } from '../context/I18nContext';
import Images from '../../assets/images';
import Spacer from '../components/Spacer';
import { readVersionNumberAndBuildNumber, getManufacturer, readPlatform, readBundleId } from '../utils/nativeInfoUtils';
import ScrollablePage from '../components/ui/ScrollablePage';

export default function AppFeedback({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const { I18n } = useI18n();

  const appVersion = readVersionNumberAndBuildNumber();
  const platform = readPlatform();
  const hardware = getManufacturer();
  const appId = readBundleId();

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('general.appName'),
        description: I18n.t('help.about.summaryVersion', { version: appVersion }),
        icon: ({ size }) => {
          return (
            <Images.iconAppLogo
              width={size}
              height={size}
              style={{
                width: size,
                height: size,
              }}
            />
          );
        },
      }}
    >
      <Spacer />
      <List.Section>
        <List.Item title={I18n.t('help.about.appIdentifier')} description={appId} />

        <List.Item title={I18n.t('help.about.version')} description={appVersion} />

        <List.Item title={I18n.t('help.about.platform')} description={platform} />

        <List.Item title={I18n.t('help.about.hardware')} description={hardware} />
      </List.Section>
    </ScrollablePage>
  );
}

import React, { FC } from 'react';
import { Animated, StyleSheet, TouchableOpacity, View } from 'react-native';

interface Props {
  numberOfItems: number;
  animatedActiveIndex: Animated.AnimatedValue | Animated.AnimatedInterpolation<string | number>;
  onGoToPage: (page: number) => void;
}

const styles = StyleSheet.create({
  paginationContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
  },
  paginationDots: {
    height: 16,
    margin: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    marginHorizontal: 4,
  },
  activeDotStyle: {
    backgroundColor: '#fff',
  },
  dotStyle: {
    borderWidth: 1,
    borderColor: '#fff',
    backgroundColor: 'transparent',
  },
});

const AnimatedView = Animated.createAnimatedComponent(View);

const PaginateItem: FC<{
  index: number;
  animatedActiveIndex: Animated.AnimatedValue | Animated.AnimatedInterpolation<string | number>;
  onPress: () => void;
}> = ({ onPress, index, animatedActiveIndex }) => {
  const opacity = animatedActiveIndex.interpolate({
    inputRange: [index - 1, index, index + 1],
    outputRange: [0, 1, 0],
    extrapolate: 'clamp',
  });
  return (
    <TouchableOpacity
      hitSlop={{ top: 3, left: 3, right: 3, bottom: 3 }}
      onPress={onPress}
      style={[
        styles.dot,
        styles.dotStyle,
        // i === activeIndex ? styles.activeDotStyle : ,
      ]}
    >
      <AnimatedView style={[styles.activeDotStyle, { flexGrow: 1 }, { opacity }]} />
    </TouchableOpacity>
  );
};

const Pagination: FC<Props> = ({ numberOfItems, animatedActiveIndex, onGoToPage }) => {
  const slide = new Array(numberOfItems).fill(0);
  return (
    <View style={[styles.paginationContainer]}>
      <View style={styles.paginationDots}>
        {slide.length > 1 &&
          slide.map((_, i) => (
            <PaginateItem
              index={i}
              animatedActiveIndex={animatedActiveIndex}
              onPress={() => onGoToPage(i)}
              key={String(i)}
            />
          ))}
      </View>
    </View>
  );
};

export default Pagination;

import I18n from 'i18n-js';
import * as yup from 'yup';

interface I18nObj {
  t: typeof I18n.t;
}

export function getNameValidationSchema(i18nobj: I18nObj) {
  return yup.string().min(2, `${i18nobj.t('validation.min')} 2 ${i18nobj.t('validation.characters')}`);
}

export function signupSchema(i18n: I18nObj) {
  return yup.object().shape({
    firstname: yup
      .string()
      .min(2, i18n.t('validation.min', { min: 2 }))
      .required(
        i18n.t('validation.fieldRequired', {
          fieldName: i18n.t('inputFields.firstname'),
        }),
      ),
    lastname: yup
      .string()
      .min(2, i18n.t('validation.min', { min: 2 }))
      .required(
        i18n.t('validation.fieldRequired', {
          fieldName: i18n.t('inputFields.lastname'),
        }),
      ),
    organization: yup.string(),
  });
}

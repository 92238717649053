import Style from 'maplibre-gl';

type layerTypes =
  | Style.LineLayer
  | Style.FillLayer
  | Style.SymbolLayer
  | Style.FillExtrusionLayer
  | Style.BackgroundLayer;

type MapThemeFill = { fill: Style.FillPaint };
type MapThemeLine = { line: Style.LinePaint };
type MapThemeLabelPoint = { labelPoint: { layout: Style.SymbolLayout; paint: Style.SymbolPaint } };
type MapThemeLabelAlong = { labelAlong: { layout: Style.SymbolLayout; paint: Style.SymbolPaint } };

type MapThemeHatchedLine = {
  line: Style.LinePaint;
  hatching: Style.LinePaint;
};

type MapThemeCasedLine = {
  line: Style.LinePaint;
  casing: Style.LinePaint;
};

type MapThemeWay = {
  way: MapThemeCasedLine;
  bridge: MapThemeCasedLine;
  tunnel: MapThemeCasedLine;
};

type MapThemeRail = {
  way: MapThemeHatchedLine;
  tunnel: MapThemeHatchedLine;
  bridge: MapThemeHatchedLine;
};

export type BaseMapFullTheme = {
  land: {
    default: Style.BackgroundPaint;
    residential: MapThemeFill;
    wood: MapThemeFill;
    grass: MapThemeFill;
    ice: MapThemeFill;
    cemetery: MapThemeFill;
    hospital: MapThemeFill;
    school: MapThemeFill;
    sand: MapThemeFill;
    park: MapThemeFill;
  };

  water: {
    default: MapThemeFill & MapThemeLabelAlong & MapThemeLabelPoint;
    tunnel: MapThemeLine;
    river: MapThemeLine;
    way: MapThemeLine;
  };

  aeroway: MapThemeFill & {
    runway: MapThemeLine;
    taxiway: MapThemeLine;
  };

  pedestrian: {
    way: MapThemeLine;
    tunnel: MapThemeLine;
    bridge: MapThemeCasedLine;
  };

  rail: {
    major: MapThemeRail;
    transit: MapThemeRail;
  };

  road: {
    default: MapThemeLabelAlong &
      MapThemeLabelPoint & {
        fill: Style.FillPaint;
        oneway: {
          layout: Style.SymbolLayout;
        };
        otherway: {
          layout: Style.SymbolLayout;
        };
      };

    motorway: MapThemeWay;
    primary: MapThemeWay;
    secondary: MapThemeWay;
    motorwayLink: MapThemeWay;
    otherLink: MapThemeWay;
    street: MapThemeWay;
    track: MapThemeWay;
  };

  building: MapThemeFill & {
    fill3d: Style.FillExtrusionPaint;
  };

  boundaries: {
    level3: MapThemeLine;
    level2: {
      zoomedOut: MapThemeLine;
      zoomedIn: MapThemeLine;
    };
  };

  poi: {
    rank20: MapThemeLabelPoint;
    rank7_20: MapThemeLabelPoint;
    rank1_6: MapThemeLabelPoint;
    transit: MapThemeLabelPoint;
  };

  place: {
    default: MapThemeLabelPoint;
    village: MapThemeLabelPoint;
    town: MapThemeLabelPoint;
    city: MapThemeLabelPoint;
    state: MapThemeLabelPoint;
    country: {
      rank1: MapThemeLabelPoint;
      rank2: MapThemeLabelPoint;
      rank3: MapThemeLabelPoint;
    };
    continent: MapThemeLabelPoint;
  };
};

export function getBaseLayers(theme: BaseMapFullTheme): layerTypes[] {
  const source = 'openmaptiles';
  return [
    {
      id: 'background',
      type: 'background',
      paint: theme.land.default,
    },
    {
      id: 'park',
      type: 'fill',
      minzoom: 7,
      source,
      'source-layer': 'park',
      paint: theme.land.park.fill,
    },
    {
      id: 'landuse_residential',
      type: 'fill',
      source,
      'source-layer': 'landuse',
      maxzoom: 8,
      filter: ['==', 'class', 'residential'],
      paint: theme.land.residential.fill,
    },
    {
      id: 'landcover_wood',
      type: 'fill',
      source,
      'source-layer': 'landcover',
      filter: ['all', ['==', 'class', 'wood']],
      paint: theme.land.wood.fill,
    },
    {
      id: 'landcover_grass',
      type: 'fill',
      source,
      'source-layer': 'landcover',
      filter: ['all', ['==', 'class', 'grass']],
      paint: theme.land.grass.fill,
    },
    {
      id: 'landcover_ice',
      type: 'fill',
      source,
      'source-layer': 'landcover',
      filter: ['all', ['==', 'class', 'ice']],
      paint: theme.land.ice.fill,
    },
    {
      id: 'landuse_cemetery',
      type: 'fill',
      source,
      'source-layer': 'landuse',
      filter: ['==', 'class', 'cemetery'],
      paint: theme.land.cemetery.fill,
    },
    {
      id: 'landuse_hospital',
      type: 'fill',
      source,
      'source-layer': 'landuse',
      filter: ['==', 'class', 'hospital'],
      paint: theme.land.hospital.fill,
    },
    {
      id: 'landuse_school',
      type: 'fill',
      source,
      'source-layer': 'landuse',
      filter: ['==', 'class', 'school'],
      paint: theme.land.school.fill,
    },
    {
      id: 'waterway_tunnel',
      type: 'line',
      source,
      'source-layer': 'waterway',
      filter: ['all', ['==', 'brunnel', 'tunnel']],
      paint: theme.water.tunnel.line,
    },
    {
      id: 'waterway_river',
      type: 'line',
      source,
      'source-layer': 'waterway',
      filter: ['all', ['==', 'class', 'river'], ['!=', 'brunnel', 'tunnel']],
      layout: { 'line-cap': 'round' },
      paint: theme.water.river.line,
    },
    {
      id: 'waterway_other',
      type: 'line',
      source,
      'source-layer': 'waterway',
      filter: ['all', ['!=', 'class', 'river'], ['!=', 'brunnel', 'tunnel']],
      layout: { 'line-cap': 'round' },
      paint: theme.water.way.line,
    },
    {
      id: 'water',
      type: 'fill',
      source,
      'source-layer': 'water',
      filter: ['all', ['!=', 'brunnel', 'tunnel']],
      paint: theme.water.default.fill,
    },
    {
      id: 'landcover_sand',
      type: 'fill',
      source,
      'source-layer': 'landcover',
      filter: ['all', ['==', 'class', 'sand']],
      paint: theme.land.sand.fill,
    },
    {
      id: 'aeroway_fill',
      type: 'fill',
      source,
      'source-layer': 'aeroway',
      minzoom: 11,
      filter: ['==', '$type', 'Polygon'],
      paint: theme.aeroway.fill,
    },
    {
      id: 'aeroway_runway',
      type: 'line',
      source,
      'source-layer': 'aeroway',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'runway']],
      paint: theme.aeroway.runway.line,
    },
    {
      id: 'aeroway_taxiway',
      type: 'line',
      source,
      'source-layer': 'aeroway',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'taxiway']],
      paint: theme.aeroway.taxiway.line,
    },
    {
      id: 'tunnel_motorway_link_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'ramp', 1], ['==', 'brunnel', 'tunnel']],
      layout: { 'line-join': 'round' },
      paint: theme.road.motorwayLink.way.casing,
    },
    {
      id: 'tunnel_service_track_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'service', 'track']],
      layout: { 'line-join': 'round' },
      paint: theme.road.track.tunnel.casing,
    },
    {
      id: 'tunnel_link_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'ramp', 1], ['==', 'brunnel', 'tunnel']],
      layout: { 'line-join': 'round' },
      paint: theme.road.otherLink.way.casing,
    },
    {
      id: 'tunnel_street_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'street', 'street_limited']],
      layout: { 'line-join': 'round' },
      paint: theme.road.street.tunnel.casing,
    },
    {
      id: 'tunnel_secondary_tertiary_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      layout: { 'line-join': 'round' },
      paint: theme.road.secondary.tunnel.casing,
    },
    {
      id: 'tunnel_trunk_primary_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'primary', 'trunk']],
      layout: { 'line-join': 'round' },
      paint: theme.road.primary.tunnel.casing,
    },
    {
      id: 'tunnel_motorway_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'motorway'], ['!=', 'ramp', 1], ['==', 'brunnel', 'tunnel']],
      layout: { 'line-join': 'round' },
      paint: theme.road.motorway.tunnel.casing,
    },
    {
      id: 'tunnel_path_pedestrian',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'brunnel', 'tunnel'],
        ['in', 'class', 'path', 'pedestrian'],
      ],
      layout: { 'line-join': 'round', 'line-cap': 'round' },
      paint: theme.pedestrian.tunnel.line,
    },
    {
      id: 'tunnel_motorway_link',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'ramp', 1], ['==', 'brunnel', 'tunnel']],
      layout: { 'line-join': 'round' },
      paint: theme.road.motorwayLink.tunnel.casing,
    },
    {
      id: 'tunnel_service_track',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'service', 'track']],
      layout: { 'line-join': 'round' },
      paint: theme.road.track.tunnel.line,
    },
    {
      id: 'tunnel_link',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'ramp', 1], ['==', 'brunnel', 'tunnel']],
      layout: { 'line-join': 'round' },
      paint: theme.road.otherLink.tunnel.line,
    },
    {
      id: 'tunnel_minor',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'minor']],
      layout: { 'line-join': 'round' },
      paint: theme.road.street.tunnel.line,
    },
    {
      id: 'tunnel_secondary_tertiary',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      layout: { 'line-join': 'round' },
      paint: theme.road.secondary.tunnel.line,
    },
    {
      id: 'tunnel_trunk_primary',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'primary', 'trunk']],
      layout: { 'line-join': 'round' },
      paint: theme.road.primary.tunnel.line,
    },
    {
      id: 'tunnel_motorway',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'motorway'], ['!=', 'ramp', 1], ['==', 'brunnel', 'tunnel']],
      layout: { 'line-join': 'round' },
      paint: theme.road.motorway.tunnel.line,
    },
    {
      id: 'tunnel_major_rail',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'rail']],
      paint: theme.rail.major.tunnel.line,
    },
    {
      id: 'tunnel_major_rail_hatching',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'rail']],
      paint: theme.rail.major.tunnel.hatching,
    },
    {
      id: 'tunnel_transit_rail',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'transit']],
      paint: theme.rail.transit.tunnel.line,
    },
    {
      id: 'tunnel_transit_rail_hatching',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'transit']],
      paint: theme.rail.transit.tunnel.hatching,
    },
    {
      id: 'road_area_pattern',
      type: 'fill',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'Polygon']],
      paint: theme.road.default.fill,
    },
    {
      id: 'road_motorway_link_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      minzoom: 12,
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway'], ['==', 'ramp', 1]],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.motorwayLink.way.casing,
    },
    {
      id: 'road_service_track_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'service', 'track']],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.track.way.casing,
    },
    {
      id: 'road_link_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      minzoom: 13,
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['!in', 'class', 'pedestrian', 'path', 'track', 'service', 'motorway'],
        ['==', 'ramp', 1],
      ],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.otherLink.way.casing,
    },
    {
      id: 'road_minor_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['in', 'class', 'minor'],
        ['!=', 'ramp', 1],
      ],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.street.way.casing,
    },
    {
      id: 'road_secondary_tertiary_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['in', 'class', 'secondary', 'tertiary'],
        ['!=', 'ramp', 1],
      ],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.secondary.way.casing,
    },
    {
      id: 'road_trunk_primary_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'primary', 'trunk']],
      layout: { 'line-join': 'round' },
      paint: theme.road.primary.way.casing,
    },
    {
      id: 'road_motorway_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      minzoom: 5,
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway'], ['!=', 'ramp', 1]],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.motorway.way.casing,
    },
    {
      id: 'road_path_pedestrian',
      type: 'line',
      source,
      'source-layer': 'transportation',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['in', 'class', 'path', 'pedestrian'],
      ],
      layout: { 'line-join': 'round', 'line-cap': 'round' },
      paint: theme.pedestrian.way.line,
    },
    {
      id: 'road_motorway_link',
      type: 'line',
      source,
      'source-layer': 'transportation',
      minzoom: 12,
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway'], ['==', 'ramp', 1]],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.motorwayLink.way.line,
    },
    {
      id: 'road_service_track',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'service', 'track']],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.track.way.line,
    },
    {
      id: 'road_link',
      type: 'line',
      source,
      'source-layer': 'transportation',
      minzoom: 13,
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['==', 'ramp', 1],
        ['!in', 'class', 'pedestrian', 'path', 'track', 'service', 'motorway'],
      ],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.otherLink.way.line,
    },
    {
      id: 'road_minor',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'LineString'], ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'minor']],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.street.way.line,
    },
    {
      id: 'road_secondary_tertiary',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.secondary.way.line,
    },
    {
      id: 'road_trunk_primary',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'primary', 'trunk']],
      layout: { 'line-join': 'round' },
      paint: theme.road.primary.way.line,
    },
    {
      id: 'road_motorway',
      type: 'line',
      source,
      'source-layer': 'transportation',
      minzoom: 5,
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway'], ['!=', 'ramp', 1]],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.road.motorway.way.line,
    },
    {
      id: 'road_major_rail',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'rail']],
      paint: theme.rail.major.way.line,
    },
    {
      id: 'road_major_rail_hatching',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'rail']],
      paint: theme.rail.major.way.hatching,
    },
    {
      id: 'road_transit_rail',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'transit']],
      paint: theme.rail.transit.way.line,
    },
    {
      id: 'road_transit_rail_hatching',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'transit']],
      paint: theme.rail.transit.way.hatching,
    },
    {
      id: 'road_one_way_arrow',
      type: 'symbol',
      source,
      'source-layer': 'transportation',
      minzoom: 15,
      filter: ['==', 'oneway', 1],
      layout: theme.road.default.oneway.layout,
    },
    {
      id: 'road_one_way_arrow_opposite',
      type: 'symbol',
      source,
      'source-layer': 'transportation',
      minzoom: 15,
      filter: ['==', 'oneway', -1],
      layout: theme.road.default.otherway.layout,
    },
    {
      id: 'bridge_motorway_link_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'ramp', 1], ['==', 'brunnel', 'bridge']],
      layout: { 'line-join': 'round' },
      paint: theme.road.motorwayLink.bridge.casing,
    },
    {
      id: 'bridge_service_track_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'service', 'track']],
      layout: { 'line-join': 'round' },
      paint: theme.road.track.bridge.casing,
    },
    {
      id: 'bridge_link_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'link'], ['==', 'brunnel', 'bridge']],
      layout: { 'line-join': 'round' },
      paint: theme.road.otherLink.bridge.casing,
    },
    {
      id: 'bridge_street_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'street', 'street_limited']],
      layout: { 'line-join': 'round' },
      paint: theme.road.street.bridge.casing,
    },
    {
      id: 'bridge_path_pedestrian_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'brunnel', 'bridge'],
        ['in', 'class', 'path', 'pedestrian'],
      ],
      paint: theme.pedestrian.bridge.casing,
    },
    {
      id: 'bridge_secondary_tertiary_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'secondary', 'tertiary']],
      layout: { 'line-join': 'round' },
      paint: theme.road.secondary.bridge.casing,
    },
    {
      id: 'bridge_trunk_primary_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'primary', 'trunk']],
      layout: { 'line-join': 'round' },
      paint: theme.road.primary.bridge.casing,
    },
    {
      id: 'bridge_motorway_casing',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'motorway'], ['!=', 'ramp', 1], ['==', 'brunnel', 'bridge']],
      layout: { 'line-join': 'round' },
      paint: theme.road.motorway.bridge.casing,
    },
    {
      id: 'bridge_path_pedestrian',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'brunnel', 'bridge'],
        ['in', 'class', 'path', 'pedestrian'],
      ],
      paint: theme.pedestrian.bridge.line,
    },
    {
      id: 'bridge_motorway_link',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'ramp', 1], ['==', 'brunnel', 'bridge']],
      layout: { 'line-join': 'round' },
      paint: theme.road.motorwayLink.bridge.line,
    },
    {
      id: 'bridge_service_track',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'service', 'track']],
      layout: { 'line-join': 'round' },
      paint: theme.road.track.bridge.line,
    },
    {
      id: 'bridge_link',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'link'], ['==', 'brunnel', 'bridge']],
      layout: { 'line-join': 'round' },
      paint: theme.road.otherLink.bridge.line,
    },
    {
      id: 'bridge_street',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'minor']],
      layout: { 'line-join': 'round' },
      paint: theme.road.street.bridge.line,
    },
    {
      id: 'bridge_secondary_tertiary',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'secondary', 'tertiary']],
      layout: { 'line-join': 'round' },
      paint: theme.road.secondary.bridge.line,
    },
    {
      id: 'bridge_trunk_primary',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'primary', 'trunk']],
      layout: { 'line-join': 'round' },
      paint: theme.road.primary.bridge.line,
    },
    {
      id: 'bridge_motorway',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'motorway'], ['!=', 'ramp', 1], ['==', 'brunnel', 'bridge']],
      layout: { 'line-join': 'round' },
      paint: theme.road.motorway.bridge.line,
    },
    {
      id: 'bridge_major_rail',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'rail'], ['==', 'brunnel', 'bridge']],
      paint: theme.rail.major.bridge.line,
    },
    {
      id: 'bridge_major_rail_hatching',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'rail'], ['==', 'brunnel', 'bridge']],
      paint: theme.rail.major.bridge.hatching,
    },
    {
      id: 'bridge_transit_rail',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'transit'], ['==', 'brunnel', 'bridge']],
      paint: theme.rail.transit.way.line,
    },
    {
      id: 'bridge_transit_rail_hatching',
      type: 'line',
      source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'class', 'transit'], ['==', 'brunnel', 'bridge']],
      paint: theme.rail.transit.way.hatching,
    },
    {
      id: 'building',
      type: 'fill',
      source,
      'source-layer': 'building',
      minzoom: 13,
      paint: theme.building.fill,
    },
    {
      id: 'building-3d',
      type: 'fill-extrusion',
      source,
      'source-layer': 'building',
      minzoom: 14,
      layout: { visibility: 'none' },
      paint: theme.building.fill3d,
    },
    {
      id: 'boundary_3',
      type: 'line',
      source,
      'source-layer': 'boundary',
      minzoom: 8,
      filter: ['all', ['in', 'admin_level', 3, 4]],
      layout: { 'line-join': 'round' },
      paint: theme.boundaries.level3.line,
    },
    {
      id: 'boundary_2_z0-4',
      type: 'line',
      source,
      'source-layer': 'boundary',
      maxzoom: 5,
      filter: ['all', ['==', 'admin_level', 2], ['!has', 'claimed_by']],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.boundaries.level2.zoomedOut.line,
    },
    {
      id: 'boundary_2_z5-',
      type: 'line',
      source,
      'source-layer': 'boundary',
      minzoom: 5,
      filter: ['all', ['==', 'admin_level', 2]],
      layout: { 'line-cap': 'round', 'line-join': 'round' },
      paint: theme.boundaries.level2.zoomedIn.line,
    },
    {
      id: 'water_name_line',
      type: 'symbol',
      source,
      'source-layer': 'waterway',
      filter: ['all', ['==', '$type', 'LineString']],
      layout: theme.water.default.labelAlong.layout,
      paint: theme.water.default.labelPoint.paint,
    },
    {
      id: 'water_name_point',
      type: 'symbol',
      source,
      'source-layer': 'water_name',
      filter: ['==', '$type', 'Point'],
      layout: theme.water.default.labelPoint.layout,
      paint: theme.water.default.labelPoint.paint,
    },
    {
      id: 'poi_z16',
      type: 'symbol',
      source,
      'source-layer': 'poi',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'Point'], ['>=', 'rank', 20]],
      layout: theme.poi.rank20.labelPoint.layout,
      paint: theme.poi.rank20.labelPoint.paint,
    },
    {
      id: 'poi_z15',
      type: 'symbol',
      source,
      'source-layer': 'poi',
      minzoom: 15,
      filter: ['all', ['==', '$type', 'Point'], ['>=', 'rank', 7], ['<', 'rank', 20]],
      layout: theme.poi.rank7_20.labelPoint.layout,
      paint: theme.poi.rank7_20.labelPoint.paint,
    },
    {
      id: 'poi_z14',
      type: 'symbol',
      source,
      'source-layer': 'poi',
      minzoom: 14,
      filter: ['all', ['==', '$type', 'Point'], ['>=', 'rank', 1], ['<', 'rank', 7]],
      layout: theme.poi.rank1_6.labelPoint.layout,
      paint: theme.poi.rank1_6.labelPoint.paint,
    },
    {
      id: 'poi_transit',
      type: 'symbol',
      source,
      'source-layer': 'poi',
      filter: ['all', ['in', 'class', 'bus', 'rail', 'airport']],
      layout: theme.poi.transit.labelPoint.layout,
      paint: theme.poi.transit.labelPoint.paint,
    },
    {
      id: 'road_label',
      type: 'symbol',
      source,
      'source-layer': 'transportation_name',
      filter: ['all'],
      layout: theme.road.default.labelAlong.layout,
      paint: theme.road.default.labelAlong.paint,
    },
    {
      id: 'road_shield',
      type: 'symbol',
      source,
      'source-layer': 'transportation_name',
      minzoom: 7,
      filter: ['all', ['<=', 'ref_length', 6]],
      layout: theme.road.default.labelPoint.layout,
      paint: theme.road.default.labelPoint.paint,
    },
    {
      id: 'place_other',
      type: 'symbol',
      source,
      'source-layer': 'place',
      filter: ['all', ['in', 'class', 'hamlet', 'island', 'islet', 'neighbourhood', 'suburb']],
      layout: theme.place.default.labelPoint.layout,
      paint: theme.place.default.labelPoint.paint,
    },
    {
      id: 'place_village',
      type: 'symbol',
      source,
      'source-layer': 'place',
      filter: ['all', ['==', 'class', 'village']],
      layout: theme.place.village.labelPoint.layout,
      paint: theme.place.village.labelPoint.paint,
    },
    {
      id: 'place_town',
      type: 'symbol',
      source,
      'source-layer': 'place',
      filter: ['all', ['==', 'class', 'town']],
      layout: theme.place.town.labelPoint.layout,
      paint: theme.place.town.labelPoint.paint,
    },
    {
      id: 'place_city',
      type: 'symbol',
      source,
      'source-layer': 'place',
      minzoom: 5,
      filter: ['all', ['==', 'class', 'city']],
      layout: theme.place.city.labelPoint.layout,
      paint: theme.place.city.labelPoint.paint,
    },
    {
      id: 'state',
      type: 'symbol',
      source,
      'source-layer': 'place',
      maxzoom: 6,
      filter: ['all', ['==', 'class', 'state']],
      layout: theme.place.state.labelPoint.layout,
      paint: theme.place.state.labelPoint.paint,
    },
    {
      id: 'country_3',
      type: 'symbol',
      source,
      'source-layer': 'place',
      filter: ['all', ['>=', 'rank', 3], ['==', 'class', 'country']],
      layout: theme.place.country.rank3.labelPoint.layout,
      paint: theme.place.country.rank3.labelPoint.paint,
    },
    {
      id: 'country_2',
      type: 'symbol',
      source,
      'source-layer': 'place',
      filter: ['all', ['==', 'rank', 2], ['==', 'class', 'country']],
      layout: theme.place.country.rank2.labelPoint.layout,
      paint: theme.place.country.rank2.labelPoint.paint,
    },
    {
      id: 'country_1',
      type: 'symbol',
      source,
      'source-layer': 'place',
      filter: ['all', ['==', 'rank', 1], ['==', 'class', 'country']],
      layout: theme.place.country.rank1.labelPoint.layout,
      paint: theme.place.country.rank1.labelPoint.paint,
    },
    {
      id: 'continent',
      type: 'symbol',
      source,
      'source-layer': 'place',
      maxzoom: 1,
      filter: ['all', ['==', 'class', 'continent']],
      layout: theme.place.continent.labelPoint.layout,
      paint: theme.place.continent.labelPoint.paint,
    },
  ];
}

import React, { useEffect, useState } from 'react';
import { Appbar, Button, TextInput, useTheme } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';
import { useI18n } from '../context/I18nContext';
import GradientButton from '../components/GradientButton';
import { screenNames } from '../navigators/screenNames';
import ScrollablePage from '../components/ui/ScrollablePage';
import Container from '../components/Container';
import Spacer from '../components/Spacer';
import { RootStackRouteParams } from '../navigators/RootNavigator';
import { UiIcon } from '../components/SimpleListIcon';

export type EnterInvitationCodeRouteParams = undefined | { invitationCode: string };
type Props = StackScreenProps<RootStackRouteParams, 'EnterInvitationCode'>;

export const EnterInvitationCodeScreen: React.FC<Props> = ({ navigation, route }) => {
  const [invitationCode, setInvitationCode] = useState('');
  const { I18n } = useI18n();
  const { colors } = useTheme();

  useEffect(() => {
    if (route.params?.invitationCode) {
      setInvitationCode(route.params?.invitationCode);
    }
  }, [route.params?.invitationCode]);

  useEffect(() => {
    navigation.setOptions({
      title: I18n.t('enterInvitationCode.header'),
      headerLeft: () => (
        <Appbar.Action
          animated={false}
          accessibilityRole={'button'}
          icon={({ size }) => {
            return (
              <UiIcon name={'arrow-left'} size={size} accessible accessibilityRole="button" color={colors.secondary} />
            );
          }}
          onPress={() => {
            navigation.navigate(screenNames.Login, { invitationCode });
          }}
        />
      ),
    });
  }, [I18n, navigation, invitationCode, colors.secondary]);
  return (
    <ScrollablePage
      summary={{
        title: I18n.t('enterInvitationCode.title'),
        description: I18n.t('enterInvitationCode.subtitle'),
        icon: 'star',
      }}
    >
      <Container>
        <TextInput
          autoFocus={true}
          value={invitationCode}
          onChangeText={(code) => setInvitationCode(code.toLocaleUpperCase())}
          label={I18n.t('enterInvitationCode.inputLabel')}
          autoCapitalize={'characters'}
          mode={'outlined'}
        />
        <Spacer type={'section'} />
        {/* 
      </Container>
      <Container style={{justifyContent: "flex-end"}}>
      */}
        <GradientButton
          onPress={() => {
            navigation.navigate(screenNames.Login, { invitationCode });
          }}
        >
          {I18n.t('enterInvitationCode.applyCodeBtn')}
        </GradientButton>
        <Button
          style={{ alignSelf: 'flex-end', marginTop: 15 }}
          onPress={() => {
            navigation.navigate(screenNames.Login, { invitationCode: '' });
          }}
          color={colors.primary}
        >
          {I18n.t('enterInvitationCode.dismissCodeBtn')}
        </Button>
      </Container>
    </ScrollablePage>
  );
};

import React, { useLayoutEffect, useState } from 'react';
import { Linking } from 'react-native';
import { IconButton, List, Menu, useTheme } from 'react-native-paper';
import { CompositeScreenProps } from '@react-navigation/native';
import { useMutation, useQueryClient } from 'react-query';
import { StackScreenProps } from '@react-navigation/stack';
import { useI18n } from '../../context/I18nContext';
import { getStripePayment, removePaymentMethod, useGetStripePaymentQuery } from '../paymentApis';
import { useModal } from '../../context/ModalContext';
import TextModal, { ModalAlertType } from '../../components/modal/TextModal';
import { PENDING_PAYMENT } from '../../utils/errorCodes';
import { useActivePayment } from '../MapViewActivePaymentContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { InformationStackRouteParams } from '../../navigators/InformationStackNavigator';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { PaymentMethodOption } from '../../types/misc.types';
import { listIconGenerator, uiIconSourceGenerator } from '../../components/SimpleListIcon';
import { ListItemRow } from '../../components/ListItemRow';

const RightMenu = ({ onPressRemove }: { onPressRemove: () => void }) => {
  const { colors } = useTheme();
  const [visible, setVisible] = useState(false);
  const { I18n } = useI18n();

  const handleClose = () => {
    setVisible(false);
  };
  return (
    <Menu
      visible={visible}
      onDismiss={handleClose}
      anchor={
        <IconButton
          onPress={() => setVisible(true)}
          icon={uiIconSourceGenerator({ name: 'options-vertical', color: colors.onBackground, size: 22 })}
        />
      }
    >
      <Menu.Item onPress={onPressRemove} title={I18n.t('payment.remove')} />
    </Menu>
  );
};

export type CardPaymentDetailRouteParams = {
  cardId: string;
};

type Props = CompositeScreenProps<StackScreenProps<InformationStackRouteParams, 'CardDetail'>, AppDrawerScreenProp>;

const CardPaymentDetail: React.FC<Props> = ({ navigation, route }) => {
  const { I18n, formatDate, formatCurrency } = useI18n();
  const modal = useModal();
  const cards = useGetStripePaymentQuery();
  const queryClient = useQueryClient();
  const { activePaymentMethod, setActivePaymentMethod } = useActivePayment();

  const removePaymentMethodMutation = useMutation(
    async (input: Parameters<typeof removePaymentMethod>) => {
      await removePaymentMethod(...input);
    },
    {
      onSuccess: () => {
        // TODO: On ios simulator payment method didn't vanish.
        //  Do we need some timoeout here?
        queryClient.invalidateQueries(getStripePayment.id);
        navigation.goBack();
      },
      onError: (error: any) => {
        if (error?.errors?.[0]?.message === PENDING_PAYMENT) {
          modal.openModal({
            content: (
              <TextModal
                type={ModalAlertType.ERROR}
                title={I18n.t('payment.failToRemoveTitle')}
                description={I18n.t('payment.failToRemovePaymentPending')}
                buttons={[
                  {
                    title: I18n.t('general.dismiss'),
                    onPress: modal.closeModal,
                  },
                ]}
              />
            ),
          });
        } else {
          modal.openModal({
            content: (
              <TextModal
                type={ModalAlertType.ERROR}
                title={I18n.t('payment.failToRemoveTitle')}
                description={I18n.t('payment.failToRemoveUnknown')}
                buttons={[
                  {
                    title: I18n.t('general.dismiss'),
                    onPress: modal.closeModal,
                  },
                ]}
              />
            ),
          });
        }
      },
    },
  );
  const currentCard = cards.data?.data?.getStripePayment?.find((paymentMethod) => {
    return paymentMethod?.id === route?.params?.cardId;
  });

  function setAsDefault() {
    if (currentCard) {
      setActivePaymentMethod({
        paymentMethodType: PaymentMethodOption.CARD,
        stripeCard: currentCard,
      });
    }
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      title: currentCard?.brand?.toUpperCase(),
    });
  }, [navigation, currentCard?.brand]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <RightMenu
          onPressRemove={() => {
            if (currentCard?.id) {
              removePaymentMethodMutation.mutate([{ card_id: currentCard?.id }]);
            }
          }}
        />
      ),
    });
  }, [navigation, currentCard?.id, removePaymentMethodMutation]);

  const expiringText = `${I18n.t('payment.expiring', {
    date: `${currentCard?.exp_month}/${currentCard?.exp_year}`,
  })}.`;

  const isCurrentCardDefault =
    activePaymentMethod &&
    activePaymentMethod.paymentMethodType === PaymentMethodOption.CARD &&
    activePaymentMethod.stripeCard.id === currentCard?.id;

  const defaultText = isCurrentCardDefault ? I18n.t('payment.isDefaultPaymentMethod') : '';
  return (
    <ScrollablePage
      summary={{
        title: currentCard?.brand?.toUpperCase() ?? '',
        description: `${expiringText}\n${defaultText}`,
        icon: 'credit-card',
        button: {
          label: I18n.t('payment.setDefaultButton'),
          onPress: setAsDefault,
          disabled: isCurrentCardDefault || !currentCard || cards.isFetching,
        },
      }}
    >
      <List.Subheader>{I18n.t('payment.recentTransaction')}</List.Subheader>
      {(currentCard?.transactions ?? []).map((item) => (
        <ListItemRow
          key={item?.reservation?.id}
          title={formatCurrency(item?.amount, item?.currency)}
          description={formatDate(
            new Date(item?.reservation?.from!),
            'timeAndDate',
            item?.reservation?.building?.timezone,
          )}
          right={listIconGenerator('link')}
          onPress={() => {
            if (item?.reservation?.receiptUrl) {
              Linking.openURL(item?.reservation?.receiptUrl);
            }
          }}
          leftIcon={'doc'}
        />
      ))}
    </ScrollablePage>
  );
};

export default CardPaymentDetail;

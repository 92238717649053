import React from 'react';
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { TouchableRipple, useTheme } from 'react-native-paper';
import Spacer from './Spacer';
import Typography from './Typography';
import { UiIcon } from './SimpleListIcon';

type ArrowButtonProps = {
  direction: 'left' | 'right';
  onPress: () => void;
  style?: ViewStyle;
  labelStyle?: TextStyle;
  disabled?: boolean;
  iconColor?: string;
  size?: number;
};

// TODO: Accessibility

export function ArrowButton({ direction, onPress, style, labelStyle, disabled, iconColor, size }: ArrowButtonProps) {
  const { colors, dark } = useTheme();
  const iconSize = size ? size / 2 : labelStyle?.fontSize ?? 16;
  const color = (labelStyle?.color as string) ?? (disabled ? colors.onSurfaceDisabled : colors.primary);
  return (
    <TouchableRipple
      onPress={onPress}
      accessibilityRole="button"
      disabled={disabled}
      style={[
        styles.arrowButton,
        /* borderColor for button: https://github.com/callstack/react-native-paper/blob/main/src/components/Button.tsx#L203 */
        { borderColor: dark ? 'rgba(255,255,255,0.29)' : 'rgba(0,0,0,0.29)' },
        style,
        size
          ? {
              height: size,
              width: size,
            }
          : null,
      ]}
    >
      <UiIcon
        color={iconColor || color}
        style={[{ width: iconSize, height: iconSize }, labelStyle]}
        size={iconSize}
        name={direction === 'left' ? 'arrow-left' : 'arrow-right'}
      />
    </TouchableRipple>
  );
}

interface LeftRightPickerProps {
  text: string;
  onLeft: () => void;
  onRight: () => void;
  leftDisabled?: boolean;
  rightDisabled?: boolean;
}

export default function LeftRightPicker({ text, onLeft, onRight, leftDisabled, rightDisabled }: LeftRightPickerProps) {
  const { colors } = useTheme();

  return (
    <View style={{ flex: 1 }}>
      <Typography variant={'body1'} color={colors.onBackground}>
        {text}
      </Typography>

      <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
        <ArrowButton direction="left" onPress={onLeft} disabled={leftDisabled} />
        <Spacer type={'column'} />
        <ArrowButton direction="right" onPress={onRight} disabled={rightDisabled} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  arrowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 36,
    height: 36,
    borderWidth: 1,
    borderRadius: 4,
  },
});

import { useQuery } from 'react-query';
import { TimeRangeInput, QueryGetBuildingsByRegionIdArgs, BuildingBuildingStatusArgs } from '../types/appsync-types';
import { appsyncGraphql, publicAppsyncGraphql } from './appsyncHelper';

export function useCountryRegionsQuery(isAuthenticated: boolean) {
  return useQuery([useCountryRegionsQuery.id], async () => {
    const query = /* GraphQL */ `
      query GetCountriesQuery {
        getCountries {
          id
          name
          translationId
          regions {
            id
            name
            translationId
          }
        }
      }
    `;
    return isAuthenticated
      ? appsyncGraphql<'getCountries'>(query, {})
      : publicAppsyncGraphql<'getCountries'>(query, {});
  });
}
useCountryRegionsQuery.id = 'useCountryRegionsQuery';

export function useBuildingsForRegion(isAuthenticated: boolean, regionId: string, timeInput: TimeRangeInput) {
  return useQuery([useBuildingsForRegion.id, regionId, ...Object.values(timeInput)], async () => {
    const variables: QueryGetBuildingsByRegionIdArgs & BuildingBuildingStatusArgs = {
      id: regionId,
      input: timeInput,
    };
    const query = /* GraphQL */ `
      query GetBuildingsByRegionIdQuery($id: String!, $input: TimeRangeInput) {
        getBuildingsByRegionId(id: $id) {
          lat
          lon
          buildingRef
          title
          buildingStatus(input: $input) {
            bookable
            total
          }
        }
      }
    `;
    return isAuthenticated
      ? appsyncGraphql<'getBuildingsByRegionId'>(query, variables)
      : publicAppsyncGraphql<'getBuildingsByRegionId'>(query, variables);
  });
}
useBuildingsForRegion.id = 'useBuildingsForRegion';

import React from 'react';
import { List, ListItemProps } from 'react-native-paper';
import { listIconGenerator, UiDefaultIconName, UiIconProps } from './SimpleListIcon';

export type ListItemRowProps = {
  leftIcon?: UiIconProps | UiDefaultIconName;
  type?: 'navigation' | 'default';
} & ListItemProps;

export const ListItemRow: React.FC<ListItemRowProps> = ({ type = 'default', ...props }) => {
  return (
    <List.Item
      title={props.title}
      description={props.description}
      left={props.leftIcon ? listIconGenerator(props.leftIcon) : props.left}
      onPress={props.onPress}
      right={type === 'navigation' ? listIconGenerator('arrow-right') : props.right}
      style={[{ paddingHorizontal: props.left || props.leftIcon ? 16 : 0 }, props.style]}
      accessibilityRole="button"
      titleStyle={props.titleStyle}
    />
  );
};

import {
  addDays,
  addMilliseconds,
  addMinutes,
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInWeeks,
  subDays,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import I18n from 'i18n-js';
import { getTimeZone } from 'react-native-localize';
import { enGB, registerTranslation } from 'react-native-paper-dates';

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d as unknown as number);
}

export function formatDate(
  MyI18n: typeof I18n,
  selectedLocale: Locale,
  date: Date,
  format:
    | 'time'
    | 'time-no-tz'
    | 'date'
    | 'date-no-tz'
    | 'dateWithYear'
    | 'dateWithYear-no-tz'
    | 'timeAndDate'
    | 'timeAndDate-no-tz',
  tz: string | undefined | null,
): string {
  if (!isValidDate(date)) return '';
  if (!tz) {
    return '?';
  }
  const translationKey = {
    time: 'datetime.timeShort',
    'time-no-tz': 'datetime.timeShort',
    date: 'datetime.dateWithoutYear',
    'date-no-tz': 'datetime.dateWithoutYear',
    dateWithYear: 'datetime.dateWithYear',
    'dateWithYear-no-tz': 'datetime.dateWithYear',
    timeAndDate: 'datetime.dateAndTime',
    'timeAndDate-no-tz': 'datetime.dateAndTime',
  }[format];

  const fmtStr =
    tz === getTimeZone() || format.endsWith('-no-tz') ? MyI18n.t(translationKey) : `${MyI18n.t(translationKey)} (zzz)`;
  return formatInTimeZone(date, tz, fmtStr, { locale: selectedLocale });
}
export type DateFormats =
  | 'time'
  | 'time-no-tz'
  | 'date'
  | 'date-no-tz'
  | 'dateWithYear'
  | 'dateWithYear-no-tz'
  | 'timeAndDate'
  | 'timeAndDate-no-tz';

export function formatDateRange(
  MyI18n: typeof I18n,
  selectedLocale: Locale,
  start: Date,
  end: Date,
  format: DateFormats,
  tz: string | undefined | null,
): string {
  const startFmt = format.endsWith('-no-tz') ? format : (`${format}-no-tz` as DateFormats);
  const startText = formatDate(MyI18n, selectedLocale, start, startFmt, tz);
  const endText = formatDate(MyI18n, selectedLocale, end, format, tz);
  return `${startText} - ${endText}`;
}
export function relativeWithDate(
  MyI18n: typeof I18n,
  selectedLocale: Locale,
  date: Date,
  baseDate: Date,
  tz: string | undefined | null,
  opts: {
    showWeekday?: boolean;
    dropWeekdayIfRelative?: boolean;
    showDate?: boolean;
    dropDateIfRelative?: boolean;
    showYear?: boolean;
    showTime?: boolean;
  },
) {
  if (!isValidDate(date)) return '';
  if (!isValidDate(baseDate)) return '';
  if (!tz) return '';

  // Not using date-fns formatRelative in order to have more control.

  const now = baseDate;
  const tomorrow = addDays(now, 1);
  const yesterday = subDays(now, 1);
  const isToday =
    date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
  const isTomorrow =
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear();
  const isYesterday =
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear();

  let translatedDate;
  if (opts.showYear || (opts.showYear !== false && date.getFullYear() !== now.getFullYear())) {
    translatedDate = formatInTimeZone(date, tz, MyI18n.t('datetime.dateWithYear'), {
      locale: selectedLocale,
    });
  } else {
    // eslint-disable-next-line no-lonely-if
    translatedDate = formatInTimeZone(date, tz, MyI18n.t('datetime.dateWithoutYear'), {
      locale: selectedLocale,
    });
  }
  const dayTranslationKey = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][
    date.getDay()
  ];
  const translatedWeekday = MyI18n.t(`datetime.${dayTranslationKey}`);

  let translatedRelative;
  if (isYesterday) {
    translatedRelative = MyI18n.t('datetime.yesterday');
  } else if (isToday) {
    translatedRelative = MyI18n.t('datetime.today');
  } else if (isTomorrow) {
    translatedRelative = MyI18n.t('datetime.tomorrow');
  }

  const showWeekday = opts.showWeekday && !(opts.dropWeekdayIfRelative && translatedRelative);
  const showDate = opts.showDate && !(opts.dropDateIfRelative && translatedRelative);
  let res;
  if (!translatedRelative) {
    if (showWeekday && showDate) {
      res = MyI18n.t('datetime.weekdayDate', {
        relative: translatedRelative,
        weekday: translatedWeekday,
        date: translatedDate,
      });
    } else if (showWeekday && !showDate) {
      res = translatedWeekday;
    } else if (!showWeekday && showDate) {
      res = translatedDate;
    } else {
      res = translatedDate;
    }
  } else if (showWeekday && showDate) {
    res = MyI18n.t('datetime.relativeWeekdayDate', {
      relative: translatedRelative,
      weekday: translatedWeekday,
      date: translatedDate,
    });
  } else if (showWeekday && !showDate) {
    res = MyI18n.t('datetime.relativeWeekday', {
      relative: translatedRelative,
      weekday: translatedWeekday,
      date: translatedDate,
    });
  } else if (!showWeekday && showDate) {
    res = MyI18n.t('datetime.relativeDate', {
      relative: translatedRelative,
      weekday: translatedWeekday,
      date: translatedDate,
    });
  } else {
    res = translatedRelative;
  }
  if (opts.showTime) {
    res += ' ' + formatDate(MyI18n, selectedLocale, date, 'time', tz);
  }

  return res;
}

export function formatDuration(
  MyI18n: typeof I18n,
  durationLength: number,
  unit: 'minutes' | 'seconds' | 'milliseconds',
  format: 'long' | 'short',
): string {
  const start = new Date();
  let end;
  if (unit === 'minutes') {
    end = addMinutes(start, durationLength);
  } else if (unit === 'seconds') {
    end = addSeconds(start, durationLength);
  } else {
    end = addMilliseconds(start, durationLength);
  }

  return formatDurationBetween(MyI18n, start, end, format);
}

export function formatDurationBetween(
  MyI18n: typeof I18n,
  date: Date,
  baseDate: Date,
  format: 'long' | 'short',
): string {
  if (!isValidDate(date)) return '';
  if (!isValidDate(baseDate)) return '';

  const weeks = Math.floor(differenceInWeeks(baseDate, date));
  const days = Math.floor(differenceInDays(baseDate, date) - 7 * weeks);
  const hours = Math.floor(differenceInHours(baseDate, date) - 24 * (days + 7 * weeks));
  const minutes = Math.floor(differenceInMinutes(baseDate, date) - 60 * (hours + 24 * (days + 7 * weeks)));

  let durationString: string = '';

  if (weeks) {
    let weekFormat: string;
    if (weeks === 1) {
      weekFormat = MyI18n.t(format === 'long' ? 'datetime.week' : 'datetime.weeksAbbr');
    } else {
      weekFormat = MyI18n.t(format === 'long' ? 'datetime.weeks' : 'datetime.weeksAbbr');
    }

    durationString = `${MyI18n.t('datetime.durationWeeks', { weeks, weekFormat })} `;
  }

  if (weeks || days) {
    let dayFormat: string;
    if (days === 1) {
      dayFormat = MyI18n.t(format === 'long' ? 'datetime.day' : 'datetime.daysAbbr');
    } else {
      dayFormat = MyI18n.t(format === 'long' ? 'datetime.days' : 'datetime.daysAbbr');
    }

    durationString = durationString.concat(`${MyI18n.t('datetime.durationDays', { days, dayFormat })} `);
  }

  if (weeks || days || hours) {
    let hourFormat: string;
    if (hours === 1) {
      hourFormat = MyI18n.t(format === 'long' ? 'datetime.hour' : 'datetime.hoursAbbr');
    } else {
      hourFormat = MyI18n.t(format === 'long' ? 'datetime.hours' : 'datetime.hoursAbbr');
    }

    durationString = durationString.concat(`${MyI18n.t('datetime.durationHours', { hours, hourFormat })} `);
  }

  if (weeks || days || hours || minutes || (!weeks && !days && !hours && !minutes)) {
    let minuteFormat: string;
    if (minutes === 1) {
      minuteFormat = MyI18n.t(format === 'long' ? 'datetime.minute' : 'datetime.minutesAbbr');
    } else {
      minuteFormat = MyI18n.t(format === 'long' ? 'datetime.minutes' : 'datetime.minutesAbbr');
    }

    durationString = durationString.concat(`${MyI18n.t('datetime.durationMinutes', { minutes, minuteFormat })}`);
  }

  return durationString;
}

export function registerPaperDatesTranslations(I18njs: typeof I18n) {
  /* To make time picker use 24h clock, we don't pass 'en' local but instead pass 'en-GB' */
  registerTranslation('en-GB', enGB);
  registerCustom('sv', I18njs);
  registerCustom('fi', I18njs);
}

function registerCustom(locale: string, I18njs: typeof I18n) {
  registerTranslation(locale, {
    save: I18njs.t('paperDates.save'),
    selectSingle: I18njs.t('paperDates.selectSingle'),
    selectMultiple: I18njs.t('paperDates.selectDates'),
    selectRange: I18njs.t('paperDates.selectRange'),
    notAccordingToDateFormat: (inputFormat) => `${I18njs.t('paperDates.notAccordingToFormat')} ${inputFormat}`,
    mustBeHigherThan: (date) => `${I18njs.t('paperDates.mustBeHigherThan')} ${date}`,
    mustBeLowerThan: (date) => `${I18njs.t('paperDates.mustBeLowerThan')} ${date}`,
    mustBeBetween: (startDate, endDate) => `${I18njs.t('paperDates.mustBeBetween')} ${startDate} - ${endDate}`,
    dateIsDisabled: I18njs.t('paperDates.dateIsDisabled'),
    previous: I18njs.t('paperDates.previous'),
    next: I18njs.t('paperDates.next'),
    typeInDate: I18njs.t('paperDates.typeInDate'),
    pickDateFromCalendar: I18njs.t('paperDates.pickDateFromCalendar'),
    close: I18njs.t('paperDates.close'),
    hour: I18njs.t('paperDates.hour'),
    minute: I18njs.t('paperDates.minute'),
  });
}

/* eslint-disable prefer-destructuring, no-empty-function */
import type {
  notificationAsync as noticationAsyncNative,
  NotificationFeedbackType,
  impactAsync as impactAsyncNative,
  ImpactFeedbackStyle,
  selectionAsync as selectionAsyncNative,
} from 'expo-haptics';

const notificationAsync: typeof noticationAsyncNative = (type?: NotificationFeedbackType) => {
  return new Promise((resp, _rej) => resp());
};

const impactAsync: typeof impactAsyncNative = (style?: ImpactFeedbackStyle) => {
  return new Promise((resp, _rej) => resp());
};

const selectionAsync: typeof selectionAsyncNative = () => {
  return new Promise((resp, _rej) => resp());
};

export const Haptics = {
  notificationAsync,
  impactAsync,
  selectionAsync,
};

import React, { FC } from 'react';
import { useI18n } from '../../context/I18nContext';
import Images from '../../../assets/images';
import Slide from './Slide';

const Slide3: FC = () => {
  const { I18n } = useI18n();

  return (
    <Slide
      title={I18n.t('intro.step3Title')}
      description={I18n.t('intro.step3Description')}
      image={Images.imageSlide3}
    />
  );
};

export default Slide3;

import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useAuth } from '../context/AuthContext';
import { useI18n } from '../context/I18nContext';
import { NavigationDialog } from './CommonDialogs';
import { fetchMyProfile } from '../apis/appsyncApis';

type Props = StackScreenProps<{ ConfirmLogoutDialog: undefined }, 'ConfirmLogoutDialog'>;

export function ConfirmLogoutDialog({ navigation }: Props) {
  const { I18n } = useI18n();
  const { logout } = useAuth();

  const queryClient = useQueryClient();

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <NavigationDialog
      title={I18n.t('myinfo.signoutConfirmation.title')}
      description={I18n.t('myinfo.signoutConfirmation.description')}
      iconName={'question'}
      onDismiss={goBack}
      actions={[
        {
          title: I18n.t('general.cancel'),
          onPress: goBack,
        },
        {
          title: I18n.t('myinfo.signoutConfirmation.confirm'),
          onPress: () => {
            queryClient.invalidateQueries(fetchMyProfile.id);
            logout();
          },
        },
      ]}
    />
  );
}

import React from 'react';
import { List } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from 'react-query';
import { fetchMyProfile } from '../../apis/appsyncApis';
import { screenNames } from '../../navigators/screenNames';
import { useI18n } from '../../context/I18nContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AppDrawerNavigationProp } from '../../components/DrawerMenuContent';
import { ListItemRow } from '../../components/ListItemRow';

const Organisation: React.FC = () => {
  const result = useQuery(fetchMyProfile.id, fetchMyProfile);
  const data = result.data?.data?.getMyProfile;
  const navigation = useNavigation<AppDrawerNavigationProp>();
  const { I18n } = useI18n();
  return (
    <ScrollablePage
      summary={{
        title: I18n.t('organisation.summaryTitle'),
        description: I18n.t('organisation.summaryDescription'),
        icon: 'organization',
      }}
    >
      <List.Subheader>{I18n.t('organisation.organisation')}</List.Subheader>
      <ListItemRow
        title={data?.organization ? data?.organization : I18n.t('organisation.noOrganisation')}
        leftIcon={'organization'}
        onPress={() => navigation.navigate('me', { screen: screenNames.UpdateOrganisation })}
        type="navigation"
      />
    </ScrollablePage>
  );
};

export default Organisation;

import React, { FC } from 'react';
import { View } from 'react-native';
import { List, RadioButton } from 'react-native-paper';
import {
  useI18n,
  isSupportedTranslation,
  SupportedTranslation,
  getSupportedSystemLanguage,
} from '../../context/I18nContext';
import Radio from '../../components/ui/Radio';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { capitalizeFirstLetter } from '../../utils/helpers';

const MAGIC_DEFAULT_VALUE = 'MAGIC DEFAULT VALUE';
const UpdateLanguage: FC = () => {
  const { I18n, changeLocale, currentLocale, followingSystemLanguage } = useI18n();
  const mp = useMixpanel();
  const handleChangeLanguage = (lang: string) => {
    if (lang !== MAGIC_DEFAULT_VALUE && isSupportedTranslation(lang)) {
      changeLocale(lang);
      mp?.getPeople().set('language', lang);
    } else {
      changeLocale(null);
      mp?.getPeople().set('language', lang);
    }
  };

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('updateLanguage.summaryTitle'),
        description: I18n.t('updateLanguage.summaryDescription'),
        iconFromSet: { set: 'ion', name: 'language-outline' },
      }}
    >
      <List.Subheader>{I18n.t('updateLanguage.preferredLanguage')}</List.Subheader>
      <View style={{ paddingLeft: 9, paddingRight: 0 }}>
        <RadioButton.Group
          value={followingSystemLanguage ? MAGIC_DEFAULT_VALUE : currentLocale}
          onValueChange={handleChangeLanguage}
        >
          <Radio
            value={MAGIC_DEFAULT_VALUE}
            label={I18n.t('updateLanguage.defaultLanguage', {
              lang: I18n.t(`language.${getSupportedSystemLanguage()}`),
            })}
          />
          {Object.values(SupportedTranslation)
            .sort((a, b) => (I18n.t(`language.${a}`) > I18n.t(`language.${b}`) ? 1 : -1))
            .map((localeName) => (
              <Radio
                key={localeName}
                value={localeName}
                label={capitalizeFirstLetter(I18n.t(`language.${localeName}`))}
              />
            ))}
        </RadioButton.Group>
      </View>
    </ScrollablePage>
  );
};

export default UpdateLanguage;

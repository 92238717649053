import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

enum State {
  hide,
  show,
}

export const IntroContext = createContext({
  state: State.hide,
  handleShow: () => {},
  handleHide: () => {},
});

export const useIntroContext = () => {
  return useContext(IntroContext);
};

export const IntroContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<State>(State.hide);

  const handleShow = useCallback(() => {
    setState(State.show);
  }, []);
  const handleHide = useCallback(() => {
    setState(State.hide);
  }, []);

  const value = useMemo(() => {
    return {
      state,
      handleShow,
      handleHide,
    };
  }, [handleHide, handleShow, state]);

  return <IntroContext.Provider value={value}>{React.Children.only(children)}</IntroContext.Provider>;
};

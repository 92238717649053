import React, { useMemo, useCallback, FC } from 'react';
import { FAB } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { screenNames } from '../../navigators/screenNames';
import { AppDrawerNavigationProp } from '../DrawerMenuContent';
import { uiIconSourceGenerator } from '../SimpleListIcon';

export const QRCodeButton: FC = (props) => {
  const navigator = useNavigation<AppDrawerNavigationProp>();

  const styles = useMemo(() => {
    return {
      qrCodeButton: {
        elevation: 6,
      },
    };
  }, []);
  const handlePress = useCallback(
    () =>
      navigator.navigate('map', {
        screen: screenNames.QRScanScreen,
        params: {
          mode: 'UNLOCK',
        },
      }),
    [navigator],
  );

  return (
    <FAB
      style={styles.qrCodeButton}
      variant="secondary"
      icon={uiIconSourceGenerator({ set: 'ion', name: 'qr-code-outline' })}
      onPress={handlePress}
      animated={false}
      {...props}
    />
  );
};

import React from 'react';
import { View } from 'react-native';
import { TouchableRipple, useTheme } from 'react-native-paper';
import Typography from '../../components/Typography';
import { useI18n } from '../../context/I18nContext';
import { styles } from './sharedCardConfig';
import { PaymentMethod } from '../../payment/MapViewActivePaymentContext';
import { PaymentMethodOption } from '../../types/misc.types';
import { GooglePayIcon } from '../../utils/GooglePayIcon';
import { UiIcon } from '../../components/SimpleListIcon';

interface PaymentMethodRowProps {
  onPress: () => void;
  paymentMethod?: PaymentMethod | null;
}

function Helper({ paymentMethod }: { paymentMethod?: PaymentMethod | null }) {
  const { colors } = useTheme();
  const { I18n } = useI18n();

  if (paymentMethod?.paymentMethodType === PaymentMethodOption.GOOGLEPAY) {
    return (
      <>
        <GooglePayIcon
          style={{
            marginLeft: -10, // make the icon and payment button have same offset on Y-axis
            marginTop: 1, // align Google Pay icon with Google Pay text in the same row
          }}
        />
        <Typography variant={'body2'}>Google Pay</Typography>
      </>
    );
  }

  const cardInfoDisplayText = (() => {
    if (paymentMethod === null || paymentMethod === undefined) {
      return I18n.t('mapview.podInfoCard.noActiveCard');
    }
    const { stripeCard } = paymentMethod;
    return `${stripeCard.brand?.toUpperCase()} **** ${stripeCard.last4}`;
  })();
  return (
    <>
      <UiIcon name={'credit-card'} size={24} color={colors.onSurface} />
      <Typography style={{ marginLeft: 10 }} variant={'body2'}>
        {cardInfoDisplayText}
      </Typography>
    </>
  );
}

export default function PaymentMethodRow({ onPress, paymentMethod }: PaymentMethodRowProps) {
  const { colors } = useTheme();

  return (
    <TouchableRipple onPress={onPress}>
      <View style={styles.creditCard}>
        <View style={styles.creditCardInfo}>
          <Helper paymentMethod={paymentMethod} />
        </View>
        <UiIcon name={'arrow-right'} size={16} color={colors.secondary} style={{ paddingVertical: 16 }} />
      </View>
    </TouchableRipple>
  );
}

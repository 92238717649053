import { AppsyncData } from '../apis/appsyncHelper';
import { MarkerClusterProperties, MarkerGenerator } from '../components/map/content/MarkerCluster';
import { PodStatus } from '../types/appsync-types';
import { Pod } from '../types/misc.types';

export function headingAccuracyToDeg(n: number) {
  if (n === 0) return 180;
  if (n === 1) return 50;
  if (n === 2) return 35;
  if (n === 3) return 20;
  return n;
}
export const POD_SELECT_ZOOM = 18;
export const BUILDING_SELECT_ZOOM = 14.1;

export type SimpleGJSpaceProps = { type: 'space'; selected: boolean; podStatus: PodStatus };
export type SimpleGJVenueProps = { type: 'venue'; selected?: boolean; podStatus?: PodStatus };
export type SimpleGJClusterProps = { available: number };

/* In clusters, the total number of available will be in the available prop */
export const venueClusterProperties: MarkerClusterProperties<SimpleGJClusterProps> = {
  available: ['+', ['number', ['get', 'available'], 0]],
};

export const podClusterProperties: MarkerClusterProperties<SimpleGJClusterProps> = {
  available: ['+', ['case', ['==', ['string', PodStatus.FREE], ['get', 'podStatus']], 1, 0]],
};
export type SimpleMarkerVars = {
  selectedId?: number;
  leafTitleTemplate: string;
  clusterTitleTemplate: string;
};

export const generateMarker: MarkerGenerator<
  SimpleMarkerVars,
  SimpleGJSpaceProps | SimpleGJVenueProps,
  SimpleGJClusterProps
> = (e, doc, vars) => {
  // Simple parser for templates like "Hi [[name]]!"
  function stringTemplateParser(expression: string, valueObj: Record<string, any>) {
    const templateMatcher = /\[\[\s?([^[\]\s]*)\s?\]\]/g;

    const text = expression
      ? expression.replace(templateMatcher, (_substring, value, _index) => {
          return valueObj[value];
        })
      : '';
    return text;
  }

  const el = doc.createElement('div');

  function updateToNewVars(newVars: SimpleMarkerVars) {
    const isSelected =
      (!e.properties.cluster && e.properties.selected === true) ||
      (newVars.selectedId !== undefined && newVars.selectedId === e.id);

    el.style.borderStyle = 'solid';
    el.style.borderRadius = '6px';
    el.style.borderWidth = '2px';
    el.style.paddingLeft = '10px';
    el.style.paddingRight = '10px';
    el.style.paddingTop = '5px';
    el.style.paddingBottom = '5px';
    el.style.boxShadow = '0px 0px 8px #000';
    el.style.fontSize = '16px';

    el.style.backgroundColor = '#273442';
    el.style.maxWidth = '90px';
    el.style.textAlign = 'center';

    el.style.color = '#fff';

    if (e.properties.cluster) {
      // el.style.color = "#ccc";
      el.style.borderColor = '#ccc';
    } else if (e.properties.podStatus === 'RESERVED_BY_ME') {
      /* My own booking */
      // el.style.color = "#fff";
      el.style.borderColor = '#fff';
      el.style.borderWidth = '2px';
      el.style.background =
        'linear-gradient(0deg, rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(92.68deg, #2B6A97 25.94%, #063B61 82.45%)';
      if (isSelected) {
        el.style.borderWidth = '2.5px';
        el.style.boxShadow = '0px 0px 8px #EEEEEE';
      }
    } else if (e.properties.podStatus === 'UNAVAILABLE' || e.properties.podStatus === 'RESERVED_BY_OTHER') {
      /* Unavailable */
      // el.style.color = "#888";
      el.style.borderColor = '#888';
      el.style.color = '#888';
      if (isSelected) {
        el.style.borderWidth = '2.5px';
        el.style.boxShadow = '0px 0px 8px #AAA';
      }
    } else {
      /* Available to book */
      // el.style.color = "#ccc";
      el.style.borderColor = '#ccc';
      if (isSelected) {
        // el.style.color = "#fff";
        el.style.borderColor = '#fff';
        el.style.borderWidth = '2.5px';
        el.style.boxShadow = '0px 0px 8px #EEEEEE';
      }
    }

    if (e.properties.cluster) {
      el.innerHTML = stringTemplateParser(vars.clusterTitleTemplate, e.properties);
    } else {
      el.innerHTML = stringTemplateParser(vars.leafTitleTemplate, e.properties);
    }
  }
  updateToNewVars(vars);
  return {
    html: el,
    onStateUpdate: updateToNewVars,
  };
};

export function extractAllPods(buildingData: AppsyncData<'getBuildingsWithinRadius'> | undefined): Pod[] {
  if (!buildingData) {
    return [];
  }
  const pods: Pod[] = [];
  buildingData.getBuildingsWithinRadius?.forEach((building) => {
    const filterNullAndUndefined = (pod: Pod | undefined | null): pod is Pod => {
      return !!pod;
    };
    const buildingPois: Pod[] = building?.spaces?.filter(filterNullAndUndefined) ?? [];
    pods.push(...buildingPois);
  });
  return pods;
}

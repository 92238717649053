import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { MutationSignupArgs } from '../types/appsync-types';
import { publicAppsyncGraphql } from './appsyncHelper';

export async function signUp(phone: string, invitationCode: string): Promise<CognitoUser> {
  const mutation = /* GraphQL */ `
    mutation Signup($phonenumber: AWSPhone!, $code: String) {
      signup(phonenumber: $phonenumber, code: $code)
    }
  `;
  const response = await publicAppsyncGraphql<'signup'>(mutation, {
    phonenumber: phone,
    // If invitationCode is an empty string, dismiss it
    code: invitationCode || undefined,
  } as MutationSignupArgs);

  if (response.data?.signup !== 'SUCCESS') throw new Error(response.data?.signup ?? 'Unknown Error');
  return Auth.signIn(phone);
}

export function verifyWithCode(cognitoUser: CognitoUser, code: string): Promise<CognitoUser> {
  return Auth.sendCustomChallengeAnswer(cognitoUser, code);
}

export function resendVerifyCode(phone: string): Promise<CognitoUser> {
  return Auth.signIn(phone);
}

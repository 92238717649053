import React, { useCallback, useEffect, useState } from 'react';
import { Keyboard, StyleSheet, View } from 'react-native';
import { Button, HelperText, useTheme } from 'react-native-paper';
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field';
import { useMutation } from 'react-query';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { StackScreenProps } from '@react-navigation/stack';
import { useI18n } from '../../context/I18nContext';
import Typography from '../../components/Typography';
import GradientButton from '../../components/GradientButton';
import { verifyWithCode } from '../../apis/authApis';
import { useAuth } from '../../context/AuthContext';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import Container from '../../components/Container';
import { RootStackRouteParams } from '../../navigators/RootNavigator';

// FYI: Analytics of sign in are done in auth context.

export type ConfirmationCodeRouteParams = {
  phoneNumber: string;
};

const CELL_COUNT = 6;
const WAIT_SECONDS = 30;
type Props = StackScreenProps<RootStackRouteParams, 'Verification'>;

export const ConfirmCode: React.FC<Props> = ({ route, navigation }) => {
  const { I18n } = useI18n();
  const { colors } = useTheme();
  const [code, setCode] = useState('');
  const [canPressResend, setCanPressResend] = useState(false);
  const mp = useMixpanel();
  const [shouldShowResendHelperText, setShouldShowResendHelperText] = useState(false);
  const [timerSecond, setTimerSecond] = useState(WAIT_SECONDS);
  const { cognitoUser, resendCode } = useAuth();

  const verifyCodeMutation = useMutation(
    async ({
      cognitoUser: cognitoUserInner,
      code: codeToVerify,
    }: {
      cognitoUser: CognitoUser;
      code: string;
    }): Promise<CognitoUser> => {
      const user = await verifyWithCode(cognitoUserInner, codeToVerify);
      return user;
    },
  );
  const ref = useBlurOnFulfill({ value: code, cellCount: CELL_COUNT });

  const handleChangeCode = (newCode: string) => {
    setCode(newCode);
    verifyCodeMutation.reset();
    if (newCode.length === 6) {
      verifyCodeMutation.mutate({
        cognitoUser: cognitoUser!,
        code: newCode,
      });
    }
  };

  const [_props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: code,
    setValue: handleChangeCode,
  });

  const { refreshAuthState } = useAuth();

  useEffect(() => {
    // We get error on ios in dev env saying "NotAuthorizedException: Invalid session for the user"
    // The error document is here https://repost.aws/knowledge-center/cognito-mfa-errors
    if (verifyCodeMutation.isSuccess) {
      refreshAuthState();
    }
  }, [verifyCodeMutation.isSuccess, refreshAuthState, verifyCodeMutation]);

  const verify = useCallback(async () => {
    Keyboard.dismiss();
    mp?.getPeople().increment('Code verification attempts', 1);
    mp?.track('Code verification attempt', { phonenumber: cognitoUser!.getUsername() });
    verifyCodeMutation.mutate({ cognitoUser: cognitoUser!, code });
  }, [code, verifyCodeMutation, cognitoUser, mp]);

  useEffect(() => {
    let interval: number;
    let mounted = true;
    if (!canPressResend) {
      interval = window.setInterval(() => {
        if (!mounted) return;
        setTimerSecond((prev) => {
          if (prev - 1 <= 0) {
            setCanPressResend(true);
            setShouldShowResendHelperText(false);
            // When the timer reaches 0, reset the timer
            clearInterval(interval);
            return WAIT_SECONDS;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      mounted = false;
      window.clearInterval(interval);
    };
  }, [canPressResend]);

  const resend = async () => {
    if (canPressResend) {
      setCanPressResend(false);
      await resendCode(cognitoUser!.getUsername());
    } else {
      setShouldShowResendHelperText(true);
    }
  };

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('confirmCode.title'),
        description: I18n.t('confirmCode.subtitle', {
          phoneNumber: route.params.phoneNumber ?? '',
        }),
        icon: 'magic-wand',
      }}
      footerContent={
        <Container>
          <GradientButton
            onPress={verify}
            loading={verifyCodeMutation.isLoading}
            disabled={code.length !== CELL_COUNT || code === '000000'}
          >
            {I18n.t('general.continue')}
          </GradientButton>
        </Container>
      }
    >
      <Container>
        <CodeField
          ref={ref}
          value={code}
          autoFocus={true}
          onChangeText={handleChangeCode}
          cellCount={CELL_COUNT}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          rootStyle={{ marginBottom: 0, marginHorizontal: 8 }}
          renderCell={({ index, symbol, isFocused }) => (
            <View
              style={[
                styles.cell,
                isFocused && {
                  borderColor: colors.primary,
                },
                verifyCodeMutation.isError && {
                  borderColor: colors.error,
                },
              ]}
              onLayout={getCellOnLayoutHandler(index)}
              key={index}
            >
              <Typography variant="body1" color={colors.secondary}>
                {symbol || (isFocused ? <Cursor /> : null)}
              </Typography>
            </View>
          )}
        />
        <HelperText type={verifyCodeMutation.isError ? 'error' : 'info'}>
          {verifyCodeMutation.isError ? I18n.t('confirmCode.invalidCode') : I18n.t('confirmCode.notReceiveCode')}
        </HelperText>
        <Button onPress={resend} mode="text" color={colors.secondary}>
          {I18n.t('confirmCode.resendCode')}
        </Button>
        {shouldShowResendHelperText && (
          <HelperText type={'error'}>{I18n.t('confirmCode.waitForNewCode', { seconds: timerSecond })}</HelperText>
        )}
      </Container>
    </ScrollablePage>
  );
};

const styles = StyleSheet.create({
  cell: {
    width: 32,
    height: 54,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,0.12)',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 4,
  },
});

import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { getTimeZone } from 'react-native-localize';
import { useNowMinutes } from '../hooks/useNow';

export const DEFAULT_BOOKING_TIME_MINS = 60;
export const DEFAULT_DURATION_STEP_MINS = 15;

export const DEFAULT_BOOKING_TIME_S = DEFAULT_BOOKING_TIME_MINS * 60;

export type TimeZone = string;

interface Filter {
  date: Date;
  // Duration in second
  durationInSecond: number;
}

interface MapViewFilterValue {
  filter: Filter;
  timeZone: TimeZone;
  onChange: (f: Filter) => void;
  onTimeZoneChange: (tz: TimeZone) => void;
}

const MapViewFilterContext = createContext<MapViewFilterValue>({
  filter: {
    date: new Date(0),
    durationInSecond: 0,
  },
  timeZone: getTimeZone() as TimeZone,
  onTimeZoneChange(tz) {},
  onChange() {},
});

export const useMapViewFilter = () => {
  return useContext(MapViewFilterContext);
};

export function MapViewFilterProvider({ children }: PropsWithChildren<{}>) {
  const now = useNowMinutes();
  const [timeZone, setTimeZone] = useState(getTimeZone() as TimeZone);

  const [filter, setFilter] = useState({
    date: now,
    durationInSecond: DEFAULT_BOOKING_TIME_S,
  });

  useEffect(() => {
    setFilter((prevFilter) => {
      // Update filter if needed to prevent looking at the past.
      if (prevFilter.date < now) {
        return {
          ...prevFilter,
          date: now,
        };
      }
      return prevFilter;
    });
  }, [now]);

  return (
    <MapViewFilterContext.Provider
      value={{
        timeZone,
        filter,
        onChange: setFilter,
        onTimeZoneChange: setTimeZone,
      }}
    >
      {children}
    </MapViewFilterContext.Provider>
  );
}

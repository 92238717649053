import { endOfDay, set, startOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export function withTimeZone(fun: (input: number | Date) => Date, tz: string) {
  return (input: string | number | Date) => {
    const inputZoned = utcToZonedTime(input, tz);

    // UTC: 09-25 22:00
    // Browser (Germany/Berlin): 09-26 00:00
    const dayStartZoned = fun(inputZoned);
    // UTC: 09-26 04:00
    // Browser (Germany/Berlin): 09-26 06:00
    // Target (America/New_York): 09-26 00:00
    const dayStart = zonedTimeToUtc(dayStartZoned, tz);
    return dayStart;
  };
}
export function setTz(
  input: number | Date,
  values: {
    year?: number;
    month?: number;
    date?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
  },
  tz: string,
) {
  return zonedTimeToUtc(set(input, values), tz);
}
export function startOfDayTz(input: string | number | Date, tz: string) {
  return withTimeZone(startOfDay, tz)(input);
}
export function endOfDayTz(input: string | number | Date, tz: string) {
  return withTimeZone(endOfDay, tz)(input);
}

import React, { FC, PropsWithChildren, useContext, useEffect } from 'react';
import { Platform } from 'react-native';
import { EU_API_HOST, Mixpanel, MixpanelLibraryType } from './Mixpanel';
import { useHardwareStatus } from '../context/HardwareContext';

export const MixpanelContext = React.createContext<MixpanelLibraryType | undefined>(undefined);

/*
Mixpanel documentation is here
https://developer.mixpanel.com/docs/react-native
*/

export const useMixpanel = () => {
  return useContext(MixpanelContext);
};

export const MixpanelProvider: FC<PropsWithChildren<{ apiToken: string | undefined }>> = ({ apiToken, children }) => {
  const [mixpanel, setMixpanel] = React.useState<MixpanelLibraryType | undefined>(undefined);
  const { isBluetoothEnabled } = useHardwareStatus();

  useEffect(() => {
    if (apiToken) {
      const requireOptIn = false; // We track by default
      const initMixpanel = async () => {
        const mp = new Mixpanel(apiToken);
        await mp.init(requireOptIn);

        /* Note: On android this is in the manifest */
        mp.setServerURL(EU_API_HOST);
        mp.track('Session start');
        setMixpanel(mp);
      };

      initMixpanel();
    } else {
      setMixpanel(undefined);
    }
  }, [apiToken]);

  useEffect(() => {
    // On Android bluetooth_enabled is tracked, but not on other platforms.
    // See https://github.com/mixpanel/mixpanel-react-native/issues/39
    if (Platform.OS !== 'android' && mixpanel && isBluetoothEnabled !== undefined) {
      mixpanel.registerSuperProperties({ $bluetooth_enabled: isBluetoothEnabled });
    }
  }, [mixpanel, isBluetoothEnabled]);

  return <MixpanelContext.Provider value={mixpanel}>{children}</MixpanelContext.Provider>;
};

import React, { ComponentType, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Animated, Dimensions, LayoutChangeEvent, NativeScrollEvent, ScrollView, View } from 'react-native';
import IntroWrapper from './Wrapper';
import Pagination from './Pagination';

const { height: ScreenHeight, width: ScreenWidth } = Dimensions.get('screen');

type SlideListItem<T> = {
  item: T;
  index: number;
};

export interface IntroSlide {
  key: string;
  screen: ComponentType<{
    onNext: () => void;
    onPrevious: () => void;
    animatedIndex: Animated.Value | Animated.AnimatedInterpolation<string | number>;
    index: number;
  }>;
  header?: boolean;
}

interface Props {
  slide: IntroSlide[];
  index: number;
  onSlideChange(index: number): void;
}

const IntroSlider: FC<Props> = ({ slide, index: requestedIndex, onSlideChange }) => {
  const ref = useRef<ScrollView>(null);
  const scrollAnimatedValue = useRef(new Animated.Value(0)).current;
  const [flatListDimensions, setWidthHeight] = useState({
    width: ScreenWidth,
    height: ScreenHeight,
  });

  const animatedIndex = useMemo(() => {
    return scrollAnimatedValue.interpolate({
      inputRange: [0, flatListDimensions.width],
      outputRange: [0, 1],
      extrapolate: 'extend',
    });
  }, [flatListDimensions.width, scrollAnimatedValue]);

  const handleGoToPage = useCallback(
    (page: number) => {
      if (onSlideChange) {
        onSlideChange(page);
      }
    },
    [onSlideChange],
  );

  useEffect(() => {
    ref.current?.scrollTo({
      x: requestedIndex * flatListDimensions.width,
    });
  }, [flatListDimensions.width, requestedIndex]);

  const renderItem = ({ item, index }: SlideListItem<IntroSlide>) => {
    return (
      <View style={{ width: flatListDimensions.width, flex: 1 }} key={index}>
        <IntroWrapper>
          {React.createElement(item.screen, {
            onNext: () => handleGoToPage(index + 1),
            onPrevious: () => handleGoToPage(index - 1),
            animatedIndex,
            index,
          })}
        </IntroWrapper>
      </View>
    );
  };

  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    const { width, height } = nativeEvent.layout;
    if (width !== flatListDimensions.width || height !== flatListDimensions.height) {
      // Set new width to update rendering of pages
      setWidthHeight({ width, height });
      // Set new scroll position
      const func = () => {
        ref?.current?.scrollTo({
          x: requestedIndex * width,
          animated: false,
        });
      };
      setTimeout(func, 0); // Must be called like this to avoid bugs
    }
  };

  const onMomentumScrollEnd = (e: { nativeEvent: NativeScrollEvent }) => {
    const offset = e.nativeEvent.contentOffset.x;
    // Touching very very quickly and continuous brings about
    // a variation close to - but not quite - the width.
    // That's why we round the number.
    // Also, Android phones and their weird numbers
    const newIndex = Math.round(offset / flatListDimensions.width);
    if (newIndex === requestedIndex) {
      // No page change, don't do anything
      return;
    }
    if (onSlideChange) {
      onSlideChange(newIndex);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <Animated.ScrollView
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollAnimatedValue } } }], {
          useNativeDriver: true,
        })}
        ref={ref}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        bounces={false}
        style={{ flex: 1 }}
        onMomentumScrollEnd={onMomentumScrollEnd}
        onLayout={onLayout}
        // make sure all slides are rendered so we can use dots to navigate to them
      >
        {slide.map((i, index) => renderItem({ item: i, index }))}
      </Animated.ScrollView>
      <Pagination animatedActiveIndex={animatedIndex} numberOfItems={slide.length} onGoToPage={handleGoToPage} />
    </View>
  );
};

export default IntroSlider;

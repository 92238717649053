import React from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useI18n } from '../../context/I18nContext';
import GradientButton from '../../components/GradientButton';
import { useAuth } from '../../context/AuthContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { useGetRecentCreditBalanceHistory } from '../../payment/paymentApis';
import { CreditType } from '../../types/appsync-types';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';

export default function WelcomeWithCredits({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const { I18n, formatCurrency } = useI18n();
  const auth = useAuth();
  const recentCreditBalanceQuery = useGetRecentCreditBalanceHistory();
  const creditHistory = recentCreditBalanceQuery.data?.data?.getMyRecentCreditHistory;
  const latestInviteTransaction = creditHistory?.find((h) => {
    return h && (h.creditType === CreditType.FRIEND_INVITE_CODE || h.creditType === CreditType.PROMO_INVITE_CODE);
  });

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('welcome.withInvite.title', {
          amount: formatCurrency(latestInviteTransaction?.change, latestInviteTransaction?.currency),
        }),
        description: I18n.t('welcome.withInvite.description', {
          amount: formatCurrency(latestInviteTransaction?.change, latestInviteTransaction?.currency),
        }),
        icon: 'heart',
      }}
    >
      <Container>
        <Spacer />
        <GradientButton onPress={() => auth.refreshAuthState()}>{I18n.t('general.continue')}</GradientButton>
      </Container>
    </ScrollablePage>
  );
}

import React, { useCallback, useMemo, useState } from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { Formik } from 'formik';
import * as yup from 'yup';

import { FormikHelpers } from 'formik/dist/types';
import Container from '../../components/Container';
import FormikInput from '../../components/formik/FormikInput';
import { useModal } from '../../context/ModalContext';
import * as adminApi from '../../apis/adminApis';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AdminConfirmModalContent, RawAdminCommandOutput, SendAdminCommandButton } from './AdminTools';

interface RequestForm {
  code: string;
}

export default function ShowPromoCode({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const [initForm] = useState<RequestForm>({
    code: '',
  });
  const [request, setRequest] = useState<Promise<any>>();

  const modal = useModal();

  const validateSchema = useMemo(() => {
    return yup.object().shape({
      code: yup.string().required('Code is required'),
    });
  }, []);

  const handleAction = useCallback(
    (form: RequestForm) => {
      const p = adminApi.inviteCodeGet(form.code);
      setRequest(p);
      p.finally(() => modal.closeModal());
      return p;
    },
    [modal],
  );

  const handleSubmitForm = useCallback(
    async (form: RequestForm, formikHelper: FormikHelpers<RequestForm>) => {
      modal.openModal({
        content: <AdminConfirmModalContent onDismiss={() => modal.closeModal()} onConfirm={() => handleAction(form)} />,
      });
    },
    [modal, handleAction],
  );

  return (
    <ScrollablePage
      summary={{
        title: 'Get invitation code',
        description: 'View invitation code details',
        icon: 'pencil',
      }}
    >
      <Container>
        <Formik initialValues={initForm} onSubmit={handleSubmitForm} validationSchema={validateSchema}>
          {({ handleSubmit, isSubmitting }) => (
            <>
              <FormikInput
                name={'code'}
                mode="outlined"
                keyboardType="default"
                autoComplete="off"
                autoCapitalize="sentences"
                label={'Code'}
              />

              <SendAdminCommandButton handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
            </>
          )}
        </Formik>
        <RawAdminCommandOutput request={request} />
      </Container>
    </ScrollablePage>
  );
}

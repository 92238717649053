import React, { PropsWithChildren, useCallback } from 'react';
import { ScrollView, StyleSheet, View, ViewProps } from 'react-native';
import { Dialog, Portal, Surface } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';
import { ModalButtonProps, ModalButtonWrapper } from '../components/modal/TextModal';
import { useI18n } from '../context/I18nContext';
import { BlurOverlay } from '../components/BlurOverlay';
import { UiDefaultIconName, UiIconType } from '../components/SimpleListIcon';
import SummaryHeader from '../components/SummaryHeader';

export type DialogBaseProps = PropsWithChildren<
  ViewProps & {
    title: string;
    description: string;
    iconName?: UiDefaultIconName;
    iconFromSet?: UiIconType;
    actions: ModalButtonProps[];
    onDismiss?: () => any;
  }
>;

export function NavigationDialog({
  title,
  description,
  actions,
  children,
  style,
  iconName,
  iconFromSet,
  onDismiss,
  ...other
}: DialogBaseProps) {
  return (
    <Portal>
      <BlurOverlay onPress={onDismiss}>
        <Surface style={[styles.modalSurface, style]} {...other}>
          <View style={{ overflow: 'hidden' }}>
            <SummaryHeader
              title={title}
              description={description}
              style={styles.summaryHeader}
              icon={iconName}
              iconFromSet={iconFromSet}
            />
            <ScrollView>{children}</ScrollView>
            <Dialog.Actions>
              {actions.map((btn) => (
                <ModalButtonWrapper {...btn} key={btn.title} />
              ))}
            </Dialog.Actions>
          </View>
        </Surface>
      </BlurOverlay>
    </Portal>
  );
}

export type ErrorDialogRouteParams = {
  titleKey: string;
  titleParams?: Record<string, any>;
  descriptionKey: string;
  descriptionParams?: Record<string, any>;
};

type ErrorDialogScreenProps = StackScreenProps<{ ErrorDialog: ErrorDialogRouteParams }, 'ErrorDialog'>;

export function ErrorDialog({ navigation, route }: ErrorDialogScreenProps) {
  const { I18n } = useI18n();
  const { titleKey, titleParams, descriptionKey, descriptionParams } = route.params;
  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);
  return (
    <NavigationDialog
      title={I18n.t(titleKey, titleParams)}
      description={I18n.t(descriptionKey, descriptionParams)}
      iconName={'exclamation'}
      onDismiss={goBack}
      actions={[
        {
          title: I18n.t('general.ok'),
          onPress: goBack,
        },
      ]}
    />
  );
}

const styles = StyleSheet.create({
  summaryHeader: { paddingHorizontal: 16 },
  modalSurface: {
    paddingTop: 0,
    paddingLeft: 8,
    paddingRight: 8,
    maxWidth: 400,
    width: '100%',
  },
});

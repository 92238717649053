import React, { useCallback, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormikHelpers } from 'formik/dist/types';
import Container from '../../components/Container';
import FormikInput from '../../components/formik/FormikInput';
import { useModal } from '../../context/ModalContext';
import * as adminApi from '../../apis/adminApis';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AdminConfirmModalContent, RawAdminCommandOutput, SendAdminCommandButton } from './AdminTools';
import { useI18n } from '../../context/I18nContext';

interface RequestForm {
  code: string;
  source: string;
  valueEuros: number | '';
  note: string;
  currency: string;
  expiryDate: string;
}

export default function CreatePromoCode({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const [initForm] = useState<RequestForm>({
    code: '',
    source: '',
    valueEuros: '',
    currency: 'EUR',
    note: '',
    expiryDate: new Date().toISOString().split('T')[0],
  });
  const [request, setRequest] = useState<Promise<any>>();
  const { parseDecimal } = useI18n();
  const modal = useModal();

  const validateSchema = useMemo(() => {
    return yup.object().shape({
      code: yup.string().required(),
      source: yup.string().required(),
      currency: yup.string().required(),
      valueEuros: yup
        .string()
        .required()
        .test('is-parseable', 'Not a number', (v: any) => !!v && parseDecimal(v) !== null),
      expiryDate: yup.date().min(new Date()).required(),
      note: yup.string().required(),
    });
  }, [parseDecimal]);

  const handleAction = useCallback(
    (form: RequestForm) => {
      const p = adminApi.inviteCodeCreate(
        form.code,
        form.source,
        form.expiryDate,
        form.note,
        form.currency,
        (parseDecimal(form.valueEuros) ?? 0) * 100,
      );
      p.finally(() => modal.closeModal());
      setRequest(p);
      return p;
    },
    [modal, parseDecimal],
  );

  const handleSubmitForm = useCallback(
    async (form: RequestForm, formikHelper: FormikHelpers<RequestForm>) => {
      modal.openModal({
        content: <AdminConfirmModalContent onDismiss={() => modal.closeModal()} onConfirm={() => handleAction(form)} />,
      });
    },
    [modal, handleAction],
  );
  return (
    <ScrollablePage
      summary={{
        title: 'Create invitation code',
        description: 'Create an invitation code for promotional purposes',
        icon: 'pencil',
      }}
    >
      <Container>
        <Formik initialValues={initForm} onSubmit={handleSubmitForm} validationSchema={validateSchema}>
          {({ values, handleSubmit, isSubmitting }) => (
            <>
              <FormikInput
                name={'code'}
                mode="outlined"
                value={values.code.toUpperCase()}
                keyboardType="default"
                autoComplete="off"
                autoCapitalize="characters"
                label={'Code'}
              />
              <FormikInput
                name={'valueEuros'}
                mode="outlined"
                keyboardType="numeric"
                autoComplete="off"
                autoCapitalize="none"
                label={'Value in euros (not cents)'}
              />
              <FormikInput
                name={'currency'}
                mode="outlined"
                keyboardType="default"
                autoComplete="off"
                autoCapitalize="none"
                label={'Currency (i.e EUR)'}
              />
              <FormikInput
                name={'source'}
                mode="outlined"
                keyboardType="default"
                autoComplete="off"
                autoCapitalize="none"
                label={'Code owner (visible to user)'}
              />
              <FormikInput
                name={'note'}
                mode="outlined"
                keyboardType="default"
                autoComplete="off"
                autoCapitalize="none"
                label={'Internal note'}
              />

              <FormikInput
                name={'expiryDate'}
                mode="outlined"
                keyboardType={Platform.OS === 'ios' ? 'numbers-and-punctuation' : 'numeric'}
                autoComplete="off"
                autoCapitalize="none"
                label={'Expiry date (i.e. 2021-12-31)'}
              />

              <SendAdminCommandButton handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
            </>
          )}
        </Formik>

        <RawAdminCommandOutput request={request} />
      </Container>
    </ScrollablePage>
  );
}

import { useNavigation } from '@react-navigation/native';
import React, { Fragment, useCallback } from 'react';
import { List, useTheme } from 'react-native-paper';
import { useCountryRegionsQuery } from '../../apis/venueApis';
import { AppDrawerNavigationProp } from '../../components/DrawerMenuContent';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AuthState, useAuth } from '../../context/AuthContext';
import { useI18n } from '../../context/I18nContext';
import { ListItemRow } from '../../components/ListItemRow';

function RegionItem({ title, regionId }: { title: string; regionId: string }) {
  const { colors } = useTheme();
  const navigation = useNavigation<AppDrawerNavigationProp>();

  const onPress = useCallback(() => {
    navigation.navigate('admin', {
      screen: 'AdminRegionReservations',
      params: { regionId, regionName: title },
    });
  }, [navigation, regionId, title]);

  return (
    <ListItemRow
      title={title}
      leftIcon={{ name: 'business-outline', set: 'ion' }}
      onPress={onPress}
      accessibilityRole="button"
      titleStyle={{ color: colors.secondary }}
      type="navigation"
    />
  );
}

export default function RegionsListing() {
  const { colors } = useTheme();
  const { I18n } = useI18n();
  const { state: authState } = useAuth();

  const countriesQuery = useCountryRegionsQuery(authState === AuthState.AUTHENTICATED);
  const countries = countriesQuery.data?.data?.getCountries;
  return (
    <ScrollablePage
      summary={{
        title: I18n.t('locations.summaryTitle'),
        description: I18n.t('locations.summaryDescription'),
        icon: 'pin',
      }}
    >
      {/* TODO: Placeholder while loading */}
      {countries?.map((countryInfo) =>
        countryInfo ? (
          <Fragment key={countryInfo.translationId}>
            <List.Subheader accessibilityRole={'header'} style={{ color: colors.secondary }}>
              {countryInfo.name}
            </List.Subheader>
            {countryInfo.regions?.map((region) =>
              region ? <RegionItem key={region.id} title={region.name ?? ''} regionId={region.id ?? ''} /> : null,
            )}
          </Fragment>
        ) : null,
      )}
    </ScrollablePage>
  );
}

import React, { useCallback, useMemo, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigation } from '@react-navigation/native';
import { FormikHelpers } from 'formik/dist/types';
import { useTheme } from 'react-native-paper';
import GradientButton from '../../components/GradientButton';
import { useI18n } from '../../context/I18nContext';
import { requestDiscount } from '../../payment/paymentApis';
import FormikInput from '../../components/formik/FormikInput';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import { useModal } from '../../context/ModalContext';
import TextModal, { ModalAlertType } from '../../components/modal/TextModal';
import { useMyProfileQuery } from '../../apis/appsyncApis';
import ScrollablePage from '../../components/ui/ScrollablePage';
import Container from '../../components/Container';
import Typography from '../../components/Typography';

interface UpdateOrganisationForm {
  website: string;
  email: string;
}

const UpdateOrganisation = () => {
  const navigation = useNavigation();
  const mp = useMixpanel();
  const fetchMyProfileQuery = useMyProfileQuery();
  const { colors } = useTheme();

  const [formValue] = useState<UpdateOrganisationForm>({
    website: '',
    email: '',
  });
  const { I18n } = useI18n();
  const modal = useModal();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        website: yup.string().required(I18n.t('discountRequest.validate.websiteRequired')),
        email: yup
          .string()
          .required(I18n.t('discountRequest.validate.emailRequired'))
          .email(I18n.t('discountRequest.validate.emailInvalid')),
      }),
    [I18n],
  );
  const handleSubmitForm = useCallback(
    async (form: UpdateOrganisationForm, formikHelpers: FormikHelpers<UpdateOrganisationForm>) => {
      const user = `${fetchMyProfileQuery.data?.data?.getMyProfile?.firstname ?? ''} ${
        fetchMyProfileQuery.data?.data?.getMyProfile?.lastname ?? ''
      }`;
      try {
        mp?.track('Discount request', { ...form });
        await requestDiscount(form.website, form.email, user);
        modal.openModal({
          content: (
            <TextModal
              icon="check"
              title={I18n.t('discountRequest.success')}
              description={''}
              buttons={[
                {
                  title: I18n.t('general.dismiss'),
                  onPress: () => {
                    navigation.goBack();
                    modal.closeModal();
                  },
                },
              ]}
            />
          ),
        });
        formikHelpers.setSubmitting(false);
      } catch (e) {
        mp?.track('Discount request submission failed', { ...form });
        formikHelpers.setSubmitting(false);
        modal.openModal({
          content: (
            <TextModal
              type={ModalAlertType.ERROR}
              title={I18n.t('general.requestFailedTitle')}
              description={I18n.t('general.requestFailedMsg')}
              buttons={[
                {
                  title: I18n.t('general.dismiss'),
                  onPress: () => {
                    navigation.goBack();
                    modal.closeModal();
                  },
                },
              ]}
            />
          ),
        });
      }
    },
    [I18n, navigation, mp, modal, fetchMyProfileQuery],
  );
  return (
    <ScrollablePage
      summary={{
        title: I18n.t('discountRequest.summaryTitle'),
        description: I18n.t('discountRequest.summaryDescription'),
        icon: 'pencil',
      }}
    >
      <Formik initialValues={formValue} validationSchema={validationSchema} onSubmit={handleSubmitForm}>
        {({ handleSubmit, isSubmitting }) => (
          <Container>
            <Typography variant="body2" color={colors.onSurface}>
              {I18n.t('discountRequest.formTitle')}
            </Typography>
            <FormikInput
              /* There seems to be a bug on web with autofocus happening during a react navigation transition with a fixed side bar causing glitching */
              // https://github.com/react-navigation/react-navigation/issues/9875
              autoFocus={Platform.OS === 'web' ? undefined : true}
              name={'website'}
              label={I18n.t('discountRequest.website')}
              style={styles.input}
              keyboardType={'url'}
              autoCapitalize={'none'}
            />
            <FormikInput
              name={'email'}
              label={I18n.t('discountRequest.email')}
              style={styles.input}
              keyboardType={'email-address'}
              autoCapitalize={'none'}
            />
            <GradientButton
              disabled={isSubmitting}
              loading={isSubmitting}
              onPress={() => handleSubmit()}
              style={{ marginTop: 50 }}
            >
              {I18n.t('discountRequest.send')}
            </GradientButton>
          </Container>
        )}
      </Formik>
    </ScrollablePage>
  );
};

const styles = StyleSheet.create({
  input: {
    marginTop: 30,
  },
  textButton: {
    marginTop: 15,
    alignSelf: 'flex-end',
  },
});

export default UpdateOrganisation;

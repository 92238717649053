import React, { useMemo, useState } from 'react';
import { HelperText } from 'react-native-paper';
import { useMutation, useQueryClient } from 'react-query';
import { StackScreenProps } from '@react-navigation/stack';
import { isWithinInterval } from 'date-fns';
import { useI18n } from '../../context/I18nContext';
import { endReservation, invalidateReservationData } from '../../apis/reservationApis';
import ConfirmationRow from '../components/ConfirmationRow';
import { useNowMinutes } from '../../hooks/useNow';
import { CompoundReservation } from '../../types/misc.types';
import { flattenDict } from '../../utils/helpers';
import { NavigationDialog } from '../../screens/CommonDialogs';
import { useMixpanel } from '../../mixpanel/MixpanelContext';

export interface EndReservationDialogRouteParams {
  reservation: CompoundReservation;
}

type ScreenProps = StackScreenProps<{ EndReservationDialog: EndReservationDialogRouteParams }, 'EndReservationDialog'>;

function findCurrentOriginalReservation(
  res: CompoundReservation,
  now: Date,
): CompoundReservation['originals'][number] | undefined {
  // console.log("Oiriginals", res);
  return res.originals.find((orig) => {
    try {
      return isWithinInterval(now, { start: new Date(orig.from!), end: new Date(orig.to!) });
    } catch {
      return false;
    }
  });
}

export default function EndReservationDialog({ navigation, route }: ScreenProps) {
  const { reservation: compoundReservation } = route.params;
  const { I18n, formatDateRange, formatDurationBetween } = useI18n();
  const mp = useMixpanel();
  const [errorMessage, setErrorMessage] = useState<string>();
  const now = useNowMinutes();
  const queryClient = useQueryClient();

  const currentReservation: CompoundReservation['originals'][number] | undefined = useMemo(
    () => findCurrentOriginalReservation(compoundReservation, now),
    [compoundReservation, now],
  );

  const endReservationMutation = useMutation(
    async (input: Parameters<typeof endReservation>) => {
      return endReservation(...input);
    },
    {
      onSuccess(data) {
        mp?.getPeople().increment('Reservations ended', 1);
        mp?.track('Reservation end success', flattenDict('reservation', data.data?.endAReservation));

        setTimeout(() => {
          invalidateReservationData(queryClient);
        }, 1000);

        navigation.goBack();
      },
      onError(error: any) {
        console.error('Reservation end failed', error);
        mp?.getPeople().increment('Reservations endings failed', 1);
        mp?.track('Reservation end fail', error);
        setErrorMessage(I18n.t('extendReservation.endReservationFailed'));
      },
    },
  );

  const onConfirmEnd = async () => {
    /* Actual success / fail handling is in the mutation */
    await endReservationMutation.mutateAsync([{ id: currentReservation?.id! }]);
  };

  const durationText = formatDurationBetween(compoundReservation.from, compoundReservation.to);
  const timeText = `${formatDateRange(
    compoundReservation.from,
    compoundReservation.to,
    'time',
    compoundReservation.timeZone,
  )} (${durationText})`;

  return (
    <NavigationDialog
      title={I18n.t('general.endBooking')}
      description={''}
      iconName={'check'}
      actions={[
        {
          title: I18n.t('general.cancel'),
          onPress: () => {
            navigation.goBack();
          },
          disable: endReservationMutation.isLoading,
        },
        {
          title: I18n.t('general.endBooking'),
          loading: endReservationMutation.isLoading,
          disable: endReservationMutation.isLoading || !currentReservation,
          onPress: onConfirmEnd,
        },
      ]}
    >
      <ConfirmationRow title={timeText} iconName={'calendar'} />

      <ConfirmationRow
        title={I18n.t('extendReservation.endTimeConfirm', {
          duration: formatDurationBetween(new Date(compoundReservation?.from!), now),
        })}
        iconName={'clock'}
      />
      {/* TODO: Calculate estimated refund and security deposit */}
      {/* <ConfirmationRow title={formatCurrency(price, currency)} iconName={'wallet'} /> */}
      <HelperText visible={!!errorMessage} type={'error'}>
        {errorMessage}
      </HelperText>
    </NavigationDialog>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { RadioButton, TextInput } from 'react-native-paper';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { MixpanelProperties } from 'mixpanel-react-native';
import { useI18n } from '../../context/I18nContext';
import GradientButton from '../../components/GradientButton';
import { useAuth } from '../../context/AuthContext';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import Radio from '../../components/ui/Radio';
import ScrollablePage from '../../components/ui/ScrollablePage';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';

export default function WelcomeWithoutInvite({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const { I18n } = useI18n();
  const auth = useAuth();
  const mp = useMixpanel();
  const [value, setValue] = useState<string>('none');
  const [otherDetails, setOtherDetails] = useState<string>('');

  // https://github.com/callstack/react-native-paper/issues/1322
  const otherDetailsRef = useRef<any>(null);

  useEffect(() => {
    if (value === 'Other') {
      otherDetailsRef.current?.focus();
    }
  });
  return (
    <ScrollablePage
      summary={{
        title: I18n.t('welcome.questions.title'),
        description: I18n.t('welcome.questions.description'),
        icon: 'heart',
      }}
    >
      <Container>
        <RadioButton.Group onValueChange={setValue} value={value}>
          <Radio label={I18n.t('welcome.questions.optFriend')} value="Friend" />
          <Radio label={I18n.t('welcome.questions.optSeen')} value="Seen" />
          <Radio label={I18n.t('welcome.questions.optEmployer')} value="Employer" />
          <Radio label={I18n.t('welcome.questions.optSocial')} value="SocialMedia" />
          <Radio label={I18n.t('welcome.questions.optNonOnlineAd')} value="Meeting" />
          <Radio label={I18n.t('welcome.questions.optSearch')} value="SearchOnline" />
          <Radio label={I18n.t('welcome.questions.optOther')} value="Other" />
        </RadioButton.Group>

        <TextInput
          ref={otherDetailsRef}
          mode={'outlined'}
          label={I18n.t('welcome.questions.otherDetails')}
          value={otherDetails}
          style={{ paddingTop: 0, paddingLeft: 46, marginTop: -10 }}
          onFocus={() => setValue('Other')}
          multiline
          onChangeText={setOtherDetails}
        />

        <Spacer type={'section'} />
        <GradientButton
          disabled={!!(value === 'none' || (value === 'Other' && otherDetails === ''))}
          style={{ width: '100%' }}
          onPress={() => {
            const properties: MixpanelProperties = {
              source: value,
            };
            if (value === 'Other') {
              properties.details = otherDetails;
            }
            mp?.track('Welcome Questionnaire', properties);
            mp?.getPeople().set('Source', value);
            auth.refreshAuthState();
          }}
        >
          {I18n.t('general.continue')}
        </GradientButton>
        <Spacer type={'section'} />
      </Container>
    </ScrollablePage>
  );
}

const Images = <const>{
  iconAppLogo: require('./icons/ic_spacehub.svg').default,
  iconApp: require('./icons/ic_app.svg').default,
  iconProfile: require('./icons/ic_profile.svg').default,
  iconError: require('./icons/ic_error.svg').default,
  iconInfo: require('./icons/ic_information_circle_outline.svg').default,
  iconLogout: require('./icons/ic_logout.svg').default,
  iconGooglePayMark: require('./icons/google-pay-mark_800.svg').default,
  imageLeftPerson: require('./images/img_left_person.svg').default,
  imageLeftPersonLarge: require('./images/img_left_person_large.svg').default,
  imageRightPerson: require('./images/img_right_person.svg').default,
  imageRightPersonLarge: require('./images/img_right_person_large.svg').default,
  imageMask: require('./images/img_mask.svg').default,
  imagePodComfort: require('./images/img_pod_comfort.svg').default,
  imageSlide2: require('./images/img_slide2.png'), // todo change to svg
  imageSlide3: require('./images/img_slide3.png'), // todo change to svg
  imageSlide4: require('./images/img_slide4.png'), // todo change to svg
  imageSlide5: require('./images/img_slide5.png'), // todo change to svg
  pods: {
    coming_soon: require('./images/img_pod_coming_soon.png'),
    framery_q_maggie: require('./images/img_pod_framery-q-maggie.png'),
    framery_o: require('./images/img_pod_framery-o.png'),
    framery_2q_huddle_av: require('./images/img_pod_framery-2q-huddle-av.png'),
    smartblock_chattime_closed_av: require('./images/img_pod_smartblock-chattime-closed-av.png'),
    smartblock_meettime_closed_av: require('./images/img_pod_smartblock-meettime-closed-av.png'),
    smartblock_chattime_closed: require('./images/img_pod_smartblock-chattime-closed.png'),
    smartblock_meettime_closed: require('./images/img_pod_smartblock-meettime-closed.png'),
    vetrospace_groupspace_m: require('./images/img_pod_vetrospace-groupspace-m.png'),
  },
  ImageScanTarget: require('./images/img_scan_target.svg').default,
  ImageCard: require('./icons/ic_card.svg').default,
  playStore: require('./images/img_download_google_play.svg'),
  appStore: require('./images/img_download_app_store.svg'),
  storeIcon: require('./icons/storeIcon.svg'),
  storeIcon_512: require('./images/storeIcon_512.png'),
  meetingRoom: require('./images/img_meeting_room.png'),
};

export default Images;

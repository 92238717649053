/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { Amplify, Hub } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LogBox, Platform } from 'react-native';
import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import { Analytics } from '@aws-amplify/analytics';
import { HubCapsule } from '@aws-amplify/core';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import I18nProvider from './src/context/I18nContext';
import config from './aws-exports';
import podconf from './pod-exports.json';
import { StripeProvider } from './src/payment/utils/stripe';
import { AuthProvider } from './src/context/AuthContext';
import { IntroContextProvider } from './src/context/IntroContext';
import PreferenceProvider from './src/context/PreferenceContext';
import RootNavigationContainer from './src/navigators/RootNavigationContainer';
import { UserLocationProvider } from './src/context/UserLocationContext';
import { HardwareStatusProvider } from './src/context/HardwareContext';
import { MixpanelProvider } from './src/mixpanel/MixpanelContext';
import { useCustomFonts } from './src/utils/paperTheme';
import { initAppPushNotification } from './src/utils/pushNotifications';

type AuthErrorData = any;
type AuthEventPayload =
  | { event: 'forgotPasswordSubmit'; data: CognitoUser; message: string }
  | { event: 'completeNewPassword_failure'; data: AuthErrorData; message: string }
  | { event: 'forgotPassword_failure'; data: AuthErrorData; message: string }
  | { event: 'forgotPasswordSubmit_failure'; data: AuthErrorData; message: string }
  | { event: 'parsingCallbackUrl'; data: { url: URL }; message: string }
  | { event: 'cognitoHostedUI'; data: CognitoUser; message: string }
  | { event: 'signIn'; data: CognitoUser; message: string }
  | { event: 'signUp'; data: ISignUpResult; message: string }
  | { event: 'signOut'; data: any; message: string }
  | { event: 'signIn_failure'; data: AuthErrorData; message: string }
  | { event: 'tokenRefresh'; data: undefined; message: string }
  | { event: 'tokenRefresh'; data: undefined; message: string }
  | { event: 'tokenRefresh_failure'; data: AuthErrorData; message: string }
  | { event: 'configured'; data: null; message: string };

type AuthEventHubCapsule = {
  channel: 'auth';
  payload: AuthEventPayload;
  source: 'Auth';
};

// Using this to hide following warning while debugging as they spam the console quite a lot
if (Platform.OS !== 'web') {
  LogBox.ignoreLogs(['Setting a timer', 'ViewPropTypes will be removed from React Native']);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true, // If doing profiling in the browser, change to false.
    },
  },
});

export default function App() {
  const [fontsLoaded] = useCustomFonts();
  const [isAmplifyAuthConfigured, setAmplifyAuthConfigured] = useState<boolean>(false);

  useEffect(() => {
    const hubListener = (origEv: HubCapsule) => {
      const ev = origEv as AuthEventHubCapsule;
      if (ev.payload.event === 'configured') {
        setAmplifyAuthConfigured(true);
      }
    };

    Hub.listen('auth', hubListener);
    Amplify.configure(config);
    Analytics.configure({
      disabled: false,
      AWSPinpoint: {
        appId: config.aws_mobile_analytics_app_id,
        region: config.aws_project_region,
        mandatorySignIn: false,
      },
    });

    // Initializes push notes in case we have all permissions granted, otherwise we deal with them later
    initAppPushNotification();

    return () => {
      Hub.remove('auth', hubListener);
    };
  }, []);

  return fontsLoaded ? (
    <SafeAreaProvider>
      <HardwareStatusProvider>
        <MixpanelProvider apiToken={podconf.mixpanelToken}>
          <QueryClientProvider client={queryClient}>
            <IntroContextProvider>
              <AuthProvider amplifyAuthConfigured={isAmplifyAuthConfigured}>
                <I18nProvider>
                  <PreferenceProvider>
                    <StripeProvider publishableKey={podconf.stripePublishKey}>
                      <UserLocationProvider>
                        <RootNavigationContainer />
                      </UserLocationProvider>
                    </StripeProvider>
                  </PreferenceProvider>
                </I18nProvider>
              </AuthProvider>
            </IntroContextProvider>
          </QueryClientProvider>
        </MixpanelProvider>
      </HardwareStatusProvider>
    </SafeAreaProvider>
  ) : (
    <></>
  );
}

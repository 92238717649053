import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import { HelperText, TextInput, TextInputProps, useTheme } from 'react-native-paper';
import { UiIcon } from '../SimpleListIcon';

export interface FormikInputProps extends Partial<TextInputProps> {
  name: string;
}

const FormikInput: FC<FormikInputProps> = ({ name, value, ...other }) => {
  const [field, meta] = useField(name);
  const { colors } = useTheme();
  const { handleBlur, handleChange } = useFormikContext();
  const isError = meta.touched && meta.error;
  return (
    <>
      <TextInput
        {...other}
        value={value === undefined ? field.value : value}
        onChangeText={handleChange(name)}
        onBlur={handleBlur(name)}
        error={!!isError}
        right={isError && <UiIcon name={'exclamation'} color={colors.error} />}
        mode={'outlined'}
      />
      <HelperText visible={!!isError} type={'error'}>
        {meta.error}
      </HelperText>
    </>
  );
};

export default FormikInput;

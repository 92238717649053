import React, { useCallback, useEffect, useState } from 'react';
import { HelperText, List, useTheme } from 'react-native-paper';
import { Platform } from 'react-native';
import { useActivePayment } from '../MapViewActivePaymentContext';
import { usePlatformPay } from '../utils/stripe';
import { useI18n } from '../../context/I18nContext';
import Container from '../../components/Container';
import { PaymentMethodOption } from '../../types/misc.types';
import { GooglePayIcon } from '../../utils/GooglePayIcon';
import { listIconGenerator } from '../../components/SimpleListIcon';
import { ListItemRow } from '../../components/ListItemRow';

type OnPressHandleWalletMethod = (paymentMethodOption: PaymentMethodOption.GOOGLEPAY | never) => void;

interface PaymentWalletListProps {
  onPress: OnPressHandleWalletMethod;
  hasArrows: boolean;
}

function GooglePayListItem({ onPress, hasArrows }: PaymentWalletListProps) {
  const { I18n } = useI18n();
  const { colors } = useTheme();
  const { activePaymentMethod } = useActivePayment();

  const googlePayText = 'Google Pay';
  const isGooglePayDefault =
    activePaymentMethod !== null && activePaymentMethod.paymentMethodType === PaymentMethodOption.GOOGLEPAY;
  const title = isGooglePayDefault ? `${googlePayText} ${I18n.t('payment.markedDefault')}` : googlePayText;

  return (
    <ListItemRow
      title={title}
      titleStyle={{
        fontWeight: '500',
      }}
      description={I18n.t('payment.usedWhenAvailable')}
      left={() => <GooglePayIcon style={{ marginTop: 1 }} />}
      right={hasArrows ? listIconGenerator({ name: 'arrow-right', color: colors.onSurface }) : undefined}
      onPress={() => {
        onPress(PaymentMethodOption.GOOGLEPAY);
      }}
    />
  );
}

export default function PaymentWalletList({ onPress, hasArrows }: PaymentWalletListProps) {
  const { I18n } = useI18n();
  const { isPlatformPaySupported } = usePlatformPay();
  const [showGoogleWallet, setShowGoogleWallet] = useState(false);

  useEffect(() => {
    (async () => {
      if (Platform.OS === 'android' && (await isPlatformPaySupported())) {
        setShowGoogleWallet(true);
      }
    })();
  }, [isPlatformPaySupported]);

  const renderContent = useCallback((): JSX.Element => {
    if (showGoogleWallet) {
      return <GooglePayListItem hasArrows={hasArrows} onPress={onPress} />;
    }
    return (
      <Container>
        <HelperText type={'info'}>{I18n.t('payment.noWalletDescription')}</HelperText>
      </Container>
    );
  }, [showGoogleWallet, I18n, hasArrows, onPress]);

  return (
    <>
      <List.Subheader>{I18n.t('payment.wallets')}</List.Subheader>
      {renderContent()}
    </>
  );
}

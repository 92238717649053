// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2021-02-17T13:31:57.882Z

interface IAWSAmplifyFederatedConfiguration {
  google_client_id?: string;
  facebook_app_id?: string;
  amazon_client_id?: string;
}

interface IAWSAmplifyCloudLogicConfiguration {
  [index: number]: {
    endpoint: string;
    name: string;
    region: string;
  };
}

interface IAWSAmplifyConfiguration {
  aws_appsync_authenticationType?: string;
  aws_appsync_graphqlEndpoint?: string;
  aws_appsync_region?: string;
  aws_cognito_identity_pool_id?: string;
  aws_cognito_region?: string;
  aws_cloud_logic_custom?: IAWSAmplifyCloudLogicConfiguration;
  aws_project_region: string;
  aws_user_files_s3_bucket?: string;
  aws_user_files_s3_bucket_region?: string;
  aws_user_pools_id?: string;
  aws_user_pools_web_client_id?: string;
  aws_user_pools_web_client_secret?: string;
  aws_mobile_analytics_app_id?: string;
  federated?: IAWSAmplifyFederatedConfiguration;
  aws_rest_api_url?: string;
}

const awsmobile: IAWSAmplifyConfiguration = {
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://appsync.app.dev.getspacehub.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:4dfdf6e0-bce8-4a41-80a4-ef9882ee62e5',
  aws_cognito_region: 'eu-central-1',
  aws_project_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_wkkeA0zM7',
  aws_user_pools_web_client_id: '1qhlirt0elrrg40rqmp0m3jmqk',
  aws_mobile_analytics_app_id: 'b37f61f735f841a0a5ae5965d64bcbdd',
  aws_rest_api_url: 'https://rest.app.dev.getspacehub.com',
};

export default awsmobile;

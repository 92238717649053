import React, { FC } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

interface Props {
  marginSize?: 'xs' | 'none' | 'medium' | 'small';
}

const Container: FC<ViewProps & Props> = ({ marginSize = 'small', children, style, ...other }) => {
  const marginSizeMap = {
    medium: styles.mediumSize,
    xs: styles.xtraSmallSize,
    small: styles.smallSize,
    none: styles.noneSize,
  };
  return (
    <View style={[marginSizeMap[marginSize], style]} {...other}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  noneSize: {
    paddingHorizontal: 0,
  },
  xtraSmallSize: {
    paddingHorizontal: 8,
  },
  smallSize: {
    paddingHorizontal: 16,
  },
  mediumSize: {
    paddingHorizontal: 24,
  },
});

export default Container;

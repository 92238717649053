import React, { useMemo } from 'react';
import { FlatList } from 'react-native';
import { List } from 'react-native-paper';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { getTimeZone } from 'react-native-localize';
import { useI18n } from '../../context/I18nContext';
import { useMyProfileQuery } from '../../apis/appsyncApis';
import { useGetRecentCreditBalanceHistory } from '../paymentApis';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { InformationStackRouteParams } from '../../navigators/InformationStackNavigator';
import { CreditType } from '../../types/appsync-types';
import { ListItemRow } from '../../components/ListItemRow';

type Props = CompositeScreenProps<StackScreenProps<InformationStackRouteParams, 'CreditBalance'>, AppDrawerScreenProp>;

const CreditBalance: React.FC<Props> = ({ navigation }) => {
  const { I18n, formatDate, formatCurrency } = useI18n();
  const myProfileResult = useMyProfileQuery();
  const recentCreditBalanceQuery = useGetRecentCreditBalanceHistory();

  const creditItems = useMemo(() => {
    /* Drop transactions that had no change */
    return recentCreditBalanceQuery.data?.data?.getMyRecentCreditHistory?.filter((ev) => ev?.change !== 0) ?? [];
  }, [recentCreditBalanceQuery.data?.data?.getMyRecentCreditHistory]);

  return (
    <ScrollablePage
      summary={{
        icon: 'credit-card',
        title: formatCurrency(
          myProfileResult.data?.data?.getMyProfile?.creditBalance,
          myProfileResult.data?.data?.getMyProfile?.currency,
        ),
      }}
    >
      <List.Subheader>{I18n.t('payment.recentTransaction')}</List.Subheader>
      <FlatList
        data={creditItems}
        keyExtractor={(item) => item!.transactionId!}
        renderItem={({ item }) => {
          const leftIconName = (() => {
            switch (item?.creditType) {
              case CreditType.FRIEND_INVITE_CODE:
              case CreditType.REFUND:
              case CreditType.KICKBACK:
              case CreditType.VOUCHER_CODE:
                return 'heart';
              case CreditType.RESERVATION:
              case CreditType.RESERVATION_EXTEND:
              case CreditType.BONUS:
              case CreditType.PROMO_INVITE_CODE:
              default:
                return 'rocket';
            }
          })();

          const title = (() => {
            // The reason I used a switch statement rather than clever string interpolation
            // Is that for i18n string it is easier to search for where a string is used
            // If the actual string is present than a variable name
            switch (item?.creditType) {
              case CreditType.FRIEND_INVITE_CODE:
                return I18n.t('creditBalance.gotCreditFromInvite', {
                  name: item?.sourceName ?? '',
                });
              case CreditType.REFUND:
                return I18n.t('creditBalance.refunded');
              case CreditType.KICKBACK:
                return I18n.t('creditBalance.someoneUsedCode', { name: item?.sourceName ?? '' });
              case CreditType.VOUCHER_CODE:
                return I18n.t('creditBalance.appliedVoucher');
              case CreditType.RESERVATION:
                return I18n.t('creditBalance.creditSpentOnBooking');
              case CreditType.RESERVATION_EXTEND:
                return I18n.t('creditBalance.creditSpentOnBookingExtend');
              case CreditType.BONUS:
                return I18n.t('creditBalance.bonusEarned');
              case CreditType.PROMO_INVITE_CODE:
                return I18n.t('creditBalance.promoCodeApplied');
              default:
                return item?.creditType!;
            }
          })();

          return (
            <ListItemRow
              title={title}
              description={`${item?.change! > 0 ? '+' : '-'} ${formatCurrency(
                Math.abs(item?.change!),
                myProfileResult.data?.data?.getMyProfile?.currency?.toLowerCase(),
              )} | ${formatDate(new Date(item?.transactionTime!), 'timeAndDate', getTimeZone())}`}
              leftIcon={leftIconName}
            />
          );
        }}
      />
    </ScrollablePage>
  );
};

export default CreditBalance;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, HelperText, TextInput } from 'react-native-paper';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { Keyboard, Platform } from 'react-native';
import { useI18n } from '../context/I18nContext';
import { useGetQRPayloadValue } from '../apis/mapApis';
import { screenNames, drawerScreens } from '../navigators/screenNames';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import { useModal } from '../context/ModalContext';
import ScrollablePage from '../components/ui/ScrollablePage';
import Container from '../components/Container';
import Spacer from '../components/Spacer';
import ApplyVoucherCodeModal from '../components/modal/ApplyVoucherCodeModal';
import { SetVoucherCodeAction, UnlockAction } from '../utils/urlHandler';
import { flattenDict } from '../utils/helpers';
import { MapStackRouteParams } from '../navigators/MapStackNavigator';
import { AppDrawerScreenProp } from '../components/DrawerMenuContent';
import { AppsyncData } from '../apis/appsyncHelper';

type screenprops1 = StackScreenProps<MapStackRouteParams, 'EnterCodeManuallyScreen'>;
type screenpropsCombined = CompositeScreenProps<screenprops1, AppDrawerScreenProp>;
const EnterCodeManuallyScreen: React.FC<screenpropsCombined> = ({ navigation, route }) => {
  const {
    I18n: { t },
  } = useI18n();
  const modal = useModal();
  // const [manuallyAddedCode, setManuallyAddedCode] = useState("");
  const [action, setAction] = useState<UnlockAction | SetVoucherCodeAction>({
    type: route.params?.mode ?? 'UNLOCK',
    id: '',
  });
  const qrCodeResult = useGetQRPayloadValue(action?.id ? action : null);
  const mp = useMixpanel();

  useEffect(() => {
    navigation.setOptions({
      title:
        route.params?.mode === 'VOUCHER'
          ? t('mapview.enterId.headerTitleVoucherCode')
          : t('mapview.enterId.headerTitleSpaceId'),
    });
  }, [navigation, route.params?.mode, t]);

  useEffect(() => {
    if (route.params?.mode === 'VOUCHER' && route.params?.code) {
      setAction({
        type: 'VOUCHER',
        id: route.params.code,
      });
    }
  }, [route.params]);

  const handleChangeCode = useCallback(
    (value: string) => {
      setAction((oldAction) => {
        return {
          type: oldAction?.type ?? route.params?.mode ?? 'UNLOCK',
          id: value.replace(' ', '').toLocaleUpperCase(),
        };
      });
    },
    [route.params?.mode],
  );

  const textForScreen = useMemo(() => {
    switch (route.params?.mode) {
      case 'VOUCHER':
        return {
          summaryTitle: t('mapview.enterId.voucherSummaryTitle'),
          summaryDescription: t('mapview.enterId.voucherSummaryDescription'),
          notFoundText: t('mapview.enterId.noSuchVoucher'),
        };
      case 'UNLOCK':
      default:
        return {
          summaryTitle: t('mapview.enterId.podSummaryTitle'),
          summaryDescription: t('mapview.enterId.podSummaryDescription'),
          notFoundText: t('mapview.enterId.noSuchPod'),
        };
    }
  }, [route.params?.mode, t]);

  const handleSpaceCodeDone = useCallback(
    (poi: AppsyncData<'getPoisByCode'>) => {
      if (poi.getPoisByCode?.localRef) {
        mp?.track('QR code manually entered', flattenDict('space', poi?.getPoisByCode));
        const params: { action: UnlockAction } = {
          action: {
            type: 'UNLOCK',
            id: poi.getPoisByCode.localRef,
          },
        };
        navigation.navigate({
          name: drawerScreens.MapStack,
          params: {
            screen: screenNames.Map,
            params,
          },
          merge: true, // This makes the parent screen, i.e. mapview get these new params
        });
      }
    },
    [mp, navigation],
  );

  const handleVoucherCodeDone = useCallback(
    (voucher: AppsyncData<'getVoucherByCode'>) => {
      modal.openModal({
        content: <ApplyVoucherCodeModal voucher={voucher} onDismiss={modal.closeModal} />,
      });
    },
    [modal],
  );

  const handleOnPress = useCallback(() => {
    Keyboard.dismiss();
    if (qrCodeResult.isSuccess) {
      if (qrCodeResult.data?.data?.getPoisByCode) {
        return handleSpaceCodeDone(qrCodeResult.data?.data);
      }
      if (qrCodeResult.data?.data?.getVoucherByCode) {
        return handleVoucherCodeDone(qrCodeResult.data?.data);
      }
    }
  }, [handleSpaceCodeDone, handleVoucherCodeDone, qrCodeResult]);

  return (
    <ScrollablePage
      summary={{
        title: textForScreen.summaryTitle,
        description: textForScreen.summaryDescription,
        icon: 'pencil',
      }}
    >
      <Container>
        <TextInput
          /* There seems to be a bug on web with autofocus happening during a react navigation transition with a fixed side bar causing glitching */
          // https://github.com/react-navigation/react-navigation/issues/9875
          autoFocus={Platform.OS === 'web' ? undefined : true}
          mode={'outlined'}
          label={t('mapview.enterId.id')}
          value={action?.id}
          autoCapitalize={'characters'}
          onChangeText={handleChangeCode}
          error={qrCodeResult.isError}
        />
        {qrCodeResult.isError && <HelperText type={'error'}>{textForScreen.notFoundText}</HelperText>}
        <Spacer />
        <Button
          mode={'outlined'}
          onPress={handleOnPress}
          style={{ marginBottom: 20 }}
          loading={qrCodeResult.isLoading}
          disabled={!qrCodeResult.isSuccess}
        >
          {t('mapview.enterId.continue')}
        </Button>
      </Container>
    </ScrollablePage>
  );
};

export default EnterCodeManuallyScreen;

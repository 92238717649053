import React from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, useTheme } from 'react-native-paper';
import { UiDefaultIconName } from '../../components/SimpleListIcon';
import { ListItemRow } from '../../components/ListItemRow';

interface ConfirmationRowProps {
  title: string;
  iconName: UiDefaultIconName;
  isLoading?: boolean;
}
export default function ConfirmationRow({ title, iconName, isLoading }: ConfirmationRowProps) {
  const { colors } = useTheme();
  return (
    <ListItemRow
      title={isLoading ? '' : title}
      titleNumberOfLines={2}
      titleStyle={{ color: colors.onSurfaceVariant }}
      leftIcon={iconName}
      style={styles.listItem}
      right={() => {
        if (isLoading) {
          return <ActivityIndicator color={colors.secondary} />;
        }
        return null;
      }}
    />
  );
}

const styles = StyleSheet.create({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    marginStart: 8, // To aling the list item with other content
  },
  modalRow: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
  },
});
export const ModalRowStyle = styles.modalRow;

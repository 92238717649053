import {
  PaymentMethod as PaymentMethodJS,
  PaymentIntent as PaymentIntentJS,
  SetupIntent as SetupIntentJS,
  SetupIntentResult as SetupIntentResultJS,
  StripeCardElementChangeEvent as StripeCardElementChangeEventJS,
  PaymentMethodCreateParams as PaymentMethodCreateParamsJS,
} from '@stripe/stripe-js';
import type {
  ConfirmSetupIntentError,
  ConfirmSetupIntentResult,
  Token,
  PaymentIntent,
  PaymentMethod,
  Address,
  BillingDetails,
} from '@stripe/stripe-react-native';
import { LastPaymentError } from '@stripe/stripe-react-native/lib/typescript/src/types/PaymentIntent';
import type {
  FutureUsage as SetupIntentFuturueUsage,
  Status as SetupIntentStatus,
} from '@stripe/stripe-react-native/lib/typescript/src/types/SetupIntent';
import type { Status as PaymentIntentStatus } from '@stripe/stripe-react-native/lib/typescript/src/types/PaymentIntent';
import type { NextAction, UrlRedirectAction } from '@stripe/stripe-react-native/lib/typescript/src/types/NextAction';

type PaymentMethodTypesJS =
  | 'acss_debit'
  | 'alipay'
  | 'au_becs_debit'
  | 'bancontact'
  | 'card'
  | 'eps'
  | 'giropay'
  | 'ideal'
  | 'klarna'
  | 'p24'
  | 'sepa_debit'
  | 'sofort'
  | 'afterpay_clearpay'
  | 'grabpay'
  | 'fpx'
  | 'wechat_pay';

export function brandToNative(
  brandJS: StripeCardElementChangeEventJS['brand'] | string | undefined,
): Token.CardBrand | undefined {
  const lookup: Record<StripeCardElementChangeEventJS['brand'], Token.CardBrand> = {
    visa: 'Visa',
    mastercard: 'MasterCard',
    amex: 'AmericanExpress',
    discover: 'Discover',
    diners: 'DinersClub',
    jcb: 'JCB',
    unionpay: 'UnionPay',
    unknown: 'Unknown',
  };
  if (brandJS) {
    return lookup[brandJS as StripeCardElementChangeEventJS['brand']] || lookup.unknown;
  }
  return undefined;
}
export function intentNextActionToNative(
  nextActionJS: SetupIntentJS.NextAction | PaymentIntentJS.NextAction | null,
): NextAction | null {
  if (!nextActionJS) return null;
  if (nextActionJS.type === 'redirect_to_url' && nextActionJS.redirect_to_url?.url) {
    const res: UrlRedirectAction = {
      type: 'urlRedirect',
      redirectUrl: nextActionJS.redirect_to_url.url,
    };
    return res;
  }
  if (nextActionJS.type === 'use_stripe_sdk') {
    // TODO
  }
  if (nextActionJS.type === 'wechat_pay_display_qr_code') {
    // TODO
  }
  return null;
}
export function paymentMethodTypeToNative(typeJS: PaymentMethodTypesJS | undefined): PaymentMethod.Type {
  if (!typeJS) return 'Unknown';
  const lookup: Record<PaymentMethodTypesJS, PaymentMethod.Type> = {
    // stripe_account: "Unknown",
    // wechat: "Unknown",
    // ach_credit_transfer: "Unknown",
    // ach_debit: "Unknown",
    acss_debit: 'Unknown',
    // multibanco: "Unknown",
    klarna: 'Unknown',
    wechat_pay: 'Unknown',
    card: 'Card',
    alipay: 'Alipay',
    ideal: 'Ideal',
    // card_present: "CardPresent",
    sepa_debit: 'SepaDebit',
    au_becs_debit: 'AuBecsDebit',
    giropay: 'Giropay',
    p24: 'P24',
    eps: 'Eps',
    bancontact: 'Bancontact',
    sofort: 'Sofort',
    afterpay_clearpay: 'AfterpayClearpay',
    grabpay: 'GrabPay',
    fpx: 'Fpx',
  };
  return lookup[typeJS] ?? 'Unknown';
}

export function paymentMethodTypeToJs(t: PaymentMethod.Type): PaymentMethodTypesJS | undefined {
  const lookup: Record<PaymentMethod.Type, PaymentMethodTypesJS | undefined> = {
    AfterpayClearpay: 'afterpay_clearpay',
    GrabPay: 'grabpay',
    Fpx: 'fpx',
    BacsDebit: undefined,
    Oxxo: undefined,
    Upi: undefined,
    Unknown: undefined,
    Card: 'card',
    Alipay: 'alipay',
    Ideal: 'ideal',
    CardPresent: undefined,
    SepaDebit: 'sepa_debit',
    AuBecsDebit: 'au_becs_debit',
    Giropay: 'giropay',
    P24: 'p24',
    Eps: 'eps',
    Bancontact: 'bancontact',
    Sofort: 'sofort',
    USBankAccount: undefined,
    PayPal: undefined, // TODO: should this be undefined or something else?
  };
  return lookup[t];
}
function setupIntentUsageToNative(s: string): SetupIntentFuturueUsage {
  const lookup: Record<string, SetupIntentFuturueUsage> = {
    on_session: 'OnSession',
    off_session: 'OffSession',
  };
  return lookup[s] || 'Unknown';
}

function setupIntentStatusToNative(status: string): SetupIntentStatus {
  const lookup: Record<string, SetupIntentStatus> = {
    requires_payment_method: 'RequiresPaymentMethod' as SetupIntentStatus,
    requires_confirmation: 'RequiresConfirmation' as SetupIntentStatus,
    requires_action: 'RequiresAction' as SetupIntentStatus,
    processing: 'Processing' as SetupIntentStatus,
    canceled: 'Canceled' as SetupIntentStatus,
    succeeded: 'Succeeded' as SetupIntentStatus,
  };
  return lookup[status] || 'Unknown';
}

export function paymentIntentStatusToNative(status: PaymentIntentJS.Status): PaymentIntentStatus {
  const lookup: Record<string, PaymentIntentStatus> = {
    requires_payment_method: 'RequiresPaymentMethod' as PaymentIntentStatus.RequiresPaymentMethod,
    requires_confirmation: 'RequiresConfirmation' as PaymentIntentStatus.RequiresConfirmation,
    requires_capture: 'RequiresCapture' as PaymentIntentStatus.RequiresCapture,
    requires_action: 'RequiresAction' as PaymentIntentStatus.RequiresAction,
    processing: 'Processing' as PaymentIntentStatus.Processing,
    canceled: 'Canceled' as PaymentIntentStatus.Canceled,
    succeeded: 'Succeeded' as PaymentIntentStatus.Succeeded,
  };
  return lookup[status] || ('Unknown' as PaymentIntentStatus.Unknown);
}

export function paymentMethodIdToNative(payment_method: string | PaymentMethodJS | null): string | null {
  return payment_method && typeof payment_method === 'object' ? payment_method.id : payment_method;
}

export function convertConfirmSetupIntentResult(resultJS: SetupIntentResultJS): ConfirmSetupIntentResult {
  if (resultJS.setupIntent) {
    return {
      setupIntent: {
        id: resultJS.setupIntent.id,
        clientSecret: resultJS.setupIntent.client_secret ?? '',
        lastSetupError: paymentIntentLastPaymentErrorToNative(resultJS.setupIntent.last_setup_error),
        created: new Date(resultJS.setupIntent.created).toISOString(),
        livemode: resultJS.setupIntent.livemode,
        paymentMethodId: paymentMethodIdToNative(resultJS.setupIntent.payment_method),
        status: setupIntentStatusToNative(resultJS.setupIntent.status),
        paymentMethodTypes: resultJS.setupIntent.payment_method_types.map((e) =>
          paymentMethodTypeToNative(e as PaymentMethodTypesJS),
        ),
        usage: setupIntentUsageToNative(resultJS.setupIntent.usage),
        description: resultJS.setupIntent.description,
        nextAction: intentNextActionToNative(resultJS.setupIntent.next_action),
        paymentMethod: resultJS.setupIntent.payment_method as any, // TODO
      },
    };
  }
  return {
    error: {
      message: resultJS.error.message ?? 'Stripe-js error message missing',
      code: resultJS.error.code as ConfirmSetupIntentError,
    },
  };
}

export function paymentIntentShippingToNative(
  shipping: PaymentIntentJS.Shipping | null,
): PaymentIntent.ShippingDetails | null {
  if (!shipping || !shipping.address) return null;

  return {
    address: {
      city: shipping.address.city ?? '',
      country: shipping.address.country ?? '',
      line1: shipping.address.line1 ?? '',
      line2: shipping.address.line2 ?? '',
      postalCode: shipping.address.postal_code ?? '',
      state: shipping.address.state ?? '',
    },
    name: shipping.name ?? '',
    carrier: shipping.carrier ?? '',
    phone: shipping.phone ?? '',
    trackingNumber: shipping.tracking_number ?? '',
  };
}

export function paymentMethodToNative(meth: PaymentMethodJS | undefined): PaymentMethod.Result {
  return {
    id: meth?.id ?? '',
    liveMode: meth?.livemode ?? false,
    customerId: meth?.customer ?? '',
    billingDetails: paymentMethodBillingDetailsToNative(meth?.billing_details),
    paymentMethodType: paymentMethodTypeToNative(meth?.type as PaymentMethodTypesJS | undefined),
    AuBecsDebit: {
      fingerprint: undefined, // Unsupported
      last4: undefined, // Unsupported
      bsbNumber: undefined, // Unsupported
    },
    BacsDebit: {
      sortCode: undefined, // Unsupported
      last4: undefined, // Unsupported
      fingerprint: undefined, // Unsupported
    },
    Card: {
      brand: brandToNative(meth?.card?.brand),
      country: meth?.card?.country ?? undefined,
      expYear: meth?.card?.exp_year,
      expMonth: meth?.card?.exp_month,
      fingerprint: meth?.card?.fingerprint ?? undefined,
      funding: meth?.card?.funding,
      last4: meth?.card?.last4,
    },
    Fpx: {
      bank: meth?.fpx?.bank,
    },
    Ideal: {
      bank: meth?.ideal?.bank ?? undefined,
      bankIdentifierCode: meth?.ideal?.bic ?? undefined,
    },
    SepaDebit: {
      bankCode: meth?.sepa_debit?.bank_code ?? undefined,
      country: meth?.sepa_debit?.country ?? undefined,
      fingerprint: meth?.sepa_debit?.fingerprint ?? undefined,
      last4: meth?.sepa_debit?.last4 ?? undefined,
    },
    Sofort: {
      country: undefined, // Unsupported
    },
    Upi: {
      vpa: undefined, // Unsupported
    },
    USBankAccount: {
      routingNumber: meth?.us_bank_account?.routing_number,
      accountHolderType: meth?.us_bank_account?.account_holder_type === 'company' ? 'Company' : 'Individual',
      accountType: meth?.us_bank_account?.account_type === 'savings' ? 'Savings' : 'Checking',
      last4: undefined,
      bankName: undefined,
      linkedAccount: undefined,
      fingerprint: undefined,
      preferredNetwork: undefined,
      supportedNetworks: undefined,
    },
  };
}

export function paymentIntentLastPaymentErrorToNative(
  perror: PaymentIntentJS.LastPaymentError | null,
): LastPaymentError | null {
  if (!perror) return null;
  return {
    message: perror.message ?? '',
    code: perror.code ?? '',
    paymentMethod: paymentMethodToNative(perror.payment_method),
    type: perror.type,
  };
}

function addressToNative(address: PaymentMethodJS.BillingDetails['address'] | undefined): Address {
  return {
    city: address?.city ?? undefined,
    country: address?.country ?? undefined,
    line1: address?.line1 ?? undefined,
    line2: address?.line2 ?? undefined,
    postalCode: address?.postal_code ?? undefined,
    state: address?.state ?? undefined,
  };
}
function addressToJS(address: Address | undefined): PaymentMethodCreateParamsJS.BillingDetails['address'] {
  if (!address) return undefined;
  return {
    city: address.city,
    country: address.country,
    line1: address.line1,
    line2: address.line2,
    postal_code: address.postalCode,
    state: address.state,
  };
}

function paymentMethodBillingDetailsToNative(bd: PaymentMethodJS.BillingDetails | undefined): BillingDetails {
  return {
    name: bd?.name ?? undefined,
    phone: bd?.phone ?? undefined,
    email: bd?.email ?? undefined,
    address: addressToNative(bd?.address),
  };
}
export function billingDetailsToJS(
  bd: BillingDetails | undefined,
): PaymentMethodCreateParamsJS.BillingDetails | undefined {
  return {
    name: bd?.name,
    phone: bd?.phone,
    email: bd?.email,
    address: addressToJS(bd?.address),
  };
}

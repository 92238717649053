import React, { useCallback, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormikHelpers } from 'formik/dist/types';
import { useI18n } from '../context/I18nContext';
import GradientButton from '../components/GradientButton';
import Spacer from '../components/Spacer';
import Radio from '../components/ui/Radio';
import FormikInput from '../components/formik/FormikInput';
import { sendAppFeedback } from '../apis/feedbackApis';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import { useModal } from '../context/ModalContext';
import { useMyProfileQuery } from '../apis/appsyncApis';
import TextModal, { ModalAlertType } from '../components/modal/TextModal';
import ScrollablePage from '../components/ui/ScrollablePage';
import Container from '../components/Container';

interface AppFeedbackForm {
  feedback: string;
  email: string;
  type: 'contact' | 'anonymously';
}

export default function AppFeedback({ navigation }: { navigation: DrawerNavigationProp<any> }) {
  const [initForm] = useState<AppFeedbackForm>({
    type: 'contact',
    feedback: '',
    email: '',
  });
  const mp = useMixpanel();
  const { I18n } = useI18n();
  const modal = useModal();
  const fetchMyProfileQuery = useMyProfileQuery();

  const validateSchema = useMemo(() => {
    return yup.object().shape({
      feedback: yup.string().required(I18n.t('help.appFeedback.validate.feedbackRequire')),
      type: yup.string(),
    });
  }, [I18n]);

  const handleSubmitForm = useCallback(
    async (form: AppFeedbackForm, formikHelper: FormikHelpers<AppFeedbackForm>) => {
      const myProfile = fetchMyProfileQuery.data?.data?.getMyProfile;
      const name = `${myProfile?.firstname ?? ''} ${myProfile?.lastname ?? ''}`;

      try {
        await sendAppFeedback(I18n.t('help.appFeedback.emailSubject'), form.feedback, form.type, form.email, name);
        mp?.track('Send feedback', { ...form });
        formikHelper.setSubmitting(false);
        modal.openModal({
          content: (
            <TextModal
              icon={'check'}
              title={I18n.t('help.appFeedback.success')}
              description={''}
              buttons={[
                {
                  title: I18n.t('general.dismiss'),
                  onPress: () => {
                    modal.closeModal();
                    navigation.goBack();
                  },
                },
              ]}
            />
          ),
        });
      } catch (e) {
        modal.openModal({
          content: (
            <TextModal
              type={ModalAlertType.ERROR}
              title={I18n.t('general.requestFailedTitle')}
              description={I18n.t('general.requestFailedMsg')}
              buttons={[{ title: I18n.t('general.dismiss'), onPress: modal.closeModal }]}
            />
          ),
        });
        formikHelper.setSubmitting(false);
      }
    },
    [I18n, navigation, mp, modal, fetchMyProfileQuery],
  );

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('help.appFeedback.summaryTitle'),
        description: I18n.t('help.appFeedback.summaryDescription'),
        icon: 'pencil',
      }}
    >
      <Formik initialValues={initForm} onSubmit={handleSubmitForm} validationSchema={validateSchema}>
        {({ values, handleChange, handleSubmit, isSubmitting, touched, errors }) => (
          <Container>
            <FormikInput
              name="feedback"
              autoCapitalize={'sentences'}
              autoFocus={Platform.OS === 'web' ? undefined : true}
              value={values.feedback}
              mode="outlined"
              label={I18n.t('help.appFeedback.freeFeedback')}
              multiline={true}
              style={{ height: 300, textAlignVertical: 'top' }}
            />
            <Spacer />
            <FormikInput
              name={'email'}
              mode="outlined"
              keyboardType="email-address"
              autoComplete="email"
              autoCapitalize="none"
              label={I18n.t('help.appFeedback.email')}
              disabled={values.type !== 'contact'}
              value={values.type !== 'contact' ? '' : undefined} // Hide value from UI if sending anonymously
            />
            <Spacer type="section" />

            <RadioButton.Group onValueChange={handleChange('type')} value={values.type}>
              <Radio label={I18n.t('help.appFeedback.allowContacting')} value={'contact'} />
              <Spacer />
              <Radio label={I18n.t('help.appFeedback.sendAnonymously')} value={'anonymously'} />
            </RadioButton.Group>

            <Spacer type="section" />

            <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
              <GradientButton
                onPress={() => handleSubmit()}
                disabled={isSubmitting}
                loading={isSubmitting}
                icon="paper-plane"
              >
                {I18n.t('help.appFeedback.sendButton')}
              </GradientButton>
              <Spacer type="section" />
            </View>
          </Container>
        )}
      </Formik>
    </ScrollablePage>
  );
}

/* eslint-disable no-shadow */
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Auth, DataStore } from 'aws-amplify';
import type { CognitoUser } from 'amazon-cognito-identity-js';
import { useQueryClient } from 'react-query';
import { Platform } from 'react-native';
import { resendVerifyCode, signUp } from '../apis/authApis';
import { fetchMyProfile } from '../apis/appsyncApis';
import { useIntroContext } from './IntroContext';
import Loading from '../components/Loading';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import { clearAll, getData, InternalStorageItemKey, storeData } from '../utils/internalStorage';
import { readVersionNumber } from '../utils/nativeInfoUtils';
import { trackProfile } from '../utils/mixpanelUtils';
import { updatePinpointDataIfNeeded } from '../utils/pushNotificationsUtils';
import { AppsyncResult } from '../apis/appsyncHelper';

export enum AuthState {
  LOADING = 'loading',
  AUTHENTICATED = 'authenticated',
  NOT_AUTHENTICATED = 'not_authenticated',
  NEED_COMPLETE_REGISTRATION = 'need_complete',
  GUEST_LOGIN = 'guest_login',
}

export enum AccessLevel {
  PUBLIC = 0,
  AUTHENTICATED = 1,
  SUPER_ADMIN = 999,
}

export interface AuthContextValue {
  state: AuthState;
  cognitoUser: CognitoUser | null;
  userAccessLevel: AccessLevel;
  signUp: (phone: string, invitationCode: string) => Promise<void>;
  resendCode: (phone: string) => Promise<void>;
  refreshAuthState: () => void;
  logout: () => Promise<void>;
  logoutAsGuest: () => void;
  loginAsGuest: () => void;
}

const AuthContext = createContext<AuthContextValue | null>(null);

export function useAuth() {
  const value = useContext(AuthContext);
  if (!value) {
    throw new Error('Malformed useAuth!');
  }
  return value;
}

/**
 * This provider is meant to be used in next.js web mode to support
 * the web map / qr code website.
 */
export function GuestOnlyAuthProvider({ children }: { children: ReactNode }) {
  return (
    <AuthContext.Provider
      value={{
        state: AuthState.GUEST_LOGIN,
        cognitoUser: null,
        userAccessLevel: AccessLevel.PUBLIC,
        signUp: () => Promise.resolve(),
        resendCode: () => Promise.resolve(),
        refreshAuthState: () => {},
        logout: () => Promise.resolve(),
        loginAsGuest: () => undefined,
        logoutAsGuest: () => undefined,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function AuthProvider({
  amplifyAuthConfigured,
  children,
}: {
  amplifyAuthConfigured: boolean;
  children: ReactNode;
}) {
  const [cognitoUser, setCognitoUser] = useState<null | CognitoUser>(null);
  const [isNewRegistration, setIsNewRegistration] = useState<boolean>(false);
  const [authState, setAuthState] = useState(AuthState.LOADING);
  const [userAccessLevel, setAccessLevel] = useState<AccessLevel>(AccessLevel.PUBLIC);
  const queryClient = useQueryClient();
  const mp = useMixpanel();
  const { handleShow } = useIntroContext();

  const signUpInner = useCallback(
    async (phone: string, invitationCode: string) => {
      mp?.track('Sign in attempt', { phone, invitationCode });
      mp?.getPeople().increment('Sign in attempts', 1);
      mp?.getPeople().union('Tried phone numbers', [phone]);
      const user = await signUp(phone, invitationCode);
      setCognitoUser(user);
    },
    [mp],
  );

  const resendCode = useCallback(
    async (phone: string) => {
      mp?.track('Resend code', { phonenumber: phone });
      mp?.getPeople()?.increment('Resend code', 1);
      const user = await resendVerifyCode(phone);
      setCognitoUser(user);
    },
    [mp],
  );

  const configureLoggedIn = useCallback(
    async (profile: AppsyncResult<'getMyProfile'>) => {
      await updatePinpointDataIfNeeded();
      /* Initialization done once per app version */
      const storedAppVersion = await getData<string>(InternalStorageItemKey.STORED_APP_VERSION);
      const appVersion = readVersionNumber();
      if (storedAppVersion !== appVersion) {
        /* Update profile info to mixpanel */
        trackProfile(mp, profile);

        storeData<string>(InternalStorageItemKey.STORED_APP_VERSION, appVersion);
      }
      if (isNewRegistration) {
        /* Show intro screens */
        handleShow();
        mp?.track('Registration');
      } else {
        mp?.track('Login');
      }
    },
    [handleShow, isNewRegistration, mp],
  );

  useEffect(() => {
    let mounted = true;
    if (authState === AuthState.LOADING && amplifyAuthConfigured) {
      const configureAuthState = async () => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          if (!mounted) return;
          setCognitoUser(user);
          mp?.identify(user.getUsername());

          const profile = await fetchMyProfile();
          if (!mounted) return;
          mp?.identify(user.getUsername());
          if (!profile.data?.getMyProfile?.firstname) {
            /* This is a new registration */
            mp?.getPeople().setOnce('Registered', new Date().toISOString());
            setIsNewRegistration(true);

            /* Firstname not set, trigger needing to complete registration */
            setAuthState(AuthState.NEED_COMPLETE_REGISTRATION);
            setAccessLevel(AccessLevel.AUTHENTICATED);
          } else if (profile.data?.getMyProfile?.userRole === 'SUPER_ADMIN') {
            setAuthState(AuthState.AUTHENTICATED);
            setAccessLevel(AccessLevel.SUPER_ADMIN);
            await configureLoggedIn(profile);
          } else {
            setAuthState(AuthState.AUTHENTICATED);
            setAccessLevel(AccessLevel.AUTHENTICATED);
            await configureLoggedIn(profile);
          }
        } catch (error) {
          setAccessLevel(AccessLevel.PUBLIC);
          if (Platform.OS === 'web') {
            setAuthState(AuthState.GUEST_LOGIN);
          } else {
            setAuthState(AuthState.NOT_AUTHENTICATED);
            mp?.timeEvent('Login');
            mp?.timeEvent('Registration');
          }
        }
      };
      configureAuthState();
    }
    return () => {
      mounted = false;
    };
  }, [amplifyAuthConfigured, authState, configureLoggedIn, mp]);

  const refreshAuthState = useCallback(async () => {
    setAuthState(AuthState.LOADING);
    setAccessLevel(AccessLevel.PUBLIC);
  }, []);

  const logout = useCallback(async () => {
    await Auth.signOut();
    mp?.track('Logout');
    mp?.getPeople().increment('Logouts', 1);
    mp?.flush();
    mp?.reset();
    /* Clear internal storage */
    clearAll();
    /* Removing this seems to break push notification toke generation */
    DataStore.clear();
    setAuthState(AuthState.NOT_AUTHENTICATED);
    setAccessLevel(AccessLevel.PUBLIC);
    // TODO: Push notification token?
    queryClient.invalidateQueries();
    mp?.timeEvent('Login');
    mp?.timeEvent('Registration');
  }, [mp, queryClient]);

  const loginAsGuest = useCallback(() => {
    mp?.track('Guest login');
    setAuthState(AuthState.GUEST_LOGIN);
    setAccessLevel(AccessLevel.PUBLIC);
    handleShow();
  }, [handleShow, mp]);

  const logoutAsGuest = useCallback(() => {
    mp?.track('Guest logout');
    setAuthState(AuthState.NOT_AUTHENTICATED);
    setAccessLevel(AccessLevel.PUBLIC);
    queryClient.invalidateQueries();
  }, [mp, queryClient]);

  const value = useMemo(
    () => ({
      state: authState,
      userAccessLevel,
      cognitoUser,
      signUp: signUpInner,
      resendCode,
      refreshAuthState,
      logout,
      loginAsGuest,
      logoutAsGuest,
    }),
    [
      authState,
      cognitoUser,
      loginAsGuest,
      logout,
      logoutAsGuest,
      refreshAuthState,
      resendCode,
      signUpInner,
      userAccessLevel,
    ],
  );

  return (
    <AuthContext.Provider value={value}>
      {authState === AuthState.LOADING ? <Loading /> : children}
    </AuthContext.Provider>
  );
}

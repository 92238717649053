/* eslint max-classes-per-file: ["error", 3] */
import MixpanelWeb, { Mixpanel as MixpanelWebType } from 'mixpanel-browser';
import type {
  MixpanelGroup,
  MixpanelType as MixpanelValueType,
  Mixpanel as MixpanelNative,
  People,
  MixpanelProperties,
} from 'mixpanel-react-native';

export const EU_API_HOST = 'https://api-eu.mixpanel.com';
export type MixpanelLibraryType = MixpanelNative;

class MixpanelPeopleImpl implements People {
  lib: MixpanelWebType;

  set(prop: string | MixpanelProperties, to?: MixpanelValueType): void {
    if (typeof prop === 'string') {
      this.lib.people.set(prop, to);
    } else {
      Object.entries(prop).forEach((k) => {
        this.lib.people.set(k[0], k[1]);
      });
    }
  }

  setOnce(prop: string | MixpanelProperties, to?: MixpanelValueType): void {
    if (typeof prop === 'string') {
      this.lib.people.set_once(prop, to);
    } else {
      Object.entries(prop).forEach((k) => {
        this.lib.people.set_once(k[0], k[1]);
      });
    }
  }

  increment(name: string | MixpanelProperties, value?: number): void {
    if (typeof name === 'string') {
      this.lib.people.increment(name, value!);
    } else {
      Object.entries(name).forEach((k) => {
        this.lib.people.increment(k[0], k[1] as number);
      });
    }
  }

  append(name: string, value: MixpanelValueType): void {
    return this.lib.people.append(name, value);
  }

  union(name: string, value: Array<MixpanelValueType>): void {
    return this.lib.people.union(name, value);
  }

  remove(name: string, value: MixpanelValueType): void {
    return this.lib.people.remove(name, value);
  }

  unset(props: string): void {
    return this.lib.people.unset(props);
  }

  trackCharge(amount: number, properties: MixpanelProperties): void {
    return this.lib.people.track_charge(amount, properties);
  }

  clearCharges(): void {
    return this.lib.people.clear_charges();
  }

  deleteUser(): void {
    return this.lib.people.delete_user();
  }

  constructor(mp: MixpanelWebType) {
    this.lib = mp;
  }
}

export class Mixpanel implements MixpanelLibraryType {
  private _token: string;

  private _lib: MixpanelWebType | undefined;

  private _people: People | undefined;

  static async _internal_web_init(
    self: Mixpanel,
    opt_out_tracking_by_default: boolean,
    ignore_dnt: boolean,
  ): Promise<void> {
    return new Promise<void>((resolve, _reject) => {
      MixpanelWeb.init(self._token, {
        opt_out_tracking_by_default,
        ignore_dnt,
        loaded: () => {
          // eslint-disable-next-line no-param-reassign
          self._lib = MixpanelWeb;
          // eslint-disable-next-line no-param-reassign
          self._people = new MixpanelPeopleImpl(self._lib);
          resolve();
        },
      });
    });
  }

  constructor(token: string) {
    this._token = token;
  }

  static async init(apitoken: string, optional: boolean /* DEFAULT_OPT_OUT */): Promise<MixpanelLibraryType> {
    const self = new Mixpanel(apitoken);
    await Mixpanel._internal_web_init(self, optional, true);
    return self;
  }

  async init(optOutTrackingDefault?: boolean): Promise<void> {
    await Mixpanel._internal_web_init(this, optOutTrackingDefault ?? false, true);
  }

  alias(alias: string, distinctId: string): void {
    if (!this._lib) throw Error('Init not called');
    this._lib.alias(alias, distinctId);
  }

  /* eslint-disable-next-line class-methods-use-this */
  clearSuperProperties(): void {
    throw new Error('Method not implemented on web.');
  }

  setServerURL(serverURL: string): void {
    if (!this._lib) throw Error('Init not called');
    this._lib.set_config({ api_host: serverURL });
  }

  setLoggingEnabled(loggingEnabled: boolean): void {
    if (!this._lib) throw Error('Init not called');
    this._lib.set_config({ debug: loggingEnabled });
  }

  setUseIpAddressForGeolocation(useIpAddressForGeolocation: boolean): void {
    if (!this._lib) throw Error('Init not called');
    this._lib.set_config({ ip: useIpAddressForGeolocation });
  }

  /* eslint-disable-next-line class-methods-use-this */
  deleteGroup(groupKey: string, groupID: any): void {
    if (!this._lib) throw Error('Init not called');
    throw new Error('Method not implemented on web.');
  }

  /* eslint-disable-next-line class-methods-use-this */
  getSuperProperties(): Promise<MixpanelProperties> {
    if (!this._lib) throw Error('Init not called');
    throw new Error('Method not implemented on web.');
  }

  /* eslint-disable-next-line class-methods-use-this */
  eventElapsedTime(eventName: string): Promise<number> {
    /* 
    The source code from mixpanel browser does not give any easy
    way to retrive the timer without also removing it.
    */
    throw new Error('Method not implemented on web.');
  }

  /* eslint-disable-next-line class-methods-use-this */
  flush(): void {
    // No need on web
  }

  getDistinctId(): Promise<string> {
    if (!this._lib) throw Error('Init not called');
    return new Promise<string>(this._lib.get_distinct_id);
  }

  hasOptedOutTracking(): Promise<boolean> {
    if (!this._lib) throw Error('Init not called');
    const ret = this._lib.has_opted_out_tracking();
    return new Promise<boolean>((resolve, reject) => {
      resolve(ret);
    });
  }

  identify(distinctId: string): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.identify(distinctId);
  }

  optInTracking(): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.opt_in_tracking();
  }

  optOutTracking(): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.opt_out_tracking();
  }

  trackWithGroups(eventName: string, properties: Record<string, any>, groups: Record<string, any>): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.track_with_groups(eventName, properties, groups);
  }

  getGroup(group_key: string, group_id: string): MixpanelGroup {
    if (!this._lib) throw Error('Init not called');
    const g = this._lib.get_group(group_key, group_id);
    return {
      set: g.set,
      setOnce: g.set_once,
      unset: g.unset,
      remove: g.remove,
      union: g.union,
    };
  }

  addGroup(group_key: string, group_id: string): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.add_group(group_key, group_id);
  }

  removeGroup(group_key: string, group_id: string): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.remove_group(group_key, group_id);
  }

  setGroup(groupKey: string, groupID: string): void {
    if (!this._lib) throw Error('Init not called');
    this._lib.set_group(groupKey, groupID);
  }

  timeEvent(event_name: string): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.time_event(event_name);
  }

  registerSuperProperties(properties: Record<string, any>): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.register(properties);
  }

  registerSuperPropertiesOnce(properties: Record<string, any>): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.register_once(properties);
  }

  unregisterSuperProperty(name: string): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.unregister(name);
  }

  reset(): void {
    if (!this._lib) throw Error('Init not called');
    return this._lib.reset();
  }

  track(eventName: string, properties?: MixpanelProperties) {
    if (!this._lib) throw Error('Init not called');

    // Create a copy of the properties, as mixpanel lib modifies them.
    let propCopy;
    if (properties) {
      try {
        propCopy = JSON.parse(JSON.stringify(properties));
      } catch {
        console.error('Invalid mixpanel properties given to track()', properties);
      }
    }
    this._lib.track(eventName, propCopy);
  }

  getPeople() {
    if (!this._people) throw Error('Init not called');
    return this._people;
  }
}

import React, { useCallback, useEffect } from 'react';
import { PixelRatio, View } from 'react-native';
import Typography from '../../components/Typography';
import LeftRightPicker from '../../components/LeftRightPicker';
import { styles } from './sharedCardConfig';

interface BookingCardDurationSelectorProps {
  pickerText: string;
  pickerMax: number | undefined;
  pickerMin: number | undefined;
  pickerStep: number;
  pickerValue: number;
  onValueChange: (val: number) => any;
  caption?: string;
  mainText?: string;
  details?: string;
}
export default function BookingCardDurationSelector({
  pickerText,
  pickerMax,
  pickerMin,
  pickerStep,
  pickerValue,
  onValueChange,
  caption,
  mainText,
  details,
}: BookingCardDurationSelectorProps) {
  const hasLargeFont = PixelRatio.getFontScale() > 2;
  const changePickerValue = useCallback(
    (changeAmount: number) => {
      let newValue = pickerValue + changeAmount;
      if (pickerMax !== undefined) newValue = Math.min(pickerMax, newValue);
      if (pickerMin !== undefined) newValue = Math.max(pickerMin, newValue);
      if (newValue !== pickerValue) {
        onValueChange(newValue);
      }
    },
    [onValueChange, pickerMax, pickerMin, pickerValue],
  );
  const onPickerLeft = useCallback(() => {
    changePickerValue(-pickerStep);
  }, [changePickerValue, pickerStep]);

  const onPickerRight = useCallback(() => {
    changePickerValue(+pickerStep);
  }, [changePickerValue, pickerStep]);

  useEffect(() => {
    changePickerValue(0);
  }, [changePickerValue]);

  return (
    <View style={[styles.scheduleSection, { flexDirection: hasLargeFont ? 'column' : 'row' }]}>
      <LeftRightPicker
        onLeft={onPickerLeft}
        onRight={onPickerRight}
        text={pickerText}
        leftDisabled={pickerMin !== undefined && pickerValue <= pickerMin}
        rightDisabled={pickerMax !== undefined && pickerValue >= pickerMax}
      />

      <View>
        {caption ? (
          <Typography variant={'body2'} style={{ textAlign: 'right' }}>
            {caption}
          </Typography>
        ) : null}
        {mainText ? (
          <Typography variant={'h5'} style={{ textAlign: 'right' }}>
            {' '}
            {mainText}
          </Typography>
        ) : null}
        {details ? (
          <Typography variant={'body2'} style={{ textAlign: 'right' }}>
            {details}
          </Typography>
        ) : null}
      </View>
    </View>
  );
}

import React, { FC } from 'react';
import { useI18n } from '../../context/I18nContext';
import Images from '../../../assets/images';

import Slide from './Slide';

const Slide4: FC = () => {
  const { I18n } = useI18n();

  return (
    <Slide
      title={I18n.t('intro.step4Title')}
      description={I18n.t('intro.step4Description')}
      image={Images.imageSlide4}
    />
  );
};

export default Slide4;

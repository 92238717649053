import React, { Fragment, useCallback, useMemo } from 'react';
import { List } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useI18n } from '../../context/I18nContext';
import { screenNames } from '../../navigators/screenNames';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { AppDrawerNavigationProp } from '../../components/DrawerMenuContent';
import { useCountryRegionsQuery } from '../../apis/venueApis';
import { AuthState, useAuth } from '../../context/AuthContext';
import { ListItemRow } from '../../components/ListItemRow';

export function RegionItem({
  title,
  regionId,
  translationId,
}: {
  title: string;
  regionId: string;
  translationId: string;
}) {
  const navigation = useNavigation<AppDrawerNavigationProp>();

  const onPress = useCallback(() => {
    navigation.navigate('places', {
      screen: screenNames.RegionVenuesScreen,
      params: {
        regionId,
        regionDefaultName: title,
        regionTranslationId: translationId,
      },
    });
  }, [navigation, regionId, title, translationId]);

  return (
    <ListItemRow
      title={title}
      leftIcon={{ name: 'business-outline', set: 'ion' }}
      onPress={onPress}
      accessibilityRole="button"
      type="navigation"
    />
  );
}

export default function LocationsScreen() {
  const { I18n } = useI18n();
  const { state: authState } = useAuth();

  const countriesQuery = useCountryRegionsQuery(authState === AuthState.AUTHENTICATED);

  const translatedLocations = useMemo(() => {
    return countriesQuery.data?.data?.getCountries
      ?.filter((ci) => ci !== null && ci !== undefined && ci.regions && ci.regions.length > 0)
      ?.map((countryInfo) => {
        return {
          ...countryInfo,
          name: I18n.t(`locations.countries.${countryInfo?.translationId ?? 'none'}`, {
            defaultValue: countryInfo?.name ?? '',
          }),
          regions: countryInfo?.regions
            ?.map((region) => {
              return {
                ...region,
                name: I18n.t(`locations.regions.${region?.translationId ?? 'none'}`, {
                  defaultValue: region?.name ?? '',
                }),
              };
            })
            .sort((a, b) => a.name.localeCompare(b.name, I18n.locale)),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name, I18n.locale));
  }, [I18n, countriesQuery.data?.data?.getCountries]);

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('locations.summaryTitle'),
        description: I18n.t('locations.summaryDescription'),
        icon: 'pin',
      }}
    >
      {/* TODO: Placeholder while loading */}
      {translatedLocations?.map((countryInfo) => (
        <Fragment key={countryInfo.translationId}>
          <List.Subheader accessibilityRole={'header'}>{countryInfo.name /* already translated */}</List.Subheader>
          {countryInfo?.regions?.map((region) => (
            <RegionItem
              key={region.id}
              title={region.name ?? ''}
              regionId={region.id ?? ''}
              translationId={region.translationId ?? 'none'}
            />
          ))}
        </Fragment>
      ))}
    </ScrollablePage>
  );
}

import React, { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import {
  StyleProp,
  TextStyle,
  FlatList,
  ListRenderItemInfo,
  Pressable,
  StyleSheet,
  View,
  ViewProps,
  LayoutChangeEvent,
} from 'react-native';

import { useTheme, Surface, Badge } from 'react-native-paper';
import Typography from '../Typography';

export interface FloorInfo {
  index: number;
  name: string;
}
export interface FloorBadge {
  value: string | number;
  style?: StyleProp<TextStyle>;
}

interface FloorSwitcherProps {
  floors: FloorInfo[];
  bluedotFloor?: number;
  selectedFloor?: number;
  badges?: Record<number, FloorBadge>;
  onClick: (floorIndex: number) => void;
}

const FLOOR_BOX_HEIGHT = 60;
const BADGE_SIZE = 10;
const RADIUS = 15;

const FloorSwitcher: FC<FloorSwitcherProps & ViewProps> = ({
  style,
  floors,
  bluedotFloor,
  onClick,
  selectedFloor,
  badges,
  ...other
}) => {
  const { colors } = useTheme();
  const flatlist = useRef<FlatList<FloorInfo>>(null);
  const [height, setHeight] = useState<number>(400);

  useEffect(() => {
    const listIndex = floors.findIndex((e) => e.index === selectedFloor);
    if (listIndex >= 0) {
      flatlist.current?.scrollToIndex({
        viewOffset: height / 2 - RADIUS - FLOOR_BOX_HEIGHT / 2,
        index: floors.findIndex((e) => e.index === selectedFloor),
      });
    }
  }, [selectedFloor, floors, height]);

  const styles = useMemo(() => {
    return StyleSheet.create({
      bluedotBadge: {
        position: 'absolute',
        top: 12,
        left: 30 / 2 - BADGE_SIZE / 2,
        borderColor: colors.secondary,
        borderWidth: 0.25,
        width: BADGE_SIZE,
        height: BADGE_SIZE,
        minWidth: BADGE_SIZE,
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: colors.primary,
      },
      clickableFloorBox: {
        height: FLOOR_BOX_HEIGHT,
        width: 50,
      },
      flatList: {
        elevation: 6,
        marginTop: RADIUS,
        marginBottom: RADIUS,
      },
      container: {
        width: 50,
        maxHeight: 400,
        flexShrink: 1,
      },
      background: {
        borderRadius: RADIUS,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 10,
        right: 10,
        elevation: 4,
        backgroundColor: colors.secondaryContainer,
      },
      unselectedFloorNumberArea: {
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginHorizontal: 10,
        height: FLOOR_BOX_HEIGHT,
      },
      selectedFloorNumberArea: {
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginHorizontal: 10,
        height: FLOOR_BOX_HEIGHT,
        backgroundColor: colors.surface,
      },
      floorText: { textAlign: 'center', width: '100%' },
      resultBadge: {
        /* Don't add styling here, it comes from SmartMap */
        position: 'absolute',
        top: 0,
        right: 0,
        /* Center align regardless of font size */
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    });
  }, [colors]);

  const renderFloorItem = useCallback(
    (elem: ListRenderItemInfo<FloorInfo>) => {
      const isSelected = elem.item.index === selectedFloor;
      const floorBadge = badges && badges[elem.item.index] ? badges[elem.item.index] : undefined;
      return (
        <Pressable
          style={styles.clickableFloorBox}
          accessibilityRole={'button'}
          accessible
          onPress={() => onClick(elem.item.index)}
        >
          <View style={isSelected ? styles.selectedFloorNumberArea : styles.unselectedFloorNumberArea}>
            <Typography
              numberOfLines={1}
              ellipsizeMode={'clip'}
              adjustsFontSizeToFit={false}
              variant="button"
              color={isSelected ? colors.secondary : colors.onSurface}
              style={styles.floorText}
            >
              {elem.item.name}
            </Typography>

            {/* Bluedot badge */}
            <Badge style={styles.bluedotBadge} visible={bluedotFloor === elem.item.index} />
          </View>

          {/* Result badge */}
          <Badge style={[styles.resultBadge, floorBadge?.style]} visible={!!floorBadge}>
            {floorBadge?.value ?? ''}
          </Badge>
        </Pressable>
      );
    },
    [colors, styles, bluedotFloor, onClick, badges, selectedFloor],
  );

  const handleLayout = useCallback((e: LayoutChangeEvent) => {
    setHeight(e.nativeEvent.layout.height);
  }, []);

  const keyExtractor = useCallback((item: FloorInfo) => '' + item.index, []);

  const extraData = useMemo(() => {
    return [selectedFloor, badges];
  }, [selectedFloor, badges]);

  if (!floors.length) return null;

  return (
    <View onLayout={handleLayout} style={[styles.container, style]} {...other}>
      {/* Background */}
      <Surface style={styles.background}>
        <View />
      </Surface>

      {/* Actual list */}

      <FlatList<FloorInfo>
        ref={flatlist}
        style={styles.flatList}
        getItemLayout={(_data, index) => {
          return {
            length: FLOOR_BOX_HEIGHT,
            offset: FLOOR_BOX_HEIGHT * index,
            index,
          };
        }}
        snapToAlignment={'center'}
        data={floors}
        extraData={extraData}
        renderItem={renderFloorItem}
        keyExtractor={keyExtractor}
        horizontal={false}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

export default FloorSwitcher;

import React, { useState } from 'react';
import { Checkbox, Dialog, Modal, Portal, Surface } from 'react-native-paper';
import { View } from 'react-native';
import { useI18n } from '../../context/I18nContext';
import { BlurOverlay } from '../BlurOverlay';
import SummaryHeader from '../SummaryHeader';
import { ModalButtonWrapper } from './TextModal';
import { InternalStorageItemKey, storeData } from '../../utils/internalStorage';

type NotificationPermissionDialogProps = {
  onDismiss: () => void;
  onActionPress: () => void;
  isVisible: boolean;
};

export function NotificationPermissionDialog(props: NotificationPermissionDialogProps) {
  const { I18n } = useI18n();
  const { onDismiss, onActionPress, isVisible } = props;
  const [dontAskAgain, setDontAskAgain] = useState(false);

  return (
    <Portal>
      {isVisible && (
        <BlurOverlay onPress={onDismiss}>
          <Modal visible={isVisible} dismissable={false} style={{ marginHorizontal: 16 }}>
            <Surface>
              <View style={{ overflow: 'hidden' }}>
                <SummaryHeader
                  title={I18n.t('general.grantNotificationPermission.summaryTitle')}
                  description={I18n.t('general.grantNotificationPermission.description')}
                  style={{ paddingHorizontal: 16 }}
                  icon={'bell'}
                />
                <Checkbox.Item
                  mode="android"
                  style={{
                    flexDirection: 'row-reverse',
                  }}
                  labelStyle={{
                    fontSize: 14,
                  }}
                  label={I18n.t('general.grantNotificationPermission.dontAskAgain')}
                  status={dontAskAgain ? 'checked' : 'unchecked'}
                  onPress={() => {
                    storeData(InternalStorageItemKey.DONT_SHOW_NOTIFICATION_DIALOG, dontAskAgain);
                    setDontAskAgain((prev) => !prev);
                  }}
                />
                <Dialog.Actions>
                  <ModalButtonWrapper
                    key={'no'}
                    title={I18n.t('general.grantNotificationPermission.no')}
                    onPress={onDismiss}
                  />
                  <ModalButtonWrapper
                    key={'yes'}
                    title={I18n.t('general.grantNotificationPermission.yes')}
                    onPress={() => {
                      // Don't show prompt again
                      storeData(InternalStorageItemKey.DONT_SHOW_NOTIFICATION_DIALOG, true);
                      onActionPress();
                    }}
                  />
                </Dialog.Actions>
              </View>
            </Surface>
          </Modal>
        </BlurOverlay>
      )}
    </Portal>
  );
}

import { GraphQLAPIClass } from '@aws-amplify/api-graphql';
import rootAwsExports from '../../aws-exports';
import podExports from '../../pod-exports.json';

const publicGraphQLApiOptions = {
  aws_appsync_graphqlEndpoint: rootAwsExports.aws_appsync_graphqlEndpoint,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: podExports.publicGraphQlAPIKey,
};

export const PublicGraphQLAPI = new GraphQLAPIClass(publicGraphQLApiOptions);
PublicGraphQLAPI.configure(publicGraphQLApiOptions);

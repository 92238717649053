import React, { useCallback } from 'react';
import { useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useI18n } from '../../context/I18nContext';
import { AppDrawerNavigationProp } from '../../components/DrawerMenuContent';
import { Reservation } from '../../types/appsync-types';
import { ListItemRow } from '../../components/ListItemRow';

export default function ReservationListItem({ reservation }: { reservation: Reservation }) {
  const { colors } = useTheme();

  const { formatDate, formatDateRange, formatDuration, formatCurrency } = useI18n();
  const navigation = useNavigation<AppDrawerNavigationProp>();

  const onPress = useCallback(() => {
    navigation.navigate({
      name: 'admin',
      params: {
        screen: 'AdminReservationDetails',
        params: {
          reservationId: reservation.id,
        },
      },
      merge: true, // This makes the parent screen, i.e. mapview get these new params
    });
  }, [navigation, reservation.id]);
  const duration = formatDuration(Date.parse(reservation.to!) - Date.parse(reservation.from!), 'milliseconds', 'short');
  const moneyUsed = formatCurrency((reservation.paymentAmountInCents ?? 0) / 100, reservation.currency);
  const creditUsed = formatCurrency((reservation.credit ?? 0) / 100, reservation.currency);
  const friendlyFrom = formatDate(new Date(reservation.from!), 'time', reservation.building?.timezone);
  const timeRange = formatDateRange(
    new Date(reservation.from!),
    new Date(reservation.to!),
    'time',
    reservation.building?.timezone,
  );

  return (
    <ListItemRow
      title={`${friendlyFrom}, ${duration}: ${moneyUsed} + ${creditUsed} credits`}
      description={`${reservation.building?.title} ${reservation.spaceId} ${timeRange} (${reservation.status})`}
      onPress={onPress}
      titleStyle={{ color: colors.secondary }}
      type="navigation"
    />
  );
}

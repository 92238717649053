import React, { PropsWithChildren, ReactNode, useCallback } from 'react';
import { BlurView } from 'expo-blur';
import { GestureResponderEvent, Platform, Pressable, StyleSheet, View, ViewStyle } from 'react-native';

export type BlurOverlayProps = PropsWithChildren<{
  backgroundStyle?: ViewStyle;
  onPress?: () => any;
}>;

export function BlurOverlay({ children, backgroundStyle, onPress }: BlurOverlayProps) {
  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      if (event.target === event.currentTarget && onPress) {
        onPress();
      }
    },
    [onPress],
  );

  return (
    <BlurWrapper>
      <Pressable style={[styles.background, backgroundStyle]} onPress={handlePress}>
        {children}
      </Pressable>
    </BlurWrapper>
  );
}

function BlurWrapper({ children }: { children: ReactNode }): JSX.Element {
  // BlurView doesn't work on Android at all. It is not even recommended to use blur on Android
  if (Platform.OS === 'android') {
    return <View style={[styles.blur, { backgroundColor: 'rgba(0, 0, 0, 0.8)' }]}>{children}</View>;
  }
  return (
    <BlurView tint="dark" style={styles.blur}>
      {children}
    </BlurView>
  );
}

const styles = StyleSheet.create({
  blur: { flex: 1 },
  background: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

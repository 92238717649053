import React, { useState } from 'react';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { View } from 'react-native';
import { Checkbox } from 'react-native-paper';
import { AppDrawerScreenProp } from '../components/DrawerMenuContent';
import { useI18n } from '../context/I18nContext';
import { MapStackRouteParams } from '../navigators/MapStackNavigator';
import ConfirmationRow, { ModalRowStyle } from '../payment/components/ConfirmationRow';
import { Pod } from '../types/misc.types';
import { NavigationDialog } from './CommonDialogs';
import { InternalStorageItemKey, storeData } from '../utils/internalStorage';
import Spacer from '../components/Spacer';
import Typography from '../components/Typography';

export type SecurityDepositScreenRouteParams = {
  space: Pod;
  amount: number;
  currency: string;
  showCheckbox: boolean;
  onOk: 'GO_BACK' | 'REPLACE_WITH_CONFIRM';
};

type Props = CompositeScreenProps<StackScreenProps<MapStackRouteParams, 'SecurityDepositDialog'>, AppDrawerScreenProp>;

export function SecurityDepositDialog({ navigation, route }: Props) {
  const { I18n, formatCurrency } = useI18n();
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);
  const formattedAmount = formatCurrency(route.params.amount, route.params.currency);

  return (
    <NavigationDialog
      title={I18n.t('depositModal.title', { amount: formattedAmount })}
      description={I18n.t('depositModal.description', { amount: formattedAmount })}
      iconName={'exclamation'}
      onDismiss={() => navigation.goBack()}
      actions={[
        {
          title: I18n.t('general.ok'),
          onPress: () => {
            if (route.params.onOk === 'REPLACE_WITH_CONFIRM') {
              navigation.replace('ConfirmReservationDialog', {
                space: route.params.space,
              });
            } else {
              navigation.goBack();
            }
          },
        },
      ]}
    >
      <ConfirmationRow title={I18n.t('depositModal.noOverstaying')} iconName={'clock'} />
      <ConfirmationRow title={I18n.t('depositModal.noTrash')} iconName={'trash'} />
      <ConfirmationRow title={I18n.t('depositModal.noDamage')} iconName={'heart'} />

      <View style={ModalRowStyle}>
        <Typography variant={'body2'}>{I18n.t('depositModal.warningText')}</Typography>
      </View>
      <Spacer type="section" />
      {route.params.showCheckbox && (
        <Checkbox.Item
          mode="android"
          style={{
            flexDirection: 'row-reverse',
          }}
          labelStyle={{
            fontSize: 14,
          }}
          label={I18n.t('general.dontShowAgain')}
          status={dontShowAgain ? 'checked' : 'unchecked'}
          onPress={() => {
            storeData(InternalStorageItemKey.DONT_SHOW_DEPOSIT_DIALOG, !dontShowAgain);
            setDontShowAgain((prev) => !prev);
          }}
        />
      )}
    </NavigationDialog>
  );
}
